import styled from "@emotion/styled";
import { Grid } from "@mui/material";

export const Container = styled(Grid)({
  height: "100vh",
});
export const NavBarContainer = styled(Grid)({
  height: "9.9074%",
  background: "#2D2D2D",
  boxShadow: "0px 3.25123px 69px rgba(17, 17, 17, 0.35)",
});
export const TemplateCreationContainer = styled(Grid)({
  height: "90.09%",
  backgroundColor: "#202020",
});
