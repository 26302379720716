import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MailIcon from "@mui/icons-material/Mail";
import sideBarCloseIcon from "../../../../assets/icons/sideBarCloseIcon.png";
import { propertiesIcon } from "../../../../constants/icons";
import IconWrapper from "../../../shared/IconWrapper";
import {
  CustomListItemButton,
  CustomListItemIcon,
  ListItemLabel,
  NoProperties,
  PropertiesListConatiner,
  propertySideBarListStyle,
  ScreenName,
  SideBarCloseButton,
} from "../../../../styles/templateCreation/screenMenuPanel";
import { ArrowIcon } from "../../../../styles/templateCreationTools";
import { PREVIEW_ACTION_CLASSES } from "../../../../constants/renderUiMatchActionClass";

export default function PropertiesSideBar(props) {
  const {
    setOpenProperties,
    openProperties,
    screenName,
    selectedScreenPageSections = [],
    handleProperties,
    selectedPropertyKey,
  } = props;

  return (
    <PropertiesListConatiner role="presentation" isOpen={openProperties}>
      <ScreenName>{screenName || ""}</ScreenName>
      <List style={propertySideBarListStyle}>
        {selectedScreenPageSections?.length ? (
          selectedScreenPageSections
            ?.filter(
              (item) =>
                item?.actionClass !== PREVIEW_ACTION_CLASSES.HIDDEN_CONTAINER
            )
            ?.map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => handleProperties(item)}
              >
                <CustomListItemButton>
                  <IconWrapper>
                    <CustomListItemIcon
                      isSelected={item?.key === selectedPropertyKey}
                    >
                      {propertiesIcon?.[item?.icon] || <MailIcon />}
                    </CustomListItemIcon>
                  </IconWrapper>

                  <ListItemLabel isSelected={item?.key === selectedPropertyKey}>
                    {item?.name}
                  </ListItemLabel>
                </CustomListItemButton>
              </ListItem>
            ))
        ) : (
          <NoProperties isOpen={openProperties}>No Properties</NoProperties>
        )}
      </List>

      <SideBarCloseButton
        onClick={() => setOpenProperties(false)}
        isOpen={openProperties}
      >
        <ArrowIcon src={sideBarCloseIcon} alt="closeIcon" />
      </SideBarCloseButton>
    </PropertiesListConatiner>
  );
}
