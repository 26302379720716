import React from "react";
import DashBoardNavBar from "../../shared/NavBar";
import { TemplatesContainer } from "../Templates";
import { TemplateContainer, NavBarContainer } from "../styled/TemplateDashBoard";
import { Footer } from "../../shared/Footer";

const TemplateDashBoard = () => (
  <>
    <TemplateContainer>
      <NavBarContainer item xs={12}>
        <DashBoardNavBar />
      </NavBarContainer>
      <TemplatesContainer />
      <Footer/>
    </TemplateContainer>
  </>
);

export default TemplateDashBoard;
