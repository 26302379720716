import { QRCodeCanvas } from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import {
  downloadIcon,
  editIcon,
  informationIcon,
  markIcon,
} from "../../../../constants/icons";
import {
  PreviewQRCodeContainer,
  PreviewTitleLabel,
  PreviewWrapper,
  QRCodeDownloadAllFormat,
  QRDownloadIcon,
  QRDownloadLabel,
} from "../../../../styles/previewAndPublish/previewTabStyle";
import {
  ApprovalButtonContainer,
  ApprovalDescription,
  ApprovalModalContainer,
  PublishAccountContainer,
  PublishAccountDetalis,
  PublishApprovedButton,
  PublishButtonContainer,
  publishDoitLaterStyle,
  PublishGoBackButton,
  PublishImageView,
  publishMetaImageUploader,
  publishSendButtonStyle,
  PublishTextBoxContainer,
  PublishTextBoxLabel,
  PublishUploadContainer,
  PublishUploadInfoIcon,
  publishUploadWrapperStyle,
  UploadImageLabel,
} from "../../../../styles/previewAndPublish/publishTabStyle";
import { ImageView } from "../../../../styles/sharedComponents";
import { useNavigate } from "react-router-dom";
import {
  getByIdApiServices,
  multipartPostApiServices,
  putApiWithIdServices,
} from "../../../../api/api";
import { apiRoutes } from "../../../../api/apiPath";
import {
  projectStatusPublished,
  PUBLISH_STATUS_LIST,
} from "../../../../constants/constant";
import { Box } from "@mui/system";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../utils/getDimensionalRatio";
import CommonFormModal from "../../../shared/CommonFormModal";
import CustomImageUpload from "../../../shared/CustomImageUpload";
import { appendFormData } from "../../../../utils/formDataConverter";
import jsPDF from "jspdf";
import queryString from "query-string";
import { ROUTE_PATHS } from "../../../../routes/routePaths";
import InformationBlob from "../../../shared/InformationBlob";
import InformationBlobContent from "../../../shared/InformationBlobContent";
import { errorMessage, successMessage } from "../../../../redux/constant";
import { useDispatch } from "react-redux";

const textBoxList = (userCredentials, projectName) => {
  const accountName = userCredentials?.tenant?.name;

  return [
    { label: "Account Name", value: accountName },
    { label: "Project Name", value: projectName },
  ];
};

export default function PublishTab(props) {
  const {
    handleBackToEdit = () => {},
    projectUrl,
    userCredentials,
    projectId,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const qrCodeRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const projectName = urlParams.get("projectName");
  const templateName = urlParams.get("templateName");

  const [projectStatus, setProjectStatus] = useState("");
  const [openApprovedModal, setOpenApprovedModal] = useState(false);
  const [metaImage, setMetaImage] = useState("");

  const getProjectDetails = async () => {
    try {
      const { data } = await getByIdApiServices(
        apiRoutes.getProject,
        projectId
      );
      setProjectStatus(data?.status);
      setMetaImage(data?.previewUrl);
      const params = {
        projectStatus: data?.status,
        templateName: templateName,
        projectName: projectName,
        projectId: projectId,
      };
      const query = queryString.stringify(params, { arrayFormat: "bracket" });
      navigate(`${ROUTE_PATHS.templateCreation}?${query}`);
    } catch (err) {}
  };

  const handlePublishProject = async () => {
    try {
      const statusData = {
        status: PUBLISH_STATUS_LIST.Pending,
      };
      const { data } = await putApiWithIdServices(
        apiRoutes.publishStatus,
        projectId,
        statusData
      );
      getProjectDetails();
      setOpenApprovedModal(false);
      dispatch(
        successMessage(data?.message || "Project has been sent to approval")
      );
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  const handleApprovedButton = () => {
    if (metaImage) setOpenApprovedModal(true);
    else dispatch(errorMessage("Please upload meta image"));
  };

  const handleStatusText = () => {
    const projectStatusPublished = [
      PUBLISH_STATUS_LIST.Active,
      PUBLISH_STATUS_LIST.Completed,
      PUBLISH_STATUS_LIST.Published,
    ];
    if (projectStatus === PUBLISH_STATUS_LIST.Pending)
      return "Approval Pending";
    else if (projectStatus === PUBLISH_STATUS_LIST.Rejected)
      return "Not Approved";
    else if (projectStatus === PUBLISH_STATUS_LIST.Draft)
      return "Get it Approved";
    else if (projectStatusPublished?.includes(projectStatus))
      return "Approved";
    else return "Get it Approved";
  };

  const handleStatusBasedStyle = () => {
    if (projectStatus === PUBLISH_STATUS_LIST.Pending)
      return {
        pointerEvents: "none",
        background:
          "linear-gradient(90deg, #B7C700 -53.74%, #EF8244 28.22%, #FFA629 82.46%, #C4C800 139.12%)",
      };
    else if (projectStatus === PUBLISH_STATUS_LIST.Rejected)
      return {
        pointerEvents: "none",
        background:
          "linear-gradient(90.02deg, #EF4444 -0.1%, #EF6D44 98.2%, #EF4444 98.21%)",
      };
    else if (projectStatusPublished?.includes(projectStatus))
      return {
        pointerEvents: "none",
        justifyContent: "space-evenly",
        background:
          "linear-gradient(90deg, #004003 -53.74%, #05BC37 39.27%, #0FBD5F 139.12%)",
      };
    else return {};
  };

  const handlePrejectApprovedImageUpload = async (file) => {
    try {
      const imageData = {
        pid: projectId,
        preview: file,
      };
      const { data } = await multipartPostApiServices(
        apiRoutes.publishMetaImageUpload,
        appendFormData(imageData)
      );
      dispatch(
        successMessage(data?.message || "Image uploaded successfully ...")
      );
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  const handleMetaImageChange = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      setMetaImage(reader?.result);
    };
    reader.readAsDataURL(file);
    handlePrejectApprovedImageUpload(file);
  };

  const handleDownloadQRcode = () => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    const canvas = qrCodeRef.current?.querySelector("canvas");
    const dataURL = canvas?.toDataURL("image/png");
    const pngLink = document.createElement("a");
    const espLink = document.createElement("a");
    pngLink.href = dataURL;
    espLink.href = dataURL;
    pngLink.download = "qrcode.png";
    espLink.download = "qrcode.esp";
    pngLink.click();
    espLink.click();
    // Add the image to the PDF document
    pdf?.addImage(dataURL, "PNG", 0, 0, canvas?.width, canvas?.height);
    // Save the PDF file
    pdf?.save("qrcode.pdf");
  };

  const infoForMetaImage = () => {
    return (
      <InformationBlobContent
        fileSize={"250 KB"}
        fileFormats={`image/jpeg, image/png`}
      />
    );
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  return (
    <>
      <PreviewWrapper>
        {projectStatusPublished?.includes(projectStatus) ? (
          <></>
        ) : (
          <PublishAccountContainer>
            <PublishAccountDetalis>
              {textBoxList(userCredentials, projectName)?.map(
                (textBox, index) => {
                  return (
                    <PublishTextBoxContainer key={index}>
                      <PublishTextBoxLabel>
                        {textBox?.label} :
                      </PublishTextBoxLabel>
                      <PublishTextBoxLabel>
                        {textBox?.value || "--"}
                      </PublishTextBoxLabel>
                    </PublishTextBoxContainer>
                  );
                }
              )}
            </PublishAccountDetalis>

            <PublishImageView>
              <PublishUploadContainer>
                <Box sx={publishUploadWrapperStyle}>
                  {metaImage ? (
                    <ImageView src={metaImage || ""} alt="meta Image" />
                  ) : (
                    <CustomImageUpload
                      accept={"image/*"}
                      name="uploadImage"
                      onChange={handleMetaImageChange}
                      uploadField={true}
                      style={publishMetaImageUploader}
                    />
                  )}
                </Box>

                {metaImage ? (
                  <></>
                ) : (
                  <InformationBlob content={infoForMetaImage()}>
                    <PublishUploadInfoIcon>
                      {informationIcon}
                    </PublishUploadInfoIcon>
                  </InformationBlob>
                )}
              </PublishUploadContainer>

              {metaImage ? (
                <></>
              ) : (
                <UploadImageLabel>Upload Meta image</UploadImageLabel>
              )}
            </PublishImageView>
          </PublishAccountContainer>
        )}

        {projectStatusPublished?.includes(projectStatus) ? (
          <Box>
            <PreviewTitleLabel>Here is your final QR Code </PreviewTitleLabel>

            <Box sx={{ display: "flex" }} ref={qrCodeRef}>
              <PreviewQRCodeContainer>
                <QRCodeCanvas
                style={{width: '120%'}}
                  value={projectUrl || "Project Link"}
                />
              </PreviewQRCodeContainer>
              <QRCodeDownloadAllFormat onClick={handleDownloadQRcode}>
                <QRDownloadIcon>{downloadIcon}</QRDownloadIcon>
                <QRDownloadLabel>
                  Download QR Code in all the formats
                </QRDownloadLabel>
              </QRCodeDownloadAllFormat>
            </Box>
          </Box>
        ) : (
          <></>
        )}

        <PublishButtonContainer>
          <PublishApprovedButton
            onClick={handleApprovedButton}
            style={handleStatusBasedStyle()}
          >
            {handleStatusText()}
            {projectStatusPublished?.includes(projectStatus) ? (
              <Box style={{marginLeft: '2rem'}}>{markIcon}</Box>
            ) : (
              <></>
            )}
          </PublishApprovedButton>

          {projectStatus === PUBLISH_STATUS_LIST.Draft || !projectStatus ? (
            <PublishGoBackButton onClick={handleBackToEdit}>
              Go back to editing
              {editIcon}
            </PublishGoBackButton>
          ) : (
            <></>
          )}
          {projectStatus === PUBLISH_STATUS_LIST.Rejected ? (
            <PublishGoBackButton style={{ width: "198px" }}>
              Know more
            </PublishGoBackButton>
          ) : (
            <></>
          )}
        </PublishButtonContainer>
      </PreviewWrapper>

      <CommonFormModal
        open={openApprovedModal}
        modalBodyContainerStyle={{
          width: getWidthRatio(1129),
          height: getHeightRatio(389),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(1129),
          height: getHeightRatio(389),
        }}
        iconContainerHeight={{
          height: getHeightRatio(389),
        }}
        hanldeClose={() => setOpenApprovedModal(false)}
        backIcon={false}
        cancelIcon={true}
      >
        <ApprovalModalContainer>
          <ApprovalDescription>
            Are you sure, you will not be able to edit the project once you send
            it for an approval.
          </ApprovalDescription>

          <ApprovalButtonContainer>
            <PublishGoBackButton
              style={publishDoitLaterStyle}
              onClick={() => setOpenApprovedModal(false)}
            >
              Do it Later
            </PublishGoBackButton>

            <PublishApprovedButton
              onClick={handlePublishProject}
              style={publishSendButtonStyle}
            >
              Send
            </PublishApprovedButton>
          </ApprovalButtonContainer>
        </ApprovalModalContainer>
      </CommonFormModal>
    </>
  );
}
