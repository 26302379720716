import React from "react";
import {
  EditPanelScreenList,
  ScreenIcon,
  ScreenLabel,
} from "../../../../styles/shared/screenPanelScreenNameWrapper";
import IconWrapper from "../../../shared/IconWrapper";
import { MaterialUISwitch } from "../../../../styles/templateCreation/screenMenuPanel.js";

export default function ScreenPanelScreenNameWrapper(props) {
  const {
    onClick,
    icon,
    label,
    style,
    switchButton,
    openProperties,
    isSelected,
  } = props;

  return (
    <EditPanelScreenList
      onClick={onClick}
      isSelected={isSelected}
      isOpen={openProperties}
      style={style}
    >
      {switchButton ? (
        <MaterialUISwitch defaultChecked />
      ) : (
        <IconWrapper>
          <ScreenIcon isSelected={isSelected}>{icon}</ScreenIcon>
        </IconWrapper>
      )}

      <ScreenLabel isSelected={isSelected}>{label}</ScreenLabel>
    </EditPanelScreenList>
  );
}
