import React, { useEffect } from "react";

export function ModelImageRenderer(props) {
  const {
    element,
    getParentSizes,
    cameraControls = true,
    configStyle = true,
  } = props;
  // useEffect(() => {
  //   const modelViewer = document.getElementById(element?.name)
  //   if(modelViewer){
  //     modelViewer.addEventListener('load',()=>{
  //       const names = modelViewer.availableAnimations;
  //       console.log('modelViewer',names);
  //     });
  //   }
  // }, []);
  const assignStyle =
    element?.size && configStyle ? { ...element?.size } : { ...getParentSizes };
  return (
    <model-viewer
      // id={element?.name}
      loading="eager"
      style={{ ...(assignStyle || {}), zIndex: 9 }}
      src={element?.assetUrl}
      alt={element?.name || "empty"}
      camera-controls={cameraControls}
      disable-tap={true}
    />
  );
}
