import { CircularProgress } from "@mui/material";
import React from "react";
import { LoaderContainer } from "../../styles/loader";

export default function Loader() {
  return (
    <LoaderContainer>
      <CircularProgress color="secondary" />
    </LoaderContainer>
  );
}
