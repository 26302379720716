import { useEffect, useState } from "react";
import { StyledTextarea } from "../../styles/shared/customTextArea";

export default function CustomTextArea(props) {
  const { handleChangeSubPropertyDatas, selectedSubProperty } = props;

  const [inputData, setInputData] = useState("");

  const handleChange = (e) => {
    const setValue = { ...selectedSubProperty?.value, value: e.target?.value };
    handleChangeSubPropertyDatas(setValue);
    setInputData(e.target?.value);
  };

  useEffect(() => {
    setInputData(selectedSubProperty?.value?.value || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubProperty?.key, selectedSubProperty?.value?.type]);

  return (
    <StyledTextarea
      minRows={3}
      value={inputData}
      onChange={handleChange}
      placeholder="Enter the text"
    />
  );
}
