import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { centerStyle } from "../commonStyles";

export const TimerHeading = styled("p")({
  textAlign: "left",
  fontStyle: "normal",
  fontWeight: "400",
  color: "#49C4D8",
  fontSize: "clamp(0.8rem, 1vw, 2rem)",
  letterSpacing: "-0.011em",
  textTransform: "capitalize",
  margin: "5px 0px",
});
export const HighLighterButton = styled("p")(({ isSelected, isTimer }) => {
  const x2 = window.innerWidth / 1080;
  const exactWidth = 75 * x2;

  return {
    height: `36px`,
    width: `${exactWidth}px`,
    background: isSelected ? (isTimer ? "#1B1B1B" : "#49C4D8") : "#4C4C4C",
    color: isSelected ? (isTimer ? "#FFFFFF" : "#2E2E2E") : "#FFFFFF",
    borderRadius: "10px",
    fontSize: "clamp(0.6rem, 1vh, 2rem)",
    ...centerStyle,
    marginRight: "20px",
    padding: "0px 10px",
    cursor: "pointer",
  };
});
export const TimerButtonWrapper = styled(Box)(() => {
  return {
    display: "flex",
    alignItems: "center",
  };
});
