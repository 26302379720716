import { Status } from "./Status";

export const StatusConfigMap = {
  All: {
    value: "All",
    label: "All",
  },
  myProjects: {
    value: "myProjects",
    label: "My Projects",
  },
  [Status.Draft]: {
    value: Status.Draft,
    label: "Draft",
  },
  [Status.Pending]: {
    value: Status.Pending,
    label: "Approval Pending",
  },
  [Status.Active]: {
    value: Status.Active,
    label: "Active Projects",
  },
  [Status.Completed]: {
    value: Status.Completed,
    label: "Completed Projects",
  },
};
