import React from "react";
import { useLocation } from "react-router-dom";
import {
  NavContainer,
  NavLogoContainer,
} from "../../../../../styles/navBar";
import IconWrapper from "../../../../shared/IconWrapper";
import XtendrLogo from "../../../../../assets/icons/HomeXtendR_LogoColours.png";
import {
  getWidthRatio,
  getHeightRatio,
} from "../../../../../utils/getDimensionalRatio";

const DashBoardNavBar = ({ onSubmit }) => {
  const routeStateData = useLocation()?.state;

  const projectName = routeStateData?.projectName;
  const templateName = routeStateData?.templateName;
  return (
    <NavContainer width={"100%"} m={"auto"}>
      <NavLogoContainer sx={{ mr: "auto" }}>
        <IconWrapper>
          <img
            src={XtendrLogo}
            alt="logo"
            style={{ width: getWidthRatio(165), height: getHeightRatio(49) }}
          />
        </IconWrapper>
      </NavLogoContainer>
      {/* <CustomButton
        item
        label={"Get started"}
        onClick={() => alert(projectName)}
        variant="filled"
        style={{ ...DashBoardButton(getDomHeight) }}
        disableElevation={true}
      /> */}
    </NavContainer>
  );
};

export default DashBoardNavBar;
