import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postApiServices } from "../../../../api/api";
import { apiRoutes } from "../../../../api/apiPath";
import { errorMessage, successMessage } from "../../../../redux/constant";
import { ROUTE_PATHS } from "../../../../routes/routePaths";
import { centerStyle } from "../../../../styles/commonStyles";
import {
  LoginButton,
  LoginContainer,
  LoginHeading,
  LoginStyledInput,
} from "../../../../styles/sharedComponents";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../utils/getDimensionalRatio";
import CommonFormModal from "../../../shared/CommonFormModal";
import IconWrapper from "../../../shared/IconWrapper";

export default function LoginPage(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const resetToken = query.get("token");
  const [credentials, updatedCredentials] = useState({
    password: null,
    confirmPassword: null,
  });

  useEffect(() => {
    console.log('resetToken', resetToken)
    if (!resetToken) return navigate(ROUTE_PATHS.login); 
  }, [resetToken]);

  const handleCredentialsChange = ({ target: { name, value } }) =>
    updatedCredentials({
      ...credentials,
      [name]: value,
    });

    const handleReset = async () => {
      try {
        const { confirmPassword, password } = credentials;
        if (!confirmPassword || !password)
          return dispatch(errorMessage("Enter Valid Fields"));
        if (confirmPassword !== password)
          return dispatch(errorMessage("Password Dos'nt seem to match"));
        
        await postApiServices(apiRoutes.resetPass, {
          password,
          token: resetToken,
        });
        dispatch(successMessage("Password is reset"));
        navigate(ROUTE_PATHS.landingPage);
      } catch (err) {
      dispatch(errorMessage("Error while PassWord Reset"));
      }
    };
  const [showPassword, updatedShowPassword] = useState(false);
  const renderPassWordContainer = ({
    handleChange,
    name,
    placeholder,
    value,
  }) => {
    return (
      <div style={{ position: "relative" }}>
        <LoginStyledInput
          name={name}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
        {showPassword ? (
          <IconWrapper>
            <VisibilityIcon
              onClick={() => updatedShowPassword(!showPassword)}
              sx={{
                width: "20px",
                position: "absolute",
                right: "7%",
                height: "20px",
                bottom: "35%",
              }}
            />
          </IconWrapper>
        ) : (
          <IconWrapper>
            <VisibilityOffIcon
              onClick={() => updatedShowPassword(!showPassword)}
              sx={{
                width: "20px",
                position: "absolute",
                right: "7%",
                height: "20px",
                bottom: "35%",
              }}
            />
          </IconWrapper>
        )}
      </div>
    );
  };
  return (
    <CommonFormModal
      open
      backIcon={false}
      cancelIcon={true}
      modalBodyContainerStyle={{
        width: getWidthRatio(1500),
        height: getHeightRatio(752),
      }}
      modalChildrenContainerStyle={{
        width: getWidthRatio(1500),
        height: getHeightRatio(752),
      }}
      iconContainerHeight={{
        height: getHeightRatio(752),
      }}
    >
      <LoginContainer>
        <Box sx={{ height: "87%" }}>
          <LoginHeading>Reset Your Password</LoginHeading>

          <Box
            sx={{ ...centerStyle, flexDirection: "column", margin: "40px 0" }}
          >
            {[
              {
                handleChange: handleCredentialsChange,
                name: "password",
                placeholder: "password",
                value: credentials["password"],
              },
              {
                handleChange: handleCredentialsChange,
                name: "confirmPassword",
                placeholder: "confirm password",
                value: credentials["confirmPassword"],
              },
            ].map(renderPassWordContainer)}
          </Box>

          <LoginButton 
            style={{
              width: 'fit-content',
            }}
          onClick={handleReset}>Rest Password</LoginButton>
        </Box>
      </LoginContainer>
    </CommonFormModal>
  );
}
