import React, { memo } from "react";
import { getHeightRatio, getWidthRatio } from "./getDimensionalRatio";

const RandomImagePlacer = ({
  alt,
  src,
  mobileScreenWidth,
  cameraControls = true,
}) => {
  const ChipStyles = {
    position: "absolute",
    margin: "5px",
    top: `${Math.floor(Math.random() * 80)}%`,
    left: `${Math.floor(Math.random() * 80)}%`,
    width: getWidthRatio(43),
    // height: getHeightRatio(55),
  };
  return (
    <model-viewer
      loading="eager"
      style={ChipStyles}
      src={src}
      alt={alt}
      camera-controls={cameraControls}
      // disable-tap={true}
    />
  );
};

export default memo(RandomImagePlacer);
