import React, { useEffect, useState } from "react";
import {
  PreviewAndPublishContainer,
  PreviewContentViewContainer,
  PreviewIconAndLabelWrapper,
  PreviewTabListContainer,
  TapIcon,
  TapLabel,
} from "../../../../styles/shared/previewAndPublish";

export default function PreviewAndPublish(props) {
  const { tabList = [], tabContentList = [], rejectedStatus = false } = props;

  const [selectedTab, setSelectedTab] = useState("preview");

  useEffect(() => {
    if (rejectedStatus) setSelectedTab("publish");
    else setSelectedTab("preview");
  }, [rejectedStatus]);

  return (
    <PreviewAndPublishContainer>
      <PreviewTabListContainer>
        {tabList?.map((tab, index) => {
          return (
            <PreviewIconAndLabelWrapper
              key={index}
              isSelected={selectedTab === tab?.value}
              onClick={() => setSelectedTab(tab?.value)}
              isSingleTab={rejectedStatus}
            >
              <TapIcon>{tab?.icon}</TapIcon>
              <TapLabel>{tab?.label}</TapLabel>
            </PreviewIconAndLabelWrapper>
          );
        })}
      </PreviewTabListContainer>

      <PreviewContentViewContainer>
        {tabContentList?.map((content, index) => {
          return (
            <React.Fragment key={index}>
              {selectedTab === content?.value ? content?.Component : <></>}
            </React.Fragment>
          );
        })}
      </PreviewContentViewContainer>
    </PreviewAndPublishContainer>
  );
}
