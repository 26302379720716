import { appApi, appApiFileData, appApiFormData } from "./config";

// common get call
export const getApiServices = (path) => {
  return appApi.get(`${path}`);
};

export const getFileApiServices = (path) => {
  return appApiFileData.get(`${path}`);
};

// post call
export const postApiServices = (path, value) => {
  return appApi.post(`${path}`, value);
};

// post call with id
export const postApiWithIdServices = (path, id) => {
  return appApi.post(`${path}/${id}`);
};

//  get By id
export const getByIdApiServices = (path, id) => {
  return appApi.get(`${path}/${id}`);
};

//  put call
export const putApiServices = (path, value) => {
  return appApi.put(`${path}`, value);
};

//  put call with id
export const putApiWithIdServices = (path, id, value) => {
  return appApi.put(`${path}/${id}`, value);
};
//delete api with id
export const deleteApiServices = (path, id) => {
  return appApi.delete(`${path}/${id}`);
};

//multipart form data apis

// multipart post call
export const multipartPostApiServices = (path, value) => {
  return appApiFormData.post(`${path}`, value, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}
