import React, { useEffect, useRef, useState } from "react";
import { pauseIcon, playIcon } from "../../constants/icons";

const audioStyle = () => {
  const x1 = window?.innerHeight / 1080;
  const exactHeight = 39 * x1;
  const exactWidth = 39 * x1;

  return {
    height: exactHeight,
    width: exactWidth,
  };
};

export default function AduioComponent({ src }) {
  const [isPlaying, changeIsPaying] = useState(false);
  const audioRef = useRef();

  const onAudioPlayPause = () => {
    changeIsPaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, audioRef, src]);
  return (
    <>
      <span onClick={onAudioPlayPause} style={audioStyle()}>
        {isPlaying ? pauseIcon : playIcon}
      </span>
      <audio src={src} ref={audioRef} />
    </>
  );
}
