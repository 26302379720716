import { Box } from "@mui/material";
import React from "react";
import { subPropertiesIcon } from "../../../../constants/icons";
import { libraryHeadLabel } from "../../../../constants/templateCreation";
import {
  LibraryBodyContainer,
  LibraryContainer,
  LibraryPanelHeadContainer,
  LibraryPanelHeading,
  LibraryPropertyContainer,
  LibraryPropertyWrapper,
  PropertyHeader,
  PropertyMainHeader,
  subPropertyIconContainer,
} from "../../../../styles/templateCreation/libraryPanelRenderer";
import { libraryPanelContainerStyle } from "../../../../styles/templateCreationTools";
import LibraryCommonComponentRender from "../../../shared/LibraryCommonComponentRender";
import { getHeightRatio } from "../../../../utils/getDimensionalRatio";
import CustomSelection from "../../../shared/CustomSelection";
import { ACTION_CLASSES } from "../../../../constants/renderUiMatchActionClass";

export default function LibraryPanel(props) {
  const {
    openProperties,
    selectedProperty,
    selectedSubProperty,
    setSelectedSubProperty,
    handleSubProperties,
    handleChangeSubPropertyDatas,
    assetOptions,
    handleTypeBasedSelect,
    selectedType,
    handleSelectPropertyLevel,
    selectedPropertyLevel,
    selectedScreen,
    selectedMultiSubProperties,
    retrivedSourceFiles,
    updateRetrivedSourceFiles,
    handleScoreSelections,
    setSelectedMultiSubProperties,
  } = props;

  const renderLevelSelectionBlock = () => {
    return selectedProperty?.actionClass ===
      ACTION_CLASSES.ASSET_TREATURE_HUNT ||
      selectedProperty?.actionClass ===
        ACTION_CLASSES.MULTI_SECTION_CONTAINER ? (
      <Box sx={{ margin: `${getHeightRatio(30)}px 0px` }}>
        <CustomSelection
          options={assetOptions}
          defaultValue={selectedPropertyLevel}
          handleChange={(value) => {
            handleSelectPropertyLevel(value);
          }}
          getFullData={true}
        />
      </Box>
    ) : (
      ""
    );
  };

  const renderInstructionsBlock = () => {
    return selectedProperty?.actionClass === ACTION_CLASSES.INSTRUCTIONS ||
      selectedProperty?.actionClass ===
        ACTION_CLASSES.DEFAULT_SECTION_CONTAINER ||
      selectedProperty?.key === "LeaderBoard" ? (
      <LibraryPropertyWrapper>
        {selectedProperty?.pageSections?.map((item, index) => (
          <LibraryPropertyContainer
            key={index}
            onClick={() => handleSelectPropertyLevel(item)}
            isSelected={selectedPropertyLevel?.key === item?.key}
          >
            <Box sx={subPropertyIconContainer}>
              {subPropertiesIcon[item?.key]}
            </Box>
            {item?.name || ""}
          </LibraryPropertyContainer>
        ))}
      </LibraryPropertyWrapper>
    ) : (
      <></>
    );
  };

  const getNestedMenus = (data) => {
    return data?.find((item) => item?.key === selectedPropertyLevel?.key)
      ?.elements;
  };

  const librarySubPropertiesMenus = selectedProperty?.elements?.length
    ? selectedProperty?.elements
    : getNestedMenus(selectedProperty?.pageSections);

  return (
    <LibraryContainer
      item
      xs={2.3}
      style={libraryPanelContainerStyle(openProperties)}
    >
      <LibraryPanelHeadContainer>
        <LibraryPanelHeading>{libraryHeadLabel}</LibraryPanelHeading>
        {/* treasure hunt 3d */}
        {renderLevelSelectionBlock()}
        {/* Instuction  module*/}
        {renderInstructionsBlock()}

        {selectedProperty?.elements?.length === 1 ? (
          <></>
        ) : (
          <>
            {selectedProperty?.actionClass ===
              ACTION_CLASSES.ASSET_TREATURE_HUNT ||
            selectedProperty?.actionClass ===
              ACTION_CLASSES.MULTI_SECTION_CONTAINER ? (
              <></>
            ) : (
              <>
                {selectedPropertyLevel?.actionClass ===
                  ACTION_CLASSES.TEXTINPUT_GROUPER_CONTAINER ||
                selectedPropertyLevel?.actionClass ===
                  "DefaultSectionContainer" ||
                selectedPropertyLevel?.key === "LeaderBoard" ? (
                  <LibraryPropertyContainer style={{ textAlign: "start" }}>
                    <PropertyHeader>
                      {selectedPropertyLevel?.name}
                    </PropertyHeader>
                  </LibraryPropertyContainer>
                ) : (
                  <>
                    {selectedProperty?.actionClass ===
                      ACTION_CLASSES.ASSET_MULTISELECT_CONTAINER ||
                    selectedProperty?.key === "LeaderBoard" ? (
                      <></>
                    ) : (
                      <LibraryPropertyWrapper>
                        {librarySubPropertiesMenus?.map((item, index) => (
                          <>
                            <LibraryPropertyContainer
                              key={index}
                              onClick={() => handleSubProperties(item)}
                              isSelected={
                                selectedSubProperty?.key === item?.key &&
                                selectedSubProperty?.actionClass ===
                                  item?.actionClass
                              }
                            >
                              {subPropertiesIcon[item?.key] ? (
                                <PropertyMainHeader>
                                  {subPropertiesIcon[item?.key]}
                                </PropertyMainHeader>
                              ) : (
                                <></>
                              )}
                              {item?.name || ""}
                            </LibraryPropertyContainer>
                          </>
                        ))}
                      </LibraryPropertyWrapper>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </LibraryPanelHeadContainer>

      <LibraryBodyContainer>
        <LibraryCommonComponentRender
          selectedType={selectedType}
          handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          selectedProperty={selectedProperty}
          selectedSubProperty={selectedSubProperty}
          setSelectedSubProperty={setSelectedSubProperty}
          handleTypeBasedSelect={handleTypeBasedSelect}
          selectedPropertyLevel={selectedPropertyLevel}
          handleSelectPropertyLevel={handleSelectPropertyLevel}
          selectedScreen={selectedScreen}
          selectedMultiSubProperties={selectedMultiSubProperties}
          retrivedSourceFiles={retrivedSourceFiles}
          updateRetrivedSourceFiles={updateRetrivedSourceFiles}
          handleScoreSelections={handleScoreSelections}
          setSelectedMultiSubProperties={setSelectedMultiSubProperties}
        />
      </LibraryBodyContainer>
    </LibraryContainer>
  );
}
