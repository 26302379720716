import React from "react";
import {
  FormatContainer,
  FormatLabel,
  FormatListLabel,
  InfoBlobContentContentContainer,
} from "../../styles/infoBlob";
import { getHeightRatio } from "../../utils/getDimensionalRatio";

export default function InformationBlobContent(props) {
  const { fileFormats = "File formats", fileSize = "File size" } = props;

  return (
    <InfoBlobContentContentContainer>
      <FormatContainer>
        <FormatLabel>Format : </FormatLabel>
        <FormatListLabel>{fileFormats}</FormatListLabel>
      </FormatContainer>

      <FormatContainer sx={{ marginTop: getHeightRatio(2) }}>
        <FormatLabel>Size :</FormatLabel>
        <FormatListLabel>{`max ${fileSize}`}</FormatListLabel>
      </FormatContainer>
    </InfoBlobContentContentContainer>
  );
}
