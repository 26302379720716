import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  animationImageContainerStyle,
  ImageView,
  ImageViewContainer,
} from "../../styles/sharedComponents";
import CommonImageRender from "./CommonImageRender";
import CustomCheckBoxField from "./CustomCheckBoxField";
import xtendrEmptyImage from "../../assets/icons/xtendrEmptyImage.jpg";

export default function ThreeDModelAnimation({
  handleChangeSubPropertyDatas = () => {},
  selectedProperty = {},
  selectedSubProperty = {},
}) {
  const [animationTypes, setAnimationTypes] = useState({});
  const [animationOptions, setAnimationOptions] = useState([]);

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setAnimationTypes({
      ...animationTypes,
      [name]: checked,
    });
    handleChangeSubPropertyDatas({
      ...selectedSubProperty?.value,
      [name]: checked,
    });
  };

  useEffect(() => {
    if (selectedSubProperty) {
      const getAnimatioTypes = {};
      selectedSubProperty?.options?.forEach((item) => {
        Object.assign(getAnimatioTypes, {
          [item?.type]:
            selectedSubProperty?.value?.[item?.type] || item?.[item?.type],
        });
      });
      setAnimationTypes(getAnimatioTypes);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.key, selectedSubProperty]);

  useEffect(() => {
    if (selectedSubProperty) {
      const getAnimationOptions = selectedSubProperty?.options?.map((item) => {
        return {
          ...item,
          [item?.type]: animationTypes?.[item?.type] || item?.[item?.type],
        };
      });
      setAnimationOptions(getAnimationOptions);
    }
  }, [selectedProperty?.key, selectedSubProperty, animationTypes]);

  return (
    <>
      {selectedSubProperty?.options?.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <CustomCheckBoxField
              label={item?.name || ""}
              name={item?.type || ""}
              onChange={handleChange}
              checked={animationTypes?.[item?.type]}
              containerStyle={{ margin: "20px 0px 0px 10px" }}
            />
          </React.Fragment>
        );
      })}
      {/* <ImageViewContainer style={animationImageContainerStyle}> */}
        {/* { selectedSubProperty?.value?.previewUrl ? (
           typeof selectedSubProperty?.value?.previewUrl == "string" ? (
            <ImageView
              src={selectedSubProperty?.value?.previewUrl}
              alt="selectedImage"
            />
          ) : (
            selectedSubProperty?.value?.previewUrl
          )
        ) : (
          <Box sx={{ color: "#FFFFFF", fontSize: "10px", padding: "10px" }}>
            Selected Animation
          </Box>
        )} */}

        {/* front handle image for animationStyle */}
        {/* {selectedSubProperty?.value?.previewUrl ? (
          <ImageView
            src={selectedSubProperty?.value?.previewUrl || xtendrEmptyImage}
            alt={"Image"}
            style={{ height: "auto", width: "auto" }}
          />
        ) : (
          <Box
            sx={{
              color: "#FFFFFF",
              fontSize: "clamp(1rem, 1.5vmin, 4rem)",
              padding: "10px",
            }}
          >
            Selected Animation
          </Box>
        )}
      </ImageViewContainer>

      <CommonImageRender
        disabledHeading={true}
        selectedProperty={selectedProperty}
        imageList={animationOptions || []}
        handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
        viewMore={false}
        imageViewContainerCustomStyle={{ aspectRatio: "1/0.763" }}
      /> */}
    </>
  );
}
