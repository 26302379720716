export const SUB_PROPERTY_TYPES = {
  LOGO: "logo",
  LOADER: "loader",
  FOOTER: "footer",
  CAMERA: "camera",
  AUDIO: "audio",
  PROMPT: "prompt",
  DEFAULT: "default",
  COLLECTIABLE: "collectible",
  AVOIDABLE: "avoidable",
  TIMER: "timer",
  SUBMIT: "submit",
  MAIN_LOGO: "mainLogo",
};
