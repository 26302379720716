import { Box } from "@mui/material";
import React from "react";
import {
  darkModeIcon,
  resetCodeIcon,
  screenIcons,
} from "../../../../constants/icons";
import {
  drakModeLabel,
  resetLabel,
} from "../../../../constants/templateCreation";
import { darkAndResetStyle } from "../../../../styles/shared/screenPanelScreenNameWrapper";
import {
  ScreenPanelContainer,
  ScreenPanelListContainer,
  ScreensPropertiesContainer,
} from "../../../../styles/templateCreation/screenMenuPanel";
import ScreenPanelScreenNameWrapper from "./ScreenPanelScreenNameWrapper";
import PropertiesSideBar from "./PropertiesSideBar";
import CommonFormModal from "../../../shared/CommonFormModal";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../utils/getDimensionalRatio";
import {
  ApprovalButtonContainer,
  ApprovalDescription,
  ApprovalModalContainer,
  PublishApprovedButton,
  PublishGoBackButton,
  resetButtonStyle,
  resetCencelButtonStyle,
} from "../../../../styles/previewAndPublish/publishTabStyle";

export default function ScreenMenuPanel(props) {
  const {
    templateScreenList,
    openProperties,
    setOpenProperties,
    handleScreenChange,
    selectedScreen,
    handleProperties,
    selectedProperty,
    selectedDarkMode,
    setSelectedDarkMode,
    handleReset = () => {},
    openResetMsg = false,
    setOpenResetMsg = () => {},
  } = props;
  return (
    <>
      <ScreenPanelContainer item xs={2.7}>
        <ScreenPanelListContainer isSelected={openProperties}>
          <Box>
            {templateScreenList?.map((item, index) => (
              <React.Fragment key={index}>
                <ScreenPanelScreenNameWrapper
                  label={item?.name}
                  icon={screenIcons?.[item?.key]}
                  openProperties={openProperties}
                  isSelected={item?.key === selectedScreen?.key}
                  onClick={() => handleScreenChange(item)}
                />
              </React.Fragment>
            ))}
          </Box>

          {/* <Box sx={{ marginTop: "130px" }}>
            <ScreenPanelScreenNameWrapper
              icon={resetCodeIcon}
              label={resetLabel}
              style={{ ...darkAndResetStyle }}
              openProperties={openProperties}
              selectedScreenName={resetLabel}
              onClick={() => setOpenResetMsg(true)}
            />
          </Box> */}

          <Box sx={{ marginBottom: "0%" }}>
            <ScreenPanelScreenNameWrapper
              icon={darkModeIcon}
              label={selectedDarkMode ? 'Light mode' : drakModeLabel}
              switchButton={true}
              style={{ ...darkAndResetStyle }}
              openProperties={openProperties}
              selectedScreenName={drakModeLabel}
              onClick={() => setSelectedDarkMode(!selectedDarkMode)}
            />
          </Box>
        </ScreenPanelListContainer>

        <ScreensPropertiesContainer>
          <PropertiesSideBar
            setOpenProperties={setOpenProperties}
            openProperties={openProperties}
            screenName={selectedScreen?.name}
            selectedScreenPageSections={selectedScreen?.pageSections || []}
            handleProperties={handleProperties}
            selectedPropertyKey={selectedProperty?.key}
          />
        </ScreensPropertiesContainer>
      </ScreenPanelContainer>

      {/* handle reset modal */}
      <CommonFormModal
        open={openResetMsg}
        modalBodyContainerStyle={{
          width: getWidthRatio(1129),
          height: getHeightRatio(389),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(1129),
          height: getHeightRatio(389),
        }}
        iconContainerHeight={{
          height: getHeightRatio(389),
        }}
        hanldeClose={() => setOpenResetMsg(false)}
        backIcon={false}
        cancelIcon={true}
      >
        <ApprovalModalContainer>
          <ApprovalDescription>
            Are you sure, do you want reset the project
          </ApprovalDescription>

          <ApprovalButtonContainer>
            <PublishGoBackButton
              style={resetCencelButtonStyle}
              onClick={() => setOpenResetMsg(false)}
            >
              Do it Later
            </PublishGoBackButton>

            <PublishApprovedButton
              onClick={handleReset}
              style={resetButtonStyle}
            >
              Reset
            </PublishApprovedButton>
          </ApprovalButtonContainer>
        </ApprovalModalContainer>
      </CommonFormModal>
    </>
  );
}
