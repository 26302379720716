import React from "react"
import { LandingContainer } from "../../../styles/landingPage"
import ResetContainer from "./components/ResetContainer"

const LandingPage = () => (
  <LandingContainer>
    <ResetContainer />
  </LandingContainer>
)
export default LandingPage
