import React, { useState } from "react";
import { CustomSlider } from "../../../styles/colorPalette";

const rangeMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 100,
    label: "100",
  },
];
export const rangeIndicator = (firstThumbColor, secondThumbColor) => {
  return {
    "& .MuiSlider-thumb": {
      padding: "0px !important",
    },
    "& .MuiSlider-thumb": {
      backgroundColor: firstThumbColor || "#f00",
      "&.MuiSlider-thumb:last-of-type": {
        backgroundColor: secondThumbColor || "#0f0",
      },
    },
  };
};

export default function CustomGradientRangeBar({
  selectedColor,
  grandientHandleChange = () => {},
  firstThumbColor,
  secondThumbColor,
}) {
  const [value, setValue] = useState([0, 100]);

  const handleChange = (event, newValue, index) => {
    setValue(newValue);
    const sendValue = newValue?.map((item) => {
      return {
        offset: item,
      };
    });
    grandientHandleChange(sendValue, index);
  };

  return (
    <CustomSlider
      value={value}
      onChange={handleChange}
      min={0}
      max={100}
      step={1}
      valueLabelDisplay="auto"
      marks={rangeMarks}
      background={selectedColor}
      sx={{ ...rangeIndicator(firstThumbColor, secondThumbColor) }}
    />
  );
}
