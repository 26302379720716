import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { Box } from "@mui/system";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import { centerStyle } from "../commonStyles";

export const PreviewWrapper = styled(Box)(({ style }) => {
  return {
    width: "100%",
    height: "97%",
    ...style,
  };
});
export const PreviewTitleLabel = styled(Box)(({ style }) => {
  return {
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    textAlign: "start",
    margin: "45px 0px 10px 0px",
    ...style,
  };
});
export const PreviewTextBoxWrapper = styled(Box)(({ style }) => {
  return {
    width: "100%",
    ...centerStyle,
    justifyContent: "space-between",
    alignItems: "baseline",
    ...style,
  };
});
export const PreviewUrlTextBox = styled(InputBase)(({ style }) => {
  return {
    height: "56px",
    width: "75%",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1.8rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#000000",
    textAlign: "start",
    margin: "20px 5px 0px 20px",
    background: "#FFFFFF",
    padding: "0px 40px",
    borderRadius: "30px 10px",
    ...style,
  };
});
export const PreviewUrlCopyButton = styled(Box)(({ style }) => {
  return {
    width: "15%",
    height: "58.34px",
    background: "#49C4D8",
    boxShadow: "0px 3.64626px 15.4966px rgba(0, 0, 0, 0.25)",
    borderRadius: "10.9388px",
    fontFamily: "OpenSans-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2.1rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    color: "#000000",
    cursor: "pointer",
    ...centerStyle,
    ...style,
  };
});
export const PreviewQRCodeContainer = styled(Box)(({ style }) => {
  return {
    width: 'fit-content',
    margin: "25px 0px 0px 15px",
    padding: '2rem 2.5rem',
    background: "#FFFFFF",
    borderRadius: "20px 50px 50px 50px",
    ...centerStyle,
    ...style,
  };
});
export const QRDownloadIcon = styled(Box)(({ style }) => {
  return {
    width: `${getWidthRatio(50)}px`,
    height: `${getHeightRatio(55)}px`,
    borderRadius: "5em",
    background: "#FFFFFF",
    ...centerStyle,
    ...style,
  };
});
export const QRDownloadLabel = styled(Box)(({ style }) => {
  return {
    fontFamily: "OpenSans-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.5rem",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    margin: "0px 0px 0px 1rem",
    ...centerStyle,
    ...style,
  };
});
export const QRCodeDownloadAllFormat = styled(Box)(({ style }) => {
  return {
    ...centerStyle,
    alignItems: "end",
    display: 'flex',
    alignItems: 'center',
    justifyContent: "space-between",
    marginLeft: 'auto',
    cursor: "pointer",
    ...style,
  };
});
