import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { centerStyle } from "./commonStyles";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";

export const MainContainer = styled(Box)(({ style }) => {
  return {
    width: getWidthRatio(1615),
    height: getHeightRatio(910),
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxSizing: "border-box",
    // overflow: "hidden",
    "&:focus": { outline: "none" },
    ...style,
    background: "#2F2F2F",
    borderRadius: "18px",
  };
});

export const ModalContainer = styled(Box)(({ style }) => {
  return {
    width: "100%",
    ...centerStyle,
    justifyContent: "space-between",
    ...style,
  };
});

export const CancelIcon = styled(Box)(({ style }) => {
  return {
    position: "relative",
    top: 0,
    left: 0,
  };
});

export const BackIcon = styled(Box)(({ style }) => {
  return {
    width: "51px",
    height: "51px",
    background: "#858585",
    borderRadius: "50%",
    ...centerStyle,
    ...style,
  };
});
