import React, { useState, useEffect } from "react";
import {
  LandingContainer,
  SignInButton,
  LandingPageLogoContainer,
  LandingPageBody,
  LandingPageLogo,
  MenuContainer,
  FooterContainer,
  FooterLogo,
  IframeBox,
  ViewsContainer,
  ViewsTextContainer,
  ViewsText,
  ViewsPlusText,
  ViewsSubText,
  HowDoesItWorkContainer,
  HowDoesItWorkText,
  HowDoesItWorkQuestionMark,
  HowDoesItWorkTextContainer,
  HowDoesItWorkSubText,
  HowDoesItWorkCardContainer,
  HowDoesItWorkCard,
  HowDoesItWorkCardText,
  HowDoesItWorkCardTitle,
  HowDoesItWorkCardIcon,
  BrandsImage,
  SocialIconContainer,
  SocialIcon,
} from "../../../styles/landingPage";
import LoginPage from "../login";
import logo from "../../../assets/icons/logo.png";
import landingPageLogo from "../../../assets/icons/landingPageLogo.png";
import one from "../../../assets/icons/one.png";
import two from "../../../assets/icons/two.png";
import three from "../../../assets/icons/three.png";
import four from "../../../assets/icons/four.png";
import brands from "../../../assets/icons/brands.png";
import linkedIn from "../../../assets/icons/linkedIn.svg";
import youTube from "../../../assets/icons/youTube.svg";
import twitter from "../../../assets/icons/twitter.svg";
import mail from "../../../assets/icons/mail.svg";
import rec from "../../../assets/icons/rec.svg";
import physicalGiveaway from "../../../assets/icons/PhygitalGiveaway.png";
import grid4 from "../../../assets/icons/4x4.svg";
import arrow from "../../../assets/icons/arrow.svg"
import line1 from "../../../assets/icons/Line1.png";
import iframeSvg from "../../../assets/icons/iframe.svg";
import { Divider } from "@mui/material";

function CountUp({ start, end, duration }) {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let intervalId;
    if (count < end) {
      const difference = end - start;
      const increment = difference / duration;
      intervalId = setInterval(() => {
        setCount((prevCount) => prevCount + increment);
      }, 10);
    }
    return () => clearInterval(intervalId);
  }, [count, end, duration, start]);

  return <ViewsText>{Math.floor(count).toLocaleString()}
  </ViewsText>;
}

export default function LandingPage() {
  const [loginOpen, setLoginOpen] = useState(false);
  return (
    <>
      <LandingContainer>
        <LandingPageLogoContainer>
          <div style={{ width: "50%" }}>
            <LandingPageLogo src={logo} alt="logo"/>
          </div>

          <MenuContainer>
            <a>Templates</a>
            <a href="https://www.xtendr.co/about-us/" target="_blank">About us</a>
          </MenuContainer>

          <div style={{ width: "50%" }}>
            <SignInButton onClick={() => setLoginOpen(true)}>
              Get Started
            </SignInButton>
          </div>
        </LandingPageLogoContainer>

        <LandingPageBody>
          <div style={{
            position:'relative',
            height: '-webkit-fill-available',
            top: '1%',
          }}>
            <div style={{
              borderRadius: '1rem',
              background: 'rgba(22, 128, 206, 0.20)',
              boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
              width: '17.5625rem',
              height: '30.8125rem',
              position: 'absolute',
              top: '50%',
              left: '25%',
              transform: 'translate(-50%, -50%)',
            }}></div>

              <svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none" style={{
                position: 'relative',
                top: '70%',
                left: '18%',
                transform: 'translate(-50%, -50%)',
              }}>
                <circle cx="34" cy="34" r="29.5" stroke="url(#paint0_linear_310_156)" stroke-opacity="0.76" stroke-width="9"/>
                <defs>
                  <linearGradient id="paint0_linear_310_156" x1="34" y1="2.23517e-06" x2="109" y2="68" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#1CEEA7"/>
                    <stop offset="1" stop-color="#1CEEA7" stop-opacity="0"/>
                  </linearGradient>
                </defs>
              </svg>
              <div style={{
                borderRadius: '48.625rem',
                background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(184, 104, 247, 0.21) 0%, rgba(217, 217, 217, 0.00) 100%)',
                width: '48.625rem',
                height: '48.125rem',
              }}></div>

              <div style={{
                borderRadius: '31.5625rem',
                background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(28, 238, 167, 0.20) 0%, rgba(217, 217, 217, 0.00) 100%)',
                width: '31.5625rem',
                height: '31.25rem',
                position: 'absolute',
                top: '70%',
                left: '45%',
                transform: 'translate(-50%, -50%)',
              }}></div>
              <img src={physicalGiveaway} style={{
                position: 'absolute',
                width: '50%',
                top: '45%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}/>
              <div style={{
                color:'black',
                position: 'absolute',
                background: 'white',
                top: '65%',
                left: '33%',
                fontSize: '1.5rem',
                borderRadius:'0.625rem',
                fontWeight: 500,
                padding: '1rem 2rem',
                transform: 'translate(-50%, -50%)',
              }} onClick={() => setLoginOpen(true)}>
                Explore Templates
              </div>
              <img src={rec} style={{
                position: 'absolute',
                top: '30%',
                left: '65%',
                width: '14.5rem',
                height: '6.27025rem',
                transform: 'translate(-50%, -50%)',
              }}/>

              <div style={{
                borderRadius: '31.5625rem',
                background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(87, 23, 159, 0.34) 0%, rgba(217, 217, 217, 0.00) 100%)',
                width: '31.5625rem',
                height: '31.25rem',
                position: 'absolute',
                top: '10%',
                right: 0,
                transform: 'translate(-50%, -50%)',
              }}></div>
              <img src={grid4} style={{
                position: 'absolute',
                top: '50%',
                right: '5%',
                width: '6.5rem',
                height: '6.125rem',
                transform: 'translate(-50%, -50%)',
              }} />
              <img src={line1} style={{
                position: 'absolute',
                top: '45%',
                right: '-30%',
                width: '55%',
                transform: 'translate(-50%, -50%)',
              }}/>
            <img src={arrow} style={{
              position: 'absolute',
              top: '30%',
              right: '15%',
              width: '4rem',
              transform: 'translate(-50%, -50%)',
            }} />
          </div>
        </LandingPageBody>

        <BrandsImage src={brands} />

        <HowDoesItWorkContainer>
          <div style={{
            borderRadius: '48.625rem',
            background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(13, 153, 255, 0.22) 0%, rgba(217, 217, 217, 0.00) 100%)',
            width: '48.625rem',
            height: '48.125rem',
            position: 'absolute',
            top: '20%',
            left: '20%',
            transform: 'translate(-50%, -50%)',
          }}></div>

          <div style={{
            borderRadius: '48.625rem',
            background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(225, 11, 113, 0.15) 0%, rgba(217, 217, 217, 0.00) 100%)',
            width: '48.625rem',
            height: '48.125rem',
            position: 'absolute',
            top: '-25%',
            right: '-65%',
            transform: 'translate(-50%, -50%)',
          }}></div>

          <img src={grid4} style={{
            position: 'absolute',
            top: '60%',
            left: '-10%',
            width: '6.5rem',
            height: '6.125rem',
            transform: 'translate(-50%, -50%)',
          }} />
          <HowDoesItWorkTextContainer>
            <div style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: '2rem',
              }}>
              <HowDoesItWorkText> How does it work</HowDoesItWorkText>
              <HowDoesItWorkQuestionMark>?</HowDoesItWorkQuestionMark>
            </div>
            <HowDoesItWorkSubText>Make your freebies unique to reflect your brand preferences.</HowDoesItWorkSubText>
          </HowDoesItWorkTextContainer>

          <HowDoesItWorkCardContainer>
            <HowDoesItWorkCard>
              <HowDoesItWorkCardIcon src={one} alt="logo"/>
              <HowDoesItWorkCardTitle>Select Template </HowDoesItWorkCardTitle>
              <HowDoesItWorkCardText>Select a template from a wide range of  engaging WebAR experiences.</HowDoesItWorkCardText>
            </HowDoesItWorkCard>
            <HowDoesItWorkCard>
              <HowDoesItWorkCardIcon src={two} alt="logo"/>
              <HowDoesItWorkCardTitle>Customize</HowDoesItWorkCardTitle>
              <HowDoesItWorkCardText>Add inputs for assets like brand logo, UI, colours, 3D assets and more. </HowDoesItWorkCardText>
            </HowDoesItWorkCard>
            <HowDoesItWorkCard>
              <HowDoesItWorkCardIcon src={three} alt="logo"/>
              <HowDoesItWorkCardTitle>Submit the brief </HowDoesItWorkCardTitle>
              <HowDoesItWorkCardText>Send in your customised template to us and wait for the approval. </HowDoesItWorkCardText>
            </HowDoesItWorkCard>
            <HowDoesItWorkCard>
              <HowDoesItWorkCardIcon src={four} alt="logo"/>
              <HowDoesItWorkCardTitle>Go live! </HowDoesItWorkCardTitle>
              <HowDoesItWorkCardText>Once approved, go live with the experiences and let the world experience magic.</HowDoesItWorkCardText>
            </HowDoesItWorkCard>
          </HowDoesItWorkCardContainer>
        </HowDoesItWorkContainer>

      
        <ViewsContainer>
          <ViewsTextContainer id="target">
            <CountUp start={0} end={60000000} duration={150} />
            <ViewsPlusText>+</ViewsPlusText>
          </ViewsTextContainer>
          <ViewsSubText>Phygital Giveaways adore by brands and consumers  </ViewsSubText>
          <div style={{
            borderRadius: '48.625rem',
            background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(184, 104, 247, 0.21) 0%, rgba(217, 217, 217, 0.00) 100%)',
            width: '48.625rem',
            height: '48.125rem',
            position: 'absolute',
            top: '20%',
            left: '15%',
            transform: 'translate(-50%, -50%)',
          }}></div>
          <IframeBox>
            <iframe 
              style={{margin: "auto", padding: "2em"}}
              width="900"
              height="500" 
              src="https://www.youtube.com/embed/_75RemAL8Fc" 
              title="Chupa Chups - Gaming Portal"
              frameborder="0">
            </iframe>
          </IframeBox>
          <img src={iframeSvg} style={{
            position:'absolute',
            width: '12.5%',
            top: '40%',
            left: '18%',
            transform: 'translate(-50%, -50%)',
          }}/>
        </ViewsContainer>

        <FooterContainer>
          <Divider style={{
            width: "100%",
            marginTop: "1rem",
            marginBottom: "1rem",
            borderColor: "white",
          }} variant="middle" />
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
            <FooterLogo src={landingPageLogo} alt="logo"/>
            <SocialIconContainer>
              <a style={{
                marginRight: '10%',
              }}>
                <SocialIcon src={linkedIn} />
              </a>
              <a href="https://youtube.com/@xtendr6258" style={{
                marginRight: '10%',
              }}>
                <SocialIcon src={youTube} />
              </a>
              <a style={{
                marginRight: '10%',
              }}>
               <SocialIcon src={twitter} />
              </a>
              <a href="mailto:info@xtendr.co">
                <SocialIcon src={mail} />
              </a>
            </SocialIconContainer>
          </div>
        </FooterContainer>
      </LandingContainer>

      <LoginPage open={loginOpen} handleClose={() => setLoginOpen(false)} />
    </>
  );
}
