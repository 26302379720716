import React from "react";
import {
  Header,
  HighLighterButton,
  ButtonWrapper,
  ContainerWrapper,
} from "../../styles/shared/scoreSelections";

export function ScoreSelector(props) {
  const {
    isSelected,
    selectedSubProperty,
    onClick,
    header,
    options,
    key = "value",
  } = props;

  return (
    <ContainerWrapper>
      <Header>{header}</Header>
      <ButtonWrapper>
        {options?.map((item, index) => {
          return (
            <HighLighterButton
              isSelected={selectedSubProperty?.value?.value === item?.value}
              onClick={() => onClick(item?.value)}
              key={index}
            >
              {item[key]}
            </HighLighterButton>
          );
        })}
      </ButtonWrapper>

      <div></div>
    </ContainerWrapper>
  );
}
