import React from "react";
import {
  CustomAlphaPicker,
  CustomAlphaPickerPointer,
} from "../../../styles/colorPalette";

export default function CustomAlphaPickerPanel(props) {
  const { color, onChange = () => {} } = props;
  return (
    <CustomAlphaPicker
      color={color}
      onChange={onChange}
      pointer={CustomAlphaPickerPointer}
    />
  );
}
