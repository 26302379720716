import { Box, Modal } from "@mui/material";
import React from "react";
import { backArrowIcon, publishCancelIcon } from "../../constants/icons";
// import {
//   ModalBodyContainer,
//   ModalChildrenContainer,
//   PublishBackIcon,
//   PublishCancelIconContainer,
//   ModalContainer,
// } from "../../styles/previewAndPublish/commonFormModal";

import {
  MainContainer,
  ModalContainer,
  CancelIcon,
  BackIcon,
} from "../../styles/dashboardModal";

export default function DashBoardModal(props) {
  const {
    open = false,
    children,
    handleClose = () => {},
    modalBodyContainerStyle = {},
    modalChildrenContainerStyle = {},
    iconContainerHeight = {},
    backIcon = true,
    cancelIcon = false,
  } = props;
  return (
    <Modal open={open} onClose={handleClose}>
      <MainContainer>
        <ModalContainer>{children}</ModalContainer>
        {cancelIcon && (
          <Box onClick={handleClose} sx={{ position: "absolute", top: 0, right: "-5%" }}>
            <BackIcon>{publishCancelIcon}</BackIcon>
          </Box>
        )}
      </MainContainer>
    </Modal>
  );
}
