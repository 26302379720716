import { FormGroup } from "@mui/material";
import React from "react";
import {
  CustomCheckbox,
  CustomCheckBoxFormControlLabel,
} from "../../styles/sharedComponents";

export default function CustomCheckBoxField(props) {
  const {
    label,
    checked = false,
    onChange = () => {},
    containerStyle = {},
    formControlLabelStyle = {},
    checkBoxStyle = {},
    name,
    defaultChecked = false,
  } = props;
  return (
    <FormGroup sx={{ ...containerStyle }}>
      <CustomCheckBoxFormControlLabel
        control={
          <CustomCheckbox
            style={checkBoxStyle}
            defaultChecked={defaultChecked}
          />
        }
        label={label}
        onChange={onChange}
        checked={checked}
        style={formControlLabelStyle}
        name={name}
      />
    </FormGroup>
  );
}
