export const BaseRoutes = {
  TEMPLATES: "templates",
};

export const apiRoutes = {
  projectCreation: "users/projects",
  getProject: "users/projects",
  getTemplate: "templates",
  assetCreation: "users/assets",
  getAllAssetsByType: "users/assets/query",
  users: "users",
  login: "auth/login",
  resetPass: "auth/reset-password",
  genResetPassMail: "/auth/gen-reset-password-mail",
  jwtTokenLogin: "auth/jwt-login",
  recentColor: "users/recent-changes",
  createRecentColor: "users/recent-changes",
  autoSave: "users/projects",
  multiUploadCreation: "users/assets/upload-files",
  deleteAssets: "users/assets",
  publishStatus: "users/projects/change-status",
  publishMetaImageUpload: "users/projects/upload-meta-fields",
  templateFilters: "templates/get-filter-tags",
  getTemplateWithFilters: "templates/filter",
  reports: "users/analytics/run-report",
  logout: "users/log-out",
};
