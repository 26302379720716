import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  VoiceTriggerRemoveIcon,
  VoiceTriggerTextField,
} from "../../styles/templateCreation/libraryPanelRenderer";
import { generateArray } from "../../utils/generateArrayNumbers";

export default function VoiceTriggerTextBox(props) {
  const {
    handleChangeSubPropertyDatas = () => {},
    selectedProperty = {},
    selectedSubProperty = {},
    setSelectedSubProperty,
    selectedPropertyLevel = {},
  } = props;
  const [textBoxList, settextBoxList] = useState([]);

  const handleLimitedTexBox = () => {
    const textBoxList = selectedPropertyLevel?.elements?.map((item, index) => {
      return {
        ...item,
        key: `${item?.key}${index + 1}`,
      };
    });

    // handle max limit in fe
    const checkMax =
      selectedPropertyLevel?.max - selectedPropertyLevel?.elements?.length;

    generateArray(checkMax)?.map((item, index) => {
      textBoxList?.push({
        ...selectedPropertyLevel?.elements?.[0],
        key: `VoiceTriggerElement${
          selectedPropertyLevel?.elements?.length + index + 1
        }`,
        value: {
          ...selectedPropertyLevel?.elements?.[0]?.value,
          value: "",
        },
      });
    });

    settextBoxList(textBoxList);
    handleChangeSubPropertyDatas(textBoxList);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const getData = textBoxList?.map((item) => {
      if (name === item?.key) {
        return {
          ...item,
          value: { ...item?.value, value: value },
        };
      } else return item;
    });
    settextBoxList(getData);
    handleChangeSubPropertyDatas(getData);
  };

  const handleRemove = (data) => {
    const getData = textBoxList?.map((item) => {
      if (data?.key === item?.key) {
        return {
          ...item,
          value: { value: "" },
        };
      } else return item;
    });
    settextBoxList(getData);
    setSelectedSubProperty({
      ...selectedSubProperty,
      value: {
        ...selectedSubProperty?.value,
        value: "",
      },
    });
  };

  useEffect(() => {
    handleLimitedTexBox();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {textBoxList?.map((item, index) => {
        return (
          <Box key={index} sx={{ mt: 1, mb: 2, position: "relative" }}>
            <VoiceTriggerTextField
              placeholder="Enter voice note"
              name={item?.key}
              value={item?.value?.value || ""}
              onChange={handleChange}
              isSelected={item?.key === selectedSubProperty?.key}
              onClick={() => setSelectedSubProperty(item)}
            />
            <VoiceTriggerRemoveIcon
              onClick={() => handleRemove(item)}
              isSelected={item?.key === selectedSubProperty?.key}
            />
          </Box>
        );
      })}
    </>
  );
}
