import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { centerStyle, textOverflowEllipsStyle } from "../commonStyles";

export const PreviewAndPublishContainer = styled(Box)(({ style }) => {
  return {
    width: "80%",
    height: "90%",
    margin: "auto",
    overflow: "hidden auto",
    ...style,
  };
});
export const PreviewTabListContainer = styled(Box)(({ style }) => {
  return {
    width: "100%",
    height: "10%",
    position: "sticky",
    background: "#2F2F2F",
    top: -1,
    left: 0,
    ...centerStyle,
    justifyContent: "start",
    ...style,
  };
});
export const PreviewIconAndLabelWrapper = styled(Box)(
  ({ style, isSelected, isSingleTab }) => {
    return {
      maxWidth: "200px",
      margin: isSingleTab ? "0px" : "0px 20px",
      cursor: "pointer",
      borderBottom: isSelected ? "2.5098px solid #49C4D8" : "",
      ...textOverflowEllipsStyle,
      ...centerStyle,
      ...style,
    };
  }
);
export const TapLabel = styled(Box)(({ style }) => {
  return {
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "clamp(3rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    ...centerStyle,
    ...style,
  };
});
export const TapIcon = styled(Box)(({ style }) => {
  return {
    height: "50px",
    width: "50px",
    marginRight: "5px",
    cursor: "pointer",
    ...centerStyle,
    justifyContent: "start",
    ...style,
  };
});
export const PreviewContentViewContainer = styled(Box)(({ style }) => {
  return {
    height: "85%",
    width: "100%",
    ...centerStyle,
    ...style,
  };
});
