import { ROUTE_PATHS } from "../../routes/routePaths";

const { useLocation, Navigate } = require("react-router-dom");

export const ProtectedRoute = ({ children, isAuthenticated }) => {
  let location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={ROUTE_PATHS.landingPage}
        state={{ from: location }}
        replace
      />
    );
  }
  return children;
};
