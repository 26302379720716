import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { centerStyle, textOverflowEllipsStyle } from "./commonStyles";

export const NavContainer = styled(Box)({
  height: "100%",
  zIndex: 1999,
  ...centerStyle,
  padding: "0 4rem",
});
export const NavLogoContainer = styled(Box)({
  height: "100%",
  width: "25%",
  maxWidth: "30%",
  ...centerStyle,
  justifyContent: "start",
});
export const logoStyle = (width) => {
  const x1 = width / 3500;
  const exactWidth = 49.43 * x1;

  return {
    maxWidth: exactWidth,
    marginRight: "8%",
  };
};
export const templateIconStyle = (getDomHeight) => {
  const x1 = getDomHeight / 1080;
  const exactHeight = 10 * x1;
  return {
    height: exactHeight || "8px",
    width: "auto",
  };
};
export const newMeetButtonStyle = (getDomHeight) => {
  const x1 = getDomHeight / 1080;
  const exactHeight = 52 * x1;

  return {
    minWidth: "158px",
    height: exactHeight || "52px",
    background: "linear-gradient(90.61deg, #16A0CE -27.65%, #7C00FF 126.4%)",
    borderRadius: "6.98701px",
    fontFamily: "OpenSans-Bold",
    fontWeight: "400",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    marginLeft: "8%",
  };
};
export const NavProjectNameContainer = styled(Box)({
  height: "100%",
  width: "50%",
  ...centerStyle,
});
export const NavProjectName = styled(Box)({
  padding: "5px",
  letterSpacing: "-0.011em",
  fontFamily: "OpenSans-Medium",
  fontWeight: "400",
  fontSize: "clamp(1rem, 1.5vmin, 4rem)",
  color: "#FFFFFF",
});
export const NavAccountContainer = styled(Box)({
  height: "100%",
  width: "25%",
  maxWidth: "30%",
  ...centerStyle,
  justifyContent: "end",
  cursor: "pointer",
});
export const previewAndPublishStyle = (getDomHeight) => {
  const x1 = getDomHeight / 1080;
  const exactHeight = 52 * x1;
  const exactWidth = 158 * x1;

  return {
    height: exactHeight || "52px",
    width: exactWidth || "158px",
    borderRadius: "6.98701px",
    fontFamily: "OpenSans-Bold",
    fontWeight: "400",
    letterSpacing: "-0.011em",
    textTransform: "capitalize",
    ...textOverflowEllipsStyle,
    ...centerStyle,
    background:
      "linear-gradient(90deg, #49C4D8 -57.91%, #1BEAD4 25.59%, #CCFF00 225%)",
    color: "#212026",
    padding: "0 3%",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    marginRight: "10%",
  };
};
export const DashBoardButton = (domHeight) => {
  const x1 = domHeight / 1080;
  const exactHeight = 52 * x1;
  const exactWidth = 158 * x1;

  return {
    height: exactHeight || "52px",
    width: exactWidth || "158px",
    borderRadius: "1.79310625rem",
    fontFamily: "OpenSans-Bold",
    fontWeight: "400",
    letterSpacing: "-0.011em",
    textTransform: "capitalize",
    ...textOverflowEllipsStyle,
    ...centerStyle,
    background: "#49C4D8",
    color: "#212026",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    padding: "1.2rem 1rem",
    ":hover": {
      background: "#49C4D8",
      opacity: "90%",
    },
  };
};
export const AccountView = styled(Box)(({ style }) => {
  return {
    ...centerStyle,
    boxSizing: "border-box",
    background: "#212026",
    fontFamily: "OpenSans-Regular",
    fontWeight: "700",
    letterSpacing: "-0.011em",
    color: "#1CEEEE",
    borderRadius: "100%",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
  };
});
export const accountNameStyle = (getDomHeight) => {
  const x1 = getDomHeight / 1080;
  const exactHeight = 50.63 * x1;
  const exactWidth = 52 * x1;
  return {
    height: exactHeight,
    width: exactWidth,
    textTransform: "uppercase",
  };
};
