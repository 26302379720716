import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { centerStyle } from "../commonStyles";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";

export const ModalBodyContainer = styled(Box)(({ style }) => {
  return {
    width: getWidthRatio(1200),
    height: getHeightRatio(852),
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxSizing: "border-box",
    overflow: "hidden",
    "&:focus": { outline: "none" },
    ...style,
  };
});
export const ModalChildrenContainer = styled(Box)(({ style }) => {
  return {
    background: "#2F2F2F",
    width: "100%",
    height: "100%",
    boxShadow: "0px 4px 134px rgba(0, 0, 0, 0.61)",
    borderRadius: "20px",
    boxSizing: "border-box",
    ...centerStyle,
    ...style,
  };
});
export const PublishModalContainer = styled(Box)(({ style }) => {
  return {
    width: "100%",
    ...centerStyle,
    justifyContent: "space-between",
    ...style,
  };
});
export const PublishCancelIconContainer = styled(Box)(({ style }) => {
  return {
    width: "15%",
    padding: "0px 20px",
    height: "700px",
    cursor: "pointer",
    ...centerStyle,
    justifyContent: "end",
    alignItems: "start",
    ...style,
  };
});
export const PublishBackIcon = styled(Box)(({ style }) => {
  return {
    width: "51px",
    height: "51px",
    background: "#858585",
    borderRadius: "50%",
    ...centerStyle,
    ...style,
  };
});
