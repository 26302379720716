import {
  LOADER_ACTION,
  SNACK_BAR_MESSAGE,
  STORE_PROJECT_DATA,
} from "./constant";

export const storeProjectDatas = (data) => {
  return { type: STORE_PROJECT_DATA, payload: data };
};

export const loaderAction = (data) => {
  return { type: LOADER_ACTION, payload: data };
};

export const snackBarAction = (data) => {
  return { type: SNACK_BAR_MESSAGE, payload: data };
};
