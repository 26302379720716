export const imageFileValidation = (inputFile) => {
  const formats = ["image/jpeg", "image/png"];
  const isFormatSize = inputFile?.size < 250000;
  const isValidFileFormat = formats?.some((e) => e == inputFile?.type);

  if (inputFile?.type) {
    if (isValidFileFormat && isFormatSize) {
      return true;
    } else {
      return false;
    }
  }
};

export const gifFileValidation = (inputFile) => {
  const formats = ["image/gif"];
  const isFormatSize = inputFile?.size < 250000;
  const isValidFileFormat = formats?.some((e) => e == inputFile?.type);

  if (inputFile?.type) {
    if (isValidFileFormat && isFormatSize) {
      return true;
    } else {
      return false;
    }
  }
};

export const audioFileValidation = (inputFile) => {
  const formats = ["audio/mpeg"];
  const isFormatSize = inputFile?.size < 300000;
  const isValidFileFormat = formats?.some((e) => e == inputFile?.type);
  if (inputFile?.type) {
    if (isValidFileFormat && isFormatSize) {
      return true;
    } else {
      return false;
    }
  }
};

export const threeDFileValidation = (inputFile) => {
  const isFormatSize = inputFile?.size < 7000000;

  if (!inputFile?.type) {
    const splitName = inputFile?.name?.split(".");
    if (splitName[splitName?.length - 1] === "glb" && isFormatSize) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const videoFileValidation = (inputFile) => {
  const isFormatSize = inputFile?.size < 100000000;
  const formats = ["video/mp4", "video/mov", "video/wmv"];
  const isValidFileFormat = formats?.some((e) => e == inputFile?.type);
  if (inputFile?.type) {
    if (isValidFileFormat && isFormatSize) {
      return true;
    } else {
      return false;
    }
  }
};
