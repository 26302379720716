import { BaseRoutes, apiRoutes } from "../api/apiPath"
import { appApi as Axios } from "../api/config"

const getTemplateFilters = () => Axios.get(apiRoutes.templateFilters)

const getTemplatesWithFilters = (tags) =>
  Axios.post(apiRoutes.getTemplateWithFilters, { tags })

const getTemplates = () => Axios.get(BaseRoutes.TEMPLATES)

export { getTemplateFilters, getTemplatesWithFilters, getTemplates }
