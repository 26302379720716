import { Box } from "@mui/material";
import React from "react";
import {
  containerStyleHandle,
  HeadingText,
  iconWrapperStyle,
} from "../../styles/sharedComponents";
import { informationIcon } from "../../constants/icons";
import InformationBlob from "./InformationBlob";

export default function LibraryHeading({
  disabledHeading = false,
  heading,
  mandatory = false,
  icon,
  headingContianerStyle = {},
  handleIconClick = () => {},
  headingStyle = {},
  infoContent = () => {},
}) {
  return (
    <Box
      sx={{
        ...containerStyleHandle(
          headingContianerStyle,
          disabledHeading,
          mandatory
        ),
      }}
    >
      {disabledHeading ? (
        <></>
      ) : (
        <HeadingText style={headingStyle}>{heading}</HeadingText>
      )}
      {icon ? (
        <Box onClick={handleIconClick}>{icon}</Box>
      ) : mandatory ? (
        <InformationBlob content={infoContent()}>
          <Box sx={iconWrapperStyle}>{informationIcon}</Box>
        </InformationBlob>
      ) : (
        <></>
      )}
    </Box>
  );
}
