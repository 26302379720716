import React, { useState, useEffect } from "react";
import {
  LandingContainer,
  SignInButton,
  LandingPageLogoContainer,
  LandingPageLogo,
  MenuContainer,
  FooterContainer,
  FooterLogo,
  SocialIconContainer,
  SocialIcon,
  LandingPageBody,
} from "../../../styles/landingPage";
import LoginPage from "../login";
import logo from "../../../assets/icons/logo.png";
import landingPageLogo from "../../../assets/icons/landingPageLogo.png";
import linkedIn from "../../../assets/icons/linkedIn.svg";
import youTube from "../../../assets/icons/youTube.svg";
import twitter from "../../../assets/icons/twitter.svg";
import mail from "../../../assets/icons/mail.svg";
import error from "../../../assets/icons/error.png";
import { Divider } from "@mui/material";

export default function ErrorPage() {
  const [loginOpen, setLoginOpen] = useState(false);
  return (
    <>
      <LandingContainer>
        <LandingPageLogoContainer>
          <div style={{ width: "50%" }}>
            <LandingPageLogo src={logo} alt="logo"/>
          </div>

          <MenuContainer>
            <a>Templates</a>
            <a href="https://www.xtendr.co/about-us/" target="_blank">About us</a>
          </MenuContainer>

          <div style={{ width: "50%" }}>
            <SignInButton onClick={() => setLoginOpen(true)}>
              Get Started
            </SignInButton>
          </div>
        </LandingPageLogoContainer>

        <LandingPageBody>
        <div style={{
            position:'relative',
            height: '-webkit-fill-available',
            top: '1%',
            color: 'white',
          }}>
              <div style={{
                borderRadius: '48.625rem',
                background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(184, 104, 247, 0.21) 0%, rgba(217, 217, 217, 0.00) 100%)',
                width: '48.625rem',
                height: '48.125rem',
              }}></div>

              <div style={{
                borderRadius: '31.5625rem',
                background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(28, 238, 167, 0.20) 0%, rgba(217, 217, 217, 0.00) 100%)',
                width: '31.5625rem',
                height: '31.25rem',
                position: 'absolute',
                top: '70%',
                left: '45%',
                transform: 'translate(-50%, -50%)',
              }}></div>

              <div style={{
                borderRadius: '31.5625rem',
                background:'radial-gradient(44.36% 44.36% at 50.00% 50.00%, rgba(87, 23, 159, 0.34) 0%, rgba(217, 217, 217, 0.00) 100%)',
                width: '31.5625rem',
                height: '31.25rem',
                position: 'absolute',
                top: '10%',
                right: 0,
                transform: 'translate(-50%, -50%)',
              }}></div>
          </div>
          <div style={{
            color: 'white',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform:'translate(-50%, -50%',
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <img src={error} />
            <div>
              <p style={{
                textShadow: '0px 0px 47px rgba(21, 37, 52, 0.50)',
                fontFamily: 'Karla',
                fontSize: '6rem',
                fontWeight: '700',
                lineHeight: '6.64931rem', /* 110.822% */
                letterSpacing: '-0.06rem',
                margin: 0,
              }}>ERROR 404</p>
              <p style={{
                    textShadow: '0px 4px 14px #000',
                    fontFamily: 'Karla',
                    fontSize: '2.375rem',
                    fontWeight: '400',
                    lineHeight: '2.46875rem',
                    letterSpacing: '-0.02375rem',
                    background: 'linear-gradient(180deg, #FFF 0.43%, rgba(255, 255, 255, 0.31) 141.12%)',
                    backgroundClip: 'text',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent',
              }}>This page doesn’t exist. Go to the <br/> homepage to explore templates.</p>
            </div>
          </div>
        </LandingPageBody>

        <FooterContainer>
          <Divider style={{
            width: "100%",
            marginTop: "1rem",
            marginBottom: "1rem",
            borderColor: "white",
          }} variant="middle" />
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}>
            <FooterLogo src={landingPageLogo} alt="logo"/>
            <SocialIconContainer>
              <a style={{
                marginRight: '10%',
              }}>
                <SocialIcon src={linkedIn} />
              </a>
              <a href="https://youtube.com/@xtendr6258" style={{
                marginRight: '10%',
              }}>
                <SocialIcon src={youTube} />
              </a>
              <a style={{
                marginRight: '10%',
              }}>
               <SocialIcon src={twitter} />
              </a>
              <a href="mailto:info@xtendr.co">
                <SocialIcon src={mail} />
              </a>
            </SocialIconContainer>
          </div>
        </FooterContainer>
      </LandingContainer>

      <LoginPage open={loginOpen} handleClose={() => setLoginOpen(false)} />
    </>
  );
}
