import React, { useEffect, useRef, useState } from "react";
import {
  minimumHeightErrorMsg,
  minimumWidthErrorMsg,
} from "../../../../constants/templateCreation";
import {
  MainFrameContainer,
  MainScreenView,
  templateViewScreenModalStyle,
  // UndoButton,
  // UndoIcon,
  // UndoRedoButtonContainer,
  // UndoRedoButtonWrapper,
} from "../../../../styles/templateCreation/mainPreviewRenderer";
import CommonModal from "../../../shared/CommonModal";
import MainFrameRender from "./MainFrameRender";
import browser from "../../../../assets/icons/browser.svg";
// import redo from "../../../../assets/icons/redo.png";

export default function MainPreviewRenderer(props) {
  const {
    templateDetails,
    selectedScreen,
    selectedProperty,
    selectedSubProperty,
    selectedPropertyRenderKeys,
    handleDrop,
    selectedPropertyLevel,
    selectedType,
    selectedDarkMode,
    retrivedSourceFiles,
    updateRetrivedSourceFiles,
  } = props;

  const inputEl = useRef(null);

  //save mobile screen px size
  const [open, setOpen] = useState(false);
  const [mobileScreenWidth, setMobileScreenWidth] = useState(0);
  const [mobileScreenHeight, setMobileScreenHeight] = useState(0);

  useEffect(() => {
    setMobileScreenWidth(inputEl?.current?.offsetWidth);
    setMobileScreenHeight(inputEl?.current?.offsetHeight);
  }, [inputEl?.current?.offsetWidth, inputEl?.current?.offsetHeight]);

  return (
    <MainFrameContainer
      item
      xs={7}
      ref={inputEl}
      selectedDarkMode={selectedDarkMode}
    >
      <CommonModal
        open={open}
        disableHeader
        modalBodyConatinerStyle={templateViewScreenModalStyle}
      > 
        <img src={browser}  style={{margin:'auto', display: 'block'}}/>
        <p style={{
          fontSize:'2.25rem',
          fontFamily: 'OpenSans-Bold',
          textAlign: 'center',
          lineHeight: '2.75rem'
        }}>Your browser is too small </p>
        <p style={{
          textAlign:'center',
        }}>Resize your browser to be at least 750px wide, 600px Height to get back into creators mode </p>
      </CommonModal>

      <MainScreenView>
        <MainFrameRender
          inputEl={inputEl}
          templateDetails={templateDetails}
          selectedScreen={selectedScreen}
          selectedProperty={selectedProperty}
          selectedSubProperty={selectedSubProperty}
          handleDrop={handleDrop}
          selectedPropertyRenderKeys={selectedPropertyRenderKeys}
          selectedPropertyLevel={selectedPropertyLevel}
          mobileScreenWidth={mobileScreenWidth}
          setMobileScreenHeight={setMobileScreenHeight}
          selectedType={selectedType}
          retrivedSourceFiles={retrivedSourceFiles}
          updateRetrivedSourceFiles={updateRetrivedSourceFiles}
        />

        {/* <UndoRedoButtonContainer>
          <UndoRedoButtonWrapper>
            <UndoButton>
              <UndoIcon src={undo} alt="undo" />
            </UndoButton>
            <UndoButton>
              <UndoIcon src={redo} alt="redo" />
            </UndoButton>
          </UndoRedoButtonWrapper>
        </UndoRedoButtonContainer> */}
      </MainScreenView>
    </MainFrameContainer>
  );
}
