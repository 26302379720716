import styled from "@emotion/styled";
import { InputBase } from "@mui/material";
import { Box } from "@mui/system";
import { centerStyle } from "./commonStyles";

//ending screen button component
export const AdditionalButtonContainer = styled(Box)(() => {
  return {
    background: "#1B1B1B",
    borderRadius: "6px",
    padding: "5%",
    boxSizing: "border-box",
  };
});
export const AdditionalButtonHeading = styled(Box)({
  fontFamily: "OpenSans-Regular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1rem, 1.5vmin, 3rem)",
  lineHeight: "150%",
  letterSpacing: "-0.011em",
  color: "#FFFFFF",
});
export const CustomStyleGetUrlTextBox = styled(InputBase)({
  height: "30px",
  width: "200px",
  left: "0px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  borderTopLeftRadius: "4px",
  borderBottomLeftRadius: "4px",
  fontSize: "clamp(1.3rem, 1.5vmin, 4rem)",
  padding: "0px 5px",
});
export const DoneIconContainer = styled(Box)({
  height: "30px",
  left: "0px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
  fontSize: "clamp(1.3rem, 1.5vmin, 4rem)",
  padding: "0px 5px",
  color: "#1B1B1B",
  cursor: "pointer",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",

  "&:hover": {
    background: "#49C4D8",
    color: "#FFFFFF",
  },
  ...centerStyle,
});
