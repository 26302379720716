import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { centerStyle } from "../commonStyles";
import { getWidthRatio } from "../../utils/getDimensionalRatio";

export const TypeHeading = styled("p")({
  textAlign: "left",
  fontStyle: "normal",
  fontWeight: "400",
  color: "#49C4D8",
  fontSize: "clamp(1rem, 1.5vmin, 4rem)",
  letterSpacing: "-0.011em",
  textTransform: "capitalize",
  margin: "5px 0px",
});
export const HighLighterButton = styled("p")(({ isSelected }) => {
  const x1 = window.innerHeight / 1080;
  const exactWidth = 30 * x1;

  return {
    height: exactWidth,
    width: exactWidth,
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    background: isSelected ? "#49C4D8" : "#4C4C4C",
    color: isSelected ? "#2E2E2E" : "#FFFFFF",
    borderRadius: "10px",
    ...centerStyle,
    marginRight: getWidthRatio(20),
  };
});
export const TypeButtonWrapper = styled(Box)(() => {
  return {
    display: "flex",
    alignItems: "center",
  };
});
export const TypeContainerWrapper = styled(Box)({
  marginTop: "20px",
});
