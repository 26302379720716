import { snackBarAction } from "./actions";

export const initialState = [];
export const STORE_PROJECT_DATA = "STORE_PROJECT_DATA";
export const LOADER_ACTION = "LOADER_ACTION";
export const SNACK_BAR_MESSAGE = "SNACK_BAR_MESSAGE";

export const successMessage = (message) =>
  snackBarAction({
    color: "success",
    message: typeof message === "string" ? message : "Success ...",
    open: true,
  });

export const errorMessage = (message) =>
  snackBarAction({
    color: "error",
    message: typeof message === "string" ? message : "Something went wrong ...",
    open: true,
  });
