import React from "react";
import { libraryRenderList } from "../../constants/libraryRenderList";
import { LibraryComponentWrapper } from "../../styles/templateCreation/libraryComponentRender";
import ImageStyleLister from "./ImageStyleLister";
import CustomColorPalette from "./colorPalette/CustomColorPalette";
import EndScreenButtonComponent from "./EndScreenButtonComponent";
import FontPicker from "./FontPicker";
import ImageUploader from "./ImageUploader";
import ThreeDModelAnimation from "./ThreeDModelAnimation";
import TimerListContainer from "./TimerListContainer";
import { TypeHighlighter } from "./TypeHighlighter";
import TimerTuneList from "./TimerTuneList";
import { ImageSelectionList } from "./ImageSelectionList";
import VoiceTriggerTextBox from "./VoiceTriggerTextBox";
import MultiPlayerInput from "./MultiPlayerInput";
import MultipleUploader from "./MultipleUploader";
import { ImagePreviewer } from "./ImagePreviewer";
import CustomTextArea from "./CustomTextArea";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../constants/renderUiMatchActionClass";
import { ScoreSelector } from "./ScoreSelector";
import SharderApplyComponnets from "./SharderApplyComponnets";

export default function LibraryCommonComponentRender(props) {
  const {
    handleChangeSubPropertyDatas,
    selectedProperty,
    selectedSubProperty,
    setSelectedSubProperty,
    handleTypeBasedSelect,
    selectedType,
    selectedPropertyLevel,
    handleSelectPropertyLevel,
    selectedScreen,
    selectedMultiSubProperties,
    retrivedSourceFiles,
    updateRetrivedSourceFiles,
    handleScoreSelections,
    setSelectedMultiSubProperties,
  } = props;

  const assetUploadAndImageUploader = (
    <>
      <ScoreSelector
        header="Set Score"
        selectedPropertyLevel={selectedPropertyLevel}
        selectedSubProperty={selectedSubProperty}
        onClick={handleScoreSelections}
        options={selectedPropertyLevel?.options || []}
        isSelected={selectedPropertyLevel?.options?.some(
          (e) => e?.value === selectedSubProperty?.value?.value
        )}
      />
      <TypeHighlighter
        selectedType={selectedType}
        limit={selectedPropertyLevel?.max}
        selectedSubProperty={selectedSubProperty}
        selectedPropertyLevel={selectedPropertyLevel}
        handleTypeBasedSelect={handleTypeBasedSelect}
      />
    </>
  );

  const renderComponents = (checkComponent) => {
    switch (checkComponent) {
      case "colorPicker":
        const checkIsFontProperty = [
          PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC,
          ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW,
          ACTION_CLASSES.INSTRUCTIONS,
          PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW,
        ];

        return (
          <>
            {selectedProperty?.actionClass ===
            PREVIEW_ACTION_CLASSES.SHADER_TOGGLE ? (
              <SharderApplyComponnets
                handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
                selectedSubProperty={selectedSubProperty}
              />
            ) : (
              <></>
            )}

            {/* color picker view */}
            <CustomColorPalette
              handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
              selectedProperty={selectedProperty}
              selectedSubProperty={selectedSubProperty}
              selectedPropertyLevel={selectedPropertyLevel}
              fontProperty={
                checkIsFontProperty?.includes(selectedProperty?.actionClass) ||
                checkIsFontProperty?.includes(selectedSubProperty?.actionClass)
              }
              selectedScreen={selectedScreen}
            />
          </>
        );
      case "imageUploader":
        return (
          <ImageUploader
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            selectedPropertyLevel={selectedPropertyLevel}
          />
        );
      case "animationTypeContainer":
        return (
          <ThreeDModelAnimation
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
          />
        );
      case "MultipleUploaderContainer":
        return (
          <MultipleUploader
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            selectedPropertyLevel={selectedPropertyLevel}
          />
        );
      case "imageViewLister":
        return (
          <ImageStyleLister
            setSelectedSubProperty={setSelectedSubProperty}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedScreen={selectedScreen}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
          />
        );

      case "imageSelectionLister":
        return (
          <ImageSelectionList
            setSelectedSubProperty={setSelectedSubProperty}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            selectedMultiSubProperties={selectedMultiSubProperties}
            setSelectedMultiSubProperties={setSelectedMultiSubProperties}
          />
        );

      case "audioContainer":
        return (
          <ImageUploader
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            isAudio={true}
          />
        );
      case "fontPicker":
        const isFontFamilyOnlyRender = [
          selectedProperty.actionClass ===
            PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC,
        ];
        return (
          <>
            {/* font picker style view*/}
            <FontPicker
              handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
              selectedProperty={selectedProperty}
              selectedSubProperty={selectedSubProperty}
              selectedPropertyLevel={selectedPropertyLevel}
              selectedScreen={selectedScreen}
              isFontFamilyOnlyRender={isFontFamilyOnlyRender}
            />
          </>
        );
      case "endScreenButtonStyle":
        return (
          <EndScreenButtonComponent
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
          />
        );
      case "timerListContainer":
        return (
          <TimerListContainer
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          />
        );
      case "TimerTuneContainer":
        return (
          <TimerTuneList
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
          />
        );
      case "voiceTriggerTextContainer":
        return (
          <VoiceTriggerTextBox
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            setSelectedSubProperty={setSelectedSubProperty}
            selectedPropertyLevel={selectedPropertyLevel}
          />
        );
      case "numberInputContainer":
        return (
          <MultiPlayerInput
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            selectedPropertyLevel={selectedPropertyLevel}
          />
        );

      case "imagePreviewer":
        const getAssetKey = selectedProperty?.pageSections?.length
          ? selectedProperty?.pageSections
              ?.find((item) => item?.key === "style")
              ?.elements?.find(
                (item) => item?.actionClass === ACTION_CLASSES.STYLE_CONTAINER
              )?.value?.url
          : selectedProperty?.elements?.find(
              (ele) =>
                ele?.actionClass === ACTION_CLASSES.STYLE_CONTAINER ||
                ele?.actionClass === ACTION_CLASSES.TIMER_CONTAINER ||
                ele?.actionClass === ACTION_CLASSES.BUTTON_STYLE_WITH_CONTAINER
            )?.value?.url;

        return (
          <ImagePreviewer
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            isLibraryUsage={true}
            renderId="libraryPreviewer"
            retrivedSourceFiles={retrivedSourceFiles}
            updateRetrivedSourceFiles={updateRetrivedSourceFiles}
            assetKey={getAssetKey}
          />
        );

      case "textInputArea":
        return (
          <CustomTextArea
            value={selectedSubProperty?.value?.value}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            setSelectedSubProperty={setSelectedSubProperty}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
          />
        );

      case "assetUploadLimiter":
        if (
          selectedProperty?.actionClass === ACTION_CLASSES.ASSET_TREATURE_HUNT
        ) {
          if (
            selectedPropertyLevel?.actionClass ===
            ACTION_CLASSES.ASSET_UPLOAD_LIMITER
          ) {
            if (selectedSubProperty?.value?.type !== "default") {
              return assetUploadAndImageUploader;
            }
          } else return <></>;
        }

      default:
        return <></>;
    }
  };

  return (
    <LibraryComponentWrapper>
      {libraryRenderList?.[selectedSubProperty?.actionClass]?.map(
        (item, index) => (
          <React.Fragment key={index}>{renderComponents(item)}</React.Fragment>
        )
      )}
    </LibraryComponentWrapper>
  );
}
