import React, { useEffect, useState } from "react";
import NavBar from "../../shared/NavBar";
import TemplateCreationTools from "./TemplateCreationTools";
import { getApiServices, putApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import {
  Container,
  NavBarContainer,
  TemplateCreationContainer,
} from "../../../styles/layoutStyles";
import CommonFormModal from "../../shared/CommonFormModal";
import PreviewAndPublish from "./previewAndPublish";
import {
  previewAndPublishTabContentList,
  previewAndPublishTabList,
  PUBLISH_STATUS_LIST,
} from "../../../constants/constant";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../utils/getDimensionalRatio";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePaths";

export default function TemplateCreation() {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [projectUrl, setProjectUrl] = useState("");
  const [userCredentials, setUserCredentials] = useState({});
  const [rejectedStatus, setRejectedStatus] = useState(false);
  //save full template (or) project details
  const [templateDetails, setTemplateDetails] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const projectId = urlParams.get("projectId");
  const projectStatus = urlParams.get("projectStatus");
  const getProjectName = urlParams.get("projectName");

  const handleUserCredentials = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.users);
      setUserCredentials(data);
    } catch (err) {}
  };

  const handleSubmitPublish = async () => {
    if (templateDetails) {
      const sendAutoSaveTemplateData = {
        name: getProjectName || "Config dsf",
        version: "1.0.1-not-implemented",
        projectId: projectId || "",
        baseTemplateId: templateDetails?.id,
        screens: templateDetails?.uiConfig?.screens,
      };

      try {
        const { data } = await putApiServices(
          apiRoutes.autoSave,
          sendAutoSaveTemplateData
        );
      } catch (err) {}
    }
  };

  const handleBackToEdit = () => {
    setOpen(false);
  };

  const handlePreviewAndPublish = () => {
    setOpen(true);
    handleSubmitPublish();
  };

  useEffect(() => {
    handleUserCredentials();
  }, []);

  //handle check project editable or not
  useEffect(() => {
    if (projectStatus) {
      if (PUBLISH_STATUS_LIST.Draft !== projectStatus) setOpen(true);
      if (PUBLISH_STATUS_LIST.Rejected === projectStatus)
        setRejectedStatus(true);
    }
  }, [projectStatus]);

  return (
    <Container container>
      <NavBarContainer item xs={12}>
        <NavBar
          onSubmit={handlePreviewAndPublish}
          userCredentials={userCredentials}
        />
      </NavBarContainer>

      <TemplateCreationContainer item xs={12}>
        <TemplateCreationTools
          setProjectUrl={setProjectUrl}
          setTemplateDetails={setTemplateDetails}
          templateDetails={templateDetails}
        />
      </TemplateCreationContainer>

      <CommonFormModal
        open={open}
        modalBodyContainerStyle={{
          width: getWidthRatio(1506),
          height: getHeightRatio(834),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(1506),
          height: getHeightRatio(834),
        }}
        iconContainerHeight={{
          height: getHeightRatio(834),
        }}
        hanldeClose={
          PUBLISH_STATUS_LIST.Draft === projectStatus
            ? () => setOpen(false)
            : () => {
                navigate(ROUTE_PATHS.dashBoard);
                setOpen(false);
              }
        }
        backIcon={true}
      >
        <PreviewAndPublish
          tabList={previewAndPublishTabList({ rejectedStatus })}
          tabContentList={previewAndPublishTabContentList({
            projectUrl,
            handleBackToEdit,
            userCredentials,
            projectId,
          })}
          rejectedStatus={rejectedStatus}
        />
      </CommonFormModal>
    </Container>
  );
}
