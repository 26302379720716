import React from "react";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { OverLabel } from "../../styles/sharedComponents";

export function CustomTextField(props) {
  const {
    type,
    name,
    onChange,
    errors,
    touched,
    defaultValue,
    value,
    variant,
    onBlur,
    label,
    disabled,
    style,
    isViewMode,
    maxLength,
    fullWidth,
    fieldType,
    autoComplete,
    onkeydown,
    error,
    helpertext,
    placeholder,
    endAdornment,
    startAdornment,
    required,
    size,
    inputProps,
    overLabel,
  } = props;

  const handleKeyPress = (e) => {
    if (fieldType === "mobile" && e.keyCode !== 13) {
      return !/[0-9+-]/.test(e.key) && e.preventDefault();
    }
    if (fieldType === "alphaNumeric") {
      return !/[0-9A-Za-z-/:_]/.test(e.key) && e.preventDefault();
    }
    if (type === "number" && e.keyCode !== 13) {
      return !/[0-9]/.test(e.key) && e.preventDefault();
    }
    if (fieldType === "decimal") {
      return !/[0-9.]/.test(e.key) && e.preventDefault();
    }
  };

  return (
    <div>
      <OverLabel style={{ textAlign: "left" }}>{overLabel}</OverLabel>
      <TextField
        required={required || false}
        id="standard-basic"
        label={label}
        placeholder={placeholder}
        variant={variant ? variant : "outlined"}
        type={type || "text"}
        name={name}
        defaultValue={defaultValue}
        fullWidth={fullWidth || true}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        value={value || ""}
        size={size}
        sx={{ ...style, borderRadius: "10px" }}
        error={Boolean(error || (touched?.[name] && errors?.[name]))}
        helperText={
          error ||
          helpertext ||
          (touched?.[name] && errors?.[name] ? errors?.[name] : "")
        }
        InputProps={{
          onKeyPress: (e) => handleKeyPress(e),
          onKeyDown: (e) => onkeydown && onkeydown(e),
          readOnly: isViewMode,
          disabled: disabled,
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          ...inputProps,
        }}
        onInput={(e) => {
          e.target.value &&
            maxLength &&
            (e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, maxLength));
        }}
      />
    </div>
  );
}
