import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { getWidthRatio } from "../utils/getDimensionalRatio";
import { centerStyle } from "./commonStyles";

export const AssetsImageContainer = styled("img")(({ style }) => {
  return {
    width: "fit-content",
    height: "fit-content",
    overflow: "hidden",
    zIndex: 999,
    ...style,
  };
});
export const ShowText = styled(Box)(({ style }) => {
  return {
    fontFamily: "OpenSans-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1.5rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#000000",
    position: "absolute",
    top: "66.843373493975903%" || "10px",
    left: "78.62595419847328" || "10px",
    zIndex: 99,
    ...style,
  };
});
export const buttonRenderStyle = (item) => {
  return {
    height: item?.size?.height,
    width: item?.size?.width,
    top: item?.position?.y,
    left: item?.position?.x,
    position: "absolute",
    fontSize: `${item?.fontSize}px`,
    color: item?.fontColor || "#000000",
    fontFamily: item?.font || "OpenSans-Regular",
    overflow: "hidden",
    fontWeight: item?.bold ? "bold" : "normal",
    fontStyle: item?.italic ? "italic" : "normal",
    textDecoration: item?.underline ? "underline" : "none",

    "&:hover": {
      background: item?.color || "#fff",
      color: item?.fontColor || "#000000",
    },
    padding: "0px 5px",
    borderRadius: "26px",
  };
};
export const meetAndGreetEndScreenStyle = {
  backgroundColor: "#1B1B1B",
  left: "9.923664122137405%",
  top: "5.516431924882629%",
  width: "80.1526717557252%",
  height: "65.72769953051643%",
  overflow: "hidden",
  borderRadius: "5px",
  borderBottomLeftRadius: "30px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  position: "absolute",
};
export const meetAndGreetReloadIconStyle = {
  left: "82.095238%",
  top: "0%",
  width: "18%",
  height: "10%",
  position: "absolute",
};
export const InstructionContainer = styled(Box)(
  ({ getBackground, instructionData }) => {
    return {
      background: getBackground,
      height: instructionData ? instructionData?.value?.size?.height : "68%",
      width: instructionData ? instructionData?.value?.size?.width : "68%",
      top: instructionData ? instructionData?.value?.position?.y : "16%",
      position: "absolute",
      left: instructionData ? instructionData?.value?.position?.x : "17%",
      margin: "auto",
      border: "1.07143px dashed #FFFFFF",
      zIndex: 200,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    };
  }
);
export const InstSectionText = styled(Box)(({ fontData }) => {
  return {
    fontFamily: fontData?.font || "OpenSans-Regular",
    fontStyle: fontData?.italic ? "italic" : "normal",
    fontWeight: fontData?.bold ? "bold" : "400",
    textDecoration: fontData?.underline ? "underline" : "none",
    lineHeight: "150%",
    textAlign: "center",
    letterSpacing: "-0.011em",
    color: fontData?.fontColor || "#FFFFFF",
    padding: "0 5%",
    position: "sticky",
    top: "21%",
    fontSize: "clamp(0.4rem, 1.3vh, 2rem)",
  };
});
export const CollectionImageSections = styled(Box)(() => {
  return {
    ...centerStyle,
    justifyContent: "space-evenly",
    top: "33%",
    position: "sticky",
  };
});
export const instructionCollectableStyle = {
  backgroundColor: "#FFFFFF21",
  width: getWidthRatio(76.86),
  height: getWidthRatio(71.44),
  borderRadius: "50%",
  ...centerStyle,
  flexDirection: "column",
  position: "relative",
};
export const aviodableParentContainerStyle = {
  ...centerStyle,
  justifyContent: "space-evenly",
  top: "60%",
  position: "sticky",
};
export const aviodableImageContainer = {
  backgroundColor: "#FFFFFF21",
  width: getWidthRatio(76.86),
  height: getWidthRatio(71.44),
  borderRadius: "50%",
  ...centerStyle,
  flexDirection: "column",
  position: "relative",
};
export const collectiableTextStyle = {
  position: "absolute",
  fontFamily: "OpenSans-Regular",
  fontStyle: "normal",
  lineHeight: "150%",
  textAlign: "center",
  letterSpacing: "-0.011em",
  fontWeight: "bold",
  color: "#FFFFFF",
  bottom: "-22%",
  left: "37%",
  fontSize: "clamp(0.6rem, 1.5vh, 2rem)",
};

export const instructionCommonTextStyle = (fontData) => {
  return {
    margin: "0px auto",
    top: "49%",
    position: "sticky",
    fontFamily: fontData?.font || "OpenSans-Regular",
    fontStyle: fontData?.italic ? "italic" : "normal",
    fontWeight: fontData?.bold ? "bold" : "400",
    textDecoration: fontData?.underline ? "underline" : "none",
    lineHeight: "150%",
    textAlign: "center",
    letterSpacing: "-0.011em",
    color: fontData?.fontColor || "#FFFFFF",
    padding: "20px",
    fontSize: "clamp(0.4rem, 1.3vh, 2rem)",
  };
};
export const instructionButtonStyle = {
  fontFamily: "OpenSans-Regular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1rem,1.5vh, 2rem)",
  lineHeight: "27px",
  textTransform: "uppercase",
  color: "#FFFFFF",
};
export const instructionButtonPosition = {
  position: {
    x: "30.672862%",
    y: "85.228916%",
  },
  size: {
    width: "41.63568773234201%",
    height: "8.777969018932874%",
  },
};
