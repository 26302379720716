import DashBoard from "../components/pages/dash-board/DashBoard";
import TemplateDashBoard from "../components/pages/dash-board/components/TemplateDashBoard";
import { ROUTE_PATHS } from "./routePaths";
import TemplateCreation from "../components/pages/templateCreation";
import PasswordReset from "../components/pages/reset-password";

export const ROUTES = [
  {
    path: ROUTE_PATHS.dashBoard,
    Component: DashBoard,
    exact: true,
  },
  {
    path: ROUTE_PATHS.templateDashBoard,
    Component: TemplateDashBoard,
    exact: true,
  },
  {
    path: ROUTE_PATHS.selectedTemplateDashBoard,
    Component: TemplateDashBoard,
    exact: true,
  },
  {
    path: ROUTE_PATHS.templateCreation,
    Component: TemplateCreation,
    exact: true,
  },
];
