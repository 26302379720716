import styled from "@emotion/styled";
import { InputBase, Slider } from "@mui/material";
import { Box } from "@mui/system";
import { AlphaPicker, HuePicker } from "react-color";
import { centerStyle } from "./commonStyles";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";

//color palette styles
export const ColorPickerContainer = styled(Box)({
  width: "100%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  height: `${getHeightRatio(200)}px`,
});
export const ColorPickerWrapper = styled(Box)((props) => ({
  width: "100%",
  boxSizing: "border-box",
  background: "#383838",
  boxShadow: "0px 3.15171px 42px rgba(0, 0, 0, 0.07)",
  borderRadius: "8.6672px",
  padding: "15px 0px",
  height: "fit-content",
}));

//alpha picker style
export const CustomAlphaPicker = styled(AlphaPicker)({
  "&.alpha-picker": {
    width: "100% !important",
    height: `${getHeightRatio(9.25)}px !important`,
    cursor: "pointer",
    margin: "5% 0",

    "div:nth-child(2)": {
      borderRadius: "13.1007px !important",
    },
    "div:nth-child(3)": {
      margin: "0px 8px 0px 0px !important",
    },
  },
});
export const CustomAlphaPickerPointer = styled(Box)({
  width: `${getWidthRatio(6)}px !important`,
  height: `${getHeightRatio(6)}px !important`,
  borderRadius: "50%",
  boxShadow: "0 0 3px rgba(0, 0, 0, 0.5) !important",
  border: "2.5px solid #FFFFFF",
});

//hue picker
export const CustomHuePicker = styled(HuePicker)({
  "&.hue-picker": {
    width: "100% !important",
    height: `${getHeightRatio(7.88)}px !important`,
    boxSizing: "border-box",
    cursor: "pointer",
    margin: "5% 0",

    "div:nth-child(1)": {
      borderRadius: "15.7585px !important",
    },
    "div:nth-child(3)": {
      margin: "0px 18px 0px 0px !important",
    },
  },
});
export const CustomHuePickerPointer = styled(Box)({
  width: `${getWidthRatio(4.8)}px !important`,
  height: `${getHeightRatio(4)}px !important`,
  borderRadius: "50%",
  boxShadow: "0 0 3px rgba(0, 0, 0, 0.5) !important",
  border: "2px solid #FFFFFF",
});

//color text box
export const ColorPickerTextBoxContainer = styled(Box)({
  margin: "16px",
  width: "auto",
  background: "#1C1C1C",
  borderRadius: "7.82669px",
  ...centerStyle,
  justifyContent: "space-between",
  padding: "0px 10px",
  aspectRatio: "2/0.4",
});
export const ColorViewContainer = styled(Box)({
  width: "20%",
  ...centerStyle,
});
export const ColorView = styled(Box)({
  width: `${getWidthRatio(20)}px`,
  height: `${getHeightRatio(20)}px`,
  borderRadius: "50%",
});
export const ColorTextBoxContainer = styled(Box)({
  width: "40%",
  height: "19px",
  boxSizing: "border-box",
  ...centerStyle,
});
export const ColorTextBox = styled(InputBase)(({ style }) => {
  return {
    height: "100% !important",
    padding: "5px",
    fontFamily: "OpenSans-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(0.8rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    textAlign: "center",
    ...style,
  };
});
export const ColorTextOpacityBoxContainer = styled(Box)(({ style }) => {
  return {
    height: `${getHeightRatio(19)}px`,
    width: "20%",
    boxSizing: "border-box",
    fontSize: "clamp(1rem, 1vmin, 4rem) !important",

    ...centerStyle,
    ...style,
  };
});
export const ColorListContainer = styled(Box)(({ style }) => {
  return {
    margin: "10px 0px",
    paddingTop: "2px",
    ...style,
  };
});

//gradiant color palette styles
export const colorDegreeTextBoxStyle = {
  height: `${getHeightRatio(14)}px`,
  fontSize: "clamp(1rem, 1.5vmin, 4rem) !important",
  minWidth: "70%",
  marginTop: "2px",
};
//range bar style
export const CustomSlider = styled(Slider)(({ background }) => {
  return {
    color: "transparent",
    background: background
      ? `${background} !important`
      : "linear-gradient(90deg, #16A1CE 1.39%, #391EB9 98.8%)",
    height: `${getHeightRatio(9)}px`,
    width: "100%",
    borderRadius: "3px",
    padding: "0px",
    margin: "5% 0",

    "& .MuiSlider-track": {
      position: "relative",
      background: background
        ? `${background}`
        : "linear-gradient(90deg, #16A1CE 1.39%, #391EB9 98.8%)",
      height: `${getHeightRatio(9)}px`,
      width: "100%",
      borderRadius: "3px",
      border: "0",
      opacity: 1,
    },
    "& .MuiSlider-rail": {
      cursor: "pointer",
      background: background
        ? `${background}`
        : "linear-gradient(90deg, #16A1CE 1.39%, #391EB9 98.8%)",
      opacity: 1,
      height: "8px",
      width: "100%",
      borderRadius: "3px",
      border: "0",
    },
    "& .MuiSlider-mark": {
      display: "none",
    },
    "& .MuiSlider-thumb": {
      position: "absolute",
      width: `${getWidthRatio(12)}px`,
      height: `${getHeightRatio(12)}px`,

      boxSizing: "border-box",
      outline: 0,
      ...centerStyle,
      transition:
        "box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      transform: "translate(-50%, -50%)",
      border: "1px solid #FFFFFF",
      borderRadius: "1px",
      boxShadow: "none",

      "&:hover": {
        boxShadow: "none",
        width: `${getWidthRatio(14)}px`,
        height: `${getHeightRatio(14)}px`,
      },
      "&:active": {
        boxShadow: "none",
        width: `${getWidthRatio(12)}px`,
        height: `${getHeightRatio(12)}px`,
      },
    },
    "& .MuiSlider-markLabel": {
      display: "none",
    },
    "& .MuiSlider-valueLabel": {
      display: "none",
    },
  };
});
