import { Box, Button, Grid, Input, InputBase, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getApiServices, postApiServices } from "../../../../../api/api";
import { apiRoutes } from "../../../../../api/apiPath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  ListDisplayContainer,
  PageHeader,
  StatusContainer,
  StatusHeader,
  TemplateListContainer,
} from "../styled/DashBoardStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../../routes/routePaths";
import Cookies from "universal-cookie";
import { errorMessage, successMessage } from "../../../../../redux/constant";
import { useDispatch } from "react-redux";
import IconWrapper from "../../../../shared/IconWrapper";
import { LoginStyledInput, visibilityIconStyle } from "../../../../../styles/sharedComponents";
const Settings = () => {
  const [selectedStatus, setSelectedStatus] = useState('YourAccount');
  const [userCredentials, setUserCredentials] = useState({});
  const [credentials, updatedCredentials] = useState({});
  const [passwordChangeError, setPasswordChangeError] = useState("");
  const [showPassword, updatedShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  console.log('cookies', cookies.get('jwtToken'))
  const resetToken = cookies.get('jwtToken')
  const handleFilterChange = ({ value }) => setSelectedStatus(value);

  const handleOldPasswordChange = (event) => {
    console.log(event.target.value);
    credentials.Password = event.target.value
  };

  const handleNewPasswordChange = (event) => {
    console.log(event.target.value);
    credentials.newPassword = event.target.value
  };

  const handleConfirmPasswordChange = (event) => {
    console.log(event.target.value);
    credentials.confirmPassword = event.target.value
  };

  const handleUserCredentials = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.users);
      setUserCredentials(data);
    } catch (err) {}
  };

  useEffect(() => {
    handleUserCredentials();
  }, []);

  const SettingsConfig = {
    YourAccount: {
      value: "YourAccount",
      label: "Your Account",
    },   
    PurchaseHistory: {
      value: "PurchaseHistory",
      label: "Purchase History",
    },
  }
  const renderHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          position: "sticky",
        }}
      >
        <PageHeader>Settings</PageHeader>
        <StatusContainer>
          <Grid container sx={{ padding: "1rem 0" }}>
            {Object.values(SettingsConfig)?.map((statusConfig, index) => (
              <Grid
                key={index}
                item
                sx={{ cursor: "pointer", marginRight: "4rem" }}
                onClick={() => handleFilterChange(statusConfig)}
              >
                <StatusHeader
                  doesShowBorder={selectedStatus === statusConfig.value}
                >
                  {statusConfig.label}
                </StatusHeader>
              </Grid>
            ))}
          </Grid>
        </StatusContainer>
      </Box>
    );
  };

  const handleLogout = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.logout);
      navigate(ROUTE_PATHS.landingPage);
      cookies.remove("jwtToken");
      dispatch(successMessage(data?.message || "Logout successfully ..."));
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  const handlePasswordChange = async () =>{
    // Validate new password and confirm password
    if (credentials.newPassword !== credentials.confirmPassword) {
      setPasswordChangeError("Passwords do not match");
      return;
    } else if(!credentials.newPassword || !credentials.confirmPassword){
      setPasswordChangeError("Enter Valid Fields");
      return;
    } else {
      setPasswordChangeError('')
      try {
        await postApiServices(apiRoutes.resetPass, {
          password: credentials.newPassword,
          token: resetToken,
        });
        credentials.newPassword = ''
        credentials.confirmPassword = ''
        dispatch(successMessage("Password changed Successfully"));
      } catch (err) {
        dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
      }
    }
  }

  const SettingList = () => {
    if(selectedStatus === 'YourAccount'){
      return (
        <TemplateListContainer>
          <Box >
            <Typography variant="h4">Profile Name</Typography>
            <Box style={{
              marginTop: '2rem',
              width: '100%',
              display: 'flex',
            }}>
              <InputBase defaultValue={userCredentials?.name} style={{
                background: 'white',
                padding: "0px 30px",
                borderRadius: "25px 10px",
                fontSize: '1.5rem',
                width: '50%',
                height: '4rem',
                marginBottom: '2.5rem'
              }}/>
            </Box>
          </Box>
          <Box>
            <Typography variant="h4">Email Address</Typography>
            <Box style={{
              marginTop: '1rem',
              width: '100%',
              display: 'flex',
            }}>
              <InputBase defaultValue={userCredentials?.email} style={{
                background: 'white',
                padding: "0px 30px",
                borderRadius: "25px 10px",
                fontSize: '1.5rem',
                width: '50%',
                height: '4rem',
                marginBottom: '5rem'
              }} disabled/>
            </Box>
          </Box>
          <Box style={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h4">Change your password</Typography>
            <div style={{ position: "relative", width: '50%' }}>
              <InputBase
                type="password"
                name="newPassword"
                id='newPassword'
                placeholder="Type in your New Password"
                value={credentials.newPassword}
                onChange={handleNewPasswordChange}
                style={{
                  background: "white",
                  padding: "0px 30px",
                  borderRadius: "25px 10px",
                  fontSize: "1.5rem",
                  width: "100%",
                  height: "4rem",
                  marginTop: "2.5rem",
                  marginBottom: "2.5rem",
                }}
              />
            </div>
            <InputBase
              type="password"
              name="confirmPassword"
              id='confirmPassword'
              value={credentials.confirmPassword}
              placeholder="Repeat your Password"
              onChange={handleConfirmPasswordChange}
              style={{
                background: "white",
                padding: "0px 30px",
                borderRadius: "25px 10px",
                fontSize: "1.5rem",
                width: "50%",
                height: "4rem",
                marginBottom: "0",
              }}
            />
            {passwordChangeError && (
              <Typography
                variant="body1" 
                color="error" 
                style={{
                padding: '1rem',
                paddingBottom:0}}>
                {passwordChangeError}
              </Typography>
            )}
            <Button variant="contained" 
            onClick={handlePasswordChange}
            style={{
              width: 'fit-content',
              fontSize: '1.5rem',
              background: 'white',
              color: 'black',
              marginTop: '1rem'
            }}>
              Change password
            </Button>
          </Box>
          {/* <Button variant="contained" 
          onClick={handleLogout}
          style={{
            marginTop: '5rem',
            width: 'fit-content',
            fontSize: '1.5rem',
            background: 'white',
            color: 'black',
          }}>
            LogOut
          </Button> */}
        </TemplateListContainer>
      );
    } else {
      return (
        <TemplateListContainer>
        </TemplateListContainer>
      );
    }
  };

  return (
    <>
      <ListDisplayContainer>

        {renderHeader()}

        <SettingList/>
      </ListDisplayContainer>
    </>
  );
};

export default Settings;
