import React from "react";
import { useDrop } from "react-dnd";
import { MainFrameScreenContainer } from "../../styles/templateCreationTools";

export default function Drop({ children, handleDrop }) {
  const [collectedProps, drop] = useDrop({
    accept: "div",
    drop: (item, monitor) => {
      let tempData = { ...item };
      const { x, y } = monitor?.getClientOffset();

      handleDrop(
        { ...tempData }
        // { x, y }
      );
    },
  });

  return (
    <MainFrameScreenContainer ref={drop}>{children}</MainFrameScreenContainer>
  );
}
