import React, { useEffect, useState } from "react";
import CustomSolidColorPicker from "./CustomSolidColorPicker";
import CustomGradientColorPicker from "./CustomGradientColorPicker";
import { ColorPickerWrapper } from "../../../styles/colorPalette";
import CustomMenu from "../CustomMenu";
import {
  PREVIEW_ACTION_CLASSES,
  ACTION_CLASSES,
} from "../../../constants/renderUiMatchActionClass";

const colorTypes = [{ label: "Solid" }, { label: "Gradient" }];

export default function CustomColorPalette({
  handleChangeSubPropertyDatas = () => {},
  selectedProperty,
  selectedSubProperty,
  fontProperty = false,
  selectedScreen,
  selectedPropertyLevel = {},
}) {
  const checkInstructionColor =
    selectedProperty?.actionClass === ACTION_CLASSES.INSTRUCTIONS &&
    selectedPropertyLevel?.actionClass === ACTION_CLASSES.BACKGROUND
      ? selectedSubProperty?.value?.gradient
        ? selectedSubProperty?.value?.gradient
        : selectedSubProperty?.value?.color
      : "";
  const getInitialGradientColor = checkInstructionColor
    ? checkInstructionColor
    : fontProperty
    ? selectedSubProperty?.value?.color
    : selectedSubProperty?.value?.gradient;

  const colorType = getInitialGradientColor?.includes("gradient");

  const [colorTypeName, setColorTypeName] = useState(
    colorType ? "Gradient" : "Solid"
  );

  const onlySolidColor = () => {
    const checkIsNotGradiant = [
      PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW,
      PREVIEW_ACTION_CLASSES.SVG_PREVIEW,
      PREVIEW_ACTION_CLASSES.TEXT_PREVIEW,
      PREVIEW_ACTION_CLASSES.LEADER_BOARD_PLAYERS,
      PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC,
      ACTION_CLASSES.MULTI_SECTION_CONTAINER,
      ACTION_CLASSES.BUTTON_CONTAINER,
      ACTION_CLASSES.TEXT_CONTAINER,
    ];

    return (
      checkIsNotGradiant?.includes(selectedProperty.actionClass) ||
      checkIsNotGradiant?.includes(selectedSubProperty.actionClass)
    );
  };

  useEffect(() => {
    const getInitialGradientColor = checkInstructionColor
      ? checkInstructionColor
      : fontProperty
      ? selectedSubProperty?.value?.color
      : selectedSubProperty?.value?.gradient;
    const colorType = getInitialGradientColor?.includes("gradient");

    if (colorType) {
      setColorTypeName("Gradient");
    } else {
      setColorTypeName("Solid");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.key, selectedSubProperty?.key, selectedScreen?.key]);

  return (
    <>
      <ColorPickerWrapper>
        <CustomMenu
          options={colorTypes}
          defalutValue={colorTypeName}
          handleChange={(colorType) => setColorTypeName(colorType?.label)}
          disableDropDownIcon={onlySolidColor()}
        />

        {colorTypeName === "Solid" ? (
          <CustomSolidColorPicker
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            selectedScreen={selectedScreen}
            fontProperty={fontProperty}
          />
        ) : (
          <CustomGradientColorPicker
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            selectedProperty={selectedProperty}
            selectedSubProperty={selectedSubProperty}
            selectedScreen={selectedScreen}
            fontProperty={fontProperty}
          />
        )}
      </ColorPickerWrapper>
    </>
  );
}
