import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { centerStyle } from "../commonStyles";

//EditPanel Screen Name list styles
export const EditPanelScreenList = styled(Box)(
  ({ isSelected, style, isOpen }) => {
    const x1 = window.innerWidth / 1920;
    const exactHeight = 118 * x1;
    const exactWidth = 113 * x1;

    return {
      ...centerStyle,
      boxSizing: "border-box",
      height: exactHeight,
      width: exactWidth,
      overflow: "hidden",
      flexDirection: "column",
      cursor: "pointer",
      margin: "0px 0px",
      background: isSelected ? "#3F3F3F" : isOpen ? "#202020" : "#2E2E2E",
      padding: "0px 10px 5px",
      boxShadow: "0px 3.81503px 62.948px rgba(0, 0, 0, 0.08)",
      borderTopRightRadius: "5px",
      borderBottomRightRadius: "5px",
      ...style,
    };
  }
);
export const ScreenIcon = styled(Box)(({ isSelected, theme }) => {
  return {
    ...centerStyle,
    height: "clamp(18px, 0.5vh, 60px)",
    width: "clamp(18px, 1vh, 60px)",
    margin: "10px 0px",

    "@media screen and (min-width: 2200px)": {
      height: "clamp(35px, 0.5vh, 60px)",
      width: "clamp(35px, 1vh, 60px)",
    },

    "svg path": {
      fill: isSelected ? "#49C4D8" : "#6A6A6A",
    },
    "svg path.reset": {
      fill: "none",
      stroke: isSelected ? "#49C4D8" : "#6A6A6A",
    },
    "svg path.darkloader": {
      fill: isSelected ? "url(#paint0_linear_1_217)" : "#6A6A6A",
    },
    "svg path.lightloader": {
      fill: isSelected ? "url(#paint0_linear_1_218)" : "#6A6A6A",
    },
  };
});
export const ScreenLabel = styled(Box)(({ isSelected }) => {
  return {
    ...centerStyle,
    width: "67px",
    margin: "5px 0px",
    overflowWrap: "break-word",
    fontFamily: "OpenSans-Bold",
    fontWeight: "400",
    fontSize: " clamp(1rem, 1vmin, 4rem)",
    textAlign: "center",
    letterSpacing: "-0.011em",
    textTransform: "uppercase",
    color: isSelected ? "#49C4D8" : "#6A6A6A",
  };
});
//dark and reset style
export const darkAndResetStyle = {
  marginTop: "10px",
  padding: "18% 0",
  boxSizing: "border-box",
};
