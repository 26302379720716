import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const LoaderContainer = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  width: "100vw",
  zIndex: 9999,
  pointerEvents: "none",
});
