import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal } from "@mui/material";
import {
  PreviewContentContainer,
  PreviewModalContainer,
  PreviewModalTitle,
  PreviewModalTitleContainer,
} from "../../styles/sharedComponents";
import VideoComponent from "../VideoComponent";
import { AssetsImageContainer } from "../../styles/renderMainscreenComponents";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import { ModelImageRenderer } from "./3DImageRenderer";

export default function PreviewModal(props) {
  const {
    open = false,
    children,
    isVideo = false,
    renderPath = "",
    buttonChild = () => {},
    title,
    closeHanler = () => {},
    modalContainerStyle = {},
    modalBodyConatinerStyle = {},
    disableHeader = false,
    disabledCancel = false,
    isThreeD = false,
  } = props;

  return (
    <Modal open={open} onClose={disabledCancel ? () => {} : closeHanler}>
      <div>
        <PreviewModalContainer style={modalContainerStyle}>
          {disableHeader ? (
            <></>
          ) : (
            <PreviewModalTitleContainer>
              <PreviewModalTitle>{title}</PreviewModalTitle>
              {disabledCancel ? (
                <></>
              ) : (
                <IconButton sx={{ color: "#FFFFFF" }} onClick={closeHanler}>
                  <CloseIcon />
                </IconButton>
              )}
            </PreviewModalTitleContainer>
          )}

          <PreviewContentContainer style={modalBodyConatinerStyle}>
            {children ? (
              children
            ) : isVideo ? (
              <VideoComponent videoPath={renderPath} controls={true} />
            ) : isThreeD ? (
              <ModelImageRenderer
                element={{
                  assetUrl: renderPath,
                  name: "threeDAsset",
                  size: {
                    width: getWidthRatio(1000),
                    height: getHeightRatio(600),
                  },
                }}
                configStyle={true}
              />
            ) : (
              <img
                src={renderPath}
                style={{
                  width: "fit-content",
                  height: "-webkit-fill-available",
                  zIndex: 999,
                }}
                alt={"view list"}
              />
            )}
          </PreviewContentContainer>
        </PreviewModalContainer>
      </div>
    </Modal>
  );
}
