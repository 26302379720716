import React, { useEffect, useState } from "react";
import { getApiServices } from "../../api/api";
import { apiRoutes } from "../../api/apiPath";
import queryString from "query-string";
import {
  CameraLabel,
  ImageView,
  ImageViewContainer,
  ImageViewListerContainer,
  ImageViewListerLabelWrap,
  ImageViewListerWrap,
} from "../../styles/sharedComponents";
import Drag from "./Drag";
import xtendrEmptyImage from "../../assets/icons/xtendrEmptyImage.jpg";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../constants/renderUiMatchActionClass";

export default function ImageStyleLister({
  handleChangeSubPropertyDatas = () => {},
  selectedScreen,
  selectedProperty,
  selectedSubProperty = {},
  disabledHeading = false,
}) {
  const [assetList, setAssetList] = useState([]);

  const getAllAssetsByType = async () => {
    try {
      const selectedSubPropertyValue = selectedSubProperty?.value;
      const checkAudio = selectedSubPropertyValue?.type === "audio";
      const type = checkAudio
        ? "default"
        : selectedSubProperty?.actionClass === ACTION_CLASSES.BUTTON_CONTAINER
        ? "button"
        : selectedSubPropertyValue?.type || "logo";
      const fileType = checkAudio
        ? "audio"
        : selectedSubPropertyValue?.fileType || "2D";
      const params = {
        type,
        fileType,
        metaType: selectedSubPropertyValue?.metaType || "",
        tenantUploads: false,
      };
      const query = queryString.stringify(params, { arrayFormat: "bracket" });
      const getPath = `${apiRoutes.getAllAssetsByType}?${query}`;
      const { data } = await getApiServices(getPath);
      setAssetList(data);
    } catch (err) {}
  };

  const handleImageChange = (imageData) => {
    if (
      selectedProperty?.actionClass === PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
    ) {
      const buttonUrlSet = selectedProperty?.value?.buttons?.map(
        (buttonData) => {
          return {
            ...buttonData,
            url: imageData?.meta?.assetKey,
            style: imageData?.meta?.name,
            previewUrl: imageData?.previewUrl,
          };
        }
      );

      const buttonElementsData = selectedProperty?.elements?.map((item) => {
        if (selectedSubProperty?.key === item?.key) {
          return {
            ...item,
            value: {
              ...item?.value,
              ...imageData,
              url: imageData?.meta?.assetKey,
              previewUrl: imageData?.previewUrl,
              style: imageData?.meta?.name,
            },
          };
        } else return item;
      });

      const propertyData = {
        ...selectedProperty,
        elements: buttonElementsData,
        value: {
          buttons: buttonUrlSet,
        },
      };
      handleChangeSubPropertyDatas({ ...propertyData, nested: true });
    } else {
      handleChangeSubPropertyDatas({
        ...imageData,
        url: imageData?.meta?.assetKey,
        previewUrl: imageData?.previewUrl,
        style: imageData?.meta?.name,
      });
    }
  };

  const handleSelectedImageHighLight = (imageData) => {
    if (
      selectedProperty?.actionClass === PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
    ) {
      return (
        imageData?.meta?.assetKey === selectedProperty?.value?.buttons?.[0]?.url
      );
    } else
      return (
        imageData?.meta?.assetKey === selectedSubProperty?.value?.meta?.assetKey
      );
  };

  //handle drag and drop data send
  const handleDragAndDropData = (imageData) => {
    if (
      selectedProperty?.actionClass === PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
    ) {
      const buttonUrlSet = selectedProperty?.value?.buttons?.map(
        (buttonData) => {
          return {
            ...buttonData,
            url: imageData?.meta?.assetKey,
            previewUrl: imageData?.previewUrl,
            style: imageData?.meta?.name,
          };
        }
      );

      const buttonElementsData = selectedProperty?.elements?.map((item) => {
        if (selectedSubProperty?.key === item?.key) {
          return {
            ...item,
            value: { ...item?.value, ...imageData },
          };
        } else return item;
      });

      const propertyData = {
        ...selectedProperty,
        elements: buttonElementsData,
        value: {
          buttons: buttonUrlSet,
        },
      };
      return { ...propertyData, nested: true };
    } else {
      return {
        ...imageData,
        url: imageData?.meta?.assetKey,
        previewUrl: imageData?.previewUrl,
        style: imageData?.meta?.name,
      };
    }
  };

  useEffect(() => {
    getAllAssetsByType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedScreen?.key, selectedProperty?.key, selectedSubProperty?.key]);

  useEffect(() => {
    if (assetList?.data?.assets?.length) {
      if (
        !selectedSubProperty?.value?.url ||
        !selectedSubProperty?.value?.doesQueryResultWithFile
      ) {
        const image = assetList?.data?.assets?.[0];
        handleImageChange(image);
      }
    }
  }, [assetList?.data?.assets?.length]);

  return (
    <ImageViewListerContainer>
      <ImageViewListerWrap>
        {assetList?.data?.assets?.map((item, index) => {
          return (
            <ImageViewListerLabelWrap key={index}>
              <CameraLabel
                key={index}
                isSelected={handleSelectedImageHighLight(item)}
              >{`Style${index + 1}`}</CameraLabel>
              <ImageViewContainer
                style={{ width: "100%" }}
                isSelected={handleSelectedImageHighLight(item)}
                onClick={() => handleImageChange(item)}
              >
                <Drag
                  dragData={{
                    ...handleDragAndDropData(item),
                  }}
                >
                  <ImageView
                    src={item?.previewUrl || xtendrEmptyImage}
                    alt="image"
                  />
                </Drag>
              </ImageViewContainer>
            </ImageViewListerLabelWrap>
          );
        })}
      </ImageViewListerWrap>
    </ImageViewListerContainer>
  );
}
