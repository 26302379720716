import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import { centerStyle } from "../commonStyles";

export const MainFrameContainer = styled(Grid)((props) => {
  const { selectedDarkMode } = props;
  return {
    boxSizing: "border-box",
    // padding: "30px 5px",
    height: "inherit",
    ...centerStyle,
    backgroundColor: selectedDarkMode ? "#F5F5F5" : "none",
    overflow: "hidden",
  };
});
export const templateViewScreenModalStyle = {
  backgroundColor: "#000000",
  color: "#ffffff",
  fontFamily: "OpenSans-Regular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1.5rem, 1.5vmin, 4rem)",
  letterSpacing: "-0.011em",
};
export const MainScreenView = styled(Box)({
  height: "100%",
  width: "100%",
  ...centerStyle,
  overflow: "hidden",
  paddingLeft: "20px",
});
export const UndoRedoButtonContainer = styled(Box)({
  marginLeft: "7%",
  position: "sticky",
  left: 0,
  top: 0,
});
export const UndoRedoButtonWrapper = styled(Box)({
  ...centerStyle,
  justifyContent: "start",
  alignItems: "start",
});
export const UndoButton = styled(Box)(() => {
  const x1 = window.innerHeight / 1080;
  const exactHeight = 54 * x1;
  const exactWidth = 54 * x1;

  return {
    width: exactWidth,
    height: exactHeight,
    background: "#2E2E2E",
    borderRadius: "7px",
    cursor: "pointer",
    marginRight: "14px",
    ...centerStyle,
  };
});
export const UndoIcon = styled("img")(() => {
  const x1 = window.innerHeight / 1080;
  const exactHeight = 12 * x1;
  const exactWidth = 13 * x1;

  return {
    width: exactWidth,
    height: exactHeight,
  };
});
