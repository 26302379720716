import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteApiServices,
  getApiServices,
  multipartPostApiServices,
} from "../../api/api";
import { apiRoutes } from "../../api/apiPath";
import { errorMessage, successMessage } from "../../redux/constant";
import {
  CustomImageUploadContainer,
  ImageUploaderAndSelectBox,
  ImageView,
  ImageViewContainer,
} from "../../styles/sharedComponents";
import xtendrEmptyImage from "../../assets/icons/xtendrEmptyImage.jpg";
import CommonImageRender from "./CommonImageRender";
import CustomImageUpload from "./CustomImageUpload";
import LibraryHeading from "./LibraryHeading";
import InformationBlobContent from "./InformationBlobContent";
import { FormatContainer, FormatLabel, FormatListLabel, InfoBlobContentContentContainer } from "../../styles/infoBlob";
import { getHeightRatio } from "../../utils/getDimensionalRatio";

export default function MultipleUploader(props) {
  const {
    handleChangeSubPropertyDatas,
    selectedProperty,
    selectedSubProperty,
    selectedPropertyLevel,
  } = props;
console.log("propsssssssss", selectedSubProperty?.value?.previewUrl)
  const dispatch = useDispatch();

  const [uploadImage, setUploadImage] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const fileUploadMaxCount = selectedProperty?.max || 5;
  const formData = new FormData();

  const handleChange = (files) => {
    const getFiles = Array?.prototype?.slice?.call(files);
    const uploadedFiles = [...uploadImage];
    let limitExceeded = false;

    getFiles?.some((file) => {
      if (
        uploadedFiles?.findIndex((item) => item?.name === file?.name) === -1
      ) {
        uploadedFiles?.push(file);

        if (uploadedFiles?.length === fileUploadMaxCount) setFileLimit(true);
        if (uploadedFiles?.length > fileUploadMaxCount) {
          dispatch(
            errorMessage(
              `You can only add a maximum of ${fileUploadMaxCount} files`
            )
          );
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    if (!limitExceeded) setUploadImage(uploadedFiles);
  };

  const getAssetsListByType = async () => {
    try {
      const selectedValueClass = selectedSubProperty?.value;
      const checkAudio = selectedValueClass?.type === "audio";
      const type = checkAudio ? "default" : selectedValueClass?.type || "logo";
      const fileType = checkAudio
        ? "audio"
        : selectedValueClass?.fileType || "2D";
      const getPath = `${apiRoutes.getAllAssetsByType}?type=${type}&fileType=${fileType}&tenantUploads=true`;

      const { data } = await getApiServices(getPath);
      setAssetList(data?.data?.assets);
    } catch (err) {}
  };

  const handelSubmit = async () => {
    // if (assetList?.length >= 5) {
    //   dispatch(
    //     errorMessage("Image marker limite for 5, remove existing asstes")
    //   );
    // } else {
      try {
        uploadImage?.forEach((item) => {
          formData.append("assets", item);
        });
        formData.append(
          "type",
          selectedSubProperty?.value?.type || "imageMarker"
        );
        const data = await multipartPostApiServices(
          apiRoutes.multiUploadCreation,
          formData
        );
        getAssetsListByType();
        setUploadImage([]);
        dispatch(
          successMessage(data?.message || "Images have been uploaded")
        );
      } catch (err) {
        console.log('Image Uplaod error',err)
        dispatch(errorMessage(err?.response?.data?.errors?.[0]?.errorInfo));
      }
    // }
  };

  const handleRemove = async (getRemoveData) => {
    try {
      const data = await deleteApiServices(
        apiRoutes.deleteAssets,
        getRemoveData?.id
      );
      getAssetsListByType();
      dispatch(successMessage(data?.message || "Asset Deleted Successfully"));
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  const infoUploadFiles = () => {
    return (
      <InfoBlobContentContentContainer>
      <FormatContainer>
        <FormatLabel>Format : </FormatLabel>
        <FormatListLabel>jpg, .jpeg, .png</FormatListLabel>
      </FormatContainer>
      <FormatContainer sx={{ marginTop: getHeightRatio(1) }}>
        <FormatLabel>Dimensions:</FormatLabel>
      </FormatContainer>
        <FormatListLabel sx={{width: '75% !important', margin: 'auto'}}>min:480 x 640px max: 2048px</FormatListLabel>
      <FormatContainer sx={{ marginTop: getHeightRatio(1) }}>
      Note: If you upload something larger, the image is resized down to a max length/width of 2048, maintaining aspect ratio.
      </FormatContainer>
    </InfoBlobContentContentContainer>
    );
  };

  useEffect(() => {
    if (uploadImage?.length) handelSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImage?.length]);

  useEffect(() => {
    getAssetsListByType();
  }, [selectedProperty?.key, selectedSubProperty?.key]);

  return (
    <Box>
      <LibraryHeading
        disabledHeading={true}
        mandatory={true}
        headingContianerStyle={{ width: "44%" }}
        infoContent={infoUploadFiles}
      />
      <ImageUploaderAndSelectBox>
        <ImageViewContainer>
          <ImageView 
            src={
              selectedSubProperty?.value?.previewUrl || xtendrEmptyImage
            }
            alt={`Selected Image`}
          />
        </ImageViewContainer>
        <CustomImageUploadContainer>
          <CustomImageUpload
            accept={"image/*"}
            name="uploadImage"
            onChange={(e) => handleChange(e)}
            uploadField={true}
            multiple={true}
          />
        </CustomImageUploadContainer>
      </ImageUploaderAndSelectBox>

      {/* recent images and library images */}
      <CommonImageRender
        selectedProperty={selectedProperty}
        heading={"Uploads"}
        imageList={assetList}
        handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
        removeIcon={true}
        handleRemove={handleRemove}
        viewMore={false}
      />
    </Box>
  );
}
