import { Fade, Tooltip } from "@mui/material";
import React from "react";

export default function InformationBlob(props) {
  const { children, content } = props;

  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title={content || ""}
      placement="left-start"
    >
      {children}
    </Tooltip>
  );
}
