import React, { useEffect, useState } from "react";
import LibraryHeading from "../LibraryHeading";
import { Box } from "@mui/system";
import chroma from "chroma-js";
import { isValidHexCode } from "../../../utils/checkHexCode";
import CustomColorPicker from "./CustomColorPicker";
import CustomAlphaPickerPanel from "./CustomAlphaPickerPanel";
import CustomHuePickerPanel from "./CustomHuePickerPanel";
import {
  ColorPickerTextBoxContainer,
  ColorPickerWrapper,
  ColorTextBox,
  ColorTextBoxContainer,
  ColorTextOpacityBoxContainer,
  ColorView,
  ColorViewContainer,
} from "../../../styles/colorPalette";
import { getApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import { addIcon } from "../../../constants/icons";
import ColorList from "./ColorList";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../../constants/renderUiMatchActionClass";

export default function CustomSolidColorPicker({
  handleChangeSubPropertyDatas = () => {},
  selectedProperty,
  selectedSubProperty,
  selectedScreen,
  fontProperty = false,
}) {
  const [color, setColor] = useState("rgba(134, 100, 100, 1)");
  const [hueColor, setHueColor] = useState("#fff");
  const [opacityColor, setOpacityColor] = useState({
    r: 134,
    b: 100,
    g: 100,
    a: 1,
  });
  const [textColorValue, setTextColorValue] = useState({
    hexCode: "#15A5CF",
    opacity: 100,
  });
  const [recentColors, setRecentColors] = useState([]);

  const colorPickerHandleChange = (color) => {
    setColor(color);
    setOpacityColor(color);
    setTextColorValue({
      hexCode: chroma(color)?.hex(),
      opacity: 100,
    });
  };

  const opacityHandleChange = (color) => {
    setOpacityColor(color?.rgb);
    setTextColorValue({
      ...textColorValue,
      opacity: Math.round(color?.rgb?.a * 100 || 0),
    });
  };

  const hueHandleChange = (color) => {
    setHueColor(color?.hex);
    setColor(color?.hex);
    setOpacityColor(color?.hex);
    setTextColorValue({
      hexCode: color?.hex,
      opacity: 100,
    });
  };

  const hueColorCustomTextHandle = (e) => {
    const value = e.target?.value;
    setTextColorValue({
      ...textColorValue,
      hexCode: value,
    });

    if (isValidHexCode(value)) {
      setHueColor(value);
      setColor(value);
      const getColor = chroma(value)?.alpha(1)?.rgba();
      const convertRGB = {
        r: getColor?.[0],
        g: getColor?.[1],
        b: getColor?.[2],
        a: textColorValue?.opacity,
      };
      setOpacityColor(convertRGB);
    }
  };

  const opacityColorCustomTextHandle = (e) => {
    const value = e.target?.value;
    const getValue = value / 100 == 100 ? 1 : value / 100;
    setTextColorValue({
      ...textColorValue,
      opacity: value,
    });
    if (isValidHexCode(textColorValue?.hexCode)) {
      const getColor = chroma(textColorValue?.hexCode)
        ?.alpha(getValue || 1)
        ?.rgba();
      const convertRGB = {
        r: getColor?.[0],
        g: getColor?.[1],
        b: getColor?.[2],
        a: getColor?.[3],
      };
      setOpacityColor(convertRGB);
    }
  };

  //recent color data handle
  const getRecentSolidColors = async () => {
    try {
      const sendApiPath = `${apiRoutes.recentColor}?class=Color`;
      const { data } = await getApiServices(sendApiPath);

      const getColors = data
        ?.filter((item) => {
          let checkColor = Object.keys(item?.config)?.some(
            (item) => item === "color"
          );
          if (checkColor) return item;
        })
        ?.slice(0, 5);
      setRecentColors(getColors || []);
    } catch (err) {}
  };

  useEffect(() => {
    let checkFont = selectedSubProperty?.key === "text" ? "fontColor" : "color";
    let color = selectedSubProperty?.value?.[checkFont];

    if (selectedProperty) {
      if (color?.includes("gradient")) {
        color = "#15A5CF";
      }
      const getColor = color || "#15A5CF";
      setColor(getColor);
      setHueColor(getColor);
      setTextColorValue({
        hexCode: getColor,
        opacity: 100,
      });

      if (isValidHexCode(getColor)) {
        const getColorValue = chroma(getColor)?.alpha(1)?.rgba();
        const convertRGB = {
          r: getColorValue?.[0],
          g: getColorValue?.[1],
          b: getColorValue?.[2],
          a: textColorValue?.opacity,
        };
        setOpacityColor(convertRGB);
      } else {
        setOpacityColor(getColor);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.key, selectedSubProperty?.key, selectedScreen?.key]);

  useEffect(() => {
    getRecentSolidColors();

    setRecentColors();
  }, [selectedProperty?.key, selectedSubProperty?.key, selectedScreen?.key]);

  //meet and greet button color handle
  const commonChangeHandleMeetAndGreetButton = (getData) => {
    if (
      selectedProperty?.actionClass ===
        PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC ||
      selectedProperty?.actionClass === ACTION_CLASSES.INSTRUCTIONS ||
      selectedSubProperty?.actionClass === ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
    ) {
      const sendData = { ...getData };
      handleChangeSubPropertyDatas({ ...sendData });
    } else {
      const buttonElementsData = selectedProperty?.elements?.map((item) => {
        if (selectedSubProperty?.key === item?.key) {
          return {
            ...item,
            value: { ...item?.value, ...getData },
          };
        } else return item;
      });

      const sendData = {
        ...selectedProperty,
        elements: buttonElementsData,
        value: {
          ...selectedProperty?.value,
          buttons: selectedProperty?.value?.buttons?.map((item) => {
            return { ...item, ...getData };
          }),
        },
      };
      handleChangeSubPropertyDatas({ ...sendData, nested: true });
    }
  };

  useEffect(() => {
    const colorValue =
      textColorValue?.opacity == 100
        ? textColorValue?.hexCode
        : `${textColorValue?.hexCode}${textColorValue?.opacity}` || "#15A5CF";

    //meet and greet button color handle
    if (fontProperty) {
      let checkFont =
        selectedSubProperty?.key === "text" ? "fontColor" : "color";
      commonChangeHandleMeetAndGreetButton({
        [checkFont]: colorValue,
        gradient: "",
      });
    } else {
      handleChangeSubPropertyDatas({ color: colorValue, gradient: "" });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, hueColor, opacityColor, textColorValue]);

  const handleAddColor = async () => {
    const sendColorData = {
      class: "Color",
      value: selectedSubProperty?.value?.color,
      config: {
        color: selectedSubProperty?.value?.color,
        className: "Color",
      },
    };

    // try {
    //   const data = await postApiServices(
    //     apiRoutes.createRecentColor,
    //     sendColorData
    //   );
    // } catch (err) {}
  };

  return (
    <ColorPickerWrapper>
      <CustomColorPicker
        value={color}
        colorPickerHandleChange={colorPickerHandleChange}
      />

      <Box m={2}>
        <CustomAlphaPickerPanel
          color={opacityColor}
          onChange={opacityHandleChange}
        />
      </Box>

      <ColorPickerTextBoxContainer>
        <ColorViewContainer>
          <ColorView
            sx={{
              background:
                textColorValue?.opacity == 100
                  ? textColorValue?.hexCode
                  : `${textColorValue?.hexCode}${textColorValue?.opacity}` ||
                    "#4200B4",
            }}
          />
        </ColorViewContainer>

        <ColorTextBoxContainer>
          <ColorTextBox
            value={textColorValue?.hexCode}
            onChange={hueColorCustomTextHandle}
          />
        </ColorTextBoxContainer>

        <ColorTextOpacityBoxContainer>
          <ColorTextBox
            value={textColorValue?.opacity}
            onChange={opacityColorCustomTextHandle}
          />
        </ColorTextOpacityBoxContainer>
      </ColorPickerTextBoxContainer>

      <Box m={2}>
        <CustomHuePickerPanel color={hueColor} onChange={hueHandleChange} />
      </Box>

      <Box sx={{ mt: 2.5 }}>
        <LibraryHeading
          headingStyle={{
            fontSize: "clamp(1rem, 1vmin, 4rem)",
            width: "-webkit-fill-available",
          }}
          heading={"Saved Colors"}
          // icon={addIcon}
          headingContianerStyle={{ padding: "0px 20px" }}
          handleIconClick={handleAddColor}
        />
        <ColorList
          colorOptions={recentColors}
          handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          selectedSubProperty={selectedSubProperty}
          containerStyle={{ margin: "0px", padding: "0px 20px" }}
        />
      </Box>
    </ColorPickerWrapper>
  );
}
