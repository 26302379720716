import { Box, Grid, Typography } from "@mui/material";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRoutes } from "../../../../../api/apiPath";
import { Status } from "../../../../../constants/dash-board/Status";
import { StatusConfigMap } from "../../../../../constants/dash-board/project-status-config";
import { ROUTE_PATHS } from "../../../../../routes/routePaths";
import { deleteProject } from "../../../../../service/projects";
import useQuery from "../../../../../utils/hooks/useQuery";
import ContextMenu from "../../shared/ContextMenu/index";
import {
  ElementContainer,
  EmptyTemplateContainer,
  ImageDummy,
  ListDisplayContainer,
  PageHeader,
  StatusContainer,
  StatusHeader,
  SubHeader,
  TemplateContainer,
  TemplateList,
  TemplateListContainer,
} from "../styled/DashBoardStyle";
import queryString from "query-string";
import { getApiServices, putApiWithIdServices } from "../../../../../api/api";
import CommonFormModal from "../../../../shared/CommonFormModal";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../../utils/getDimensionalRatio";
import DashBoardAnalytics from "../analytics";
import DashBoardModal from "../../../../shared/DashboardModal";
import {
  ApprovalButtonContainer,
  ApprovalDescription,
  ApprovalModalContainer,
  PublishApprovedButton,
  PublishGoBackButton,
} from "../../../../../styles/previewAndPublish/publishTabStyle";
import { errorMessage, successMessage } from "../../../../../redux/constant";
import { useDispatch } from "react-redux";
import analayticsIcon from "../../../../../assets/icons/analyticsIcon.svg"
import deleteIcon from "../../../../../assets/icons/delete.svg"

const ProjectList = () => {
  const [selectedStatus, setSelectedStatus] = useState(Status.Draft);
  const { state, loading, queryFn } = useQuery(`${apiRoutes.projectCreation}`);
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [userCredentials, setUserCredentials] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserCredentials = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.users);
      setUserCredentials(data);
    } catch (err) {}
  };

  useEffect(() => {
    handleUserCredentials();
  }, []);

  const [dataModalOpen, setDataModalOpen] = useState(false);

  const promptPayLoad = useRef(null);
  const [currentContextOptions, setCurrentContextOptions] = useState(null);

  const deleteProjectHandler = (id) =>
    deleteProject(id)
      .then(queryFn)
      .catch(() => dispatch(errorMessage("Error Deleting Project")));

  const handStatusChange = (id, status) =>
    putApiWithIdServices(apiRoutes.publishStatus, id, { status })
      .then(queryFn)
      .catch(() => dispatch(errorMessage("Error Ending Project")));

  const getStatusContextOptions = (status) => {
    const StatusContextOptions = {
      [Status.Draft]: {
        promptConfig: {
          ["Delete"]: {
            handleClose: handleModelClose,
            content: "Are you sure, you want to delete the project ?",
            actionChoices: {
              actionHandler: async () => {
                try {
                  await deleteProjectHandler(promptPayLoad.current.id);
                  handleModelClose();
                  dispatch(successMessage("Project deleted successfully ..."));
                } catch (err) {
                  dispatch(
                    errorMessage(err?.response?.data?.errors?.[0]?.message)
                  );
                }
              },
              name: "Delete",
              icon: deleteIcon,
            },
            // ],
          },
        },
        contextOptions: [
          {
            name: "Data Analytics",
            icon: analayticsIcon,
            action: (id) => {
              setDataModalOpen(() => {
                promptPayLoad.current = id;
                return true;
              });
              const params = {
                projectId: id || "",
              };
              const query = queryString.stringify(params, {
                arrayFormat: "bracket",
              });
              navigate(`${ROUTE_PATHS.dashBoard}?${query}`);
            },
          },
          {
            name: "Delete",
            icon: deleteIcon,
            actionId: "Delete",
            action: (id) => {
              setIsPromptOpen(() => {
                promptPayLoad.current = { id, actionId: "Delete" };
                return true;
              });
            },
          },
        ],
      },
      [Status.Active]: {
        promptConfig: {
          [Status.Completed]: {
            handleClose: handleModelClose,
            content: "Are you sure, you want to End the project ?",
            actionChoices:
              // [
              // { actionHandler: () => handleModelClose(), name: "cancel" },
              {
                actionHandler: async () => {
                  try {
                    await handStatusChange(
                      promptPayLoad.current.id,
                      Status.Completed
                    );
                    handleModelClose();
                    dispatch(successMessage("Project End successfully ..."));
                  } catch (err) {
                    dispatch(
                      errorMessage(err?.response?.data?.errors?.[0]?.message)
                    );
                  }
                },
                name: "End Project",
              },
            // ],
          },
        },
        contextOptions: [
          {
            name: "Data Analytics",
            icon: analayticsIcon,
            action: (id) => {
              setDataModalOpen(() => {
                promptPayLoad.current = id;
                return true;
              });
              const params = {
                projectId: id || "",
              };
              const query = queryString.stringify(params, {
                arrayFormat: "bracket",
              });
              navigate(`${ROUTE_PATHS.dashBoard}?${query}`);
            },
          },
          {
            name: "End",
            actionId: Status.Completed,
            action: (id) => {
              setIsPromptOpen(() => {
                promptPayLoad.current = { id, actionId: Status.Completed };
                return true;
              });
            },
          },
        ],
      },
      [Status.Completed]: {
        promptConfig: {
          ["Delete"]: {
            handleClose: handleModelClose,
            content: "Are you sure, you want to delete the project ?",
            actionChoices:
              // [
              //   { actionHandler: () => handleModelClose(), name: "cancel" },
              {
                actionHandler: async () => {
                  try {
                    await deleteProjectHandler(promptPayLoad.current.id);
                    handleModelClose();
                    dispatch(
                      successMessage("Project deleted successfully ...")
                    );
                  } catch (err) {
                    dispatch(
                      errorMessage(err?.response?.data?.errors?.[0]?.message)
                    );
                  }
                },
                name: "Delete",
                icon: deleteIcon,
              },
            // ],
          },
        },
        contextOptions: [
          {
            name: "Data Analytics",
            icon: analayticsIcon,
            action: (id) => {
              setDataModalOpen(() => {
                promptPayLoad.current = id;
                return true;
              });
              const params = {
                projectId: id || "",
              };
              const query = queryString.stringify(params, {
                arrayFormat: "bracket",
              });
              navigate(`${ROUTE_PATHS.dashBoard}?${query}`);
            },
          },
          {
            name: "Delete",
            icon: deleteIcon,
            actionId: "Delete",
            action: (id) => {
              setIsPromptOpen(() => {
                promptPayLoad.current = { id, actionId: "Delete" };
                return true;
              });
            },
          },
        ],
      },
    };

    return StatusContextOptions[status];
  };

  const handleModelClose = () => setIsPromptOpen(false);

  useEffect(
    () => setCurrentContextOptions(getStatusContextOptions(selectedStatus)),
    [selectedStatus]
  );
  if (loading) return "";

  const handleFilterChange = ({ value }) => setSelectedStatus(value);
  const renderHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: "2rem",
          position: "sticky",
        }}
      >
        <PageHeader>Projects</PageHeader>
        <StatusContainer>
          <Grid container sx={{ padding: "1rem 0" }}>
            {Object.values(StatusConfigMap)?.map((statusConfig, index) => (
              <Grid
                key={index}
                item
                sx={{ cursor: "pointer", marginRight: "4rem" }}
                onClick={() => handleFilterChange(statusConfig)}
              >
                <StatusHeader
                  doesShowBorder={selectedStatus === statusConfig.value}
                >
                  {statusConfig.label}
                </StatusHeader>
              </Grid>
            ))}
          </Grid>
        </StatusContainer>
      </Box>
    );
  };

  const ProjectList = ({ templates, filterFn, name, reload }) => {
    const navigate = useNavigate();
    const [moreIconOpen, setMoreIconOpen] = useState(false);

    const handleEditProject = (id, projectName, templateName, status) => {
      const params = {
        projectId: id || "",
        projectName: projectName || "",
        templateName: templateName || "",
        projectStatus: status || "",
      };
      const query = queryString.stringify(params, { arrayFormat: "bracket" });
      navigate(`${ROUTE_PATHS.templateCreation}?${query}`);
    };

    const handleMoreIconClick = () => {
      setMoreIconOpen(true);
    };

    const renderTemplate = ({ name, previewUrl, id, status, baseTemplate }) => (
      <ElementContainer id="ElementContainer">
        <TemplateContainer
          onClick={() =>
            handleEditProject(id, name, baseTemplate?.name, status)
          }
        >
          {!previewUrl ? (
            <ImageDummy
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component="h6"
                sx={{
                  fontSize: "0.8rem",
                  fontWeight: "medium",
                  opacity: "0.2",
                }}
              >
                No Preview
              </Typography>
            </ImageDummy>
          ) : (
            <img
              src={previewUrl}
              alt="projectImage"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          )}
        </TemplateContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
            color: "white",
            margin: "1rem 0rem",
          }}
        >
          <SubHeader
            onClick={() => handleEditProject(id, name, baseTemplate?.name)}
          >
            {name}
          </SubHeader>
          <ContextMenu
            options={get(currentContextOptions, "contextOptions")}
            actinPayload={id}
          />
        </Box>
      </ElementContainer>
    );
    let filteredTemplates
    if(selectedStatus !== 'myProjects') filteredTemplates = templates?.filter(filterFn);
    else filteredTemplates = templates?.filter(template => template.userId === userCredentials.id);
    filteredTemplates = filteredTemplates
      .filter(t => t.name)
      .sort((a, b) => a.name.localeCompare(b.name));
    return (
      <TemplateListContainer>
        <Typography
          component="h3"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "medium",
            marginBottom: "0.2rem",
          }}
        >
          {name}
        </Typography>
        {filteredTemplates?.length <= 0 ? (
          <EmptyTemplateContainer>
            No Templates Available
          </EmptyTemplateContainer>
        ) : (
          <TemplateList key={name}>
            {filteredTemplates?.map(renderTemplate)}
          </TemplateList>
        )}
      </TemplateListContainer>
    );
  };

  return (
    <>
      <ListDisplayContainer>
        {/* handle conformation modal */}
        <CommonFormModal
          open={isPromptOpen && promptPayLoad.current}
          modalBodyContainerStyle={{
            width: getWidthRatio(1129),
            height: getHeightRatio(389),
          }}
          modalChildrenContainerStyle={{
            width: getWidthRatio(1129),
            height: getHeightRatio(389),
          }}
          iconContainerHeight={{
            height: getHeightRatio(389),
          }}
          hanldeClose={
            get(
              currentContextOptions,
              `promptConfig.${promptPayLoad.current?.actionId}`,
              {}
            ).handleClose
          }
          backIcon={false}
          cancelIcon={true}
        >
          <ApprovalModalContainer>
            <ApprovalDescription>
              {get(
                currentContextOptions,
                `promptConfig.${promptPayLoad.current?.actionId}`,
                {}
              ).content || ""}
            </ApprovalDescription>

            <ApprovalButtonContainer>
              <PublishGoBackButton
                style={{
                  width: getWidthRatio(194.18),
                  height: getHeightRatio(66.83),
                  fontSize: "clamp(2.4rem , 1.5vmin, 4rem)",
                  justifyContent: "center",
                  padding: "0px",
                }}
                onClick={
                  get(
                    currentContextOptions,
                    `promptConfig.${promptPayLoad.current?.actionId}`,
                    {}
                  ).handleClose
                }
              >
                Do it Later
              </PublishGoBackButton>

              <PublishApprovedButton
                onClick={
                  get(
                    currentContextOptions,
                    `promptConfig.${promptPayLoad.current?.actionId}`,
                    {}
                  )?.actionChoices?.actionHandler
                }
                style={{
                  width: getWidthRatio(200),
                  height: getHeightRatio(66.47),
                  background: "#49C4D8",
                  borderRadius: "9.03153px",
                  fontSize: "clamp(2.4rem , 1.5vmin, 4rem)",
                  color: "#000000",
                }}
              >
                {
                  get(
                    currentContextOptions,
                    `promptConfig.${promptPayLoad.current?.actionId}`,
                    {}
                  )?.actionChoices?.name
                }
              </PublishApprovedButton>
            </ApprovalButtonContainer>
          </ApprovalModalContainer>
        </CommonFormModal>

        {renderHeader()}

        <ProjectList
          filterFn={({ status }) =>
            selectedStatus === "All" || status === selectedStatus
          }
          reload={queryFn}
          templates={state.data}
        />
      </ListDisplayContainer>
      <DashBoardModal
        open={dataModalOpen}
        handleClose={() => setDataModalOpen(!dataModalOpen)}
        backIcon={false}
        cancelIcon={true}
      >
        <DashBoardAnalytics
          templates={state.data}
          projectId={promptPayLoad.current}
        />
      </DashBoardModal>
    </>
  );
};

export default ProjectList;
