import React from "react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from "recharts";

const LineDataChart = ({ data, yAxisKey, xAxisKey }) => {
  return (
    <ResponsiveContainer width={"95%"} height="80%">
      <LineChart
        width={"100%"}
        height={"100%"}
        data={data}
        style={{
          background: 'white', 
          padding:'1rem 0.5rem 0.5rem 0',
          borderRadius: '1.44544rem',
          marginLeft: '1rem',
        }}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xAxisKey || "name"}
          style={{ fontSize: "clamp(1rem, 2vmin, 2rem)", margin: "5px 0px" }}
        />
        <YAxis
          dataKey={yAxisKey}
          style={{ fontSize: "clamp(1rem, 2vmin, 2rem)", margin: "5px 0px" }}
        />
        <Tooltip
          contentStyle={{
            fontFamily: "OpenSans-Medium",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "clamp(1rem, 2vmin, 3rem)",
            letterSpacing: "-0.01em",
            cursor: "pointer",
            background: "white",
            borderRadius: "10px",
          }}
        />
        <Line
          type="monotone"
          strokeWidth={3}
          dot={{ strokeWidth: 4 }}
          dataKey={yAxisKey}
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
export default LineDataChart;
