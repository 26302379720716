import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const ViewMoreContainer = styled(Box)(({ style }) => {
  return {
    width: "90%",
    height: "90%",
    ...style,
  };
});
export const ViewMoreHeadContainer = styled(Box)(({ style }) => {
  return {
    position: "sticky",
    top: 0,
    left: 0,
    height: "13%",
    width: "100%",
    display:"flex",
    justifyContent: 'space-around',
    alignItems: 'center',
    ...style,
  };
});
export const ViewMoreBodyContainer = styled(Box)(({ style }) => {
  return {
    maxHeight: "87%",
    overflow: "hidden auto",
    display: "flex",
    flexWrap: "wrap",
    ...style,
  };
});
