import styled from "@emotion/styled";
import { Box, Button, Grid } from "@mui/material";
import bg_image from "../../../../../assets/icons/landingPage1.png";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../../utils/getDimensionalRatio";
import {
  centerStyle,
  textOverflowEllipsStyle,
} from "../../../../../styles/commonStyles";

const Container = styled(Grid)(() => ({
  height: "auto",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `url(${bg_image})`,
  color: "white",
}));
export const AccountViewContainer = styled(Box)(() => ({
  height: "45%",
  ...centerStyle,
  alignItems: "end",
  justifyContent: "start",
  paddingLeft: getWidthRatio(50),
}));
export const AccountName = styled(Box)(() => ({
  ...centerStyle,
  height: getHeightRatio(50.63),
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1.8rem, 1.5vmin, 4rem)",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  marginLeft: getWidthRatio(32),
}));
const SidePanelContainer = styled(Grid)(({ style }) => {
  return {
    padding: "5rem 5rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    background:"linear-gradient(180deg, rgba(78, 78, 78, 0.68) 0%, rgba(33, 32, 32, 0.632353) 28.44%, rgba(24, 24, 24, 0.546833) 79.48%, rgba(24, 24, 24, 0) 100%)",
    borderRadius: "0px 5px 5px 0px",
    boxShadow: "white 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    height: "100vh",
    position: "sticky",
    left: 0,
    top: 0,
    ...style,
  };
});

const OptionText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(1rem, 1.7vmin, 2rem)",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
});

const SettingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  height: "90%",
});

const OptionTextContainer = styled(Box)(({ isSelected }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    width: "70%",
    color: isSelected ? "#49C4D8" : "white",
    ":hover": {
      color: "#49C4D8",
      cursor: "pointer",
    },
    margin: "20px 0px",
  };
});

const ListContainer = styled(Grid)({
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const ListMidContainer = styled(Grid)({
  width: "85%",
  marginTop: "5rem",
});

const Header = styled(Box)({
  fontFamily: "OpenSans-Regular",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(3rem, 4vmin, 5rem)",
  lineHeight: "106px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  textShadow: "0px 0px 47px rgba(21, 37, 52, 0.5)",
});

const TemplateTitle = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(7.4rem, 1.5vmin, 4rem)",
  lineHeight: "92px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  textShadow: "0px 0px 40.5593px rgba(21, 37, 52, 0.5)",
  margin: "4rem 0",
});
export const TemplateViewMoreContainer = styled(Box)({
  width: "100%",
  marginTop: getHeightRatio(130),
  marginBottom: getHeightRatio(20),
  ...centerStyle,
});
export const ExploreNavigateContainer = styled(Box)({
  width: "100%",
  height: "40%",
  ...centerStyle,
  alignItems: "start",
  paddingTop: getHeightRatio(35),
});
export const SelectedTemplateLabel = styled(Box)(({ style }) => {
  return {
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "-0.01em",
    color: "#49C4D8",
    fontSize: "clamp(2.3rem, 1.5vh, 2rem)",
    ...centerStyle,
    textShadow: "0px 0px 39.4338px rgba(21, 37, 52, 0.5)",
    ...style,
    marginLeft: getWidthRatio(34),
  };
});
export const SelectedArrowIcon = styled("img")(({ style }) => {
  return {
    width: getWidthRatio(12),
    paddingTop: getHeightRatio(10),
    ...centerStyle,
    ...style,
  };
});
export const ViewMoreText = styled(Box)({
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1rem, 2vmin, 5rem)",
  letterSpacing: "-0.011em",
  color: " #02889D",
});
export const ViewMoreIcon = styled("img")({
  width: getWidthRatio(17),
  marginLeft: getWidthRatio(17),
  height: "-webkit-fill-available",
});
const InfoContainer = styled(Box)({
  height: "auto",
});

const GridInfoContainer = styled(Grid)({
  alignItems: "center",
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
});

const GradientOutlineButton = styled("button")({
  borderRadius: "10px",
  border: "2.59px solid",
  background: "transparent",
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  height: getHeightRatio(77),
  width: getWidthRatio(231),
  justifyContent: "center",
  marginRight: "2rem",
});
export const TryOutQrCodeContainer = styled(Box)({
  width: "100%",
  height: "100%",
  background: "#FFFFFF",
  boxShadow: " 0px 3.72824px 31.5827px #000000",
  borderRadius: "70px 18px 70px 70px",
});
export const QrCodeText = styled(Box)({
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(2rem, 1.7vmin, 2rem)",
  letterSpacing: "-0.01em",
  color: "#000000",
  marginTop: getHeightRatio(40),
});
export const QrTemplateName = styled(Box)({
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(3rem, 1.9vmin, 2rem)",
  letterSpacing: "-0.01em",
  marginTop: getHeightRatio(12),
  textFillColor: "transparent",
  backgroundImage: "linear-gradient(#000000 100%, #1740FE 100%)  ",
  "-webkit-background-clip": "text",
  "-moz-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  "-moz-text-fill-color": "transparent",
  width: "80%",
  ...textOverflowEllipsStyle,
});
export const QrWrapper = styled(Box)({
  marginTop: getHeightRatio(35),
});
const GradientCutomizeButton = styled("button")({
  borderRadius: "10px",
  border: "none",
  background: "transparent",
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
  height: getHeightRatio(77),
  width: getWidthRatio(231),
  justifyContent: "center",
  background: "linear-gradient(97.33deg, #C00BA0 29.09%, #9747FF 118.18%)",
});

const TemplateDesp = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  lineHeight: "39px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  margin: "3rem 0",
});

const ChipContainer = styled(Box)({
  border: "1px solid rgba(255, 255, 255, 0.733)",
  borderRadius: "0.645625rem",
  cursor: "pointer",
  transition: "all 0.2s ease",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: getWidthRatio(47),
  height: getHeightRatio(46),

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.154)",
  },
  "&.checked": {
    backgroundColor: "red",
  },
});

const ChipMaterial = styled(Box)({
  border: "1px solid rgba(255, 255, 255, 0.733)",
  borderRadius: "0.645625rem",
  padding: "1rem",
  cursor: "pointer",
  transition: "all 0.2s ease",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.154)",
  },
  "&.checked": {
    backgroundColor: "red",
  },
});

const SubHeader = styled(Box)({
  letterSpacing: "-0.011em",
  fontFamily: "OpenSans-Medium",
  fontSize: "clamp(2rem, 1vmin, 4rem)",
  fontWeight: "medium",
});

const StatusHeader = styled(Box)(({ doesShowBorder }) => ({
  letterSpacing: "-0.011em",
  fontFamily: "OpenSans-Medium",
  fontSize: "clamp(1rem, 2vmin, 3rem)",
  fontWeight: "medium",
  borderSpacing: "3rem",
  width: "fit-content",
  position: "relative",
  borderBottom: doesShowBorder && "1px solid #49C4D8",
}));

const ListDisplayContainer = styled(Box)(() => ({
  minHeight: "100vh",
  overflow: "hidden",
}));

const ExploreContainer = styled(Grid)(() => ({
  // position: "sticky",
  // top: 0,
  // left: 0,
}));

const TemplateContainer = styled(Box)(() => ({
  overflow: "hidden",
  width: `${getWidthRatio(286)}px`,
  height: `${getHeightRatio(221)}px`,
  borderTopLeftRadius: "30%",
  borderTopRightRadius: "5%",
  borderBottomLeftRadius: "5%",
  borderBottomRightRadius: "5%",

  // "-webkit-clip-path": "polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)",
  // clipPath: "polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%)",
}));

const ElementContainer = styled(Box)(() => ({
  gap: "1rem",
  margin: "1rem",
  height: "100%",
  maxWidth: `${getWidthRatio(286)}px`,
  "& :hover": {
    cursor: "pointer",
  },
}));

const StatusContainer = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  marginBottom: "2rem",
}));

const ImageDummy = styled(Box)(() => ({
  minWidth: "10rem",
  minHeight: "6rem",
  height: "100%",
  color: "black",
  backgroundColor: "#D9D9D9",
}));

const PageHeader = styled(Box)(() => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(2rem , 5.5vmin, 4rem)",
  // lineHeight: "81px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  // letterSpacing: "-0.01em",
  color: "#FFFFFF",
  textShadow: "0px 0px 35.8558px rgba(21, 37, 52, 0.5)",
  marginBottom: "3rem",
}));

const TemplateListContainer = styled(Box)(() => ({
  marginTop: "3rem",
  display: "grid",
}));

const EmptyTemplateContainer = styled(Box)(() => ({
  height: "200px",
  ...centerStyle,
  letterSpacing: "-0.011em",
  fontFamily: "OpenSans-Medium",
  fontSize: " clamp(1rem, 2vmin, 3rem)",
  fontWeight: "medium",
}));

const TemplateList = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gap: "4rem",
}));

const ChipsContainer = styled(Grid)(() => ({
  display: "flex",
  gap: "1rem",
  color: "white",
  padding: "5rem 0rem",
  gap: "20px",
}));

const FooterContainer = styled(Box)(() => ({
  height: getHeightRatio(150),
  background: "#000000",
}));

const FooterInfoContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "70%",
}));

const InfoSection = styled(Box)(() => ({
  // height: "20rem",
  width: "70%",
}));

const GeneralInfoContainer = styled(Box)(() => ({
  height: "30%",
  width: "100%",
  ...centerStyle,
}));
const GeneralInfoSection = styled(Box)(() => ({
  height: "100%",
  width: "70%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderTop: "1px solid white",
}));

const InfoText = styled(Box)(() => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1rem, 2vmin, 3rem)",
  letterSpacing: "-0.011em",
  color: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  minHeight: "100px",
}));

const InfoListSections = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
}));

const ChartContainer = styled(Grid)(() => ({
  width: getWidthRatio(300),
  height: getHeightRatio(700),
  background: "#F0F0F0",
  borderRadius: "0.72588rem",
  marginRight: "10px",
  padding: "2rem",
}));

const ChartInfoText = styled(Box)(() => ({
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "32.3784px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  justifyContent: "center",
  width: "100%",
}));

const ChartDataContainer = styled(Grid)(() => ({
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
}));

const ChartDisplayContainer = styled(Grid)(() => ({
  // height: "calc(100vh - 20rem)",
  padding: "0rem 3rem",
}));

const AnalyticsNavContainer = styled(Box)(() => ({
  height: "12%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const AnalyticsHeader = styled("h2")(() => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "36px",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
}));

const ProjectHeader = styled("h2")(() => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(2rem, 3vmin, 4rem)",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  background: "linear-gradient(92.54deg, #D946EF -9.1%, #7C00FF 48.04%)",
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
  marginLeft: "4rem",
}));

const TitleContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DataSetBox = styled(Box)(() => ({
  backgroundColor: "red",
  width: "50%",
  background: "#F0F0F0",
  padding: '3rem 5rem',
  borderRadius: "0.72588rem",
  marginBottom: '2rem',
}));

const DataSetHeader = styled(Box)(() => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(1.5rem, 2vmin, 4rem)",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  margin: "15px 0px",
}));

const DataSetContent = styled(Box)(() => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(4rem, 2vmin, 8rem)",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  justifyContent: "center",
}));

const DataSetBoxWithGraph = styled(Box)(() => ({
  backgroundColor: "red",
  height: getHeightRatio(300),
  width:'50%',
  padding: '0 3rem',
  background: "#F0F0F0",
  borderRadius: "0.72588rem",
  marginBottom: "1.5rem",
  marginTop: "1.5rem",
}));

const MenuContainer = styled(Box)(() => ({
  textAlign: "left",
  minWidth: "50px",
  marginTop: "5rem",
  padding: "0rem 3rem",
}));

const MenuIconText = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const MenuList = styled(Box)(({ isSelected }) => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "clamp(1rem, 2.3vmin, 3rem)",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  cursor: "pointer",
  color: isSelected ? "#49C4D8" : "#FFFFFF",
  margin: "1.5rem 0rem",
}));

const IconSection = styled(Box)(({ isSelected }) => ({
  width: getWidthRatio(20),
  height: getHeightRatio(20),
  marginRight: "1.5rem",
  svg: {
    path: {
      fill: isSelected ? "#49C4D8" : "#FFFFFF",
    },
  },
}));

const LogoWrapper = styled("img")(({ isSelected }) => ({
  width: "100%",
}));
const FilterContainer = styled(Box)(({ isSelected }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "28rem",
}));

export {
  DataSetBoxWithGraph,
  AnalyticsNavContainer,
  LogoWrapper,
  FilterContainer,
  DataSetHeader,
  IconSection,
  MenuList,
  TitleContainer,
  MenuContainer,
  DataSetBox,
  ProjectHeader,
  AnalyticsHeader,
  Container,
  ChartDataContainer,
  ChartContainer,
  GeneralInfoContainer,
  GeneralInfoSection,
  EmptyTemplateContainer,
  FooterContainer,
  ChartDisplayContainer,
  InfoListSections,
  Header,
  InfoSection,
  SubHeader,
  ListContainer,
  SidePanelContainer,
  DataSetContent,
  StatusHeader,
  ListMidContainer,
  ListDisplayContainer,
  ExploreContainer,
  TemplateContainer,
  ElementContainer,
  ImageDummy,
  TemplateList,
  TemplateListContainer,
  PageHeader,
  StatusContainer,
  OptionText,
  SettingContainer,
  OptionTextContainer,
  ChipContainer,
  ChipsContainer,
  TemplateTitle,
  TemplateDesp,
  InfoContainer,
  GridInfoContainer,
  GradientOutlineButton,
  GradientCutomizeButton,
  InfoText,
  FooterInfoContainer,
  ChartInfoText,
  MenuIconText,
};
