import axios from "axios";
import Cookies from "universal-cookie";
import { loaderAction } from "../redux/actions";
import { store } from "../redux";

const cookies = new Cookies();

//handle interceptor common function
const handleInterceptor = (configApi) => {
  configApi.interceptors.request.use(
    (config) => {
      config.headers["Authorization"] = `bearer ${cookies.get("jwtToken")}`;
      store.dispatch(loaderAction(true));
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  configApi.interceptors.response.use(
    (response) => {
      store.dispatch(loaderAction(false));
      return response;
    },
    (error) => {
      store.dispatch(loaderAction(false));
      return Promise.reject(error);
    }
  );
};
const baseURL = "https://api.qa.xtendr.co/";

//commom header config
export const appApi = axios.create({
  baseURL,
  withCredentials: true,
});
handleInterceptor(appApi);

// handle from data header config
export const appApiFormData = axios.create({
  baseURL,
  responseType: "json",
  headers: {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  },
  withCredentials: true,
});
handleInterceptor(appApiFormData);

// handle svg file get api header config
export const appApiFileData = axios.create({
  baseURL,
  // headers: {
  //   Accept: "application/x-www-form-urlencoded",
  //   "Content-Type": "application/x-www-form-urlencoded",
  // },
  withCredentials: true,
});
handleInterceptor(appApiFileData);
