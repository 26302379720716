import React, { useEffect, useState } from "react";
import {
  HighLighterButton,
  TimerButtonWrapper,
} from "../../styles/shared/timerContainer";
import { Box } from "@mui/material";
import { SUB_PROPERTY_TYPES } from "../../constants/renderUiMatchTypes";

export default function TimerListContainer(props) {
  const {
    selectedProperty,
    selectedSubProperty,
    handleChangeSubPropertyDatas,
  } = props;

  const [selectedTime, setSelectedTime] = useState("");
  const [timingFormatValue, setTimingFormatValue] = useState("");

  const timingFormat = ["CountUp", "CountDown"];

  const handleSecondsChange = (item) => {
    setSelectedTime(item);
    handleChangeSubPropertyDatas({ value: item });
  };

  const handleTimingFormatChange = (item) => {
    setTimingFormatValue(item);
    handleChangeSubPropertyDatas({ metaType: item });
  };

  useEffect(() => {
    setSelectedTime(selectedSubProperty?.value?.value);
    setTimingFormatValue(selectedSubProperty?.value?.metaType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubProperty?.key]);

  return (
    <Box>
      <TimerButtonWrapper>
        {timingFormat?.map((item, index) => (
          <HighLighterButton
            isSelected={timingFormatValue === item}
            isTimer={
              selectedSubProperty?.value?.type === SUB_PROPERTY_TYPES.TIMER
            }
            onClick={() => handleTimingFormatChange(item)}
            key={index}
          >
            {`${item} secs`}
          </HighLighterButton>
        ))}
      </TimerButtonWrapper>

      <TimerButtonWrapper>
        {selectedSubProperty?.options?.map((item, index) => (
          <HighLighterButton
            isSelected={selectedTime === item?.value?.value}
            isTimer={
              selectedSubProperty?.value?.type === SUB_PROPERTY_TYPES.TIMER
            }
            onClick={() => handleSecondsChange(item?.value?.value)}
            key={index}
          >
            {`${item?.value?.value} secs`}
          </HighLighterButton>
        ))}
      </TimerButtonWrapper>
    </Box>
  );
}
