import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
} from "recharts";

export default function PieCharts(props) {
  const { data, key } = props;
  const datasets = data?.length ? data : [];
  const [chartData, setChartData] = useState([]);

  const CustomTooltip = (h) => {
    return (
      <Box sx={{ height: "100px", width: "100px", background: "red" }}></Box>
    );
  };

  const getRandomColor = () => {
    let r = Math.floor(Math.random() * 255);
    let g = Math.floor(Math.random() * 255);
    let b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  useEffect(() => {
    const dataWithColors = datasets?.map((item) => {
      return { ...item, color: getRandomColor() };
    });

    if (datasets?.length) {
      setChartData(dataWithColors);
    }
  }, [data]);

  return (
    <ResponsiveContainer width={"100%"} height="80%">
      <PieChart width={"100%"} height={"100%"}>
        <Pie data={chartData} dataKey="value" nameKey="name">
          {chartData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry?.color} />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{
            fontFamily: "OpenSans-Medium",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "clamp(1rem, 2vmin, 3rem)",
            display: "flex",
            alignItems: "center",
            letterSpacing: "-0.01em",
            cursor: "pointer",
            background: "white",
            borderRadius: "10px",
          }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}
