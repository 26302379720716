import { Box } from "@mui/material";
import React from "react";
import { useDrag } from "react-dnd";
import { centerStyle } from "../../styles/commonStyles";

const fullHeightWeight = {
  height: "100%",
  // width: "100%",
  display: "block",
  ...centerStyle,
};

export default function Drag({ children, dragData }) {
  const [collected, drag, dragPreview] = useDrag({
    type: "div",
    item: { ...dragData },
  });

  return (
    <Box ref={drag} sx={{ ...fullHeightWeight }} {...collected}>
      {children}
    </Box>
  );
}
