import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Popover,
  Typography,
} from "@mui/material";
import { capitalize, filter, head, isEmpty, map, some } from "lodash";
import React, { useEffect, useState } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";

import { apiRoutes } from "../../../../../api/apiPath";
import { ReactComponent as HomeIcon } from "../../../../../assets/icons/HomeIcon.svg";
import { ROUTE_PATHS } from "../../../../../routes/routePaths";
import {
  getTemplates,
  getTemplatesWithFilters,
} from "../../../../../service/template";
import useQuery from "../../../../../utils/hooks/useQuery";
import Element from "../../shared/Element/Element";
import PageLoading from "../../shared/PageLoader/PageLoader";
import VideoContainer from "../../shared/VideoContainer/Video";
import {
  ChipContainer,
  ChipsContainer,
  ElementContainer,
  ExploreContainer,
  ExploreNavigateContainer,
  GradientCutomizeButton,
  GradientOutlineButton,
  GridInfoContainer,
  Header,
  ImageDummy,
  InfoContainer,
  QrCodeText,
  QrTemplateName,
  QrWrapper,
  SelectedArrowIcon,
  SelectedTemplateLabel,
  SubHeader,
  TemplateContainer,
  TemplateDesp,
  TemplateList,
  TemplateTitle,
  TemplateViewMoreContainer,
  TryOutQrCodeContainer,
  ViewMoreIcon,
  ViewMoreText,
} from "../styled/DashBoardStyle";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../../utils/getDimensionalRatio";
import { Footer } from "../../shared/Footer";
import queryString from "query-string";
import { elementType } from "prop-types";
import viewMoreDownIcon from "../../../../../assets/icons/viewMoreDownIcon.png";
import viewMoreUpIcon from "../../../../../assets/icons/viewMoreUpIcon.png";
import { centerStyle } from "../../../../../styles/commonStyles";
import CommonFormModal from "../../../../shared/CommonFormModal";
import { QRCodeCanvas } from "qrcode.react";
import rigthArrow from "../../../../../assets/icons/rigthArrow.png";
import { errorMessage } from "../../../../../redux/constant";
import { useDispatch } from "react-redux";
import { getApiServices } from "../../../../../api/api";

const filterChipsPreprocessor = ({ data: chips = [] }) =>
  chips.map((chip) => ({ ...chip, checked: false }));

const TemplatesContainer = () => {
  const {
    state: filters,
    loading: filterLoading,
    setState: setFilters,
  } = useQuery(apiRoutes.templateFilters, filterChipsPreprocessor);

  const [templates, setTemplates] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [, setTemplatesLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateLimit, setTemplateLimit] = useState(false);
  const [openTryOut, setOpenTryOut] = useState(false);
  const [userCredentials, setUserCredentials] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const domWidth = window.innerWidth;
  const domHeight = window.innerHeight;

  const getSelectedRoutePath = location?.pathname || "";

  const handleUserCredentials = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.users);
      setUserCredentials(data);
    } catch (err) {}
  };

  useEffect(() => {
    handleUserCredentials();
  }, []);

  const handleChipClick = ({ id }) => {
    const getProcessedFilterChip = (chip) =>
      chip.id === id ? { ...chip, checked: !chip.checked } : chip;

    return setFilters(() => filters.map(getProcessedFilterChip));
  };

  useEffect(() => {
    (async () => {
      if (filterLoading || isEmpty(filters)) return;

      const doesFilterTriggered = some(filters, "checked");
      setTemplatesLoading(true);
      try {
        if (doesFilterTriggered) {
          const filterNames = map(filter(filters, "checked"), "name");
          const { data: resData } = await getTemplatesWithFilters(filterNames);
          setTemplates(() => resData.data);
        } else if (isEmpty(allTemplates)) {
          const { data } = await getTemplates();
          setAllTemplates(data.templates);
          setTemplates(data.templates);
        } else setTemplates(allTemplates);
        setTemplatesLoading(false);
      } catch (err) {
        dispatch(
          errorMessage(
            err?.response?.data?.errors?.[0]?.message ||
              "Error While Loading Templates"
          )
        );
      }
    })();
  }, [filters, allTemplates, filterLoading]);

  // handle limit check for template list view
  useEffect(() => {
    if (selectedTemplate) setTemplateLimit(true);
    else setTemplateLimit(false);
  }, [selectedTemplate]);

  useEffect(() => {
    if (getSelectedRoutePath === ROUTE_PATHS.templateDashBoard) {
      setSelectedTemplate(null);
      setTemplateLimit(false);
    }
  }, [getSelectedRoutePath]);

  if (filterLoading) return "";
  // if (filterLoading) return <PageLoading />;

  const renderChips = () => {
    const ChipHeader = () => (
      <ChipContainer onClick={() => navigate(ROUTE_PATHS.dashBoard)}>
        <HomeIcon
          style={{ width: getWidthRatio(15), height: getHeightRatio(15) }}
        />
      </ChipContainer>
    );
    return (
      <ChipsContainer xs={12} item>
        <ChipHeader />
        {filters?.map((chip) => (
          <Chip
            key={chip.id}
            label={chip.name}
            variant="outlined"
            onClick={() => handleChipClick(chip)}
            style={{
              color: chip.checked ? "#49C4D8" : "white",
              border: chip.checked ? "1px solid #49C4D8" : "1px solid white",
              width: getWidthRatio(143),
              height: getHeightRatio(47),
              fontFamily: "OpenSans-Medium",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "clamp(1rem, 1.5vmin, 3rem)",
              lineHeight: "150%",
              letterSpacing: "-0.011em",
              backdropFilter: "blur(225px)",
              borderRadius: "16px",
              "& :hover": {
                background: "#49C4D8",
                opacity: "90%",
              },
            }}
          />
        ))}
      </ChipsContainer>
    );
  };

  const renderHeaderWithChips = () => (
    <ExploreContainer container>
      <Grid item xs={12}>
        <Header>Explore Templates</Header>
      </Grid>
      {!selectedTemplate && renderChips()}
    </ExploreContainer>
  );

  const hendleOverViewTemplate = (element) => {
    setSelectedTemplate(element);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate(ROUTE_PATHS.selectedTemplateDashBoard);
  };

  const renderTemplateList = () => {
    const getTempTemplateList = [...(templates || [])];
    const checkLimit = templateLimit
      ? getTempTemplateList?.slice(0, 3)
      : getTempTemplateList;
    
    let filteredData
    if(userCredentials.tenantId === 2 && userCredentials.id === 2) filteredData = checkLimit;
    else filteredData = checkLimit.filter(item => item.status === "published")

    return (
      <TemplateList>
        {filteredData?.map((element, index) => {
          const { previewUrl, name } = element;
          return (
            <ElementContainer
              key={index}
              id="ElementContainer"
              onClick={() => hendleOverViewTemplate(element)}
            >
              <TemplateContainer>
                {!previewUrl ? (
                  <ImageDummy
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h6"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: "medium",
                        opacity: "0.2",
                      }}
                    >
                      No Preview
                    </Typography>
                  </ImageDummy>
                ) : (
                  <img
                    src={previewUrl}
                    alt="templateImage"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                )}
              </TemplateContainer>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                  color: "white",
                  margin: "1rem 0rem",
                }}
              >
                <SubHeader>{name}</SubHeader>
              </Box>
            </ElementContainer>
          );
        })}
      </TemplateList>
    );
  };

  const renderSelectedTemplate = () => {
    if (!selectedTemplate) return <></>;

    const customizeHandler = () => {
      const params = {
        templateId: selectedTemplate?.id || "",
        templateName: selectedTemplate?.name || "",
      };
      const query = queryString.stringify(params, { arrayFormat: "bracket" });
      navigate(`${ROUTE_PATHS.templateCreation}?${query}`);
    };

    const TryOutButton = () => {
      // const id = open ? "simple-popover" : undefined;

      // const [anchorEl, setAnchorEl] = React.useState(null);
      // const open = Boolean(anchorEl);

      const handleTryOut = (event) => {
        setOpenTryOut(true);
        // setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        // setAnchorEl(null);
      };

      return (
        <div>
          <GradientOutlineButton
            aria-describedby={id}
            variant="outlined"
            onClick={handleTryOut}
          >
            Try Out
          </GradientOutlineButton>
          {/* <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            anchorPosition={{ top: 200, left: 400 }}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Box>
              <QRcodeConverter value={selectedTemplate.url}></QRcodeConverter>
            </Box>
          </Popover> */}
        </div>
      );
    };

    const { id, name, metaVideoUrl } = selectedTemplate;

    const renderTemplateActionsBtns = () => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          gap: "1rem",
          padding: "4rem 0",
        }}
      >
        {TryOutButton()}
        <GradientCutomizeButton variant="outlined" onClick={customizeHandler}>
          Customize
        </GradientCutomizeButton>
      </Box>
    );
    return (
      <Grid container sx={{ margin: "5rem 0rem" }}>
        <GridInfoContainer item xs={8}>
          <ExploreNavigateContainer>
            <SelectedTemplateLabel
              style={{
                color: "#FFFFFF",
                marginRight: getWidthRatio(34),
                cursor: "pointer",
              }}
              onClick={() => {
                setSelectedTemplate(null);
                navigate(ROUTE_PATHS.templateDashBoard);
                setTemplateLimit(false);
              }}
            >
              Explore Template
            </SelectedTemplateLabel>
            <SelectedArrowIcon src={rigthArrow} alt="icon" />
            <SelectedTemplateLabel>Selected Template</SelectedTemplateLabel>
          </ExploreNavigateContainer>

          <InfoContainer>
            <TemplateTitle>
              {capitalize(selectedTemplate?.name || "")}
            </TemplateTitle>
            <TemplateDesp>{selectedTemplate?.description}</TemplateDesp>
            {renderTemplateActionsBtns()}
          </InfoContainer>
        </GridInfoContainer>
        <Grid item xs={4}>
          <VideoContainer url={metaVideoUrl} width={400} />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Container style={{ minHeight: "100vh" }}>
        {selectedTemplate && renderSelectedTemplate()}
        <Box>
          {renderHeaderWithChips()}
          {renderTemplateList()}

          {templates?.length > 3 && selectedTemplate ? (
            <TemplateViewMoreContainer>
              <Box
                onClick={() => setTemplateLimit(!templateLimit)}
                sx={{ ...centerStyle, cursor: "pointer" }}
              >
                <ViewMoreText>View More</ViewMoreText>
                <ViewMoreIcon
                  src={templateLimit ? viewMoreDownIcon : viewMoreUpIcon}
                  alt="viewMoreicon"
                />
              </Box>
            </TemplateViewMoreContainer>
          ) : (
            <></>
          )}
        </Box>
      </Container>

      {/* handle try out modal */}
      <CommonFormModal
        open={openTryOut}
        modalBodyContainerStyle={{
          width: getWidthRatio(650, domWidth),
          height: getHeightRatio(420, domHeight),
          top: "42%",
          left: "33%",
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(650, domWidth),
          height: getHeightRatio(420, domHeight),
          borderRadius: "70px 18px 70px 70px",
        }}
        iconContainerHeight={{
          height: getHeightRatio(420, domHeight),
        }}
        hanldeClose={() => setOpenTryOut(false)}
        backIcon={false}
        cancelIcon={true}
      >
        <TryOutQrCodeContainer>
          <QrCodeText>Scan QR Code to experience</QrCodeText>
          <QrTemplateName>
            {selectedTemplate?.name || "Meet & Greet"}
          </QrTemplateName>
          <QrWrapper>
            <QRCodeCanvas
              style={{ width: "50%", height: "70%" }}
              value={selectedTemplate?.url || "Project Link"}
            />
          </QrWrapper>
        </TryOutQrCodeContainer>
      </CommonFormModal>
    </>
  );
};

export { TemplatesContainer };
