import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  InputInfoText,
  LoginButton,
  LoginContainer,
  LoginHeading,
  LoginStyledInput,
  visibilityIconStyle,
  welcomeBackStyle,
} from "../../../styles/sharedComponents";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { centerStyle } from "../../../styles/commonStyles";
import IconWrapper from "../../shared/IconWrapper";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { apiRoutes } from "../../../api/apiPath";
import { postApiServices } from "../../../api/api";
import Cookies from "universal-cookie";
import CommonFormModal from "../../shared/CommonFormModal";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../utils/getDimensionalRatio";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../routes/routePaths";
import { errorMessage, successMessage } from "../../../redux/constant";
import { useDispatch } from "react-redux";
import { CONTACT_MAIL_ID } from "../../../constants/constant";
import { ContactText, ContactUsContainer, DontHaveAccountText } from "../../../styles/landingPage";

const Modes = {
  SignIn: "signIn",
  passForgot: "forgotPass",
};
const getModeConfigs = (currentMode) => {
  const configMap = {
    [Modes.SignIn]: {
      label: "Forgot password?",
    },
    [Modes.passForgot]: {
      label: "Back to sign in",
    },
  };
  return configMap[currentMode];
};

export default function LoginPage(props) {
  const { open = true, handleClose = () => {} } = props;

  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, updatedCredentials] = useState({});
  const [showPassword, updatedShowPassword] = useState(false);
  const [signMode, setSignMode] = useState(Modes.SignIn);
  const modeConfig = getModeConfigs(signMode);

  if(cookies.get('jwtToken')) navigate(ROUTE_PATHS.dashBoard);

  const handleCredentialsChange = (event) => {
    updatedCredentials({
      ...credentials,
      [event?.target?.name]: event?.target?.value,
    });
  };

  const handleSignIn = async () => {
    try {
      const loginData = await postApiServices(apiRoutes.login, credentials);
      const loginToken = await postApiServices(
        apiRoutes.jwtTokenLogin,
        credentials
      );
      cookies.remove("jwtToken");
      cookies.set("jwtToken", loginToken?.data?.token || "", { path: "/" });
      updatedCredentials({});
      navigate(ROUTE_PATHS.dashBoard);
      dispatch(
        successMessage(loginData?.data?.message || "SignIn Successfully ...")
      );
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  const handleEnterSignIn = (e) => {
    if (e.charCode === 13) {
      handleSignIn();
    }
  };

  const handleForgotPass = async () => {
    if (!credentials.email)
    return dispatch(errorMessage("Enter email to reset password"));
    try {
      await postApiServices(apiRoutes.genResetPassMail, {
        email: credentials.email,
      });
      handleClose();
      dispatch(successMessage("Check Your InBox"));
    } catch (error) {
      dispatch(errorMessage("Error Sending Reset Mail!"));
    }
  };

  const handleEnterReset = (e) => {
    if (e.charCode === 13) {
      handleForgotPass();
    }
  };

  return (
    <>
      <CommonFormModal
        open={open}
        hanldeClose={handleClose}
        backIcon={false}
        cancelIcon={true}
        modalBodyContainerStyle={{
          width: getWidthRatio(1500),
          height: getHeightRatio(752),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(1500),
          height: getHeightRatio(752),
        }}
        iconContainerHeight={{
          height: getHeightRatio(752),
        }}
      >
        <LoginContainer>
          <Box sx={{ height: "100%" }}>
            <LoginHeading>Sign In to your Account</LoginHeading>
            <LoginHeading style={welcomeBackStyle}>WELCOME BACK</LoginHeading>

            <Box
              sx={{ ...centerStyle, flexDirection: "column", margin: "1rem 0" }}
            >
              <div>
                <LoginStyledInput
                  name="email"
                  type="email"
                  value={credentials?.email}
                  placeholder="Email"
                  onChange={handleCredentialsChange}
                  onKeyPress={
                    signMode === Modes.passForgot
                      ? handleEnterReset
                      : handleEnterSignIn
                  }
                />
              </div>
              {signMode === Modes.SignIn && (
                <div style={{ position: "relative" }}>
                  <LoginStyledInput
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={credentials?.password}
                    onChange={handleCredentialsChange}
                    placeholder="Password"
                    onKeyPress={handleEnterSignIn}
                  />
                  {showPassword ? (
                    <IconWrapper>
                      <VisibilityIcon
                        onClick={() => updatedShowPassword(!showPassword)}
                        sx={visibilityIconStyle}
                      />
                    </IconWrapper>
                  ) : (
                    <IconWrapper>
                      <VisibilityOffIcon
                        onClick={() => updatedShowPassword(!showPassword)}
                        sx={visibilityIconStyle}
                      />
                    </IconWrapper>
                  )}
                </div>
              )}

              <InputInfoText
                onClick={() => {
                  setSignMode((signMode) =>
                    signMode === Modes.passForgot
                      ? Modes.SignIn
                      : Modes.passForgot
                  );
                  updatedCredentials({});
                }}
              >
                {modeConfig.label}
              </InputInfoText>
              <ContactUsContainer>
                <DontHaveAccountText>Don’t have an Account ?</DontHaveAccountText>
                <a href={CONTACT_MAIL_ID}>
                  <ContactText>Contact Us</ContactText>
                </a>
              </ContactUsContainer>
            </Box>

            {signMode === Modes.SignIn ? (
              <LoginButton onClick={handleSignIn}>Sign In</LoginButton>
            ) : (
              <LoginButton onClick={handleForgotPass}>Reset</LoginButton>
            )}
          </Box>
        </LoginContainer>
      </CommonFormModal>
    </>
  );
}
