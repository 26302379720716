/* eslint-disable array-callback-return */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getByIdApiServices,
  postApiServices,
  putApiServices,
} from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import {
  MEET_GREET_ID,
  PUBLISH_STATUS_LIST,
} from "../../../constants/constant";
import ScreenMenuPanel from "./ScreenMenuPanel";
import LibraryPanelRenderers from "./LibraryPanelRenderers";
import MainPreviewRenderer from "./MainPreviewRenderer";
import { removeDuplicates } from "../../../utils/removeDuplicates";
import { cloneDeep } from "lodash";
import { voiceTringgerConstructJson } from "../../../utils/templateCreation/MainPreviewRenderer/voiceTringgerConstructJson";

import { videoConstructJson } from "../../../utils/templateCreation/MainPreviewRenderer/videoConstructJson";
import { leaderBoardConstructJson } from "../../../utils/templateCreation/MainPreviewRenderer/leaderBoardConstructJson";
import { multiSectionContainerJson } from "../../../utils/templateCreation/MainPreviewRenderer/multiSectionContainer";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../../constants/renderUiMatchActionClass";
import queryString from "query-string";
import { ROUTE_PATHS } from "../../../routes/routePaths";
import CommonFormModal from "../../shared/CommonFormModal";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../utils/getDimensionalRatio";
import {
  FileNameContainer,
  FileNameGetTextBox,
  FileNameSubmitButton,
  TemplateImage,
  TemplateName,
  TemplateNameViewContainer,
} from "../../../styles/sharedComponents";
import templateIcon from "../../../assets/icons/Template_Selection.png";
import { multiTriggerConstructJson } from "../../../utils/templateCreation/MainPreviewRenderer/multiTriggerConstructJson";
import { errorMessage, successMessage } from "../../../redux/constant";
import { useDispatch } from "react-redux";

export default function TemplateCreationTools(props) {
  const {
    setProjectUrl = () => {},
    setTemplateDetails = () => {},
    templateDetails = {},
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const projectId = urlParams.get("projectId");
  const getProjectName = urlParams.get("projectName");
  const templateName = urlParams.get("templateName");
  const templateId = urlParams.get("templateId") || MEET_GREET_ID;
  const projectStatus = urlParams.get("projectStatus");

  //save project Name open model
  const [collectProjectNameOpen, setCollectProjectNameOpen] = useState(false);
  //save project Name
  const [projectName, setProjectName] = useState("");
  //save selected screen details
  const [selectedScreen, setSelectedScreen] = useState({});
  //save selected properties details
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedDarkMode, setSelectedDarkMode] = useState(false);

  const [selectedPropertyLevel, setSelectedPropertyLevel] = useState({});
  //save selected sub properties details
  const [selectedSubProperty, setSelectedSubProperty] = useState({});
  //save selected Multi sub properties details

  const [selectedMultiSubProperties, setSelectedMultiSubProperties] = useState(
    []
  );

  //save  selected Sub property render keys
  const [selectedPropertyRenderKeys, setSelectedPropertyRenderKeys] = useState(
    []
  );
  //save  selected Sub property render keys
  const [retrivedSourceFiles, updateRetrivedSourceFiles] = useState({});
  //screen panel sidebar handle
  const [openProperties, setOpenProperties] = useState(true);
  const [openResetMsg, setOpenResetMsg] = useState(false);

  /*******************Tresure Hunt States**********************/

  const [selectedType, setSelectedType] = useState({});
  const [assetOptions, setAssetOptions] = useState([]);

  const functionParams = {
    setSelectedScreen,
    setSelectedProperty,
    setSelectedSubProperty,
    setTemplateDetails,
    templateDetails,
    selectedScreen,
    selectedProperty,
    selectedSubProperty,
    selectedMultiSubProperties,
    setSelectedMultiSubProperties,
  };

  const handleScoreSelections = (score) => {
    const temp = { ...selectedSubProperty?.value };
    temp["value"] = score;
    setSelectedSubProperty({ ...selectedSubProperty, value: temp });
  };

  //element Type selection in treasure hunt
  const handleTypeBasedSelect = (e, type) => {
    const temprTypes = { ...selectedType };
    let object = { type };
    const tempProp = {
      ...selectedSubProperty,
      value: { ...selectedSubProperty?.value, previewUrl: "" },
    };
    setSelectedSubProperty({ ...tempProp, ...object });
    const doubleClick = e?.detail === 2;
    if (temprTypes[selectedSubProperty?.key]?.length) {
      if (
        temprTypes[selectedSubProperty?.key]?.some(
          (rt) => rt?.type === object?.type
        )
      ) {
        temprTypes[selectedSubProperty?.key]?.map((ele, index) => {
          if (ele?.type === object?.type) {
            temprTypes[selectedSubProperty?.key].splice(index, 1);
          }
        });
        updateTsAssetsInGlobalJson({
          ...selectedType,
          [selectedSubProperty?.key]: temprTypes[selectedSubProperty?.key],
        });
      } else {
        temprTypes[selectedSubProperty?.key].push({
          ...selectedSubProperty,
          ...object,
        });
      }
    } else {
      let arr = [];
      arr.push({ ...selectedSubProperty, ...object });
      temprTypes[selectedSubProperty?.key] = [...arr];
    }

    setSelectedType(temprTypes);
  };

  const handleInsertImageData = (imageData) => {
    const assets = selectedType?.[selectedSubProperty?.key]?.map((item) => {
      if (selectedSubProperty?.type === item?.type) {
        return {
          ...item,
          value: {
            ...item?.value,
            ...imageData,
          },
        };
      } else {
        return item;
      }
    });

    setSelectedType({ ...selectedType, [selectedSubProperty?.key]: assets });
  };

  const handleTriggersElements = (multiSubProps) => {
    const elementsList = multiSubProps?.map((selectedTriggerList) => {
      return {
        ...selectedProperty?.elements?.[0],
        value: {
          ...selectedProperty?.elements?.[0]?.value,
          ...selectedTriggerList,
        },
      };
    });

    return elementsList;
  };

  const handleMultiplySubProperties = (propertyData) => {
    const cloneMultiSubProps = cloneDeep(selectedMultiSubProperties);
    const propIsExist = cloneMultiSubProps?.find(
      (prp) => prp?.meta?.assetKey === propertyData?.meta?.assetKey
    );
    if (propIsExist) {
      cloneMultiSubProps?.map((ele, index) => {
        if (ele?.meta?.assetKey === propIsExist?.meta?.assetKey) {
          cloneMultiSubProps?.splice(index, 1);
        }
      });
    } else {
      cloneMultiSubProps.push(propertyData);
    }
    setSelectedMultiSubProperties(cloneMultiSubProps);

    return handleTriggersElements(cloneMultiSubProps);
  };

  const getConstructDataArray = () => {
    if (selectedProperty?.key === "treasureIsland") {
      return selectedType[selectedSubProperty?.key]
        ? selectedType[selectedSubProperty?.key]
        : [selectedSubProperty];
    } else {
      return selectedPropertyLevel?.elements;
    }
  };

  const updateTsAssetsInGlobalJson = (data) => {
    const selectedAssetsData = data[selectedSubProperty?.key];
    let getScreenData = {};
    let getPropertyData = {};

    const getChangedScreenData = templateDetails?.uiConfig?.screens?.map(
      (screenData) => {
        if (selectedScreen?.key === screenData?.key) {
          getScreenData = {
            ...screenData,
            pageSections: screenData?.pageSections?.map((page) => {
              if (page?.key === selectedProperty?.key) {
                getPropertyData = {
                  ...page,
                  pageSections: page?.pageSections?.map((property) => {
                    if (property?.key === selectedPropertyLevel?.key) {
                      return {
                        ...property,
                        elements: selectedAssetsData || [],
                      };
                    } else {
                      return property;
                    }
                  }),
                };
                return getPropertyData;
              } else {
                return page;
              }
            }),
          };
          return getScreenData;
        } else {
          return { ...screenData };
        }
      }
    );

    setSelectedScreen({ ...getScreenData });
    setSelectedProperty({ ...getPropertyData });
    setTemplateDetails({
      ...templateDetails,
      uiConfig: {
        ...templateDetails?.uiConfig,
        screens: [...(getChangedScreenData || [])],
      },
    });
  };

  //treasure hunt instructon data handle
  const constructInstructionJson = (propertyDatas) => {
    let getScreenData = {};
    let getPropertyData = {};
    let selectableData = getConstructDataArray();
    let pageSectionData = [];

    if (propertyDatas?.treasureHuntPageSectionData) {
      pageSectionData = propertyDatas?.treasureHuntPageSectionData;
      propertyDatas = {};
    }

    const getChangedScreenData = templateDetails?.uiConfig?.screens?.map(
      (screenData) => {
        if (selectedScreen?.key === screenData?.key) {
          getScreenData = {
            ...screenData,
            pageSections: screenData?.pageSections?.map((page) => {
              if (page?.key === selectedProperty?.key) {
                getPropertyData = {
                  ...page,
                  pageSections: pageSectionData?.length ? pageSectionData : [],
                };
                return getPropertyData;
              } else {
                return page;
              }
            }),
          };
          return getScreenData;
        } else {
          return { ...screenData };
        }
      }
    );

    const getSubPropData = pageSectionData
      ?.find((item) => item?.key === selectedPropertyLevel?.key)
      ?.elements?.find((item) => item?.key === selectedSubProperty?.key).value;

    setSelectedScreen({ ...getScreenData });
    setSelectedProperty({ ...getPropertyData });
    setSelectedSubProperty({
      ...selectedSubProperty,
      value: { ...selectedSubProperty?.value, ...getSubPropData },
    });
    setTemplateDetails({
      ...templateDetails,
      uiConfig: {
        ...templateDetails?.uiConfig,
        screens: [...(getChangedScreenData || [])],
      },
    });
  };

  const constructGlobalJson = (propertyDatas) => {
    let getScreenData = {};
    let getPropertyData = {};
    let selectableData = getConstructDataArray();
    let pageSectionData = [];

    if (propertyDatas?.treasureHuntPageSectionData) {
      pageSectionData = propertyDatas?.treasureHuntPageSectionData;
      propertyDatas = {};
    }

    const getChangedScreenData = templateDetails?.uiConfig?.screens?.map(
      (screenData) => {
        if (selectedScreen?.key === screenData?.key) {
          getScreenData = {
            ...screenData,
            pageSections: screenData?.pageSections?.map((page) => {
              if (page?.key === selectedProperty?.key) {
                getPropertyData = {
                  ...page,
                  pageSections: page?.pageSections?.map((property) => {
                    if (property?.key === selectedPropertyLevel?.key) {
                      return {
                        ...property,
                        elements: selectableData?.map((ele) => {
                          if (ele?.type) {
                            if (ele?.type === selectedSubProperty?.type) {
                              return {
                                ...ele,
                                value: {
                                  ...ele?.value,
                                  ...propertyDatas,
                                },
                              };
                            } else {
                              return { ...ele };
                            }
                          } else {
                            return {
                              ...ele,
                              value: {
                                ...ele?.value,
                                ...propertyDatas,
                              },
                            };
                          }
                        }),
                      };
                    } else {
                      return property;
                    }
                  }),
                };
                return getPropertyData;
              } else {
                return page;
              }
            }),
          };
          return getScreenData;
        } else {
          return { ...screenData };
        }
      }
    );

    setSelectedScreen({ ...getScreenData });
    setSelectedProperty({ ...getPropertyData });
    setSelectedSubProperty({
      ...selectedSubProperty,
      value: { ...selectedSubProperty?.value, ...propertyDatas },
    });
    setTemplateDetails({
      ...templateDetails,
      uiConfig: {
        ...templateDetails?.uiConfig,
        screens: [...(getChangedScreenData || [])],
      },
    });
  };

  //get template data to api call
  const getTemplateDetails = async () => {
    try {
      const { data } = await getByIdApiServices(
        apiRoutes.getTemplate,
        templateId
      );
      setTemplateDetails(data);
      setSelectedScreen(data?.uiConfig?.screens?.[0]);
      getRenderPropertyKeys(data?.uiConfig?.screens);
    } catch (err) {}
  };

  const getRenderPropertyKeys = (screen) => {
    let getData = [];
    screen?.map((screenData) => {
      screenData?.pageSections?.map((pageSectionsData) => {
        pageSectionsData?.elements?.map((item) => {
          getData?.push(item?.key);
        });
      });
      screenData?.commonElements?.map((commonElememt) => {
        getData?.push(commonElememt?.key);
      });
    });
    setSelectedPropertyRenderKeys(removeDuplicates(getData));
  };

  //handle to sub properties changed datas
  const handleSubProperties = (selectedClassData) => {
    setSelectedSubProperty(selectedClassData);
  };

  const preloadPropertyLevelImage = (property) => {
    const lastIndex = selectedType[property?.key]?.length
      ? selectedType[property?.key]?.length - 1
      : null;
    const lastSelectedType = selectedType[property?.key]?.[lastIndex];
    if (lastSelectedType) {
      setSelectedSubProperty({
        ...lastSelectedType,
        value: { ...lastSelectedType?.value },
      });
    } else {
      setSelectedSubProperty(property?.elements?.[0]);
    }
  };

  const handleSelectPropertyLevel = (propertyElements) => {
    preloadPropertyLevelImage(propertyElements);
    setSelectedPropertyLevel(propertyElements);
  };

  //handle to properties changed datas
  const handleProperties = (getPropertyData) => {
    setAssetOptions(getPropertyData?.pageSections);
    setSelectedProperty(getPropertyData);

    if (getPropertyData?.pageSections?.length > 0) {
      handleSelectPropertyLevel(getPropertyData?.pageSections?.[0]);
      // setSelectedSubProperty(getPropertyData?.pageSections?.[0]?.elements?.[0]);
      if (
        getPropertyData?.pageSections?.[0]?.actionClass ===
        ACTION_CLASSES.BACKGROUND
      ) {
        if (
          getPropertyData?.pageSections?.[0]?.elements?.[1]?.value?.previewUrl
        ) {
          handleSubProperties(
            getPropertyData?.pageSections?.[0]?.elements?.[1]
          );
        } else {
          handleSubProperties(
            getPropertyData?.pageSections?.[0]?.elements?.[0]
          );
        }
      } else {
        handleSubProperties(getPropertyData?.pageSections?.[0]?.elements?.[0]);
      }
    } else {
      if (getPropertyData?.actionClass === ACTION_CLASSES.BACKGROUND) {
        if (getPropertyData?.elements?.[1]?.value?.previewUrl) {
          handleSubProperties(getPropertyData?.elements?.[1]);
        } else {
          handleSubProperties(getPropertyData?.elements?.[0]);
        }
      } else {
        handleSubProperties(getPropertyData?.elements?.[0]);
      }
      setSelectedPropertyLevel({});
    }
  };

  //handle to screen changed datas
  const handleScreenChange = (getScreenData) => {
    setSelectedScreen(getScreenData);
    handleProperties(getScreenData?.pageSections?.[0]);

    if (getScreenData?.key === selectedScreen?.key)
      setOpenProperties(!openProperties);
    else setOpenProperties(true);
  };

  //element common handle change property values
  const handleChangeSubPropertyDatas = (propertyDatas) => {
    const params = (elementsData) => {
      return {
        elementsData,
        templateDetails,
        setTemplateDetails,
        selectedScreen,
        setSelectedScreen,
        setSelectedProperty,
        selectedProperty,
        setSelectedSubProperty,
        selectedSubProperty,
        selectedPropertyLevel,
        setSelectedPropertyLevel,
      };
    };

    if (selectedProperty?.actionClass === ACTION_CLASSES.ASSET_MULTISELECT) {
      multiTriggerConstructJson(
        params(handleMultiplySubProperties(propertyDatas))
      );
      return;
    }
    if (
      selectedProperty?.actionClass === ACTION_CLASSES.MULTI_SECTION_CONTAINER
    ) {
      multiSectionContainerJson(params(propertyDatas));
      return;
    }
    if (
      selectedProperty?.actionClass === PREVIEW_ACTION_CLASSES.VIDEO_CONTAINER
    ) {
      videoConstructJson(params(propertyDatas));
      return;
    }
    if (selectedProperty?.key === "LeaderBoard") {
      leaderBoardConstructJson(params(propertyDatas));
      return;
    }
    if (
      selectedProperty?.actionClass ===
        PREVIEW_ACTION_CLASSES.VOICE_TRIGGER_PREVIEW ||
      selectedProperty?.actionClass === ACTION_CLASSES.MULITI_PLAYER
    ) {
      voiceTringgerConstructJson(params(propertyDatas));
      return;
    }

    let getScreenData = {};
    let getPropertyData = {};
    let treasureHuntPageSectionData = [];

    // Create the separate structuring for the treasure Hunt Global JSON
    if (selectedProperty?.actionClass === ACTION_CLASSES.ASSET_TREATURE_HUNT) {
      handleInsertImageData(propertyDatas);
      constructGlobalJson(propertyDatas);
    } else if (selectedProperty?.actionClass === ACTION_CLASSES.INSTRUCTIONS) {
      //check either one color or image
      const checkColorOrImage = (pageSectionData) => {
        if (selectedPropertyLevel?.actionClass === ACTION_CLASSES.BACKGROUND) {
          const getBackgroundData = [];

          const getColorData = pageSectionData?.elements?.find(
            (item) => item?.actionClass === ACTION_CLASSES.COLOR_CONTAINER
          );
          const getImageData = pageSectionData?.elements?.find(
            (item) => item?.actionClass === ACTION_CLASSES.IMAGE_CONTAINER
          );

          //color handle
          if (
            selectedSubProperty?.actionClass === ACTION_CLASSES.COLOR_CONTAINER
          ) {
            getBackgroundData?.push({
              ...getColorData,
              value: {
                ...getColorData?.value,
                ...propertyDatas,
              },
            });

            getBackgroundData?.push({
              ...getImageData,
              value: {
                ...getImageData?.value,
                previewUrl: "",
                url: "",
                meta: {},
              },
            });
          }

          //image handle
          if (
            selectedSubProperty?.actionClass === ACTION_CLASSES.IMAGE_CONTAINER
          ) {
            getBackgroundData?.push({
              ...getColorData,
              value: {
                ...getColorData?.value,
                color: "",
                gradient: "",
              },
            });

            getBackgroundData?.push({
              ...getImageData,
              value: {
                ...getImageData?.value,
                ...propertyDatas,
              },
            });
          }

          return getBackgroundData;
        } else {
          return pageSectionData?.elements?.map((elementData) => {
            if (elementData?.key === selectedSubProperty?.key) {
              return {
                ...elementData,
                value: {
                  ...elementData?.value,
                  ...propertyDatas,
                },
              };
            } else {
              return { ...elementData };
            }
          });
        }
      };

      if (
        false ||
        selectedPropertyLevel?.actionClass === ACTION_CLASSES.BACKGROUND
      ) {
        treasureHuntPageSectionData = selectedProperty?.pageSections?.map(
          (item) => {
            if (item?.key === selectedPropertyLevel?.key) {
              return {
                ...item,
                elements: checkColorOrImage(selectedPropertyLevel),
              };
            } else {
              return item;
            }
          }
        );
      } else {
        treasureHuntPageSectionData = selectedProperty?.pageSections?.map(
          (item) => {
            if (item?.key === selectedPropertyLevel?.key) {
              return {
                ...item,
                elements: item?.elements?.map((item) => {
                  if (item?.key === selectedSubProperty?.key) {
                    return {
                      ...item,
                      value: { ...item?.value, ...propertyDatas },
                    };
                  } else {
                    return item;
                  }
                }),
              };
            } else {
              return item;
            }
          }
        );
      }
      constructInstructionJson({ treasureHuntPageSectionData });
    } else {
      //ending screen button data handle
      let meetGreetButtonData = {};
      if (propertyDatas?.nested) {
        delete propertyDatas?.nested;

        if (
          selectedProperty?.actionClass ===
          PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
        ) {
          meetGreetButtonData = { ...propertyDatas };
          propertyDatas = {};
        } else {
          meetGreetButtonData = {};
        }
      }

      //treasure hunt end screen common element
      const getTreasureCommonElementData = (pageSectionData) => {
        return pageSectionData?.commonElements?.map((comElementData) => {
          if (comElementData?.key === "Congratulations!") {
            return {
              ...comElementData,
              value: {
                ...comElementData?.value,
                ...propertyDatas,
              },
            };
          } else {
            let fontSize = "";
            let increseFontSize = "";

            if (propertyDatas?.fontSize) {
              increseFontSize =
                comElementData?.key === "TotalPointSscored"
                  ? 0.5
                  : comElementData?.key === "42"
                  ? 4
                  : 1;
              fontSize =
                comElementData?.key === "leaderBoardText"
                  ? comElementData?.value?.fontSize
                  : parseInt(propertyDatas?.fontSize) * increseFontSize || "18";
            }

            return {
              ...comElementData,
              value: {
                ...comElementData?.value,
                ...propertyDatas,
                ...(propertyDatas?.fontSize ? { fontSize } : {}),
              },
            };
          }
        });
      };

      //check either one color or image
      const checkColorOrImage = (pageSectionData) => {
        if (selectedProperty?.actionClass === ACTION_CLASSES.BACKGROUND) {
          const getBackgroundData = [];

          const getColorData = pageSectionData?.elements?.find(
            (item) => item?.actionClass === ACTION_CLASSES.COLOR_CONTAINER
          );
          const getImageData = pageSectionData?.elements?.find(
            (item) => item?.actionClass === ACTION_CLASSES.IMAGE_CONTAINER
          );

          //color handle
          if (
            selectedSubProperty?.actionClass === ACTION_CLASSES.COLOR_CONTAINER
          ) {
            getBackgroundData?.push({
              ...getColorData,
              value: {
                ...getColorData?.value,
                ...propertyDatas,
              },
            });

            getBackgroundData?.push({
              ...getImageData,
              value: {
                ...getImageData?.value,
                previewUrl: "",
                url: "",
                meta: {},
              },
            });
          }

          //image handle
          if (
            selectedSubProperty?.actionClass === ACTION_CLASSES.IMAGE_CONTAINER
          ) {
            getBackgroundData?.push({
              ...getColorData,
              value: {
                ...getColorData?.value,
                color: "",
                gradient: "",
              },
            });

            getBackgroundData?.push({
              ...getImageData,
              value: {
                ...getImageData?.value,
                ...propertyDatas,
              },
            });
          }

          return getBackgroundData;
        } else {
          return pageSectionData?.elements?.map((elementData) => {
            if (elementData?.key === selectedSubProperty?.key) {
              return {
                ...elementData,
                value: {
                  ...elementData?.value,
                  ...propertyDatas,
                },
              };
            } else {
              return { ...elementData };
            }
          });
        }
      };

      const getChangedScreenData = templateDetails?.uiConfig?.screens?.map(
        (screenData) => {
          if (selectedScreen?.key === screenData?.key) {
            getScreenData = {
              ...screenData,
              pageSections: screenData?.pageSections?.map((pageSectionData) => {
                if (selectedProperty?.key === pageSectionData?.key) {
                  getPropertyData = {
                    ...pageSectionData,
                    elements: checkColorOrImage(pageSectionData),
                    ...(selectedProperty?.actionClass ===
                    PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC
                      ? {
                          commonElements:
                            getTreasureCommonElementData(pageSectionData),
                        }
                      : {}),

                    ...meetGreetButtonData,
                  };
                  return getPropertyData;
                } else {
                  return { ...pageSectionData };
                }
              }),
            };
            return getScreenData;
          } else {
            return { ...screenData };
          }
        }
      );

      setSelectedScreen({ ...getScreenData });
      setSelectedProperty({ ...getPropertyData });
      setSelectedSubProperty({
        ...selectedSubProperty,
        value: { ...selectedSubProperty?.value, ...propertyDatas },
      });
      setTemplateDetails({
        ...templateDetails,
        uiConfig: {
          ...templateDetails?.uiConfig,
          screens: [...(getChangedScreenData || [])],
        },
      });
    }
  };

  //drop functionally handle
  const handleDrop = (propertyDatas) => {
    handleChangeSubPropertyDatas(propertyDatas);
  };

  //handle autoSave data
  const handleAutoSaveData = async (projectId) => {
    const sendAutoSaveTemplateData = {
      name: getProjectName || "Config dsf",
      version: "1.0.1-not-implemented",
      projectId: projectId || "",
      baseTemplateId: templateDetails?.id,
      screens: templateDetails?.uiConfig?.screens,
    };

    try {
      const { data } = await putApiServices(
        apiRoutes.autoSave,
        sendAutoSaveTemplateData
      );
    } catch (err) {}
  };

  //handle project creation
  const handleInitialProjectCreationCall = async () => {
    try {
      const sendTemplateData = {
        name: projectName || "Config dsf",
        version: "1.0.1-not-implemented",
        baseTemplateId: templateDetails?.id,
        screens: templateDetails?.uiConfig?.screens,
      };
      const { data } = await postApiServices(
        apiRoutes.projectCreation,
        sendTemplateData
      );
      setProjectUrl(data?.data?.url);
      const params = {
        projectId: data?.data?.id || "",
        projectName: data?.data?.name || "",
        templateName: templateName || "",
      };
      const query = queryString.stringify(params, { arrayFormat: "bracket" });
      navigate(`${ROUTE_PATHS.templateCreation}?${query}`);
      setCollectProjectNameOpen(false);
      dispatch(
        successMessage(data?.message || "Project created successfully ...")
      );
    } catch (err) {
      setCollectProjectNameOpen(true);
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  //Handle Auto Save Project Data
  const handleAutoSave = () => {
    if (templateDetails && getProjectName) {
      if (projectId) handleAutoSaveData(projectId);
      else handleInitialProjectCreationCall();
    }
  };

  const getProjectDetailsById = async (getProjectId) => {
    try {
      const { data } = await getByIdApiServices(
        apiRoutes.projectCreation,
        getProjectId
      );
      setTemplateDetails(data);
      setSelectedScreen(data?.uiConfig?.screens?.[0]);
      getRenderPropertyKeys(data?.uiConfig?.screens);
      setProjectUrl(data?.url);
    } catch (err) {}
  };

  useEffect(() => {
    if (projectId) getProjectDetailsById(projectId);
    else if (templateId) getTemplateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, projectId]);

  useEffect(() => {
    if (projectStatus === PUBLISH_STATUS_LIST.Draft) {
      const getClearInterval = setInterval(() => {
        handleAutoSave();
      }, 30000);

      return () => clearInterval(getClearInterval);
    }
  }, [templateDetails, projectId]);

  //handle ProjectName get
  useEffect(() => {
    if (getProjectName) setCollectProjectNameOpen(false);
    else setCollectProjectNameOpen(true);
  }, [getProjectName]);

  //handle reset
  const handleReset = () => {
    getTemplateDetails();
    setOpenResetMsg(false);
  };

  //handle projectName submit
  const handleProjectName = () => {
    if (projectName) {
      handleInitialProjectCreationCall();
    } else {
      dispatch(errorMessage("Enter project name"));
    }
  };

  return (
    <>
      <Grid container sx={{ height: "100%" }}>
        {/* screen list render */}
        <ScreenMenuPanel
          templateScreenList={templateDetails?.uiConfig?.screens}
          openProperties={openProperties}
          setOpenProperties={setOpenProperties}
          handleScreenChange={handleScreenChange}
          selectedScreen={selectedScreen}
          selectedDarkMode={selectedDarkMode}
          setSelectedDarkMode={setSelectedDarkMode}
          handleProperties={handleProperties}
          selectedProperty={selectedProperty}
          handleReset={handleReset}
          openResetMsg={openResetMsg}
          setOpenResetMsg={setOpenResetMsg}
        />

        {/* mobile screen view template */}
        <MainPreviewRenderer
          selectedPropertyLevel={selectedPropertyLevel}
          templateDetails={templateDetails}
          selectedScreen={selectedScreen}
          selectedProperty={selectedProperty}
          selectedDarkMode={selectedDarkMode}
          setSelectedDarkMode={setSelectedDarkMode}
          selectedSubProperty={selectedSubProperty}
          selectedPropertyRenderKeys={selectedPropertyRenderKeys}
          handleDrop={handleDrop}
          selectedType={selectedType}
          setSelectedScreen={setSelectedScreen}
          retrivedSourceFiles={retrivedSourceFiles}
          updateRetrivedSourceFiles={updateRetrivedSourceFiles}
        />

        {/* library render */}
        <LibraryPanelRenderers
          selectedType={selectedType}
          handleTypeBasedSelect={handleTypeBasedSelect}
          assetOptions={assetOptions}
          openProperties={openProperties}
          selectedProperty={selectedProperty}
          selectedSubProperty={selectedSubProperty}
          setSelectedSubProperty={setSelectedSubProperty}
          selectedPropertyLevel={selectedPropertyLevel}
          handleSelectPropertyLevel={handleSelectPropertyLevel}
          handleSubProperties={handleSubProperties}
          handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          selectedScreen={selectedScreen}
          selectedMultiSubProperties={selectedMultiSubProperties}
          retrivedSourceFiles={retrivedSourceFiles}
          updateRetrivedSourceFiles={updateRetrivedSourceFiles}
          handleScoreSelections={handleScoreSelections}
          setSelectedMultiSubProperties={setSelectedMultiSubProperties}
        />
      </Grid>

      {/* get upload data file name */}
      <CommonFormModal
        open={collectProjectNameOpen}
        modalBodyContainerStyle={{
          width: getWidthRatio(885),
          height: getHeightRatio(291),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(885),
          height: getHeightRatio(291),
        }}
        iconContainerHeight={{
          height: getHeightRatio(291),
        }}
        backIcon={false}
        cancelIcon={false}
      >
        <FileNameContainer>
          <TemplateNameViewContainer>
            <TemplateImage src={templateIcon} alt="templateIcon" />
            <TemplateName>Meet & Greet</TemplateName>
          </TemplateNameViewContainer>

          <FileNameGetTextBox
            onChange={(event) => setProjectName(event?.target?.value)}
            value={projectName}
            placeholder="Project Name"
          />
          <FileNameSubmitButton onClick={handleProjectName}>
            Start
          </FileNameSubmitButton>
        </FileNameContainer>
      </CommonFormModal>
    </>
  );
}
