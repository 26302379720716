import { Box, Popper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { singleObejct } from "../../utils/convertSingleObject";
import DoneIcon from "@mui/icons-material/Done";
import {
  AdditionalButtonContainer,
  AdditionalButtonHeading,
  CustomStyleGetUrlTextBox,
  DoneIconContainer,
} from "../../styles/endingScreenButtonStyle";
import CustomCheckBoxField from "./CustomCheckBoxField";
import { ButtonTextBox } from "../../styles/sharedComponents";

const popperStyle = {
  display: "flex",
  transform: "none !important",
  top: "32% !important",
  left: "81.5% !important",
};

export default function EndScreenButtonComponent({
  handleChangeSubPropertyDatas = () => {},
  selectedProperty = {},
  selectedSubProperty = {},
}) {
  const [checkList, setCheckList] = useState({});
  const [buttonLink, setButtonLink] = useState({});
  const [customizedButtonName, setCustomizedButtonName] = useState({});
  const [selectButtonName, setSelectButtonName] = useState("");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChangeCheckBox = (e) => {
    const { name, checked } = e.target;
    const getAllButtons = selectedProperty?.value?.buttons;

    const getOptions = selectedProperty?.options?.map((item) => {
      if (item?.type === name) {
        return { ...item, enabled: checked };
      } else {
        return { ...item };
      }
    });

    if (checked) {
      const getNewButton = selectedProperty?.options?.find(
        (item) => item?.type === name
      );
      if (!getAllButtons?.some((item) => item?.type === name)) {
        const existingButton = selectedProperty?.value?.buttons?.[0];
        const existingButtonStyle = {
          bold: existingButton?.bold,
          color: existingButton?.color,
          font: existingButton?.font,
          fontColor: existingButton?.fontColor,
          fontSize: existingButton?.fontSize,
          italic: existingButton?.italic,
          underline: existingButton?.underline,
        };

        const propertyData = {
          ...selectedProperty,
          options: [...getOptions],
          value: {
            buttons: [
              ...(selectedProperty?.value?.buttons || []),
              { ...getNewButton, ...existingButtonStyle },
            ],
          },
        };
        handleChangeSubPropertyDatas({ ...propertyData, nested: true });
      }
    } else {
      const tempButtons = [...getAllButtons];
      const getIndex = tempButtons?.findIndex((item) => item?.type === name);
      tempButtons?.splice(getIndex, 1);

      const propertyData = {
        ...selectedProperty,
        options: [...getOptions],
        value: {
          buttons: [...tempButtons],
        },
      };
      handleChangeSubPropertyDatas({ ...propertyData, nested: true });
    }

    setCheckList({ ...checkList, [name]: checked });
    setOpen(checked);
    setAnchorEl(e.currentTarget);
    setSelectButtonName(name);
  };

  const handleButtonLink = (e) => {
    setButtonLink({ ...buttonLink, [selectButtonName]: e.target.value });
  };

  const handleCustomizedButtonName = (e) => {
    const { name, value } = e.target;
    setCustomizedButtonName({
      ...customizedButtonName,
      [name]: value,
    });
  };

  useEffect(() => {
    const getButtonList = selectedProperty?.value?.buttons?.map((item) => {
      if (customizedButtonName?.[item?.type])
        return { ...item, name: customizedButtonName?.[item?.type] };
      else return item;
    });
    const propertyData = {
      ...selectedProperty,
      value: {
        buttons: getButtonList,
      },
    };
    handleChangeSubPropertyDatas({ ...propertyData, nested: true });
  }, [customizedButtonName]);

  useEffect(() => {
    const getButtonList = {};
    selectedProperty?.value?.buttons?.map((item) => {
      return Object.assign(getButtonList, {
        [item?.type]: item?.name || item?.type || "",
      });
    });
    setCustomizedButtonName(getButtonList);
  }, []);

  const handleButtonLinkSubmit = () => {
    setOpen(false);

    const buttonUrlSet = selectedProperty?.value?.buttons?.map((item) => {
      if (item?.type === selectButtonName) {
        return {
          ...item,
          buttonUrl: buttonLink?.[selectButtonName],
        };
      } else return item;
    });

    const propertyData = {
      ...selectedProperty,
      value: {
        buttons: buttonUrlSet,
      },
    };
    handleChangeSubPropertyDatas({ ...propertyData, nested: true });
  };

  useEffect(() => {
    let getData = [];
    selectedProperty?.options?.map((item) => {
      if (!item?.default) {
        getData?.push({ [item?.type]: item?.enabled });
      }
    });

    setCheckList(singleObejct(getData));
  }, []);

  return selectedProperty?.options?.filter((item) => !item?.default)?.length ? (
    <Box>
      <AdditionalButtonContainer sx={{ position: "relative" }}>
        <AdditionalButtonHeading>Additional Buttons</AdditionalButtonHeading>
        {selectedProperty?.options?.map((item, index) => {
          return (
            <>
              {!item?.default ? (
                <Box sx={{ mt: 2, pl: 1.5, display: "flex" }} key={index}>
                  <CustomCheckBoxField
                    label={item?.isNameEditable ? "" : item?.type}
                    checked={checkList?.[item?.type]}
                    onChange={handleChangeCheckBox}
                    name={item?.type}
                    defaultChecked={item?.enabled}
                  />

                  {item?.isNameEditable ? (
                    <ButtonTextBox
                      onChange={handleCustomizedButtonName}
                      value={
                        customizedButtonName?.[item?.type] || item?.type || ""
                      }
                      name={item?.type}
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              ) : (
                <></>
              )}
            </>
          );
        })}

        <Popper open={open} anchorEl={anchorEl} sx={popperStyle}>
          <CustomStyleGetUrlTextBox
            onChange={handleButtonLink}
            placeholder={"Enter the URL"}
          />

          <DoneIconContainer onClick={handleButtonLinkSubmit}>
            <DoneIcon sx={{ fontSize: "18px" }} />
          </DoneIconContainer>
        </Popper>
      </AdditionalButtonContainer>
    </Box>
  ) : (
    <></>
  );
}
