import React, { useEffect, useRef, useState } from "react";
import { ColorPickerContainer } from "../../../styles/colorPalette";
import ColorPicker from "react-best-gradient-color-picker";

export default function CustomColorPicker(props) {
  const { value, colorPickerHandleChange = () => {} } = props;
  const inputEl = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setWidth(inputEl?.current?.offsetWidth - 2);
    setHeight(inputEl?.current?.offsetHeight);
  }, [inputEl?.current?.offsetWidth, inputEl?.current?.offsetHeight]);

  return (
    <ColorPickerContainer ref={inputEl}>
      {width ? (
        <ColorPicker
          height={height}
          width={width}
          value={value}
          onChange={colorPickerHandleChange}
          hideInputs
          hideOpacity
          hideHue
          hideControls
          hidePresets
          hideEyeDrop
          hideAdvancedSliders
          hideInputType
          hideColorGuide
          gradient
        />
      ) : (
        <></>
      )}
    </ColorPickerContainer>
  );
}
