import { shareIcon } from "./icons";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import PreviewTab from "../components/pages/templateCreation/previewAndPublish/PreviewTab";
import PublishTab from "../components/pages/templateCreation/previewAndPublish/PublishTab";

export const CONTACT_MAIL_ID = "mailto:info@xtendr.co";
export const MEET_GREET_ID = "4cdf1b6d-0d96-44b4-a8fd-1af7075fde59";

//handle preview and publish tab list
export const previewAndPublishTabList = ({ rejectedStatus = false }) => {
  return [
    rejectedStatus
      ? {}
      : {
          icon: (
            <VisibilityOutlinedIcon
              sx={{ color: "#ffffff", fontSize: "30px" }}
            />
          ),
          label: "Preview",
          value: "preview",
        },
    { icon: shareIcon, label: "Publish", value: "publish" },
  ];
};

//handle preview and publish tab content list
export const previewAndPublishTabContentList = (props) => {
  const {
    projectUrl,
    handleBackToEdit = () => {},
    userCredentials,
    projectId,
    rejectedStatus = false,
  } = props;

  return [
    rejectedStatus
      ? {}
      : {
          Component: <PreviewTab projectUrl={projectUrl} />,
          value: "preview",
        },
    {
      Component: (
        <PublishTab
          handleBackToEdit={handleBackToEdit}
          projectUrl={projectUrl}
          userCredentials={userCredentials}
          projectId={projectId}
        />
      ),
      value: "publish",
    },
  ];
};

// handle status for publish projects
export const PUBLISH_STATUS_LIST = {
  Draft: "draft",
  Pending: "pending",
  Active: "active",
  Completed: "completed",
  Published: "published",
  Rejected: "rejected",
};
export const projectStatusPublished = [
  PUBLISH_STATUS_LIST.Active,
  PUBLISH_STATUS_LIST.Completed,
  PUBLISH_STATUS_LIST.Published,
];

export const INFO = {
  IMAGE_FORMAT_MESSAGE: "Inappropriate format or size for the image!",
  AUDIO_FORMAT_MESSAGE: "Inappropriate format or size for the audio!",
  GLB_FORMAT_MESSAGE: "Inappropriate format or size for the 3D image!",
  VIDEO_FORMAT_MESSAGE: "Inappropriate format or size for the video!",
};
export const DASHBOARD_INFO = {
  AGE_GROUP: "Age group",
  VIEWS: "Date Wise Graph of Views",
  ENGAGEMENT: "Date Wise Graph of Dwell Time",
};

//font list of values
export const fontSizeList = [
  { label: "10" },
  { label: "12" },
  { label: "14" },
  { label: "16" },
  { label: "18" },
  { label: "20" },
  { label: "22" },
  { label: "24" },
  { label: "26" },
  { label: "28" },
  { label: "30" },
];
export const fontFamilyList = [
  { label: "OpenSans-Bold" },
  { label: "OpenSans-Medium" },
  { label: "OpenSans-Regular" },
  { label: "BebasNeue-Regular" },
  { label: "CourierPrime-Bold" },
  { label: "CourierPrime-Italic" },
  { label: "CourierPrime-Regular" },
  { label: "Figtree-Bold" },
  { label: "Figtree-Medium" },
  { label: "Figtree-MediumItalic" },
  { label: "IBMPlexMono-Bold" },
  { label: "IBMPlexMono-Medium" },
  { label: "IBMPlexMono-MediumItalic" },
  { label: "IBMPlexSerif-Bold" },
  { label: "IBMPlexSerif-Medium" },
  { label: "Lato-Bold" },
  { label: "Lato-Italic" },
  { label: "Lato-Regular" },
  { label: "Merriweather-Black" },
  { label: "Merriweather-Italic" },
  { label: "Merriweather-Regular" },
  { label: "Montserrat-Bold" },
  { label: "Montserrat-Medium" },
  { label: "Montserrat-MediumItalic" },
  { label: "Oswald-Bold" },
  { label: "Oswald-Medium" },
  { label: "Oswald-Regular" },
  { label: "Poppins-Bold" },
  { label: "Poppins-Medium" },
  { label: "Poppins-MediumItalic" },
  { label: "Poppins-Regular" },
  { label: "Roboto-Bold" },
  { label: "Roboto-MediumItalic" },
  { label: "Roboto-Regular" },
  { label: "SairaCondensed-Bold" },
  { label: "SairaCondensed-Medium" },
  { label: "SairaCondensed-Regular" },
  { label: "Comfortaa-Bold" },
  { label: "Comfortaa-Medium" },
  { label: "Comfortaa-Regular" },
  { label: "Exo-Black" },
  { label: "Exo-Bold" },
  { label: "Exo-Medium" },
  { label: "Exo-Regular" },
  { label: "IBMPlexSans-Bold" },
  { label: "IBMPlexSans-Medium" },
  { label: "IBMPlexSans-Regular" },
  { label: "Neucha-Regular" },
  { label: "SpaceGrotesk-Bold" },
  { label: "SpaceGrotesk-Light" },
  { label: "SpaceGrotesk-Medium" },
  { label: "BalooBhaijaan2-Bold" },
  { label: "BalooBhaijaan2-Regular" },
  { label: "BalooBhaijaan2-SemiBold" },
  { label: "PassionOne-Black" },
  { label: "PassionOne-Bold" },
  { label: "PassionOne-Regular" },
  { label: "RammettoOne-Regular" },
  { label: "ChakraPetch-Bold" },
  { label: "ChakraPetch-Medium" },
  { label: "ChakraPetch-Regular" },
  { label: "ChakraPetch-SemiBold" },
];
