import styled from "@emotion/styled";

export const StyledTextarea = styled("textarea")({
  width: "90%",
  fontFamily: "IBM Plex Sans, sans-serif",
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "1.5",
  margin: "0px 0px 20px 0px",
  padding: "12px",
  background: "#000000",
  borderRadius: "10px",
  minHeight: 85,
  border: "none",
  color: "#FFFFFFA8",
  overflow: "auto",
  outline: "none",
});
