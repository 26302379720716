import styled from "@emotion/styled";
import { Box, Grid, InputBase } from "@mui/material";
import { centerStyle, textOverflowEllipsStyle } from "../commonStyles";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";

export const LibraryContainer = styled(Grid)(({ style }) => {
  return {
    height: "100%",
    width: "100%",
    marginLeft: "auto",
    boxSizing: "border-box",
    ...style,
  };
});
export const LibraryPanelHeadContainer = styled(Box)(({ style }) => {
  return {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 1010,
    padding: "0px 20px 0px",
    ...style,
  };
});
export const LibraryPanelHeading = styled(Box)(({ style }) => {
  return {
    fontStyle: "normal",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    ...textOverflowEllipsStyle,
    height: "50px",
    textAlign: "start",
    display: "flex",
    alignItems: "end",
    fontFamily: "OpenSans-Medium",
    fontWeight: "400",
    textTransform: "uppercase",
    color: "#FFFFFF",
    margin: `0px 0px ${getHeightRatio(20)}px 0px`,
    ...style,
  };
});
export const LibraryPropertyWrapper = styled(Box)(({ style }) => {
  return {
    margin: `${getHeightRatio(15)}px 0px`,
    display: "flex",
    overflowY: "hidden",
    overflowX: "auto",
    cursor: "pointer",
    ...style,
  };
});
export const PropertyHeader = styled(Box)(() => {
  return {
    width: "100%",
    height: getHeightRatio(20),
    marginRight: 1,
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
  };
});
export const PropertyMainHeader = styled(Box)(() => {
  return {
    width: getWidthRatio(20),
    height: getHeightRatio(20),
    marginRight: 1,
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
  };
});
export const LibraryPropertyContainer = styled(Box)(({ isSelected, style }) => {
  return {
    overflowY: "hidden",
    overflowX: "auto",
    //   margin: "5px 7% 5px 0px",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    margin: `${getHeightRatio(15)}px ${getHeightRatio(5)}px`,
    marginRight: `${getHeightRatio(15)}px`,
    ...centerStyle,
    color: isSelected ? "#49C4D8" : "#AAAAAA",
    fill: isSelected ? "#49C4D8" : "#AAAAAA",

    ".animationIcon": {
      fill: "none",
      stroke: isSelected ? "#49C4D8" : "#AAAAAA",
    },
    "svg path": {
      fill: isSelected ? "#49C4D8" : "#AAAAAA",
    },
    ...style,
  };
});
export const subPropertyIconContainer = {
  width: getWidthRatio(20),
  height: getHeightRatio(20),
  marginRight: 1,
};
export const LibraryBodyContainer = styled(Box)(({ style }) => {
  return {
    height: "77%",
    overflow: "hidden auto",
    padding: "0px 8px",
    ...style,
  };
});

//voice tigger style
export const VoiceTriggerTextField = styled(InputBase)(
  ({ isSelected, style, isNumberInputOnly }) => {
    return {
      width: "100%",
      height: "5.39906103286385%",
      padding: isNumberInputOnly
        ? "5px 20px"
        : isSelected
        ? "5px 55px 5px 20px"
        : "5px 20px",
      fontFamily: "OpenSans-Regular",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "clamp(0.8rem, 1.5vmin, 4rem)",
      letterSpacing: "-0.011em",
      lineHeight: "150%",
      color: isSelected ? "#FFFFFF" : "rgba(255, 255, 255, 0.5)",
      background: isSelected ? "#1B1B1B" : "#4C4C4C",
      border: isSelected ? "1px solid #49C4D8" : "0",
      borderRadius: "10px",
      ...style,
    };
  }
);
export const VoiceTriggerRemoveIcon = styled(Box)(({ isSelected, style }) => {
  return {
    display: isSelected ? "block" : "none",
    width: "10px",
    height: "0px",
    border: "1px solid #FFFFFF",
    position: "absolute",
    top: "50%",
    left: "90%",
    cursor: "pointer",
    ...style,
  };
});
