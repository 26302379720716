import styled from "@emotion/styled";
import { Box } from "@mui/material";
import landingPageBackground from "../assets/icons/landingPageBackground.png";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";
import { centerStyle } from "./commonStyles";

export const LandingContainer = styled(Box)({
  height: "100vh",
  width: "100%",
  boxSizing: "border-box",
  background: "#000000",
  backgroundRepeat: "no-repeat",
  overflow: "hidden auto",
});
export const LandingPageLogoContainer = styled(Box)({
  height: "10rem",
  boxSizing: "border-box",
  ...centerStyle,
  justifyContent: "start",
  color: "white",
  position: 'sticky',
  top: 0,
  zIndex: 2,
  background: '#0000000d',
  backdropFilter: 'blur(8px)',
});
export const LandingPageLogo = styled("img")(({ style }) => {
  return {
    marginLeft: getWidthRatio(75),
    maxWidth: getWidthRatio(201),
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    ...style,
  };
});
export const questionImageStyle = {
  marginLeft: getWidthRatio(14),
  marginTop: 0,
  maxWidth: getWidthRatio(45),
  height: "fit-content",
  ...centerStyle,
};
export const LandingPageBody = styled(Box)({
  height: "100%",
  width: "100%",
  marginTop: "-5%",
  // backgroundImage: `url(${landingPageBackground})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  boxSizing: "border-box",
});

export const LandingPageButon = styled(Box)({
  width: 'fit-content',
  height: getHeightRatio(75),
  margin: "35rem 0 0 -25rem",
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "-0.011em",
  fontSize: "clamp(2rem, 1vh, 1rem)",
  color: "black",
  cursor: "pointer",
  borderRadius: "0.5em",
  background: "#FFF",
  padding: "0 2em",
  zIndex: 2,
  ...centerStyle,
});

export const LandingContentContainer = styled(Box)({
  height: "90%",
  width: "50%",
  boxSizing: "border-box",
  ...centerStyle,
  justifyContent: "start",
  alignItems: "start",
  flexDirection: "column",
});
export const WelcomeText = styled(Box)(({ style }) => {
  return {
    height: "auto",
    width: "100%",
    boxSizing: "border-box",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(7.8rem,1.5vh, 2rem)",
    lineHeight: "96px",
    display: "flex",
    letterSpacing: "-0.01em",
    color: "#EFFFF4",
    textShadow: "0px 0px 38.3628px rgba(21, 37, 52, 0.5)",
    ...style,
  };
});
export const AlreadyHaveAccountTextStyle = {
  fontSize: "clamp(5rem,1.5vh, 2rem)",
  ...centerStyle,
  justifyContent: "start",
};
export const SignInButton = styled(Box)({
  width: getWidthRatio(150),
  height: getHeightRatio(50),
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "-0.011em",
  fontSize: "1.12069rem",
  lineHeight: "119px",
  color: "black",
  cursor: "pointer",
  marginLeft: "auto",
  marginRight: getWidthRatio(75),
  borderRadius: "1.5em",
  background: "#49C4D8",
  ...centerStyle,
});

export const ContactUsContainer = styled(Box)({
  height: "auto",
  width: "100%",
  ...centerStyle,
  a: {
    textDecoration: "none",
  },
});

export const MenuContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "15%",
  height: "auto",
  fontSize: "clamp(1.5rem, 1vh, 1rem)",
  flexWrap: "wrap",
  a: {
    textDecoration: "none",
    color: "white",
  },
});

export const DontHaveAccountText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2rem",
  lineHeight: "61px",
  letterSpacing: "-0.01em",
  color: "#EFFFF4",
  textShadow: "0px 0px 27.1466px rgba(21, 37, 52, 0.5)",
});
export const ContactText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2rem",
  letterSpacing: "-0.01em",
  color: "#49C4D8",
  marginLeft: getHeightRatio(20),
  lineHeight: "61px",
  cursor: "pointer",
});

export const FooterContainer = styled(Box)({
  width: "75%",
  boxSizing: "border-box",
  ...centerStyle,
  color: "white",
  justifyContent: "space-between",
  margin: "auto",
  flexDirection: "column",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
});

export const FooterLogo = styled("img")(({ style }) => {
  return {
    maxWidth: getWidthRatio(201),
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    ...style,
  };
});

export const FooterInputContainer = styled(Box)({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "row",
  flexWrap: "wrap",
  textAlign: "start",
});

export const FooterInput = styled("input")(({ style }) => {
  return {
    borderRadius: "1.5em",
    background: "transparent",
    border: "none",
    ...style,
  };
});

export const FooterButton = styled(Box)({
  width: getWidthRatio(100),
  height: getHeightRatio(50),
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  letterSpacing: "-0.011em",
  fontSize: "clamp(1rem, 1vh, 1rem)",
  lineHeight: "119px",
  color: "black",
  cursor: "pointer",
  marginLeft: "auto",
  borderRadius: "1em",
  background: "#49C4D8",
  ...centerStyle,
});

export const BrandsImage = styled("img")(({ style }) => {
  return {
    maxWidth: getWidthRatio(1800),
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    ...style,
  };
});

export const HowDoesItWorkContainer = styled(Box)({
  width: "75%",
  height: "auto",
  position: 'relative',
  boxSizing: "border-box",
  flexDirection: "column",
  margin: "auto",
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(250),
  marginBottom: getHeightRatio(50),
});

export const HowDoesItWorkTextContainer = styled(Box)({
  width: 'fit-content',
  margin: 'auto',
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
});

export const HowDoesItWorkText = styled(Box)({
  fontFamily: "Karla",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "5rem",
  letterSpacing: "-0.01em",
});

export const HowDoesItWorkQuestionMark = styled(Box)({
  fontFamily: "Karla",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "5rem",
  letterSpacing: "-0.01em",
  background: '-webkit-linear-gradient(#C00BA0, #fff)',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
});

export const HowDoesItWorkSubText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.75rem",
  letterSpacing: "-0.01em",
});

export const HowDoesItWorkCardContainer = styled(Box)({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "row",
  flexWrap: "wrap",
  color: "white",
  justifyItems: "space-between",
  justifyContent: "space-between",
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: getHeightRatio(150),
  marginBottom: getHeightRatio(50),
});

export const HowDoesItWorkCard = styled(Box)({
  width: "22%",
  height: "auto",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  padding: "0.75em",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
  background: "#1c1c1c",
  borderRadius: "1em",
});

export const HowDoesItWorkCardIcon = styled("img")(({ style }) => {
  return {
    maxWidth: getWidthRatio(100),
    marginTop: -getHeightRatio(50),
    marginLeft: -getHeightRatio(20),
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    ...style,
  };
});

export const HowDoesItWorkCardTitle = styled(Box)({
  fontFamily: "Karla",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.75rem",
  letterSpacing: "-0.01em",
  marginBottom: getHeightRatio(50),
});

export const HowDoesItWorkCardText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "1.375rem",
  letterSpacing: "-0.01513rem",
  lineHeight: '150%',
  paddingLeft: "3em",
  width: '18rem',
  textAlign: "left",
  marginBottom: getHeightRatio(50),
});

export const ViewsContainer = styled(Box)({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  position: 'relative',
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
});

export const ViewsTextContainer = styled(Box)({
  flexDirection: "row",
  display: "flex",
  flexWrap: "wrap",
  color: "white",
  marginBottom: getHeightRatio(50),
});

export const ViewsText = styled(Box)({
  fontFamily: "Karla",
  fontStyle: "italic",
  fontWeight: "700",
  fontSize: "3.75rem",
  letterSpacing: "-0.01em",
  color: "#EFFFF4",
  textShadow: "0px 0px 27.1466px rgba(21, 37, 52, 0.5)",
});

export const ViewsPlusText = styled(Box)({
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "4rem",
  letterSpacing: "-0.01em",
  background: '-webkit-linear-gradient(#C00BA0, #fff)',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
  marginTop: -getHeightRatio(40),
});

export const ViewsSubText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "2rem",
  marginBottom: getHeightRatio(50),
});

export const IframeBox = styled(Box)({
  margin: "auto",
  width: "fit-content",
  borderRadius: "0.75rem",
  border: '0.7px solid #FFF',
  background: 'linear-gradient(180deg, rgba(46, 46, 46, 0.80) 0%, #010021 100%)',
  boxShadow: '0px 4px 22px -310px #005AE0',
});

export const HowItHelpsContainer = styled(Box)({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "column",
  position: 'relative',
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(150),
});

export const HowItHelpsCardContainer = styled(Box)({
  width: "40%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
});

export const HowItHelpsText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  width: '40rem',
  fontSize: "clamp(3.6rem, 1.5vh, 2rem)",
  letterSpacing: "-0.01em",
  marginLeft: getHeightRatio(20),
  marginTop: getHeightRatio(100),
  marginBottom: getHeightRatio(100),
  lineHeight: "61px",
});

export const HowItHelpsCard = styled(Box) ({
    width: "100%",
    marginBottom: '2em',
    boxSizing: "border-box",
    ...centerStyle,
    flexDirection: "row",
    flexWrap: "wrap",
    color: "white",
    justifyContent: 'space-around',
    border: '1px dashed #FFF',
    borderRadius: '0.625rem',
    background: '#1c1c1c',
    padding: '3em',
});

export const HowItHelpsCardLogo = styled("img")(({ style }) => {
  return {
    maxWidth: getWidthRatio(100),
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    ...style,
  };
});

export const HowItHelpsCardTextContainer = styled(Box)({
  color: "white",
});

export const HowItHelpsCardSubHeading = styled(Box)({
  color:'#FFF',
  textAlign: 'left',
  fontFamily: 'OpenSans-Bold',
  fontSize: '2rem',
  fontStyle: 'italic',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: '-0.022rem',
});

export const TestimonialsContainer = styled(Box)({
  width: "100%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
});

export const TestimonialsText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontWeight: 400,
  fontSize: '3.3125rem',
  fontStyle: 'italic',
  width: '32.5rem',
  letterSpacing: "-0.01em",
  marginLeft: getHeightRatio(20),
  lineHeight: "61px",
  cursor: "pointer",
});

export const TestimonialsCardContainer = styled(Box)({
  width: "50%",
  height: "auto",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "row",
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(50),
  marginBottom: getHeightRatio(50),
  justifyContent: 'space-between',
});

export const TestimonialsCard = styled(Box)({
  width: "45%",
  height: "auto",
  boxSizing: "border-box",
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  marginTop: getHeightRatio(50),
  justifyContent: 'space-around',
  padding: '2em',
});


export const TestimonialsCardBox = styled(Box)({
  width: "100%",
  boxSizing: "border-box",
  ...centerStyle,
  flexDirection: "column",
  flexWrap: "wrap",
  color: "white",
  justifyContent: 'space-around',
  borderRadius: '1.5em',
  background: '#1c1c1c',
  padding: '2.5em',
  paddingBottom: '4em',
});

export const TestimonialsCardLogo = styled("img")(({ style }) => {
  return {
    maxWidth: getWidthRatio(100),
    marginLeft: 'auto',
    ...style,
  };
});

export const TestimonialsApostrophe = styled("Box")(({ style }) => {
  return {
    fontFamily: "OpenSans-Medium",
    fontWeight: 400,
    fontSize: 'clamp(5rem, 1.5vh, 2rem)',
    fontStyle: 'italic',
    width: '80%',
    letterSpacing: "-0.01em",
    marginLeft: -getHeightRatio(50),
    cursor: "pointer",
    textAlign: 'left',
    ...style,
  };
});

export const TestimonialsCardText = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontWeight: 400,
  fontSize: '1.1875rem',
  fontStyle: 'italic',
  marginTop: -getHeightRatio(30),
  width: '80%',
  letterSpacing: "-0.01em",
  cursor: "pointer",
});

export const TestimonialsCardTextContainer = styled(Box)({
  width: "90%",
  marginRight: 'auto',
  marginLeft: 'auto',
  boxSizing: "border-box",
  flexDirection: "column",
  justifyContent: "space-between",
  flexWrap: "wrap",
  color: "white",
  padding: '0',
  marginTop: getHeightRatio(25),
});

export const TestimonialsCardAvatar = styled("img")(({ style }) => {
  return {
    display: "flex",
    maxWidth: getWidthRatio(100),
    ...style,
  };
});

export const TestimonialsCardName = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontWeight: 400,
  fontSize: '1.1875rem',
  fontStyle: 'italic',
  marginTop: -getHeightRatio(30),
  letterSpacing: "-0.01em",
  cursor: "pointer",
  textAlign: 'right',
});

export const SocialIconContainer =  styled(Box)({
    display: "flex",
    maxWidth: getWidthRatio(100),    
    justifyContent: 'flex-end',
});

export const SocialIcon = styled("img")(({ style }) => {
  return {
    display: "flex",
    maxWidth: getWidthRatio(100),
    ...style,
  };
});