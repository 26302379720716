import {
  initialState,
  LOADER_ACTION,
  SNACK_BAR_MESSAGE,
  STORE_PROJECT_DATA,
} from "./constant";

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOADER_ACTION:
      return { ...state, loader: payload };
    case SNACK_BAR_MESSAGE:
      return { ...state, snackBarData: payload };
    case STORE_PROJECT_DATA:
      return payload;
    default:
      return state;
  }
};
