import { Modal } from "@mui/material";
import React from "react";
import { backArrowIcon, publishCancelIcon } from "../../constants/icons";
import {
  ModalBodyContainer,
  ModalChildrenContainer,
  PublishBackIcon,
  PublishCancelIconContainer,
  PublishModalContainer,
} from "../../styles/previewAndPublish/commonFormModal";

export default function CommonFormModal(props) {
  const {
    open = false,
    children,
    hanldeClose = () => {},
    modalBodyContainerStyle = {},
    modalChildrenContainerStyle = {},
    iconContainerHeight = {},
    backIcon = true,
    cancelIcon = false,
  } = props;
  return (
    <Modal open={open} onClose={hanldeClose}>
      <ModalBodyContainer style={modalBodyContainerStyle}>
        <PublishModalContainer>
          <PublishCancelIconContainer
            onClick={hanldeClose}
            style={iconContainerHeight}
          >
            {backIcon ? (
              <PublishBackIcon>{backArrowIcon}</PublishBackIcon>
            ) : (
              <></>
            )}
          </PublishCancelIconContainer>

          <ModalChildrenContainer style={modalChildrenContainerStyle}>
            {children}
          </ModalChildrenContainer>

          <PublishCancelIconContainer
            style={{ justifyContent: "start", ...iconContainerHeight }}
            onClick={hanldeClose}
          >
            {cancelIcon ? (
              <PublishBackIcon>{publishCancelIcon}</PublishBackIcon>
            ) : (
              <></>
            )}
          </PublishCancelIconContainer>
        </PublishModalContainer>
      </ModalBodyContainer>
    </Modal>
  );
}
