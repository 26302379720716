import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { snackBarAction } from "../../redux/actions";
import { snackBarAlertStyle } from "../../styles/home";
import { getWidthRatio } from "../../utils/getDimensionalRatio";

export default function CustomSnackbar() {
  const globalState = useSelector((state) => state);
  const { snackBarData = {} } = globalState;
  const [openAlert, setOpenAlert] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenAlert(snackBarData.open);
  }, [snackBarData]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setTimeout(
      () =>
        dispatch(
          snackBarAction({
            color: "",
            message: "",
            open: false,
          })
        ),
      2200
    );
  };

  return (
    <>
      <Snackbar
        open={openAlert}
        onClose={handleClose}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity={snackBarData?.color ? snackBarData?.color : "error"}
          onClose={handleClose}
          sx={snackBarAlertStyle(snackBarData)}
        >
          {/* <Box sx={{ width: getWidthRatio(500) }}> */}
          {snackBarData.message}
          {/* </Box> */}
        </Alert>
      </Snackbar>
    </>
  );
}
