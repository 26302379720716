import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { meetButtonLabel, previewButtonLabel } from "../../constants/navBar";
import {
  AccountView,
  newMeetButtonStyle,
  NavAccountContainer,
  NavContainer,
  NavLogoContainer,
  NavProjectName,
  NavProjectNameContainer,
  previewAndPublishStyle,
  accountNameStyle,
  templateIconStyle,
  logoStyle,
} from "../../styles/navBar";
import { CustomButton } from "./CustomButton";
import X_logo from "../../assets/icons/X_logo.png";
import templateIcon from "../../assets/icons/Template_Selection.png";
import IconWrapper from "./IconWrapper";
import { ROUTE_PATHS } from "../../routes/routePaths";
import CommonFormModal from "./CommonFormModal";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import {
  ApprovalButtonContainer,
  ApprovalDescription,
  ApprovalModalContainer,
  navBarBackDoitLaterStyle,
  navBarGotoTemplateStyle,
  PublishApprovedButton,
  PublishGoBackButton,
} from "../../styles/previewAndPublish/publishTabStyle";
import {
  CustomMenuItem,
  CustomMenuOptionContainer,
  MenuLabel,
} from "../../styles/sharedComponents";
import { getApiServices } from "../../api/api";
import { apiRoutes } from "../../api/apiPath";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { errorMessage, successMessage } from "../../redux/constant";

export default function NavBar(props) {
  const { onSubmit, userCredentials } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const urlParams = new URLSearchParams(window.location.search);
  const projectName = urlParams.get("projectName");
  const templateName = urlParams.get("templateName");
  const getDomWidth = window?.innerWidth;
  const getDomHeight = window?.innerHeight;

  const [openHomeButton, setOpenHomeButton] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleTemplateButton = () => {
    navigate(ROUTE_PATHS.dashBoard);
    setOpenHomeButton(false);
  };

  const handleLogout = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.logout);
      handleCloseUserMenu();
      navigate(ROUTE_PATHS.landingPage);
      cookies.remove("jwtToken");
      dispatch(successMessage(data?.message || "Logout successfully ..."));
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  return (
    <>
      <NavContainer>
        <NavLogoContainer>
          <IconWrapper>
            <img
              onClick={()=>{navigate(ROUTE_PATHS.landingPage)}}
              src={X_logo}
              alt="logo"
              style={{ ...logoStyle(getDomWidth) }}
            />
          </IconWrapper>
          <CustomButton
            label={templateName || meetButtonLabel}
            endIcon={
              <img
                src={templateIcon}
                alt="templateIcon"
                style={{ ...templateIconStyle(getDomHeight) }}
              />
            }
            variant="contained"
            style={{ ...newMeetButtonStyle(getDomHeight) }}
            disableElevation={true}
            onClick={() => setOpenHomeButton(true)}
          />
        </NavLogoContainer>

        <NavProjectNameContainer>
          <NavProjectName>{projectName || "Project Name"}</NavProjectName>
        </NavProjectNameContainer>

        <NavAccountContainer>
          <CustomButton
            item
            label={previewButtonLabel}
            onClick={() => onSubmit(projectName)}
            variant="contained"
            style={{ ...previewAndPublishStyle(getDomHeight) }}
            disableElevation={true}
          />
          <AccountView
            sx={{ ...accountNameStyle(getDomHeight) }}
            onClick={handleOpenUserMenu}
          >
            {userCredentials?.tenant?.name?.slice(0, 1) || "A"}
          </AccountView>
        </NavAccountContainer>
      </NavContainer>

      {/* handle reset modal */}
      <CommonFormModal
        open={openHomeButton}
        modalBodyContainerStyle={{
          width: getWidthRatio(1129),
          height: getHeightRatio(389),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(1129),
          height: getHeightRatio(389),
        }}
        iconContainerHeight={{
          height: getHeightRatio(389),
        }}
        hanldeClose={() => setOpenHomeButton(false)}
        backIcon={false}
        cancelIcon={true}
      >
        <ApprovalModalContainer>
          <ApprovalDescription>
            Are you sure, do you want switch the project list screen
          </ApprovalDescription>

          <ApprovalButtonContainer>
            <PublishGoBackButton
              style={navBarBackDoitLaterStyle}
              onClick={() => setOpenHomeButton(false)}
            >
              Do it Later
            </PublishGoBackButton>

            <PublishApprovedButton
              onClick={handleTemplateButton}
              style={navBarGotoTemplateStyle}
            >
              Go to projects
            </PublishApprovedButton>
          </ApprovalButtonContainer>
        </ApprovalModalContainer>
      </CommonFormModal>

      {/* handle logout */}
      <CustomMenuOptionContainer
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        style={{ marginTop: getHeightRatio(30) }}
      >
        <CustomMenuItem onClick={handleLogout}>
          <MenuLabel>Logout</MenuLabel>
        </CustomMenuItem>
      </CustomMenuOptionContainer>
    </>
  );
}
