export const propertyBackgroundKey = "background";
export const subPropertycolorKey = "color";
export const subPropertyImageKey = "image";

export const renderSubPropertyKeys = {
  prompt: "promptKey",
  avatar: "avatar",
};

export const renderPropertyKeys = {
  promptKey: "promptKey",
  avatar: "avatar",
};

export const screenKeys = {
  promptKey: "promptKey",
  avatar: "avatar",
};

export const basetemplateKeys = {
  promptKey: "promptKey",
  avatar: "avatar",
};

export const LibraryActionClasses = {
  promptKey: "promptKey",
  avatar: "avatar",
};
