import React, { useState } from "react";
import {
  CommonImageListWrapper,
  commonImageRenderHeading,
  ImageListContainer,
  ViewMoreButton,
} from "../../styles/sharedComponents";
import LibraryHeading from "./LibraryHeading";
import ImageViewWrapper from "./ImageViewWrapper";
import ViewMore from "./ViewMore";
import CommonFormModal from "./CommonFormModal";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";

export default function CommonImageRender({
  heading,
  imageList = [],
  handleChangeSubPropertyDatas = () => {},
  isAudio = false,
  isVideo = false,
  disabledHeading = false,
  mandatory = false,
  applyAudio,
  viewMore = false,
  multiSelect = false,
  selectedMultiSubProperties,
  removeIcon = false,
  handleRemove = () => {},
  setOpenPreView = () => {},
  selectedSubProperty = {},
  selectedProperty = {},
  tenantUploads = true,
  defaultSelection = false,
  imageViewContainerCustomStyle = {},
}) {
  const [viewMoreOpen, setViewMoreOpen] = useState(false);

  const handleViewMore = () => {
    setViewMoreOpen(true);
  };

  return (
    <>
      <ImageListContainer>
        <LibraryHeading
          disabledHeading={disabledHeading}
          heading={heading}
          mandatory={mandatory}
          headingContianerStyle={commonImageRenderHeading}
        />

        <CommonImageListWrapper>
          <ImageViewWrapper
            multiSelect={multiSelect}
            selectedMultiSubProperties={selectedMultiSubProperties}
            imageList={imageList}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            isAudio={isAudio}
            isVideo={isVideo}
            applyAudio={applyAudio}
            removeIcon={removeIcon}
            handleRemove={handleRemove}
            setOpenPreView={setOpenPreView}
            selectedSubProperty={selectedSubProperty}
            selectedProperty={selectedProperty}
            defaultSelection={defaultSelection}
            imageViewContainerCustomStyle={imageViewContainerCustomStyle}
          />
        </CommonImageListWrapper>

        {viewMore ? (
          <ViewMoreButton onClick={handleViewMore}>View More</ViewMoreButton>
        ) : (
          <></>
        )}
      </ImageListContainer>

      {/* view more modal */}
      <CommonFormModal
        open={viewMoreOpen}
        modalBodyContainerStyle={{
          minHeight: 700,
          height: getHeightRatio(700),
          width: getWidthRatio(1310),
        }}
        modalChildrenContainerStyle={{
          minHeight: 650,
          height: getHeightRatio(650),
          width: getWidthRatio(1310),
        }}
        iconContainerHeight={{
          height: getHeightRatio(650),
          minHeight: 650,
        }}
        hanldeClose={() => setViewMoreOpen(false)}
        backIcon={false}
        cancelIcon={true}
        selectedSubProperty={selectedSubProperty}
      >
        <ViewMore
          heading={heading}
          selectedSubProperty={selectedSubProperty}
          multiSelect={multiSelect}
          selectedMultiSubProperties={selectedMultiSubProperties}
          imageList={imageList}
          handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          isAudio={isAudio}
          isVideo={isVideo}
          applyAudio={applyAudio}
          removeIcon={removeIcon}
          handleRemove={handleRemove}
          setOpenPreView={setOpenPreView}
          tenantUploads={tenantUploads}
        />
      </CommonFormModal>
    </>
  );
}
