import { Divider } from "@mui/material";
import React from "react";
import {
  FooterContainer,
} from "../components/styled/DashBoardStyle";
import { FooterLogo, SocialIcon, SocialIconContainer } from "../../../../styles/landingPage";

import linkedIn from "../../../../assets/icons/linkedIn.svg";
import youTube from "../../../../assets/icons/youTube.svg";
import twitter from "../../../../assets/icons/twitter.svg";
import mail from "../../../../assets/icons/mail.svg";
import landingPageLogo from "../../../../assets/icons/landingPageLogo.png";

export const Footer = (props) => {
  return (
    <FooterContainer style={{
      background: 'transparent',
      width: '75%',
      margin: 'auto',
      marginTop: '5rem',
    }}>
    <Divider style={{
      width: "100%",
      margin: 0,
      marginTop: "1rem",
      marginBottom: "1rem",
      borderColor: "white",
    }} variant="middle" />
    <div style={{
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    }}>
      <FooterLogo src={landingPageLogo} alt="logo"/>
      <SocialIconContainer>
        <a style={{
          marginRight: '10%',
        }}>
          <SocialIcon src={linkedIn} />
        </a>
        <a href="https://youtube.com/@xtendr6258" style={{
          marginRight: '10%',
        }}>
          <SocialIcon src={youTube} />
        </a>
        <a style={{
          marginRight: '10%',
        }}>
         <SocialIcon src={twitter} />
        </a>
        <a href="mailto:info@xtendr.co">
          <SocialIcon src={mail} />
        </a>
      </SocialIconContainer>
    </div>
  </FooterContainer>
  );
};
