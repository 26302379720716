import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { fontFamilyList, fontSizeList } from "../../constants/constant";
import { italicIcon, underlineIcon } from "../../constants/icons";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../constants/renderUiMatchActionClass";
import {
  FontBoldContainer,
  fontPickerHeadLabelStyle,
  fontPickerMenuContainerStyle,
  FontPickerWrapper,
  ItalicIcon,
  UnderlineIcon,
} from "../../styles/sharedComponents";
import CustomMenu from "./CustomMenu";

export default function FontPicker(props) {
  const {
    handleChangeSubPropertyDatas = () => {},
    selectedProperty,
    selectedSubProperty,
    selectedPropertyLevel,
    selectedScreen,
    isFontFamilyOnlyRender,
  } = props;

  const [bold, setBold] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [italic, setItalic] = useState(false);

  const commonChangeHandleMeetAndGreetButton = (getData) => {
    let sendData = {};

    if (
      // selectedProperty?.actionClass === "text" ||
      selectedProperty?.actionClass ===
        PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC ||
      selectedProperty?.actionClass === ACTION_CLASSES.INSTRUCTIONS ||
      selectedProperty?.key === "popUps" ||
      selectedSubProperty?.actionClass === ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
    ) {
      sendData = { ...getData };
      handleChangeSubPropertyDatas({ ...sendData });
    } else {
      const buttonElementsData = selectedProperty?.elements?.map((item) => {
        if (selectedSubProperty?.key === item?.key) {
          return {
            ...item,
            value: { ...item?.value, ...getData },
          };
        } else return item;
      });

      sendData = {
        ...selectedProperty,
        elements: buttonElementsData,
        value: {
          ...selectedProperty?.value,
          buttons: selectedProperty?.value?.buttons?.map((item) => {
            return { ...item, ...getData };
          }),
        },
      };
      handleChangeSubPropertyDatas({ ...sendData, nested: true });
    }
  };

  const handleFontFamilyChange = (font) => {
    commonChangeHandleMeetAndGreetButton({ font: font?.label });
  };
  const handleFontSizeChange = (font) => {
    commonChangeHandleMeetAndGreetButton({ fontSize: font?.label });
  };

  useEffect(() => {
    setBold(selectedSubProperty?.value?.bold);
    setUnderline(selectedSubProperty?.value?.underline);
    setItalic(selectedSubProperty?.value?.italic);
  }, [
    selectedScreen?.key,
    selectedProperty?.key,
    selectedSubProperty?.key,
    selectedPropertyLevel?.key,
  ]);

  return (
    <>
      <Box sx={{ marginBottom: isFontFamilyOnlyRender ? "20px" : "0px" }}>
        <CustomMenu
          options={fontFamilyList}
          defalutValue={selectedSubProperty?.value?.font || "OpenSans-Bold"}
          headLabelStyle={fontPickerHeadLabelStyle}
          menuContainerStyle={fontPickerMenuContainerStyle}
          handleChange={handleFontFamilyChange}
        />
      </Box>

      {isFontFamilyOnlyRender ? (
        <></>
      ) : (
        <FontPickerWrapper>
          <FontBoldContainer
            isSelected={bold}
            onClick={() => {
              setBold(!bold);
              commonChangeHandleMeetAndGreetButton({ bold: !bold });
            }}
          >
            B
          </FontBoldContainer>

          <UnderlineIcon
            isSelected={underline}
            onClick={() => {
              setUnderline(!underline);
              commonChangeHandleMeetAndGreetButton({ underline: !underline });
            }}
          >
            {underlineIcon}
          </UnderlineIcon>

          <ItalicIcon
            isSelected={italic}
            onClick={() => {
              setItalic(!italic);
              commonChangeHandleMeetAndGreetButton({ italic: !italic });
            }}
          >
            {italicIcon}
          </ItalicIcon>

          <CustomMenu
            options={fontSizeList}
            defalutValue={selectedSubProperty?.value?.fontSize || "16"}
            headLabelStyle={fontPickerHeadLabelStyle}
            menuContainerStyle={fontPickerMenuContainerStyle}
            containerStyle={{ width: "50%" }}
            handleChange={handleFontSizeChange}
          />
        </FontPickerWrapper>
      )}
    </>
  );
}
