import styled from "@emotion/styled";
import {
  Accordion,
  Button,
  Checkbox,
  FormControlLabel,
  InputBase,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { centerStyle, textOverflowEllipsStyle } from "./commonStyles";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";

// signIn styles
export const LoginContainer = styled(Box)(({ style }) => {
  return {
    height: "100%",
    width: "100%",
    ...style,
    ...centerStyle,
  };
});
export const LoginHeading = styled("p")(({ style }) => {
  return {
    width: "100%",
    fontFamily: "OpenSans-Medium",
    fontSize: "clamp(4.5rem, 1.5vh, 2rem)",
    fontWeight: 400,
    letterSpacing: "-0.01em",
    textAlign: "left",
    color: "#EFFFF4",
    marginBottom: "0",
    ...centerStyle,
    ...style,
  };
});
export const welcomeBackStyle = {
  marginBottom: "0px",
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(2.1rem, 1.5vh, 2rem)",
  letterSpacing: "-0.01em",
  textTransform: "uppercase",
  backgroundClip: "text",
  textFillColor: "transparent",
  textShadow: "0px 3.52201px 12.327px #000000",
  backgroundImage:
    "linear-gradient(179.51deg, #FFFFFF 0.43%, rgba(255, 255, 255, 0.31) 141.12%)",
  "-webkit-background-clip": "text",
  "-moz-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  "-moz-text-fill-color": "transparent",
  justifyContent: "end",
};
export const LoginStyledInput = styled(InputBase)(({ style }) => {
  return {
    height: getHeightRatio(56),
    width: getWidthRatio(388),
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1.8rem, 1.5vh, 2rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#000000",
    textAlign: "start",
    margin: "20px 5px 15px 20px",
    background: "#FFFFFF",
    padding: "0px 40px",
    borderRadius: "30px 10px",
    position: "relative",
    ...style,
  };
});
export const visibilityIconStyle = {
  width: getWidthRatio(22),
  height: getHeightRatio(22),
  position: "absolute",
  right: "7%",
  bottom: "35%",
};
export const InputInfoText = styled("p")(({ style }) => {
  return {
    fontFamily: "OpenSans-Bold",
    fontSize: "clamp(2rem, 1.5vh, 2rem)",
    fontWeight: 400,
    letterSpacing: "-0.01em",
    color: "#949494",
    position: "relative",
    left: "-12%",
    cursor: "pointer",
  };
});
export const LoginButton = styled(Button)(({ style }) => {
  return {
    width: getWidthRatio(170),
    height: getHeightRatio(61),
    fontFamily: "OpenSans-Bold",
    fontSize: "clamp(1.8rem, 1.5vh, 2rem)",
    fontWeight: 400,
    letterSpacing: "-0.01em",
    background: " linear-gradient(92.55deg, #4DC5D2 0%, #C9E321 96.37%)",
    boxShadow: "0px 3.30038px 55.2814px 22.2776px rgba(0, 0, 0, 0.25)",
    borderRadius: "9.23801px",
  };
});

//checkbox style
export const CustomCheckBoxFormControlLabel = styled(FormControlLabel)(
  ({ style }) => {
    return {
      "& .MuiFormControlLabel-label": {
        fontFamily: "OpenSans-Medium",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
        letterSpacing: "-0.011em",
        color: "#FFFFFF",
        marginLeft: "8px",
        fontSize: "clamp(1rem, 1.5vmin, 4rem)",
      },
      ...style,
    };
  }
);
export const CustomCheckbox = styled(Checkbox)(({ style }) => {
  return {
    color: "#FFFFFF",
    borderRadius: "2px",
    svg: {
      height: getHeightRatio(20),
      width: getWidthRatio(20),
      marginLeft: "-8px",
    },
    "&.Mui-checked": {
      color: "#49C4D8",
    },
    ...style,
  };
});

//uploader style
export const InputField = styled("input")(({ style }) => ({
  display: "none",
  ...style,
}));
export const CustomUploadFileInputHolder = styled(Box)(({ style }) => ({
  cursor: "pointer",
  aspectRatio: "1/1",
  borderRadius: "10%",
  background: "#1B1B1B",
  border: "1px dashed #AAAAAA",
  color: "rgba(255, 255, 255, 0.68)",
  ...centerStyle,
  ...style,
}));

//image uploader component style
export const CustomImageUploadContainer = styled(Box)(({ style }) => ({
  height: "100%",
  width: "45%",
  // height: getHeightRatio(128),
  // width: getWidthRatio(128),
  aspectRatio: "1/1",
  ...style,
}));
export const FileNameContainer = styled(Box)(({ style }) => ({
  height: "70%",
  width: "80%",
  ...centerStyle,
  flexDirection: "column",
  ...style,
}));
export const FileNameSubmitButton = styled(Box)(({ style }) => ({
  height: getHeightRatio(49.6),
  width: getWidthRatio(120.31),
  marginTop: getHeightRatio(35),
  fontFamily: "OpenSans-Bold",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1.8rem, 1.5vh, 2rem)",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  background: "linear-gradient(97.75deg, #7C00FF -3.43%, #4DC5D2 99.51%)",
  borderRadius: "8.97555px",
  cursor: "pointer",
  ...centerStyle,
  ...style,
}));
export const FileNameGetTextBox = styled(InputBase)(({ style }) => ({
  height: getHeightRatio(48),
  width: getWidthRatio(500),
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1.6rem, 1.5vh, 2rem)",
  letterSpacing: "-0.01em",
  color: "#565656",
  background: "#DADADA",
  padding: "0px 40px",
  borderRadius: "23px 10px",
  ...style,
}));
export const TemplateNameViewContainer = styled(Box)(({ style }) => ({
  marginBottom: getHeightRatio(38),
  ...centerStyle,
  ...style,
}));
export const TemplateImage = styled("img")(({ style }) => ({
  ...centerStyle,
  ...style,
}));
export const TemplateName = styled(Box)(({ style }) => ({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(2.4rem, 1.5vh, 2rem)",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  textShadow: "0px 0px 40.4501px rgba(21, 37, 52, 0.5)",
  marginLeft: getWidthRatio(25),
  ...centerStyle,
  ...style,
}));
export const ImageUploaderAndSelectBox = styled(Box)(({ style }) => ({
  ...centerStyle,
  justifyContent: "space-between",
  width: "100%",
  margin: "10px 0px",
  ...style,
}));
export const selectedBoxStyle = (isAudio, applyAudio, getSpecs) => {
  return {
    border: isAudio
      ? applyAudio
        ? "1px solid #49C4D8"
        : "0px"
      : "1px solid #49C4D8",
    cursor: "default",
    background: "#1B1B1B",
  };
};
export const SelectedLabel = styled(Box)(({ style }) => ({
  color: "#FFFFFF",
  fontSize: "clamp(1rem, 1vmin, 4rem)",
  ...style,
}));
export const applyAudiocontainerStyle = {
  marginTop: "20px",
  marginLeft: "15px",
};

//image view style
export const ImageViewContainer = styled(Box)(
  ({ isSelected, getSpecs, style }) => ({
    position: "relative",
    width: "45%",
    cursor: "pointer",
    boxSizing: "border-box",
    borderRadius: "10%",
    margin: `${getHeightRatio(10)}px 0px`,
    aspectRatio: "1/1",
    border: isSelected ? "1px solid #49C4D8" : "0",
    background: isSelected ? "#1B1B1B" : "#4C4C4C",
    ...centerStyle,
    ...style,
    flexDirection: "column",
  })
);
export const animationImageContainerStyle = {
  border: "1px solid #49C4D8",
  cursor: "default",
  background: "#1B1B1B",
  margin: "30px 9px 10px 0px",
  fill: "#fff",
};
export const ImageRemoveIcon = styled(Box)(({ style }) => ({
  position: "absolute",
  cursor: "pointer",
  boxSizing: "border-box",
  borderRadius: "10px",
  aspectRatio: "1/1",
  padding: "6px",
  border: "0.6px solid #B3B3B3",
  background: "#1D1D1D",
  width: "18%",
  height: "18%",
  left: "87%",
  top: "-6%",
  ...centerStyle,
  ...style,
}));
export const imageViewContainerStyle = {
  maxWidth: "45%",
  minWidth: "45%",
};
export const ImageView = styled("img")(({ style }) => {
  return {
    height: "90%",
    width: "90%",
    objectFit: "contain",
    borderRadius: "5px",
    fontSize: "clamp(2rem, 1.5vmin, 4rem)",
    aspectRatio: "1/1",
    ...style,
    svg: {
      fill: "#49C4D8",
    },
  };
});
export const SvgPreviewContainer = styled(Box)(
  ({ isTextAlignStart, style }) => {
    return {
      ...centerStyle,
      height: "100%",
      width: "100%",
      flexDirection: "column",
      position: "relative",
      alignItems: isTextAlignStart ? "start" : "center",
      ...style,
    };
  }
);
export const LeaderWithPlayerHeadingContainer = styled(Box)(({ style }) => {
  return {
    position: "absolute",
    wordWrap: "break-word",
    overflow: "hidden",
    ...style,
  };
});
export const LeaderBoardPlayerButton = styled(Box)(({ style }) => {
  return {
    top: "78.28169014084507%",
    position: "absolute",
    zIndex: 10,
    width: "100%",
    ...centerStyle,
    ...style,
  };
});
export const LeaderWithPlayerLabel = styled(Box)(({ style }) => {
  return {
    fontFamily: "Montserrat",
    margin: "2px 0px 5px 0px",
    fontWeight: "500",
    fontSize: "clamp(2rem, 0.7vh, 4rem)",
    lineHeight: "133.4%",
    letterSpacing: "0.10em",
    color: "#000000",
    ...style,
  };
});
export const LeaderWithPlayerHeading = styled(Box)(({ style }) => {
  return {
    margin: "5px 0px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "clamp(2rem, 2.1vh, 4rem)",
    lineHeight: "35px",
    textAlign: "center",
    color: "#000000",
    ...style,
  };
});
export const SvgPreviewer = styled(Box)(
  ({ style, isLibraryUsage, isNotImage }) => {
    return {
      width: isLibraryUsage ? "45%" : "100%",
      ...(isLibraryUsage ? { minHeight: "45%" } : { height: "100%" }),
      cursor: "pointer",
      boxSizing: "border-box",
      borderRadius: "10%",
      ...(isLibraryUsage ? { margin: "10px auto 35px 0px" } : {}),
      aspectRatio: "1/1",
      padding: "5px",
      position: "relative",
      border: isLibraryUsage ? "1px solid #49C4D8" : "0",
      ...(isLibraryUsage ? { background: "#1B1B1B" } : {}),
      ...(isNotImage ? { color: "#FFFFFF" } : {}),
      svg: {
        height: "100%",
        width: "100%",
      },
      ...centerStyle,
      ...style,
    };
  }
);
export const SvgInsideText = styled(Box)(({ textStyles, style }) => {
  return {
    position: "absolute",
    wordWrap: "break-word",
    width: textStyles?.width || "auto",
    height: textStyles?.height || "auto",
    color: textStyles?.fontColor || textStyles?.color || "black",
    fontFamily: textStyles?.font || "OpenSans-Regular",
    fontSize: `${textStyles?.fontSize || 20}px`,
    fontStyle: textStyles?.italic ? "italic" : "normal",
    fontWeight: textStyles?.bold ? "bold" : "normal",
    textDecoration: textStyles?.underline ? "underline" : "none",
    overflow: "hidden auto",
    ...centerStyle,
    display: "block",
    ...style,
  };
});

//liberry heading style
export const containerStyleHandle = (
  headingContianerStyle,
  disabledHeading,
  mandatory
) => {
  return {
    ...centerStyle,
    ...headingContianerStyle,
    justifyContent: disabledHeading
      ? mandatory
        ? "end"
        : "start"
      : "space-between",
  };
};
export const iconWrapperStyle = {
  height: getHeightRatio(22),
  width: getWidthRatio(22),
  cursor: "pointer",
};

//custom menu style
export const CustomMenuContainer = styled(Box)(({ style }) => {
  return {
    height: "15%",
    ...centerStyle,
    justifyContent: "start",
    width: "100%",
    ...style,
  };
});
export const CustomMenuLabelContainer = styled(Box)(
  ({ disableClick, style }) => {
    return {
      cursor: "pointer",
      display: "flex",
      width: "100%",
      pointerEvents: disableClick ? "none" : "auto",
      ...style,
    };
  }
);
export const CustomMenuOptionContainer = styled(Menu)(({ style }) => {
  return {
    "& .MuiPaper-root": {
      backgroundColor: "#202020",
      width: "100px",
      ...style,
    },
  };
});
export const CustomMenuItem = styled(MenuItem)(
  ({ style, checkSelectedType }) => {
    return {
      background: checkSelectedType
        ? "#383838 !important"
        : "#202020 !important",
      "&:hover": { background: "#383838 !important" },
      "@media screen and (min-width: 2200px)": {
        fontSize: "clamp(2rem, 1.5vmin, 4rem)",
      },
      ...style,
    };
  }
);
export const MenuLabel = styled(Typography)(({ style }) => {
  return {
    width: "100%",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    textAlign: "center",
    fontSize: " clamp(1.2rem, 1vmin, 4rem)",
    ...style,
  };
});
export const MenuViewLabel = styled(Box)(({ style }) => {
  return {
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    textTransform: "capitalize",
    minWidth: "50%",
    maxWidth: "90%",
    ...style,
  };
});
export const MenuDropDownIcon = styled("img")(({ changeIcon }) => {
  return {
    width: getWidthRatio(12),
    objectFit: "contain",
    transform: changeIcon ? "rotate(-180deg)" : "rotate(0deg)",
  };
});

//common image render
export const ImageListContainer = styled(Box)({
  padding: "2% 0px 0px",
});
export const commonImageRenderHeading = {
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(0.8rem, 1.5vh, 2rem)",
  letterSpacing: "-0.011em",
  textTransform: "capitalize",
};
export const CommonImageListWrapper = styled(Box)(({ style }) => {
  return {
    ...centerStyle,
    overflow: "hidden",
    width: "100%",
    justifyContent: "space-between",
    ...style,
    flexWrap: "wrap",
    // paddingBottom: '1rem',
  };
});

//audio view componet style
export const AudioViewContainer = styled(Box)(({ style }) => {
  return {
    ...centerStyle,
    flexDirection: "column",
    height: "100%",
    padding: "5px",
    ...style,
  };
});
export const AudioViewLabel = styled(Box)(({ style }) => {
  return {
    width: "90%",
    fontFamily: "OpenSans-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "clamp(0.6rem, 1.3vh, 2rem)",
    color: "#FFFFFF",
    letterSpacing: "-0.011em",
    marginTop: "15px",
    ...textOverflowEllipsStyle,
    ...style,
  };
});
export const PlayIconWrapper = styled(Box)(({ style }) => {
  const x1 = window.innerHeight / 1080;
  const exactHeight = 39 * x1;
  const exactWidth = 39 * x1;

  return {
    width: exactHeight || "100px",
    height: exactWidth || "100px",
    ...style,
  };
});

//image view lister style
export const ImageViewListerContainer = styled(Box)(({ style }) => {
  return {
    margin: "20px 2px",
    ...style,
  };
});
export const ImageViewListerWrap = styled(Box)(({ style }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
    ...style,
  };
});
export const ImageViewListerLabelWrap = styled(Box)(({ style }) => {
  return {
    maxWidth: "45%",
    minWidth: "45%",
    aspectRatio: "1/1",
    cursor: "pointer",
    borderRadius: "10%",
    margin: "10px 0px 10px 0px",
    ...style,
  };
});

//fontPicker component style
export const fontPickerHeadLabelStyle = {
  minWidth: "fit-content",
  maxWidth: "fit-content",
};
export const fontPickerMenuContainerStyle = {
  ...centerStyle,
  justifyContent: "space-between",
  height: `${getHeightRatio(30)}px`,
  background: "#1B1B1B",
  borderRadius: "10px",
  padding: "2px 15px",
  margin: "0.5rem 0px",
};
export const FontPickerWrapper = styled(Box)(({ style }) => {
  return {
    ...centerStyle,
    justifyContent: "space-between",
    width: "100%",
    margin: "10px 0px",
    ...style,
  };
});
export const FontBoldContainer = styled(Box)(({ isSelected, style }) => {
  return {
    width: "20%",
    height: getHeightRatio(34),
    background: "#1B1B1B",
    borderRadius: "10px",
    fontFamily: "OpenSans-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    cursor: "pointer",
    color: isSelected ? "#49C4D8" : "#FFFFFF",
    fontSize: "clamp(1.4rem, 1.5vh, 2rem)",
    ...centerStyle,
  };
});
export const UnderlineIcon = styled(Box)(({ isSelected, style }) => {
  return {
    ...centerStyle,
    width: getWidthRatio(12),
    height: getHeightRatio(24),
    cursor: "pointer",
    svg: {
      path: { fill: isSelected ? "#49C4D8" : "#AAAAAA" },
    },
    ...style,
  };
});
export const ItalicIcon = styled(Box)(({ isSelected, style }) => {
  return {
    ...centerStyle,
    width: getWidthRatio(12),
    height: getHeightRatio(24),
    cursor: "pointer",
    svg: {
      path: { fill: isSelected ? "#49C4D8" : "#AAAAAA" },
      line: { stroke: isSelected ? "#49C4D8" : "#AAAAAA" },
    },
    ...style,
  };
});

//common modal component style
export const ModalContainer = styled(Box)(({ style }) => {
  return {
    width: "50%",
    minheight: "308px",
    background: "#FFFFFF",
    boxShadow: "0px 8px 16px #0000008A",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:focus": { outline: "none" },
    ...style,
  };
});
export const TitleContainer = styled(Box)(({ theme }) => ({
  background: "#1B1B1B",
  padding: "10px 20px",
  boxSizing: "border-box",
  minHeight: "56px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#FFFFFF",
}));
export const Title = styled(Typography)({
  textAlign: "left",
  font: "normal normal 400 20px/27px Rubik",
});
export const ContentContainer = styled(Box)(({ style }) => {
  return {
    padding: "30px 70px",
    background: 'linear-gradient(180deg, #2F2F2F 0%, rgba(40, 40, 40, 0.90) 104.39%)',
    ...style,
  };
});
export const ButtonContainer = styled(Box)({
  padding: "10px 50px",
  boxShadow: "-2px 0px 6px #00000029",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});
export const modalSubmitButtonStyle = {
  backgroundColor: "#1B1B1B",
  "&:hover": {
    backgroundColor: "#1B1B1B",
  },
};

// preview modal styles
export const PreviewModalTitleContainer = styled(Box)(({ theme }) => ({
  background: "#343434",
  boxSizing: "border-box",
  color: "#FFFFFF",
  width: "90%",
  height: "11%",
  ...centerStyle,
  justifyContent: "space-between",
  margin: "0px auto",
}));
export const PreviewModalTitle = styled(Typography)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "clamp(1.6rem, 1.5vmin, 4rem)",
  lineHeight: "128%",
  textAlign: "center",
  letterSpacing: "-0.011em",
  color: "#FFFFFF",
});
export const PreviewModalContainer = styled(Box)(({ style }) => {
  return {
    width: getWidthRatio(1012),
    height: getHeightRatio(700),
    backgroundColor: "#343434",
    boxShadow: "0px 8px 16px #0000008A",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:focus": { outline: "none" },
    ...style,
  };
});

export const PreviewContentContainer = styled(Box)(({ style }) => {
  return {
    position: "relative",
    width: "90%",
    height: "84%",
    background: "#141414",
    borderRadius: "2px",
    overflow: "hidden auto",
    margin: "0px auto",
    ...centerStyle,
    ...style,
  };
});
// button text box styles
export const ButtonTextBox = styled(InputBase)(({ style }) => {
  return {
    width: "80%",
    marginTop: "5px",
    padding: "0px 2px",
    overflow: "hidden",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    ...style,
  };
});

//accordion
export const AccordionContainer = styled(Accordion)({
  backgroundColor: "#D9D9D9",
  margin: "0px !important",
});
export const SingleList = styled(Box)({
  backgroundColor: "#D9D9D9",
  height: "44px",
  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  borderTop: "1px solid #BFBFBF",
  paddingLeft: "17px",
  cursor: "pointer",
});
export const AccordionTitleContainer = styled(Box)({
  width: "100%",
  display: "flex",
});
export const AccordionLabel = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  margin: "0px 20px",
  fontSize: "clamp(1.5rem, 1.5vmin, 4rem)",
});
export const AccordionDetailsContainer = styled(Box)({
  textAlign: "center",
  display: "flex",
  justifyContent: "space-between",
  padding: " 7px",
  color: "#fff",
  margin: "5px 0px",
  cursor: "pointer",
});
export const accordionDetailStyle = {
  backgroundColor: "#5C5C5C",
  padding: "8px 16px",
};
export const AccordionDetailsLabel = styled(Box)({
  fontSize: "clamp(1.5rem, 1.5vmin, 4rem)",
  display: "flex",
  alignItems: "center",
  paddingLeft: "30px",
});

//library
export const HeadingText = styled(Box)(({ style }) => {
  return {
    textAlign: "left",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    textTransform: "capitalize",
    padding: "5px 0px 1px",
    margin: `${getHeightRatio(15)}px 0px`,
    ...style,
  };
});
export const ColorCircle = styled(Box)(({ isSelected, style }) => {
  return {
    height: "20.93px",
    width: "20.93px",
    borderRadius: "100%",
    marginRight: "10px",
    marginTop: "9px",
    cursor: "pointer",
    border: isSelected ? "1px solid #49C4D8" : "0px",
    ...style,
  };
});
export const ViewMoreButton = styled(Box)({
  fontFamily:"OpenSans-Regular",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "clamp(0.2rem, 1vh, 2rem)",
  lineHeight: "150%",
  letterSpacing: "-0.011em",
  color: "#49C4D8",
  ...centerStyle,
  justifyContent: "end",
  paddingRight: "7px",
  cursor: "pointer",
  overflow: "hidden",
  marginLeft: "auto",
});

//font family
export const FontFamilyContainer = styled(Select)({
  width: "100%",
  height: "35px",
  fontSize: "clamp(1.4rem, 1.5vmin, 4rem)",
  backgroundColor: "#BCBCBC",
  marginTop: "10px",
});

//library container
export const LibraryStyleBox = styled(Box)({
  height: "100px",
  borderRadius: "8px",
  backgroundColor: "#BCBCBC",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  fontSize: "clamp(1.4rem, 1.5vmin, 4rem)",
  padding: "0px 3px",
  textAlign: "center",
  cursor: "pointer",
  overflow: "hidden",
});

//library camera style
export const CameraLabel = styled(Box)(({ isSelected }) => {
  return {
    fontFamily: "OpenSans-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1rem, 1.2vmin, 2rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: isSelected ? "#49C4D8" : "#FFFFFF",
    margin: `${getHeightRatio(20)}px 0px`,
  };
});

// video component styles
export const VideoPalyIconWarpper = styled(Box)({
  position: "absolute",
  width: "44px",
  height: "44px",
  background: "rgba(217, 217, 217, 0.23)",
  borderRadius: "100%",
  padding: "2px",
  ...centerStyle,
});

export const OverLabel = styled(Box)({
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15.0328px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  margin: "3px 0px",
});
