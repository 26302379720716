import React from "react";
import {
  TypeHeading,
  HighLighterButton,
  TypeButtonWrapper,
  TypeContainerWrapper,
} from "../../styles/shared/typeHighLighter";

export function TypeHighlighter(props) {
  const { selectedSubProperty, selectedType, handleTypeBasedSelect, limit } =
    props;

  const buttonSpecs = [...Array(limit || 2).keys()].map((i) =>
    JSON.stringify(i + 1)
  );

  return (
    <TypeContainerWrapper>
      <TypeHeading>{selectedSubProperty?.name}</TypeHeading>
      <TypeButtonWrapper>
        {buttonSpecs?.map((item, index) => {
          return (
            <HighLighterButton
              isSelected={selectedType[selectedSubProperty?.key]?.some(
                (f) => f.type === item
              )}
              onClick={(e) => handleTypeBasedSelect(e, item?.toString())}
              key={index}
            >
              {item}
            </HighLighterButton>
          );
        })}
      </TypeButtonWrapper>

      <div></div>
    </TypeContainerWrapper>
  );
}
