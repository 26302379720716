import { IconButton, Modal } from "@mui/material"
import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import {
  ButtonContainer,
  ContentContainer,
  ModalContainer,
  Title,
  TitleContainer,
} from "../../styles/sharedComponents"

export default function CommonModal(props) {
  const {
    open = false,
    children,
    buttonChild,
    title,
    closeHandler = () => {},
    modalContainerStyle = {},
    modalBodyConatinerStyle = {},
    disableHeader = false,
    disabledCancel = false,
  } = props
  console.log(buttonChild)
  return (
    <Modal open={open} onClose={disabledCancel ? () => {} : closeHandler}>
      <div>
        <ModalContainer style={modalContainerStyle}>
          {disableHeader ? (
            <></>
          ) : (
            <TitleContainer>
              <Title>{title}</Title>
              {disabledCancel ? (
                <></>
              ) : (
                <IconButton sx={{ color: "#fff" }} onClick={closeHandler}>
                  <CloseIcon />
                </IconButton>
              )}
            </TitleContainer>
          )}
          <ContentContainer style={modalBodyConatinerStyle}>
            {children}
          </ContentContainer>

          {buttonChild && (
            <ButtonContainer>{buttonChild()}</ButtonContainer>
          )}
        </ModalContainer>
      </div>
    </Modal>
  )
}
