import React, { useEffect } from "react";
import {
  AudioViewLabel,
  CustomCheckbox,
  ImageRemoveIcon,
  ImageView,
  ImageViewContainer,
  imageViewContainerStyle,
  VideoPalyIconWarpper,
} from "../../styles/sharedComponents";
import Drag from "./Drag";
import xtendrEmptyImage from "../../assets/icons/xtendrEmptyImage.jpg";
import AudioViewComponent from "./AudioViewComponent";
import { imageRemoveIcon, videoPalyIcon } from "../../constants/icons";
import VideoComponent from "../VideoComponent";
import { PREVIEW_ACTION_CLASSES } from "../../constants/renderUiMatchActionClass";
import { ModelImageRenderer } from "./3DImageRenderer";
import { SUB_PROPERTY_TYPES } from "../../constants/renderUiMatchTypes";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import Thrash from '../../assets/icons/Thrash.svg'

export default function ImageViewWrapper(props) {
  const {
    imageList = [],
    selectedProperty,
    handleChangeSubPropertyDatas = () => {},
    isAudio,
    isVideo,
    applyAudio,
    checkBoxStyle = {},
    multiSelect,
    selectedMultiSubProperties,
    removeIcon = false,
    handleRemove = () => {},
    setOpenPreView = () => {},
    imageViewContainerCustomStyle = {},
    isModal = false,
    selectedSubProperty = {},
    defaultSelection = false,
  } = props;

  const handleClick = (e, item) => {
    const handleChangeProps = () => {
      handleChangeSubPropertyDatas({
        ...item,
        url: item?.meta?.assetKey,
        style: item?.meta?.name,
        ...(item?.acceptType
          ? {
              animationType: item?.animationType,
            }
          : { previewUrl: item?.previewUrl }),
        ...(isAudio ? { applyAudio } : {}),
      });
    };

    if (isAudio) {
      handleChangeProps();
    } else if (e?.detail === 2) {
      setOpenPreView(true);
    } else {
      handleChangeProps();
    }
  };

  const getParentGLBSizes = {
    height: document?.getElementById("imageRenderLister")?.clientHeight,
    width: document?.getElementById("imageRenderLister")?.clientWidth,
  };

  const check3D = [
    PREVIEW_ACTION_CLASSES.TREASURE_PREVIEW,
    PREVIEW_ACTION_CLASSES.GLB_PREWVIEW,
  ];

  useEffect(() => {
    if (imageList?.length && defaultSelection) {
      const notDefaultSelect = [
        SUB_PROPERTY_TYPES.AVOIDABLE,
        SUB_PROPERTY_TYPES.COLLECTIABLE,
      ];
      if (!notDefaultSelect?.includes(selectedSubProperty?.value?.type))
        if (
          !selectedSubProperty?.value?.url ||
          check3D?.includes(selectedProperty?.actionClass)
            ? selectedSubProperty?.value?.assetUrl
              ? false
              : true
            : false
        ) {
          const image = imageList?.[0];
          handleClick({}, image);
        }
    }
  }, [
    selectedProperty?.key,
    selectedSubProperty?.value?.type,
    imageList?.length,
  ]);

  return (
    <>
      {imageList?.map((item, index) => {
        return (
          <ImageViewContainer
            id="imageRenderLister"
            key={index}
            onClick={(e) => handleClick(e, item)}
            style={{
              ...imageViewContainerStyle,
              ...imageViewContainerCustomStyle,
              border: isModal
                ? selectedSubProperty?.value?.meta?.assetKey ===
                  item?.meta?.assetKey
                  ? "1px solid #49C4D8"
                  : "0"
                : "0",
              ...(removeIcon
                ? {
                    margin: `${getHeightRatio(10)}px ${getWidthRatio(
                      10
                    )}px ${getHeightRatio(10)}px 0px`,
                  }
                : {}),
            }}
          >
            {multiSelect ? (
              <CustomCheckbox
                onChange={() =>
                  handleChangeSubPropertyDatas({
                    ...item,
                    url: item?.meta?.assetKey,
                    style: item?.meta?.name,
                    ...(item?.acceptType
                      ? {
                          animationType: item?.animationType,
                        }
                      : { previewUrl: item?.previewUrl }),
                    ...(isAudio ? { applyAudio } : {}),
                  })
                }
                style={{ position: "absolute", top: 0, right: 0 }}
                checked={Boolean(
                  selectedMultiSubProperties?.some(
                    (e) => e.meta?.assetKey === item.meta?.assetKey
                  )
                )}
              />
            ) : (
              ""
            )}

            <Drag
              dragData={{
                ...item,
                url: item?.meta?.assetKey,
                style: item?.meta?.name,
                ...(item?.acceptType
                  ? {
                      animationType: item?.animationType,
                    }
                  : { previewUrl: item?.previewUrl }),
                ...(isAudio ? { applyAudio } : {}),
              }}
            >
              {isVideo ? (
                <VideoComponent videoPath={item?.previewUrl || ""} />
              ) : isAudio ? (
                <AudioViewComponent audioDatas={item} />
              ) : (
                <>
                  {item?.meta?.fileType === "3D" ||
                  item?.meta?.fileType === "model" ? (
                    <div>
                      <ModelImageRenderer
                        element={item}
                        configStyle={false}
                        getParentSizes={getParentGLBSizes}
                        cameraControls={false}
                      />
                      <p style={{margin: 0, color: 'white',}}>{item?.name}</p>
                      {item.tenantId &&(
                        <img src={Thrash} style={{position: 'absolute', bottom:'3%', right:'3%', width:'15%'}} alt="deleteIcon" onClick={() => handleRemove(item)}/>
                      )}
                    </div>
                  ) : (
                    <>
                    <ImageView
                      src={item?.previewUrl || xtendrEmptyImage}
                      alt="image"
                    />
                    {item.tenantId &&(
                      <img src={Thrash} style={{position: 'absolute', bottom:'3%', right:'3%', width:'15%'}} alt="deleteIcon" onClick={() => handleRemove(item)}/>
                    )}
                    </>
                  )}
                </>
              )}
            </Drag>

            {isVideo ? (
              <VideoPalyIconWarpper>{videoPalyIcon}</VideoPalyIconWarpper>
            ) : (
              <></>
            )}

            {removeIcon ? (
              <ImageRemoveIcon onClick={() => handleRemove(item)}>
                {imageRemoveIcon}
              </ImageRemoveIcon>
            ) : (
              <></>
            )}

            {item?.animationType ? (
              <AudioViewLabel style={{ width: "90%" }}>
                {item?.animationType}
              </AudioViewLabel>
            ) : (
              <></>
            )}
          </ImageViewContainer>
        );
      })}
    </>
  );
}
