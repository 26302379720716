import React, { useEffect, useState } from "react";
import LibraryHeading from "../LibraryHeading";
import { Box } from "@mui/system";
import chroma from "chroma-js";
import { isValidHexCode } from "../../../utils/checkHexCode";
import gradiantColorIcon from "../../../assets/icons/gradiantColorIcon.png";
import CustomColorPicker from "./CustomColorPicker";
import {
  colorDegreeTextBoxStyle,
  ColorPickerTextBoxContainer,
  ColorPickerWrapper,
  ColorTextBox,
  ColorTextBoxContainer,
  ColorTextOpacityBoxContainer,
  ColorView,
  ColorViewContainer,
} from "../../../styles/colorPalette";
import CustomAlphaPickerPanel from "./CustomAlphaPickerPanel";
import CustomHuePickerPanel from "./CustomHuePickerPanel";
import CustomGradientRangeBar from "./CustomGradientRangeBar";
import CustomMenu from "../CustomMenu";
import { getApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import ColorList from "./ColorList";
import { addIcon } from "../../../constants/icons";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../../constants/renderUiMatchActionClass";

const gradientTypes = [{ label: "linear" }, { label: "radial" }];

export default function CustomGradientColorPicker({
  handleChangeSubPropertyDatas = () => {},
  selectedProperty = {},
  selectedSubProperty = {},
  selectedScreen = {},
  fontProperty = false,
}) {
  const [color, setColor] = useState("rgba(134, 100, 100, 1)");
  const [hueColor, setHueColor] = useState("#fff");
  const [opacityColor, setOpacityColor] = useState({
    r: 134,
    b: 100,
    g: 100,
    a: 1,
  });
  const [textColorValue, setTextColorValue] = useState({
    hexCode: "#ffffff",
    opacity: 100,
  });
  const [degree, setDegree] = useState(180);
  const [grandientType, setGrandientType] = useState("linear");
  const [activeThumbIndex, setActiveThumbIndex] = useState(0);
  const [steps, setSteps] = useState([
    { color: "#15A5CF", offset: 0 },
    { color: "#4007B5", offset: 100 },
  ]);
  const [recentColors, setRecentColors] = useState([]);

  const colorPickerHandleChange = (color) => {
    setColor(color);
    setOpacityColor(color);
    setTextColorValue({
      hexCode: chroma(color)?.hex(),
      opacity: 100,
    });
    steps[activeThumbIndex] = {
      ...steps?.[activeThumbIndex],
      color: textColorValue?.hexCode,
    };
    setSteps([...steps]);
  };

  const opacityHandleChange = (color) => {
    setOpacityColor(color?.rgb);
    setTextColorValue({
      ...textColorValue,
      opacity: Math.round(color?.rgb?.a * 100 || 0),
    });
  };

  //grandiant handle
  const grandientHandleChange = (value, index) => {
    setActiveThumbIndex(index);
    steps[index] = {
      color: textColorValue?.hexCode,
      offset: value?.[index]?.offset,
    };
    setSteps([...steps]);
  };

  const hueHandleChange = (color) => {
    setHueColor(color?.hex);
    setColor(color?.hex);
    setOpacityColor(color?.hex);
    setTextColorValue({
      hexCode: color?.hex,
      opacity: 100,
    });
  };

  const handleRecentColorChange = (color) => {};

  //get degree value
  const handleGetDegreeValue = (gradientString) => {
    // use a regular expression to extract the degree value
    const degreeRegex = /-?\d+deg/;
    const degreeMatch = gradientString.match(degreeRegex);

    if (degreeMatch) {
      // convert the degree value to a number
      const degreeString = degreeMatch?.[0]?.replace("deg", "")?.trim();
      return parseFloat(degreeString);
    }
  };

  //recent color data handle
  const getRecentGradiantColors = async () => {
    try {
      const sendApiPath = `${apiRoutes.recentColor}?class=Color`;
      const { data } = await getApiServices(sendApiPath);

      const getColors = data
        ?.filter((item) => {
          let checkColor = Object.keys(item?.config)?.some(
            (item) => item === "color"
          );
          if (!checkColor) return item;
        })
        ?.slice(0, 5);
      setRecentColors(getColors || []);
    } catch (err) {}
  };

  useEffect(() => {
    getRecentGradiantColors();
  }, [selectedProperty?.key, selectedSubProperty?.key, selectedScreen?.key]);

  useEffect(() => {
    let checkFont =
      selectedProperty?.actionClass === PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
        ? "color"
        : "gradient";
    let getColor = selectedSubProperty?.value?.[checkFont];

    const gradientString =
      getColor ||
      "radial-gradient(circle, #ff0000 0%, #00ff00 50%, #0000ff 100%)"; // Gradient string
    const colors = gradientString
      ?.replace(/(linear|radial)-gradient|to\s?\w+\s?|\(|\)/g, "") // Remove unnecessary parts of the string
      ?.split(","); // Split the string into an array of colors

    setGrandientType(gradientString?.includes("linear") ? "linear" : "radial");

    setDegree(handleGetDegreeValue(gradientString));

    if (selectedProperty) {
      const setp1ColorOffset = colors?.[1]
        ?.trim()
        ?.split("%")?.[0]
        ?.slice(8, 12)
        ?.trim();
      const setp2ColorOffset = colors?.[2]
        ?.trim()
        ?.split("%")?.[0]
        ?.slice(8, 12)
        ?.trim();
      const step1Color = colors?.[1]?.trim()?.slice(0, 8);
      const step2Color = colors?.[2]?.trim()?.slice(0, 8);

      setColor(step1Color);
      setHueColor(step1Color);
      setTextColorValue({
        hexCode: step1Color,
        opacity: 100,
      });
      setSteps([
        { color: step1Color, offset: setp1ColorOffset || 0 },
        { color: step2Color, offset: setp2ColorOffset || 100 },
      ]);

      if (isValidHexCode(step1Color)) {
        const getColorValue = chroma(step1Color)?.alpha(1)?.rgba();
        const convertRGB = {
          r: getColorValue?.[0],
          g: getColorValue?.[1],
          b: getColorValue?.[2],
          a: textColorValue?.opacity,
        };
        setOpacityColor(convertRGB);
      } else {
        setOpacityColor(step1Color);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.key, selectedSubProperty?.key, selectedScreen?.key]);

  useEffect(() => {
    const opacityWithColor =
      textColorValue?.opacity == 100
        ? textColorValue?.hexCode
        : `${textColorValue?.hexCode}${textColorValue?.opacity}` || "#ffffff";

    steps[activeThumbIndex] = {
      ...steps?.[activeThumbIndex],
      color: opacityWithColor?.trim(),
    };
    setSteps([...steps]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hueColor, opacityColor, textColorValue]);

  //meet and greet button color handle
  const commonChangeHandleMeetAndGreetButton = (getData) => {
    if (
      selectedProperty?.actionClass ===
        PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC ||
      selectedProperty?.actionClass === ACTION_CLASSES.INSTRUCTIONS
    ) {
      const sendData = { ...getData };
      handleChangeSubPropertyDatas({ ...sendData });
    } else {
      const buttonElementsData = selectedProperty?.elements?.map((item) => {
        if (selectedSubProperty?.key === item?.key) {
          return {
            ...item,
            value: { ...item?.value, ...getData },
          };
        } else return item;
      });

      const sendData = {
        ...selectedProperty,
        elements: buttonElementsData,
        value: {
          ...selectedProperty?.value,
          buttons: selectedProperty?.value?.buttons?.map((item) => {
            return { ...item, ...getData };
          }),
        },
      };
      handleChangeSubPropertyDatas({ ...sendData, nested: true });
    }
  };

  useEffect(() => {
    let color = "";
    if (grandientType == "linear") {
      color = `${grandientType}-gradient(${degree || 90}deg, ${
        steps?.[0]?.color
      } ${steps?.[0]?.offset}%,${steps?.[1]?.color} ${steps?.[1]?.offset}%)`;
    } else {
      color = `${grandientType}-gradient(circle, ${steps?.[0]?.color} ${steps?.[0]?.offset}%,${steps?.[1]?.color} ${steps?.[1]?.offset}%)`;
    }

    //meet and greet button color handle
    if (fontProperty) {
      let checkFont =
        selectedSubProperty?.key === "text" ? "fontColor " : "gradient";
      commonChangeHandleMeetAndGreetButton({ [checkFont]: color, color: "" });
    } else {
      handleChangeSubPropertyDatas({ color: "", gradient: color });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandientType, degree, color, activeThumbIndex, steps]);

  const handleAddColor = async () => {
    const sendColorData = {
      class: "Color",
      value: selectedSubProperty?.value?.gradient,
      config: {
        gradient: selectedSubProperty?.value?.gradient,
        className: "Color",
      },
    };

    // try {
    //   const data = await postApiServices(
    //     apiRoutes.createRecentColor,
    //     sendColorData
    //   );
    // } catch (err) {}
  };

  return (
    <ColorPickerWrapper>
      <CustomColorPicker
        value={color}
        colorPickerHandleChange={colorPickerHandleChange}
      />

      <Box m={2}>
        <CustomAlphaPickerPanel
          color={opacityColor}
          onChange={opacityHandleChange}
        />
      </Box>

      <ColorPickerTextBoxContainer>
        <ColorViewContainer>
          <ColorView
            sx={{
              background:
                textColorValue?.opacity == 100
                  ? textColorValue?.hexCode
                  : `${textColorValue?.hexCode}${textColorValue?.opacity}` ||
                    "#4200B4",
            }}
          />
        </ColorViewContainer>

        <ColorTextBoxContainer>
          <CustomMenu
            options={gradientTypes}
            defalutValue={grandientType}
            headLabelStyle={{ minWidth: "70%" }}
            handleChange={(value) => {
              setGrandientType(value?.label);
            }}
            icon={gradiantColorIcon}
          />
        </ColorTextBoxContainer>

        <ColorTextOpacityBoxContainer>
          <ColorTextBox
            value={degree}
            onChange={(e) => setDegree(e?.target?.value)}
            style={colorDegreeTextBoxStyle}
          />
        </ColorTextOpacityBoxContainer>
      </ColorPickerTextBoxContainer>

      <Box sx={{ margin: "0px 18px" }}>
        <CustomGradientRangeBar
          selectedColor={
            `linear-gradient(90deg, ${steps?.[0]?.color} ${steps?.[0]?.offset}%,${steps?.[1]?.color} ${steps?.[1]?.offset}%)` ||
            ""
          }
          grandientHandleChange={grandientHandleChange}
          firstThumbColor={steps?.[0]?.color}
          secondThumbColor={steps?.[1]?.color}
        />
      </Box>

      <Box sx={{ margin: "0px 16px" }}>
        <CustomHuePickerPanel color={hueColor} onChange={hueHandleChange} />
      </Box>

      <Box sx={{ mt: 2.5 }}>
        <LibraryHeading
          heading={"Saved Colors"}
          headingStyle={{
            fontSize: "clamp(1rem, 1vmin, 4rem)",
            width: "-webkit-fill-available",
          }}
          // icon={addIcon}
          headingContianerStyle={{ padding: "0px 20px" }}
          handleIconClick={handleAddColor}
        />
        <ColorList
          colorOptions={recentColors}
          handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          selectedSubProperty={selectedSubProperty}
          containerStyle={{ margin: "0px", padding: "0px 20px" }}
          solidColor={false}
        />
      </Box>
    </ColorPickerWrapper>
  );
}
