import React, { useEffect, useState } from "react";
import CustomCheckBoxField from "./CustomCheckBoxField";

export default function SharderApplyComponnets(props) {
  const { handleChangeSubPropertyDatas = () => {}, selectedSubProperty = {} } =
    props;

  const [applyShader, setApplyShader] = useState(false);

  useEffect(() => {
    setApplyShader(selectedSubProperty?.value?.isApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <CustomCheckBoxField
        label={`Apply ${selectedSubProperty?.name || ""}`}
        checked={applyShader}
        onChange={(e) => {
          setApplyShader(e.target.checked);
          handleChangeSubPropertyDatas({
            ...selectedSubProperty?.value,
            isApplied: e.target.checked,
          });
        }}
        containerStyle={{
          marginBottom: "15px",
          marginLeft: "8px",
        }}
      />
    </div>
  );
}
