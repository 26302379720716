export const multiTriggerConstructJson = (params) => {
  const {
    elementsData = [],
    templateDetails,
    setTemplateDetails,
    selectedScreen,
    setSelectedScreen,
    setSelectedProperty,
    selectedProperty,
    setSelectedSubProperty,
    selectedSubProperty,
    selectedPropertyLevel,
    setSelectedPropertyLevel,
  } = params;

  let getScreenData = {};
  let getPropertyData = {};

  const getChangedScreenData = templateDetails?.uiConfig?.screens?.map(
    (screenData) => {
      if (selectedScreen?.key === screenData?.key) {
        getScreenData = {
          ...screenData,
          pageSections: screenData?.pageSections?.map((pageSectionData) => {
            if (selectedProperty?.key === pageSectionData?.key) {
              getPropertyData = {
                ...pageSectionData,
                elements: elementsData,
              };
              return getPropertyData;
            } else {
              return { ...pageSectionData };
            }
          }),
        };
        return getScreenData;
      } else {
        return { ...screenData };
      }
    }
  );

  setSelectedScreen({ ...getScreenData });
  setSelectedProperty({ ...getPropertyData });
  setSelectedSubProperty({
    ...elementsData?.[0],
    value: { ...elementsData?.[0]?.value },
  });
  setTemplateDetails({
    ...templateDetails,
    uiConfig: {
      ...templateDetails?.uiConfig,
      screens: [...(getChangedScreenData || [])],
    },
  });
};
