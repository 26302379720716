export const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};
export const textOverflowEllipsStyle = {
  overflow: "hidden",
  position: "relative",
  display: "inline-block",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textAlign: "center",
};
export const wordWrapStyle = {
  overflowWrap: "break-word",
};
