import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const LibraryComponentWrapper = styled(Box)((props) => {
  return {
    padding: "0px 20px",
    [props.theme.breakpoints.up("sm")]: {
      padding: "0px 16px",
    },
  };
});
