import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { getApiServices } from "../../../api/api";
import { apiRoutes } from "../../../api/apiPath";
import XtendrLogo from "../../../assets/icons/HomeXtendR_LogoColours.png";
import { ReactComponent as ProjectsIcon } from "../../../assets/icons/Projects.svg";
import { ReactComponent as TemplateIcon } from "../../../assets/icons/Templates.svg";
import { errorMessage, successMessage } from "../../../redux/constant";
import { ROUTE_PATHS } from "../../../routes/routePaths";
import { centerStyle } from "../../../styles/commonStyles";
import { AccountView } from "../../../styles/navBar";
import {
  CustomMenuItem,
  CustomMenuOptionContainer,
  MenuLabel,
  modalSubmitButtonStyle,
} from "../../../styles/sharedComponents";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../utils/getDimensionalRatio";
import ProjectList from "./components/Project/ProjectList";
import {
  Container,
  ListContainer,
  ListMidContainer,
  OptionText,
  SettingContainer,
  OptionTextContainer,
  SidePanelContainer,
  AccountViewContainer,
  AccountName,
} from "./components/styled/DashBoardStyle";
import Settings from "./components/Settings/SettingsList";

const SidePanel = ({
  choices,
  selectedChoice,
  getSelectedRoutePath,
  handleOpenUserMenu,
  userCredentials,
  handleSettingsClick,
  navigate
}) => (
  <SidePanelContainer container xs={12} md={3}>
    <Grid container>
      <Grid item xs={12}>
        <Box
          onClick={navigate}
          component={"img"}
          alt="logo"
          src={XtendrLogo}
          style={{
            height: "3rem",
          }}
        />

        <AccountViewContainer>
          <AccountView
            style={{
              height: getHeightRatio(50.63),
              width: getWidthRatio(52),
              textTransform: "uppercase",
              background: "#000000",
            }}
            onClick={handleOpenUserMenu}
          >
            {userCredentials?.tenant?.name?.slice(0, 1) || "A"}
          </AccountView>
          <AccountName>
            {userCredentials?.tenant?.name || "Account Name"}
          </AccountName>
        </AccountViewContainer>

        <Box
          sx={{
            height: "69%",
            ...centerStyle,
            flexDirection: "column",
          }}
        >
          {choices?.map(({ name, action, icon: Icon, selectedPath }, index) => (
            <OptionTextContainer
              onClick={action}
              isSelected={selectedPath === getSelectedRoutePath}
              key={index}
            >
              <Icon />
              <OptionText>{name}</OptionText>
            </OptionTextContainer>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SettingContainer>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSettingsClick}
            style={{
              ...modalSubmitButtonStyle,
              fontSize: "1.4rem",
              color: "white",
            }}>
            Settings
          </Button>
        </SettingContainer>
      </Grid>
    </Grid>
  </SidePanelContainer>
);

const List = ({ selectedChoice, ...props }) => {
  const choiceSectionMap = {
    0: React.Fragment,
    1: ProjectList,
    2: Settings,
  };

  const renderSection = (selectedChoiceIdx) => {
    const ListComponent = choiceSectionMap[selectedChoiceIdx];
    return <ListComponent {...props} />;
  };

  return (
    <ListContainer item xs={12} md={9}>
      <ListMidContainer>{renderSection(selectedChoice)}</ListMidContainer>
    </ListContainer>
  );
};

const DashBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const getSelectedRoutePath = location?.pathname || "";
  const [selectedChoice, setSelectedChoice] = useState(1);
  const [userCredentials, setUserCredentials] = useState({});
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.logout);
      handleCloseUserMenu();
      navigate(ROUTE_PATHS.landingPage);
      cookies.remove("jwtToken");
      dispatch(successMessage(data?.message || "Logout successfully ..."));
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  const handleUserCredentials = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.users);
      setUserCredentials(data);
    } catch (err) {}
  };

  useEffect(() => {
    handleUserCredentials();
  }, []);

  const handleSettingsClick = () => {
    setSelectedChoice(2);
  };

  const sidePanelChoices = [
    {
      name: "My Projects",
      action: () => setSelectedChoice(1),
      icon: ProjectsIcon,
      selectedPath: ROUTE_PATHS.dashBoard,
    },
    {
      name: "Explore Templates",
      action: () => navigate(ROUTE_PATHS.templateDashBoard),
      icon: TemplateIcon,
      selectedPath: ROUTE_PATHS.templateDashBoard,
    },
  ];

  return (
    <>
      <Container container>
        <SidePanel
          choices={sidePanelChoices}
          selectedChoice={selectedChoice}
          getSelectedRoutePath={getSelectedRoutePath}
          handleOpenUserMenu={handleOpenUserMenu}
          userCredentials={userCredentials}
          handleSettingsClick={handleSettingsClick}
          navigate={()=> navigate(ROUTE_PATHS.landingPage)}
        />
        <List selectedChoice={selectedChoice} />
      </Container>

      {/* handle logout */}
      <CustomMenuOptionContainer
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        style={{ marginTop: getHeightRatio(30) }}
      >
        <CustomMenuItem onClick={handleLogout}>
          <MenuLabel>Logout</MenuLabel>
        </CustomMenuItem>
      </CustomMenuOptionContainer>
    </>
  );
};

export default DashBoard;
