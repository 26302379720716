import { BrowserRouter } from "react-router-dom";
import Root from "./components/Root";
import { Suspense } from "react";
import Loader from "./components/shared/Loader";
import { useSelector } from "react-redux";
import CustomSnackbar from "./components/shared/CustomSnackBar";

function App() {
  const globalState = useSelector((state) => state);

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Root />
          {globalState?.loader ? <Loader /> : <></>}
          <CustomSnackbar />
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
