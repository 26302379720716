import React, { useEffect, useRef } from "react";

export default function VideoComponent(props) {
  const { videoPath, controls = false, style = {}, autoPlay = false } = props;

  const videoRef = useRef(null);

  const handleChangeSrc = (newSrc) => {
    videoRef.current.src = newSrc;
  };

  useEffect(() => {
    handleChangeSrc(videoPath);
  }, [videoPath]);

  return (
    <video
      ref={videoRef}
      controls={controls}
      autoPlay={autoPlay}
      style={{ width: "100%", height: "100%", ...style }}
    >
      <source src={videoPath || ""} type="video/mp4" />
      <source src={videoPath || ""} type="video/ogg" />
    </video>
  );
}
