export const videoConstructJson = (params) => {
  const {
    elementsData = {},
    templateDetails,
    setTemplateDetails,
    selectedScreen,
    setSelectedScreen,
    setSelectedProperty,
    selectedProperty,
    setSelectedSubProperty,
    selectedSubProperty,
    selectedPropertyLevel,
    setSelectedPropertyLevel,
  } = params;

  let getScreenData = {};
  let getPropertyData = {};
  let getChangeElemetData = {};

  const getChangedScreenData = templateDetails?.uiConfig?.screens?.map(
    (screenData) => {
      if (selectedScreen?.key === screenData?.key) {
        getScreenData = {
          ...screenData,
          pageSections: screenData?.pageSections?.map((pageSectionData) => {
            if (selectedProperty?.key === pageSectionData?.key) {
              getPropertyData = {
                ...pageSectionData,
                pageSections: pageSectionData?.pageSections?.map((item) => {
                  getChangeElemetData = {
                    ...item,
                    elements: item?.elements?.map((item) => {
                      if (item?.key === selectedSubProperty?.key) {
                        return {
                          ...item,
                          value: { ...item?.value, ...elementsData },
                        };
                      } else return item;
                    }),
                  };
                  return getChangeElemetData;
                }),
              };
              return getPropertyData;
            } else {
              return { ...pageSectionData };
            }
          }),
        };
        return getScreenData;
      } else {
        return { ...screenData };
      }
    }
  );

  setSelectedScreen({ ...getScreenData });
  setSelectedProperty({ ...getPropertyData });
  setSelectedSubProperty({
    ...selectedSubProperty,
    value: { ...selectedSubProperty?.value, ...elementsData },
  });
  setSelectedPropertyLevel({
    ...selectedPropertyLevel,
    ...getChangeElemetData,
  });
  setTemplateDetails({
    ...templateDetails,
    uiConfig: {
      ...templateDetails?.uiConfig,
      screens: [...(getChangedScreenData || [])],
    },
  });
};
