import styled from "@emotion/styled";
import { Box, Grid, ListItemButton, ListItemIcon, Switch } from "@mui/material";
import {
  centerStyle,
  textOverflowEllipsStyle,
  wordWrapStyle,
} from "../commonStyles";

export const ScreenPanelContainer = styled(Grid)({
  height: "100%",
  width: "100%",
  boxshadow: "0px 3.25123px 20.3202px rgba(0, 13, 22, 0.07)",
  boxSizing: "border-box",
  display: "flex",
});
export const ScreenPanelListContainer = styled(Box)(({ isSelected, style }) => {
  return {
    height: "100%",
    width: "29%",
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "fit-content",
    backgroundColor: isSelected ? "#2E2E2E" : "#202020",
    ...style,
  };
});
export const ScreensPropertiesContainer = styled(Box)({
  height: "100%",
  width: "100%",
  position: "relative",
  boxSizing: "border-box",
});

//edit panel properties side bar styles
export const PropertiesListConatiner = styled(Box)(({ isOpen, style }) => {
  return {
    backgroundColor: "#2E2E2E",
    height: "100%",
    color: "#fff",
    position: "relative",
    boxSizing: "border-box",
    boxShadow: "0px 3.25123px 20.3202px rgba(0, 13, 22, 0.07)",
    width: "100%",
    opacity: isOpen ? 1 : 0,
    transition: "width 0.8s, opacity 0.8s linear",
    minWidth: "fit-content",
    // padding: "0px 8%",
    ...style,
  };
});
export const ScreenName = styled(Box)(({ style }) => {
  return {
    height: "9%",
    fontSize: " clamp(1rem, 1.5vmin, 4rem)",
    // padding: "10px 8% 0px 9%",
    padding: "10px 0px 0px 45px",
    boxSizing: "border-box",
    position: "sticky",
    left: 0,
    top: 0,
    zIndex: 888,
    background: "#2E2E2E",
    fontFamily: "OpenSans-Medium",
    fontWeight: "400",
    letterSpacing: "-0.011em",
    textTransform: "uppercase",
    color: "#FFFFFF",
    ...textOverflowEllipsStyle,
    ...centerStyle,
    justifyContent: "start",
    ...style,
  };
});
export const propertySideBarListStyle = {
  overflow: "hidden auto",
  padding: "0px 7%",
  height: "90%",
  boxSizing: "border-box",
};
export const CustomListItemButton = styled(ListItemButton)(({ style }) => {
  return {
    margin: "7% auto",
  };
});
export const CustomListItemIcon = styled(ListItemIcon)(
  ({ isSelected, style }) => {
    return {
      ...centerStyle,
      minWidth: "33px",
      height: "clamp(18px, 0.5vh, 60px)",
      width: "clamp(18px, 1vh, 60px)",
      color: isSelected ? "#49C4D8" : "#FFFFFF",

      "@media screen and (min-width: 2200px)": {
        height: "clamp(35px, 0.5vh, 60px)",
        width: "clamp(35px, 1vh, 60px)",
        marginRight: "25px",
      },

      ".fillAndStroke": {
        fill: isSelected ? "#49C4D8" : "#FFFFFF",
        stroke: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      "svg path": {
        fill: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      "svg circle": {
        stroke: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      "svg rect": {
        stroke: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      ".propLogo": {
        fill: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      ".threeIcon path": {
        fill: "none",
        stroke: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      ".promptIcon rect": {
        stroke: "none",
      },
      ".promptIcon": {
        fill: isSelected ? "#49C4D8" : "#FFFFFF",
      },
      ...style,
    };
  }
);
export const ListItemLabel = styled(Box)(({ isSelected, style }) => {
  return {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1rem, 1.5vmin, 4rem)",
    letterSpacing: "-0.011em",
    textTransform: "capitalize",
    ...wordWrapStyle,
    color: isSelected ? "#49C4D8" : "#FFFFFF",
    paddingTop: "3px",
    paddingLeft: "10px",
    fontFamily: isSelected ? "OpenSans-Bold" : "OpenSans-Regular",
    ...style,
  };
});
export const NoProperties = styled(Box)(({ isOpen, style }) => {
  return {
    opacity: isOpen ? 1 : 0,
    transition: "width 0.8s, opacity 0.8s linear",
    fontFamily: "OpenSans-Regular",
    textAlign: "center",
    fontSize: "clamp(1.2rem, 1.5vmin, 4rem)",
    ...style,
  };
});
export const SideBarCloseButton = styled(Box)(({ isOpen, style }) => {
  const x1 = window?.innerHeight / 1080;
  const exactHeight = 41 * x1;
  const exactWidth = 41 * x1;

  return {
    position: "absolute",
    width: exactWidth,
    height: exactHeight,
    background: "#414141",
    borderRadius: "100%",
    cursor: "pointer",
    top: "50%",
    left: "100%",
    transform: "translate(-50%, -50%)",
    display: isOpen ? "flex" : "none",
    zIndex: 999,
    ...centerStyle,
    ...style,
  };
});
export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 10,
  margin: 0,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));
