import React from "react";
import {
  CustomHuePicker,
  CustomHuePickerPointer,
} from "../../../styles/colorPalette";

export default function CustomHuePickerPanel(props) {
  const { color, onChange = () => {} } = props;
  return (
    <CustomHuePicker
      color={color}
      onChange={onChange}
      pointer={CustomHuePickerPointer}
    />
  );
}
