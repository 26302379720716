import { useEffect, useState } from "react"
import { appApi as Axios } from "../../api/config"

const useQuery = (url, preprocessor = (data) => data) => {
  const [state, setState] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  const queryFn = () =>
    Axios.get(url)
      .then(({ data }) => {
        setLoading(false)
        setState(preprocessor(data))
      })
      .catch((err) => setError(err instanceof Error ? err.message : err))
  useEffect(() => {
    queryFn()
    setLoading(true)
  }, [])
  return { state, setState, queryFn, loading, setLoading, error }
}
export default useQuery
