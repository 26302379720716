import React, { useEffect, useState } from "react";
import { getApiServices } from "../../api/api";
import { apiRoutes } from "../../api/apiPath";
import {
  ViewMoreBodyContainer,
  ViewMoreContainer,
  ViewMoreHeadContainer,
} from "../../styles/viewMore";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import ImageViewWrapper from "./ImageViewWrapper";
import LibraryHeading from "./LibraryHeading";

const imageViewContainerCustomStyle = {
  background: "#000000",
  margin: `${getWidthRatio(5)}px ${getWidthRatio(20)}px 15px 0px`,
  width: `${getWidthRatio(140)}px`,
  height: `${getHeightRatio(120)}px`,
  minWidth: "148px",
  minHeight: "148px",
};

export default function ViewMore(props) {
  const {
    selectedSubProperty,
    heading,
    multiSelect = {},
    selectedMultiSubProperties = {},
    handleChangeSubPropertyDatas = () => {},
    isAudio = false,
    isVideo = false,
    applyAudio,
    removeIcon = false,
    handleRemove = () => {},
    setOpenPreView = {},
    tenantUploads = true,
  } = props;

  const [assetList, setAssetList] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const filterAssets = (searchTerm) => {
    if (!searchTerm) {
      // If the search term is empty, reset to display all assets
      setFilteredAssets(assetList?.data?.assets || []);
      return;
    }

    const filtered = assetList?.data?.assets.filter((asset) => {
      // Customize this filtering logic based on your asset structure
      // For instance, if you're filtering based on asset name:
      return asset.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredAssets(filtered || []);
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    filterAssets(searchTerm);
  };

  const getAssetsListByType = async () => {
    try {
      const selectedValueClass = selectedSubProperty?.value;
      const checkAudio = selectedValueClass?.type === "audio";
      const type = checkAudio ? "default" : selectedValueClass?.type || "logo";
      const fileType = checkAudio
        ? "audio"
        : selectedValueClass?.fileType || "2D";
      const getPath = `${apiRoutes.getAllAssetsByType}?type=${type}&fileType=${fileType}&tenantUploads=${tenantUploads}`;
      const { data } = await getApiServices(getPath);
      setAssetList(data);
      setFilteredAssets(data.data?.assets)
    } catch (err) {}
  };

  useEffect(() => {
    getAssetsListByType();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubProperty?.value?.type, selectedSubProperty?.key]);

  return (
    <ViewMoreContainer>
      <ViewMoreHeadContainer>
        <LibraryHeading
          heading={heading}
          headingStyle={{ margin: `${getHeightRatio(12)}px 0px` }}
        />
        <input style={{
          fontFamily: "OpenSans-Medium",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "clamp(1.8rem, 1.5vh, 2rem)",
          lineHeight: "150%",
          letterSpacing: "-0.011em",
          color: "#000000",
          textAlign: "start",
          background: "#FFFFFF",
          padding: "0px 20px",
          borderRadius: "30px 10px",
        }}
          onChange={handleInputChange}
          placeholder="Search assets..."
        />
      </ViewMoreHeadContainer>

      <ViewMoreBodyContainer>
        <ImageViewWrapper
          selectedSubProperty={selectedSubProperty}
          multiSelect={multiSelect}
          selectedMultiSubProperties={selectedMultiSubProperties}
          imageList={filteredAssets}
          handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
          isAudio={isAudio}
          isVideo={isVideo}
          applyAudio={applyAudio}
          removeIcon={removeIcon}
          handleRemove={handleRemove}
          setOpenPreView={setOpenPreView}
          imageViewContainerCustomStyle={imageViewContainerCustomStyle}
          isModal={true}
        />
      </ViewMoreBodyContainer>
    </ViewMoreContainer>
  );
}
