import React, { useEffect, useState } from "react";
import CommonImageRender from "./CommonImageRender";
import { getApiServices } from "../../api/api";
import { apiRoutes } from "../../api/apiPath";

export function ImageSelectionList({
  selectedSubProperty = {},
  selectedProperty = {},
  handleChangeSubPropertyDatas = () => {},
  selectedMultiSubProperties = {},
  setSelectedSubProperty = () => {},
  setSelectedMultiSubProperties = () => {},
}) {
  const [fileLists, setFileLists] = useState([]);

  const getAssetsListByType = async () => {
    try {
      const selectedValueClass = selectedSubProperty?.value;
      const checkAudio = selectedValueClass?.type === "audio";
      const type = checkAudio ? "default" : selectedValueClass?.type || "logo";
      const fileType = checkAudio
        ? "audio"
        : selectedValueClass?.fileType || "2D";
      const getPath = `${apiRoutes.getAllAssetsByType}?type=${type}&fileType=${fileType}`;

      const { data } = await getApiServices(getPath);
      setFileLists(data?.data?.assets);
    } catch (err) {}
  };

  useEffect(() => {
    getAssetsListByType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.key, selectedSubProperty?.key]);

  return (
    <>
      <CommonImageRender
        multiSelect={true}
        heading={"List of Triggers"}
        selectedProperty={selectedProperty}
        imageList={fileLists}
        handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
        disabledHeading={false}
        viewMore={false}
        selectedMultiSubProperties={selectedMultiSubProperties}
        defaultSelection={false}
      />
    </>
  );
}
