import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import { centerStyle } from "../commonStyles";

export const PublishAccountContainer = styled(Box)(({ style }) => {
  return {
    width: "100%",
    height: "70%",
    ...centerStyle,
    justifyContent: "space-between",
    alignItems: "start",
    ...style,
  };
});
export const PublishImageView = styled(Box)(({ style }) => {
  return {
    height: "100%",
    width: "50%",
    ...centerStyle,
    flexDirection: "column",
    ...style,
  };
});
export const PublishUploadContainer = styled(Box)(({ style }) => {
  return {
    width: "100%",
    ...centerStyle,
    alignItems: "start",
    justifyContent: "space-between",
    ...style,
  };
});
export const publishUploadWrapperStyle = {
  height: "100%",
  width: "92%",
  overflow: "hidden",
};
export const publishMetaImageUploader = {
  height: `${getHeightRatio(227)}px`,
  aspectRatio: "auto",
  borderRadius: "5%",
};
export const publishDoitLaterStyle = {
  width: "194.18px",
  height: "66.83px",
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  linnHeight: "83px",
  justifyContent: "center",
  padding: "0px",
};
export const publishSendButtonStyle = {
  width: "173.08px",
  height: "66.47px",
  background: "#49C4D8",
  borderRadius: "9.03153px",
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  lineHeight: "83px",
  color: "#000000",
};
export const PublishUploadInfoIcon = styled(Box)(({ style }) => {
  return {
    height: `${getHeightRatio(22)}px`,
    width: `${getWidthRatio(22)}px`,
    cursor: "pointer",
    ...style,
  };
});
export const UploadImageLabel = styled(Box)(({ style }) => {
  return {
    height: "20%",
    width: "100%",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2.2rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    ...centerStyle,
    ...style,
  };
});
export const PublishAccountDetalis = styled(Box)(({ style }) => {
  return {
    height: "100%",
    width: "50%",
    ...style,
  };
});
export const PublishTextBoxContainer = styled(Box)(({ style }) => {
  return {
    height: "70px",
    width: "100%",
    margin: "20px",
    ...centerStyle,
    textAlign: "start",
    justifyContent: "start",
    ...style,
  };
});
export const PublishTextBoxLabel = styled(Box)(({ style }) => {
  return {
    width: "40%",
    height: "30%",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2.2rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    ...style,
  };
});
export const PublishButtonContainer = styled(Box)(({ style }) => {
  return {
    width: "100%",
    height: "27%",
    ...centerStyle,
    justifyContent: "start",
    alignItems: "end",
    ...style,
  };
});
export const PublishApprovedButton = styled(Box)(({ style }) => {
  return {
    cursor: "pointer",
    background: "linear-gradient(90deg, #004003 -53.74%, #05BC37 39.27%, #0FBD5F 139.12%)",
    boxShadow: "0px 4.25px 18.0625px 0px rgba(0, 0, 0, 0.25)",
    borderRadius: "0.79688rem",
    fontFamily: "OpenSans-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2.6rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    color: "#FFFFFF",
    padding: "1rem 2rem",
    ...centerStyle,
    ...style,
  };
});
export const navBarGotoTemplateStyle = {
  width: 'fit-content',
  background: "#49C4D8",
  borderRadius: "9.03153px",
  fontSize: "clamp(2.4rem , 1.5vmin, 4rem)",
  color: "#000000",
};
export const resetButtonStyle = {
  width: "173.08px",
  height: "66.47px",
  background: "#49C4D8",
  borderRadius: "9.03153px",
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  lineHeight: "83px",
  color: "#000000",
};
export const resetCencelButtonStyle = {
  width: "194.18px",
  height: "66.83px",
  fontSize: "clamp(2.4rem, 1.5vmin, 4rem)",
  linnHeight: "83px",
  justifyContent: "center",
  padding: "0px",
};
export const PublishGoBackButton = styled(Box)(({ style }) => {
  return {
    cursor: "pointer",
    border: "1.10938px solid #FFFFFF",
    filter: "drop-shadow(0px 4.4375px 18.8594px rgba(0, 0, 0, 0.25))",
    borderRadius: "13.3125px",
    fontFamily: "OpenSans-Bold",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2.6rem, 1.5vmin, 4rem)",
    lineHeight: "150%",
    letterSpacing: "-0.011em",
    padding: "1rem 2rem",
    color: "#FFFFFF",
    ...centerStyle,
    justifyContent: "space-evenly",
    ...style,
  };
});
export const navBarBackDoitLaterStyle = {
  width: 'fit-content',
  borderRadius: "9.03153px",
  background: "transparent",
  fontSize: "clamp(2.4rem , 1.5vmin, 4rem)",
  justifyContent: "center",
};
export const ApprovalModalContainer = styled(Box)(({ style }) => {
  return {
    width: "90%",
    height: "90%",
    flexDirection: "column",
    ...centerStyle,
    ...style,
  };
});
export const ApprovalDescription = styled(Box)(({ style }) => {
  return {
    width: "80%",
    height: "60%",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2.8rem, 1.5vmin, 4rem)",
    lineHeight: "44px",
    letterSpacing: "-0.01em",
    color: "#FFFFFF",
    ...centerStyle,
    ...style,
  };
});
export const ApprovalButtonContainer = styled(Box)(({ style }) => {
  return {
    width: "75%",
    height: "40%",
    ...centerStyle,
    alignItems: "start",
    justifyContent: "space-between",
    ...style,
  };
});
