import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import { centerStyle } from "./commonStyles";
import {
  subPropertycolorKey,
  subPropertyImageKey,
} from "../constants/renderUiMatchKeys";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";

//check style
export const templateCreationStyles = (
  height,
  width,
  getBackgroundData = []
) => {
  const x1 = height / 1080;
  //old preview screen size
  // const exactHeight = 852 * x1;
  // const exactWidth = 393 * x1;

  //new preview screen size
  const exactHeight = 929 * x1;
  const exactWidth = 429 * x1;

  let projectCommonStyle = {
    height: `${exactHeight}px !important`,
    width: `${exactWidth}px`,
    position: "relative",
    zIndex: 0,
    borderRadius: "23px",
    overflow: "hidden",
  };
  const getBackgroundColor = getBackgroundData?.find(
    (item) => item?.key === subPropertycolorKey
  )?.value;

  if (getBackgroundData?.length > 0) {
    projectCommonStyle["background"] = `url(${
      getBackgroundData?.find((item) => item?.key === subPropertyImageKey)
        ?.value?.previewUrl || ""
    }), ${
      getBackgroundColor?.gradient ||
      getBackgroundColor?.color ||
      "linear-gradient(180deg, #15A5CF 0%, #4007B5 100%)"
    }`;
    projectCommonStyle["backgroundSize"] = "cover";
    projectCommonStyle["backgroundRepeat"] = "no-repeat";
    projectCommonStyle["backgroundPosition"] = "center";
    projectCommonStyle["border"] = `${
      getBackgroundData?.find((item) => item?.key === subPropertycolorKey)
        ?.value?.border || 0
    }px solid #FFFFFF`;
  }
  return projectCommonStyle;
};
export const ShaderContainer = styled(Box)(({ style }) => {
  return {
    height: "100%",
    width: "100%",
    left: 0,
    top: 0,
    boxSizing: "border-box",
    position: "absolute",
    overflow: "hidden",
    ...style,
  };
});
export const MainFrameScreenContainer = styled(Box)({
  height: "100%",
  width: "100%",
  boxSizing: "border-box",
  position: "relative",
});
export const libraryPanelContainerStyle = (openProperties) => {
  return {
    height: "100%",
    width: "100%",
    backgroundColor: openProperties ? "#2E2E2E" : "",
    visibility: openProperties ? "visible" : "hidden",
    opacity: openProperties ? 1 : 0,
    transition: "visibility 0.8s, opacity 0.8s linear",
    padding: `${getHeightRatio(20)}px ${getWidthRatio(10)}px`,
    overflow: "hidden",
  };
};
export const ArrowIcon = styled("img")(({ style }) => {
  const x1 = window.innerHeight / 1080;
  const exactHeight = 10 * x1;
  const exactWidth = 6 * x1;

  return {
    width: exactWidth,
    height: exactHeight,
    transform: "matrix(-1, 0, 0, 1, 0, 0)",
    ...style,
  };
});
