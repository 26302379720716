import React, { useEffect, useState } from "react";
import CustomTimerSelection from "./CustomTimerSelection";

export default function TimerTuneList(props) {
  const {
    handleChangeSubPropertyDatas = () => {},
    selectedProperty = {},
    selectedSubProperty = {},
  } = props;

  const [timerTune, setTimerTune] = useState("starting");

  const options = selectedSubProperty?.options?.map((item) => {
    return {
      label: item?.value?.metaType,
    };
  });

  const handleChange = (value) => {
    setTimerTune(value);
    handleChangeSubPropertyDatas({ metaType: value });
  };

  useEffect(() => {
    setTimerTune(selectedSubProperty?.value?.metaType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubProperty?.key]);

  return (
    <CustomTimerSelection
      options={options || []}
      defaultValue={timerTune}
      handleChange={handleChange}
    />
  );
}
