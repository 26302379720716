import { Box } from "@mui/material";
import React from "react";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../../utils/getDimensionalRatio";
import VideoComponent from "../../../../VideoComponent";

const index = ({ url, width }) => {
  return (
    <Box
      sx={{
        height: `${getHeightRatio(769)}px`,
        width: `${getWidthRatio(355)}px`,
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        background: "white",
        borderRadius: "5px",
      }}
    >
      <VideoComponent
        videoPath={url}
        style={{ objectFit: "cover" }}
        autoPlay={true}
      />
    </Box>
  );
};

export default index;
