import { min } from "lodash";

export const getWidthRatio = (inputWidth) => {
  const x1 = window.innerWidth / 1920;
  return inputWidth * x1;
};

export const getHeightRatio = (inputHeight, patHeight) => {
  const parentHeight = patHeight ? patHeight : 1080;
  const x1 = window.innerHeight / parentHeight;
  return inputHeight * x1;
};

export const getDimensionRatios = (inputWidth, inputHeight) => {
  const x1 = window.innerWidth / 1920;
  const x2 = inputHeight / 1080;
  const lessValue = min([x1, x2]);
  const exactHeight = inputHeight * x1;
  const exactWidth = inputWidth * x1;
  return { height: exactHeight, width: exactWidth, ratio: lessValue };
};
