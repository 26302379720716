import { Grid, styled } from "@mui/material"
import bg_image from "../../../../../assets/icons/landingPage1.png"

const TemplateContainer = styled(Grid)(() => ({
  height: "100vh",
  width: "100%",
  boxSizing: "border-box",
  background: "#000000",
  backgroundRepeat: "no-repeat",
  overflow: "hidden auto",
  backgroundSize: "cover",
  backgroundImage: `url(${bg_image})`,
  color: "white",
}))

const NavBarContainer = styled(Grid)({
  height: "9.9074%",
  boxShadow: "0px 3.25123px 69px rgba(17, 17, 17, 0.35)",
  width: "100%",
})

export { TemplateContainer, NavBarContainer }
