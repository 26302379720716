export const ACTION_CLASSES = {
  MEET_GREET: "meet_greet",
  ASSET_MULTISELECT: "AssetMultiSelectContainer",
  ASSET_TREATURE_HUNT: "TreasureHuntAssetPreview",
  INSTRUCTIONS: "InstructionPreview",
  BACKGROUND: "Background",
  COLOR_CONTAINER: "ColorContainer",
  IMAGE_CONTAINER: "ImageUploadContainer",
  STYLE_WITH_COLOR_CONTAINER: "StyleWithColorContainer",
  STYLE_CONTAINER: "StyleContainer",
  TIMER_CONTAINER: "TimerContainer",
  VIDEO_CONTAINER: "VideoSectionContainer",
  TEXT_INPUT_PREVIEW_CONTAINER: "TextInputWithPreview",
  ANIMATION_SELECTOR: "AnimationSelector",
  MULITI_PLAYER: "MultiplayerPreview",
  MAIN_EXPERIENCE: "MainExperience",
  ASSET_MULTISELECT_CONTAINER: "AssetMultiSelectContainer",
  DEFAULT_SECTION_CONTAINER: "DefaultSectionContainer",
  TEXTINPUT_GROUPER_CONTAINER: "TextInputGrouperContainer",
  MULTI_SECTION_CONTAINER: "MultiSectionContainer",
  TEXTINPUT_WITH_PREVIEW: "TextInputWithPreview",
  TEXTINPUT_PREVIEW_CONTAINER: "TextInputPreviewContainer",
  BUTTON_STYLE_WITH_CONTAINER: "ButtonWithStyleContainer",
  NUMBER_INPUT_CONTAINER: "NumberInputContainer",
  BUTTON_CONTAINER: "ButtonContainer",
  TEXT_CONTAINER: "TextContainer",
  VIDEO_UPLOAD_CONATINER: "VideoUploadContainer",
  ASSET_UPLOAD_LIMITER: "AssetUploadLimiterWithNumberInput",
};

export const PREVIEW_ACTION_CLASSES = {
  IMAGE_PREVIEW: "ImagePreview",
  SVG_PREVIEW: "SvgPreview",
  VIDEO_CONTAINER: "VideoSectionContainer",
  VOICE_TRIGGER_PREVIEW: "VoiceTriggerPreview",
  GLB_PREVIEW: "GlbPreview",
  TREASURE_PREVIEW: "TreasureHuntAssetPreview",
  INSTRUCTION_PREVIEW: "InstructionPreview",
  MAIN_SCREEN_ELEMENT_PREVIEW: "MainScreenElementPreview",
  MAIN_SCREEN_PREVIEW: "MainScreenPreview",
  TEXT_PREVIEW: "TextPreview",
  BUTTON_PREVIEW: "ButtonGrouper",
  SHADER_TOGGLE: "ShaderToggle",
  LEADER_BOARD_PLAYERS: "SvgPreviewWithLeaderBoardPlayers",
  LEADER_BOARD_STATIC: "LeaderBoardStatic",
  HIDDEN_CONTAINER: "HiddenContainer",
};
