import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const selectedContainerStyle = {
  padding: "0px 20px",
  boxShadow: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  background: "#1B1B1B",
  borderRadius: 10,
  borderColor: "none",
  color: "white",
  width: "100%",
  "& .MuiSvgIcon-root": {
    color: "white",
    fontSize: 20,
    marginRight: 3,
  },
};
const menuStyle = {
  background: "#1B1B1B",
  width: "100%",
  color: "white",
  border: "none",
};

export default function CustomSelection({
  options = [],
  defaultValue,
  handleChange = () => {},
  icon,
  getFullData = false,
  menuContainerStyle = {},
  containerStyle = {},
  headLabelStyle = {},
  key,
}) {
  const [selectedMenu, setSelectedMenu] = useState();

  const handleValueChange = (value) => {
    const customValue = getFullData
      ? value
      : key
      ? value[key]
      : value?.label || value?.name;
    setSelectedMenu(customValue);
    handleChange(customValue);
  };

  useEffect(() => {
    setSelectedMenu(defaultValue);
  }, []);

  return (
    <FormControl sx={{ width: "100%" }} size="small">
      <InputLabel id="demo-select-small-label"></InputLabel>
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectedMenu || ""}
        onChange={(event) => handleValueChange(event.target.value)}
        MenuProps={{
          sx: { fontSize: "10px" },
          PaperProps: { sx: { bgcolor: "#1B1B1B" } },
        }}
        sx={selectedContainerStyle}
      >
        {options?.map((item, index) => {
          return (
            <MenuItem
              key={index}
              sx={menuStyle}
              value={getFullData ? item : item?.name || item?.label}
            >
              {item?.name || item?.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
