import React, { useEffect, useState } from "react";
import {
  CustomMenuContainer,
  CustomMenuItem,
  CustomMenuLabelContainer,
  CustomMenuOptionContainer,
  MenuDropDownIcon,
  MenuLabel,
  MenuViewLabel,
} from "../../styles/sharedComponents";
import colorSelectIcon from "../../assets/icons/colorSelectIcon.png";

export default function CustomMenu({
  options = [],
  defalutValue,
  handleChange = () => {},
  icon,
  getFullData = false,
  menuContainerStyle = {},
  containerStyle = {},
  headLabelStyle = {},
  key,
  disableDropDownIcon = false,
}) {
  //open menu
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState("");

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (value) => {
    const colorValue = getFullData
      ? value
      : key
      ? value[key]
      : value?.label || value?.name;
    setAnchorElUser(null);
    setSelectedMenu(colorValue);
    handleChange(value);
  };

  useEffect(() => {
    setSelectedMenu(defalutValue);
  }, [defalutValue]);

  return (
    <CustomMenuContainer style={containerStyle}>
      <CustomMenuLabelContainer
        onClick={handleOpenUserMenu}
        style={menuContainerStyle}
        disableClick={disableDropDownIcon}
      >
        <MenuViewLabel style={headLabelStyle}>
          {selectedMenu || ""}
        </MenuViewLabel>

        {disableDropDownIcon ? (
          <></>
        ) : (
          <MenuDropDownIcon
            src={icon || colorSelectIcon}
            alt="colorIcon"
            changeIcon={Boolean(anchorElUser)}
          />
        )}
      </CustomMenuLabelContainer>

      <CustomMenuOptionContainer
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={() => setAnchorElUser(null)}
      >
        {options?.map((item, index) => (
          <CustomMenuItem
            key={index}
            onClick={() => handleCloseUserMenu(item)}
            checkSelectedType={
              item?.label === selectedMenu || item?.name === selectedMenu
            }
          >
            <MenuLabel>{item?.label || item?.name}</MenuLabel>
          </CustomMenuItem>
        ))}
      </CustomMenuOptionContainer>
    </CustomMenuContainer>
  );
}
