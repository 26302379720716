import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";
import { centerStyle } from "./commonStyles";

export const InfoBlobContentContentContainer = styled(Box)({
  width: getWidthRatio(338),
  height: getHeightRatio(175),
  borderRadius: "16.3106px",
  overflow: "hidden auto",
});
export const FormatContainer = styled(Box)({
  width: getWidthRatio(280),
  height: "45%",
  ...centerStyle,
  marginLeft: getWidthRatio(20),
});
export const FormatLabel = styled(Box)({
  height: "50%",
  width: "45%",
  ...centerStyle,
  justifyContent: "start",
  alignItems: "start",
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2rem",
  letterSpacing: "-0.011em",
  color: "#FFFFFF",
});
export const FormatListLabel = styled(Box)({
  height: "50%",
  width: "55%",
  fontFamily: "OpenSans-Medium",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "2rem",
  letterSpacing: "-0.011em",
  color: "#FFFFFF",
  ...centerStyle,
  alignItems: "start",
});
