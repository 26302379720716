import { Box } from "@mui/system";
import React from "react";
import { ColorListContainer } from "../../../styles/colorPalette";
import { centerStyle } from "../../../styles/commonStyles";
import { ColorCircle, HeadingText } from "../../../styles/sharedComponents";
import Drag from "../Drag";

export default function ColorList(props) {
  const {
    colorOptions = [],
    handleChangeSubPropertyDatas = () => {},
    selectedSubProperty = {},
    containerStyle = {},
    Heading,
    solidColor = true,
  } = props;

  const handleClick = (color) => {
    if (solidColor) handleChangeSubPropertyDatas({ color, gradient: "" });
    else handleChangeSubPropertyDatas({ color: "", gradient: color });
  };

  const handleDargAndDrop = (color) => {
    if (solidColor) return { color, gradient: "" };
    else return { color: "", gradient: color };
  };

  return (
    <ColorListContainer style={containerStyle}>
      {Heading ? <HeadingText>{Heading}</HeadingText> : <></>}
      <Box sx={{ ...centerStyle, justifyContent: "start" }}>
        {colorOptions?.map((item, index) => (
          <Drag key={index} dragData={handleDargAndDrop(item?.value)}>
            <ColorCircle
              style={{ background: item?.value || "" }}
              isSelected={
                selectedSubProperty?.value?.gradient === item?.value ||
                selectedSubProperty?.value?.color === item?.value
              }
              onClick={() => handleClick(item?.value)}
            />
          </Drag>
        ))}
      </Box>
    </ColorListContainer>
  );
}
