import React, { useEffect, useRef, useState } from "react";
import { deleteApiServices, getApiServices, multipartPostApiServices } from "../../api/api";
import { apiRoutes } from "../../api/apiPath";
import {
  applyAudiocontainerStyle,
  CustomImageUploadContainer,
  FileNameContainer,
  FileNameGetTextBox,
  FileNameSubmitButton,
  ImageUploaderAndSelectBox,
  ImageView,
  ImageViewContainer,
  selectedBoxStyle,
  SelectedLabel,
  TemplateImage,
  TemplateName,
  TemplateNameViewContainer,
  VideoPalyIconWarpper,
} from "../../styles/sharedComponents";
import { appendFormData } from "../../utils/formDataConverter";
import CommonImageRender from "./CommonImageRender";
import CustomImageUpload from "./CustomImageUpload";
import LibraryHeading from "./LibraryHeading";
import AudioComponent from "./AudioComponent";
import xtendrEmptyImage from "../../assets/icons/xtendrEmptyImage.jpg";
import CustomCheckBoxField from "./CustomCheckBoxField";
import VideoComponent from "../VideoComponent";
import { videoPalyIcon } from "../../constants/icons";
import PreviewModal from "./PreviewModal";
import { ModelImageRenderer } from "./3DImageRenderer";
import { ACTION_CLASSES } from "../../constants/renderUiMatchActionClass";
import {
  audioFileValidation,
  gifFileValidation,
  imageFileValidation,
  threeDFileValidation,
  videoFileValidation,
} from "../../utils/uploaderValidations";
import { INFO } from "../../constants/constant";
import CommonFormModal from "./CommonFormModal";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";
import templateIcon from "../../assets/icons/Template_Selection.png";
import { SUB_PROPERTY_TYPES } from "../../constants/renderUiMatchTypes";
import InformationBlobContent from "./InformationBlobContent";
import { errorMessage, successMessage } from "../../redux/constant";
import { useDispatch } from "react-redux";

export default function ImageUploader(props) {
  const {
    handleChangeSubPropertyDatas = () => {},
    selectedProperty,
    selectedSubProperty,
    isAudio = false,
    selectedPropertyLevel,
  } = props;

  const dispatch = useDispatch();
  const [openFileNameModal, setOpenFileNameModal] = useState(false);
  const [openPreview, setOpenPreView] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [uploadImage, setUploadImage] = useState({ fileName: "", file: "" });
  const [tenantImageList, setTenantImageList] = useState([]);
  const [libraryImageList, setLibraryImageList] = useState([]);
  const [applyAudio, setApplyAudio] = useState(false);
  const [fileSize, setFileSize] = useState("");
  const [fileFormats, setFileFormats] = useState("");

  const getSpecs = useRef();

  const getAssetsListByType = async (props) => {
    try {
      const { setImageList = () => {}, tenantUploads = true } = props;
      const selectedValueClass = selectedSubProperty?.value;
      const checkAudio = selectedValueClass?.type === "audio";
      const type = checkAudio ? "default" : selectedValueClass?.type || "logo";
      const fileType = checkAudio
        ? "audio"
        : selectedValueClass?.fileType || "2D";
      const checkLimit =
        ACTION_CLASSES.ASSET_MULTISELECT_CONTAINER ===
        selectedProperty?.actionClass
          ? ""
          : "&limit=2";
      const getPath = `${apiRoutes.getAllAssetsByType}?type=${type}&fileType=${fileType}&tenantUploads=${tenantUploads}${checkLimit}`;

      const { data } = await getApiServices(getPath);
      setImageList(data);
    } catch (err) {}
  };

  const handleGetImageList = () => {
    getAssetsListByType({
      setImageList: setTenantImageList,
      tenantUploads: true,
    });
    getAssetsListByType({
      setImageList: setLibraryImageList,
      tenantUploads: false,
    });
  };

  const handelSubmit = async () => {
    const check = uploadImage?.fileName === "";
    check ? setCheckError(true) : setCheckError(false);

    if (!check) {
      const sendAssetDatas = {
        name: uploadImage?.fileName,
        asset: uploadImage?.file,
        type: selectedSubProperty?.value?.type || "logo",
      };

      try {
        const data = await multipartPostApiServices(
          apiRoutes.assetCreation,
          appendFormData(sendAssetDatas)
        );
        handleGetImageList();
        setUploadImage({ fileName: "", file: "" });
        setCheckError(false);
        setOpenFileNameModal(false);
        dispatch(
          successMessage(data?.message || "Asset Uploaded Successfully")
        );
      } catch (err) {
        dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
      }
    }
  };

  const handleChange = (inputFile) => {
    if (selectedSubProperty?.value?.fileType === "2D") {
      if (selectedSubProperty.value?.type === SUB_PROPERTY_TYPES.LOADER) {
        if (gifFileValidation(inputFile)) {
          setUploadImage({ ...uploadImage, file: inputFile });
          setOpenFileNameModal(true);
        } else {
          dispatch(errorMessage(INFO.IMAGE_FORMAT_MESSAGE));
        }
      } else {
        if (imageFileValidation(inputFile)) {
          setUploadImage({ ...uploadImage, file: inputFile });
          setOpenFileNameModal(true);
        } else {
          dispatch(errorMessage(INFO.IMAGE_FORMAT_MESSAGE));
        }
      }
    } else if (selectedSubProperty?.value?.fileType === "audio") {
      if (audioFileValidation(inputFile)) {
        setUploadImage({ ...uploadImage, file: inputFile });
        setOpenFileNameModal(true);
      } else {
        dispatch(errorMessage(INFO.AUDIO_FORMAT_MESSAGE));
      }
    } else if (selectedSubProperty?.value?.fileType === "3D") {
      if (threeDFileValidation(inputFile)) {
        setUploadImage({ ...uploadImage, file: inputFile });
        setOpenFileNameModal(true);
      } else {
        dispatch(errorMessage(INFO.GLB_FORMAT_MESSAGE));
      }
    } else if (selectedSubProperty?.value?.fileType === "video") {
      if (videoFileValidation(inputFile)) {
        setUploadImage({ ...uploadImage, file: inputFile });
        setOpenFileNameModal(true);
      } else {
        dispatch(errorMessage(INFO.VIDEO_FORMAT_MESSAGE));
      }
    }
  };

  const handleFileInfoBlobValues = () => {
    if (selectedSubProperty?.value?.fileType === "2D") {
      if (selectedSubProperty.value?.type === SUB_PROPERTY_TYPES.LOADER) {
        setFileSize("250 KB");
        setFileFormats("image/gif");
      } else {
        setFileSize("250 KB");
        setFileFormats("image/jpeg, image/png");
      }
    } else if (selectedSubProperty?.value?.fileType === "audio") {
      setFileSize("300 KB");
      setFileFormats("audio/mpeg");
    } else if (selectedSubProperty?.value?.fileType === "3D") {
      setFileSize("7 MB");
      setFileFormats("glb");
    } else if (selectedSubProperty?.value?.fileType === "video") {
      setFileSize("100 MB");
      setFileFormats("video/mp4, video/mov, video/wmv");
    }
  };

  const infoUploadFiles = () => {
    return (
      <InformationBlobContent fileSize={fileSize} fileFormats={fileFormats} />
    );
  };

  const handleRemove = async (getRemoveData) => {
    try {
      const data = await deleteApiServices(
        apiRoutes.deleteAssets,
        getRemoveData?.id
      );
      getAssetsListByType();
      dispatch(successMessage(data?.message || "Asset Deleted Successfully"));
    } catch (err) {
      dispatch(errorMessage(err?.response?.data?.errors?.[0]?.message));
    }
  };

  useEffect(() => {
    handleFileInfoBlobValues();
  }, [selectedSubProperty?.key, selectedSubProperty?.value?.type]);

  useEffect(() => {
    handleGetImageList();
    setApplyAudio(selectedSubProperty?.value?.applyAudio || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty?.key, selectedSubProperty?.key]);

  // recent images and library images
  const ListAllImages = () => {
    return (
      <>
        {tenantImageList?.data?.assets?.length ? (
          <CommonImageRender
            heading={"Recent Uploads"}
            imageList={tenantImageList?.data?.assets || []}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            disabledHeading={false}
            isAudio={isAudio ? true : false}
            setOpenPreView={setOpenPreView}
            selectedProperty={selectedProperty}
            isVideo={
              selectedSubProperty?.actionClass ===
              ACTION_CLASSES.VIDEO_UPLOAD_CONATINER
            }
            applyAudio={applyAudio}
            selectedSubProperty={selectedSubProperty}
            handleRemove={handleRemove}
            viewMore={
              tenantImageList?.data?.assetsCount > 2
                ? ACTION_CLASSES.ASSET_MULTISELECT_CONTAINER ===
                  selectedProperty?.actionClass
                  ? false
                  : true
                : false
            }
            tenantUploads={true}
            defaultSelection={
              tenantImageList?.data?.assets?.length ? true : false
            }
          />
        ) : (
          <></>
        )}

        {libraryImageList?.data?.assets?.length ? (
          <CommonImageRender
            heading={"Xtendr Library"}
            selectedProperty={selectedProperty}
            imageList={libraryImageList?.data?.assets || []}
            handleChangeSubPropertyDatas={handleChangeSubPropertyDatas}
            isAudio={isAudio ? true : false}
            setOpenPreView={setOpenPreView}
            isVideo={
              selectedSubProperty?.actionClass ===
              ACTION_CLASSES.VIDEO_UPLOAD_CONATINER
            }
            applyAudio={applyAudio}
            selectedSubProperty={selectedSubProperty}
            viewMore={
              libraryImageList?.data?.assetsCount > 2
                ? ACTION_CLASSES.ASSET_MULTISELECT_CONTAINER ===
                  selectedProperty?.actionClass
                  ? false
                  : true
                : false
            }
            tenantUploads={false}
            defaultSelection={
              tenantImageList?.data?.assets?.length ? false : true
            }
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  //audio check box component
  const ApplyAudioComponent = () => {
    return (
      <>
        <CustomCheckBoxField
          label={"Apply Audio"}
          checked={applyAudio}
          onChange={(e) => {
            setApplyAudio(e.target.checked);
            handleChangeSubPropertyDatas({
              ...selectedSubProperty?.value,
              applyAudio: e.target.checked,
            });
          }}
          containerStyle={applyAudiocontainerStyle}
        />
      </>
    );
  };

  return (
    <>
      {isAudio ? ApplyAudioComponent() : <></>}

      <LibraryHeading
        disabledHeading={true}
        mandatory={true}
        infoContent={infoUploadFiles}
      />
      <ImageUploaderAndSelectBox>
        <ImageViewContainer
          ref={getSpecs}
          getSpecs={getSpecs}
          style={selectedBoxStyle(isAudio, applyAudio, getSpecs)}
        >
          {selectedSubProperty?.value?.previewUrl ? (
            <>
              {selectedSubProperty?.actionClass ===
              ACTION_CLASSES.VIDEO_UPLOAD_CONATINER ? (
                <VideoComponent
                  videoPath={selectedSubProperty?.value?.previewUrl || ""}
                />
              ) : isAudio ? (
                <>
                  <AudioComponent
                    src={selectedSubProperty?.value?.previewUrl || ""}
                  />
                  <p style={{color:'white'}}>{selectedSubProperty.value.name}</p>
                </>
              ) : selectedSubProperty?.value?.type === "model" ||
                selectedSubProperty?.value?.fileType === "3D" ? (
                  <div>
                  <ModelImageRenderer
                    element={{ ...selectedSubProperty?.value }}
                    getParentSizes={{
                      width: 102,
                      height: 60,
                    }}
                    configStyle={false}
                  />
                  <p style={{color:'white'}}>{selectedSubProperty.value.name}</p>
                </div>
              ) : (
                <ImageView
                  src={
                    selectedSubProperty?.value?.previewUrl || xtendrEmptyImage
                  }
                  alt={`Selected ${isAudio ? "Audio" : "Image"}`}
                />
              )}
            </>
          ) : (
            <SelectedLabel>
              {`Selected
              ${
                selectedSubProperty?.actionClass ===
                ACTION_CLASSES.VIDEO_UPLOAD_CONATINER
                  ? "Video"
                  : isAudio
                  ? "Audio"
                  : "Image"
              }`}
            </SelectedLabel>
          )}

          {selectedSubProperty?.actionClass ===
            ACTION_CLASSES.VIDEO_UPLOAD_CONATINER &&
          selectedSubProperty?.value?.previewUrl ? (
            <VideoPalyIconWarpper>{videoPalyIcon}</VideoPalyIconWarpper>
          ) : (
            <></>
          )}
        </ImageViewContainer>

        <CustomImageUploadContainer>
          <CustomImageUpload
            accept={
              selectedSubProperty?.actionClass ===
              ACTION_CLASSES.VIDEO_UPLOAD_CONATINER
                ? "video/*"
                : isAudio
                ? "audio/*"
                : "image/*"
            }
            name="uploadImage"
            onChange={handleChange}
            uploadField={true}
          />
        </CustomImageUploadContainer>
      </ImageUploaderAndSelectBox>

      {ListAllImages()}

      {/* get upload data file name */}
      <CommonFormModal
        open={openFileNameModal}
        modalBodyContainerStyle={{
          width: getWidthRatio(885),
          height: getHeightRatio(291),
        }}
        modalChildrenContainerStyle={{
          width: getWidthRatio(885),
          height: getHeightRatio(291),
        }}
        iconContainerHeight={{
          height: getHeightRatio(291),
        }}
        backIcon={false}
        cancelIcon={false}
      >
        <FileNameContainer>
          <TemplateNameViewContainer>
            <TemplateImage src={templateIcon} alt="templateIcon" />
            <TemplateName>Meet & Greet</TemplateName>
          </TemplateNameViewContainer>

          <FileNameGetTextBox
            name="fileName"
            onChange={(event) =>
              setUploadImage({ ...uploadImage, fileName: event?.target?.value })
            }
            value={uploadImage?.fileName}
            placeholder="Enter File Name"
          />
          <FileNameSubmitButton onClick={handelSubmit}>
            Submit
          </FileNameSubmitButton>
        </FileNameContainer>
      </CommonFormModal>

      {/* preview image and video */}
      <PreviewModal
        title={selectedSubProperty?.value?.name || "File Name"}
        open={openPreview}
        isVideo={
          selectedSubProperty?.actionClass ===
          ACTION_CLASSES.VIDEO_UPLOAD_CONATINER
        }
        isThreeD={selectedSubProperty?.value?.fileType === "3D"}
        closeHanler={() => setOpenPreView(false)}
        renderPath={
          selectedSubProperty?.value?.fileType === "3D"
            ? selectedSubProperty?.value?.assetUrl
            : selectedSubProperty?.value?.previewUrl
        }
      />
    </>
  );
}
