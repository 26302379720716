import React from "react";
import { Button } from "@mui/material";

export const CustomButton = (props) => {
  const {
    label,
    className,
    onClick,
    type,
    color,
    startIcon,
    endIcon,
    variant,
    isViewMode,
    style,
    disableElevation = false,
  } = props;

  return (
    <>
      <Button
        variant={variant}
        className={className}
        onClick={onClick}
        type={type}
        sx={{ ...style }}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={isViewMode}
        disableElevation={disableElevation}
      >
        {label}
      </Button>
    </>
  );
};
