import { Box } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import {
  ShaderContainer,
  templateCreationStyles,
} from "../../../../styles/templateCreationTools";
import {
  AssetsImageContainer,
  aviodableImageContainer,
  aviodableParentContainerStyle,
  collectiableTextStyle,
  CollectionImageSections,
  instructionButtonPosition,
  instructionButtonStyle,
  instructionCollectableStyle,
  instructionCommonTextStyle,
  InstructionContainer,
  InstSectionText,
  meetAndGreetEndScreenStyle,
  meetAndGreetReloadIconStyle,
  ShowText,
} from "../../../../styles/renderMainscreenComponents";
import Drop from "../../../shared/Drop";
import Preloader_3 from "../../../../assets/icons/Preloader_3.gif";
import defaultLogo from "../../../../assets/icons/defaultLogo.png";
import poweredbyXtendrDefault from "../../../../assets/icons/PoweredbyXtendrDefault.png";
import defaultAudio from "../../../../assets/icons/defaultAudio.png";
import { centerStyle } from "../../../../styles/commonStyles";
import {
  subPropertyImageKey,
  subPropertycolorKey,
} from "../../../../constants/renderUiMatchKeys";
import RandomImagePlacer from "../../../../utils/RandonImagePlacer";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../utils/getDimensionalRatio";
import { ImagePreviewer } from "../../../shared/ImagePreviewer";
import endScreenReload from "../../../../assets/icons/endScreenReload.png";
import VideoComponent from "../../../VideoComponent";
import {
  ACTION_CLASSES,
  PREVIEW_ACTION_CLASSES,
} from "../../../../constants/renderUiMatchActionClass";
import { SUB_PROPERTY_TYPES } from "../../../../constants/renderUiMatchTypes";
import { reloadButtonIcon, shareButtonIcon } from "../../../../constants/icons";
import { ModelImageRenderer } from "../../../shared/3DImageRenderer";
import {
  LeaderBoardPlayerButton,
  LeaderWithPlayerHeading,
  LeaderWithPlayerHeadingContainer,
  LeaderWithPlayerLabel,
  SvgInsideText,
} from "../../../../styles/sharedComponents";
import { generateArray } from "../../../../utils/generateArrayNumbers";

const commonFontStyles = (item) => {
  return {
    color: item?.value?.fontColor || "#ffffff",
    fontFamily: item?.value?.font || "OpenSans-Regular",
    fontStyle: item?.value?.italic ? "italic" : "normal",
    fontWeight: item?.value?.bold ? "bold" : "normal",
    textDecoration: item?.value?.underline ? "underline" : "none",
    textShadow: item?.value?.textShadow ? `${item?.value?.textShadow}` : "none",
    boxSizing: "border-box",
    textAlign: "center",
    overflow: "auto",
    fontSize: "clamp(3.5rem, 2vh, 2rem)",
    width: "100%",
    height: "100%",
  };
};

const leaderBoardFontStyle = (item) => {
  return {
    fontSize: `clamp(${item?.value?.fontSize}rem, 2.4vmin, ${
      item?.value?.fontSize * 2
    }4rem)`,
    color: item?.value?.fontColor || "#ffffff",
    fontFamily: item?.value?.font || "OpenSans-Regular",
    fontStyle: item?.value?.italic ? "italic" : "normal",
    fontWeight: item?.value?.bold ? "bold" : "normal",
    textDecoration: item?.value?.underline ? "underline" : "none",
    textShadow: item?.value?.textShadow ? `${item?.value?.textShadow}` : "none",
    boxSizing: "border-box",
    textAlign: "center",
  };
};

const commonRenderStyles = (props) => {
  const { elements, isSelected, disableOutline = false } = props;
  const getValue = elements?.value;

  return {
    position: "absolute",
    overflow: "hidden",
    boxSizing: "border-box",
    ...centerStyle,
    height: getValue?.size?.height || "50px",
    width: getValue?.size?.width || "50px",
    top: getValue?.position?.y || "50%",
    left: getValue?.position?.x || "50%",
    border: disableOutline
      ? "0"
      : isSelected
      ? "1.07143px dashed #FFFFFF"
      : "0",
    zIndex: 10,
  };
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export default function MainFrameRender({
  inputEl,
  templateDetails,
  selectedScreen,
  selectedProperty,
  selectedSubProperty,
  handleDrop,
  selectedPropertyRenderKeys,
  selectedPropertyLevel,
  selectedType,
  mobileScreenWidth,
  setSelectedScreen,
  retrivedSourceFiles,
  updateRetrivedSourceFiles,
}) {
  const getRenderParents = useRef();

  // handle shader color
  const getShaderData = selectedScreen?.pageSections
    ?.find((item) => item?.actionClass === PREVIEW_ACTION_CLASSES.SHADER_TOGGLE)
    ?.elements?.find(
      (item) => item?.actionClass === ACTION_CLASSES.COLOR_CONTAINER
    )?.value;

  const shaderStyleData = {
    background:
      getShaderData?.gradient ||
      getShaderData?.color ||
      "linear-gradient(180deg, #15A5CF 0%, #4007B5 100%)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    opacity: "20%",
  };

  // meet and greet ending screen
  const mainScreenPreviewData = (elements) => {
    const mainScreenElements = templateDetails?.uiConfig?.screens?.find(
      (screenData) => screenData?.actionClass === ACTION_CLASSES.MAIN_EXPERIENCE
    )?.pageSections;

    let endingScreenData = [];

    elements?.forEach((endElement) => {
      mainScreenElements?.forEach((mainElement) => {
        if (mainElement?.pageSections?.length) {
          const getVideoElement = mainElement?.pageSections?.find(
            (item) =>
              item?.actionClass === PREVIEW_ACTION_CLASSES.VIDEO_CONTAINER
          )?.elements?.[0];

          if (endElement?.key === getVideoElement?.key) {
            const tempVideoData = { ...(mainElement || {}) };
            delete tempVideoData?.pageSections;

            endingScreenData?.push({
              ...tempVideoData,
              elements: [
                {
                  ...getVideoElement,
                  value: {
                    ...getVideoElement?.value,
                    position: endElement?.value?.position,
                    size: endElement?.value?.size,
                  },
                },
              ],
            });
          }
        } else {
          if (endElement?.key === mainElement?.elements?.[0]?.key) {
            endingScreenData?.push({
              ...mainElement,
              elements: [
                {
                  ...mainElement?.elements?.[0],
                  value: {
                    ...mainElement?.elements?.[0]?.value,
                    position: endElement?.value?.position,
                    size: endElement?.value?.size,
                  },
                },
              ],
            });
          }
        }
      });
    });

    return endingScreenData;
  };

  const endScreenMainScreenPreview = () => {
    const commonElements = mainScreenPreviewData(
      selectedScreen?.commonElements?.[0]?.elements
    );

    const typeBasedStyleCheck = [
      SUB_PROPERTY_TYPES.LOGO,
      SUB_PROPERTY_TYPES.FOOTER,
    ];

    const conditionBasedstyle = (type) => {
      let style = {};
      const footerStyle = {
        height: typeBasedStyleCheck?.includes(type)
          ? "-webkit-fill-available"
          : "fit-content",
      };

      style = {
        ...style,
        ...footerStyle,
      };
      return style;
    };

    if (commonElements)
      return (
        <Box sx={{ ...meetAndGreetEndScreenStyle }}>
          {commonElements?.map((item, index) => {
            let getElementData = item?.elements?.[0];
            const renderParentStyle = {
              elements: getElementData,
              isSelected: false,
            };

            return (
              <>
                {item?.actionClass === PREVIEW_ACTION_CLASSES.GLB_PREVIEW ? (
                  <ModelImageRenderer element={{ ...getElementData?.value }} />
                ) : (
                  <Box
                    key={index}
                    sx={{
                      ...commonRenderStyles(renderParentStyle),
                      ...(item?.actionClass ===
                      PREVIEW_ACTION_CLASSES.VIDEO_CONTAINER
                        ? { zIndex: 1 }
                        : {}),
                    }}
                  >
                    {item?.actionClass ===
                    PREVIEW_ACTION_CLASSES.VIDEO_CONTAINER ? (
                      <VideoComponent
                        videoPath={getElementData?.value?.previewUrl || ""}
                      />
                    ) : getElementData?.value?.previewUrl ? (
                      <AssetsImageContainer
                        alt="common"
                        src={getElementData?.value?.previewUrl || ""}
                        style={conditionBasedstyle(getElementData?.value?.type)}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </>
            );
          })}

          <Box sx={{ ...meetAndGreetReloadIconStyle, zIndex: 9 }}>
            <AssetsImageContainer
              src={endScreenReload || defaultLogo}
              alt="reload"
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Box>
      );
  };

  // Treasure hunt common element preview mian screen
  const endScreenMainScreenElementPreview = () => {
    const commonElements = mainScreenPreviewData(
      selectedScreen?.commonElements?.[0]?.elements
    );

    const typeBasedStyleCheck = [
      SUB_PROPERTY_TYPES.LOGO,
      SUB_PROPERTY_TYPES.FOOTER,
    ];

    const conditionBasedstyle = (type) => {
      let style = {};
      const footerStyle = {
        height: typeBasedStyleCheck?.includes(type)
          ? "-webkit-fill-available"
          : "fit-content",
      };

      return { ...style, ...footerStyle };
    };

    const parentConditionBasedstyle = (elementType, elementWidth) => {
      const checkConditions = SUB_PROPERTY_TYPES.MAIN_LOGO === elementType;
      if (checkConditions) {
        let style = {};
        const logoStyle = {
          maxWidth:
            SUB_PROPERTY_TYPES.MAIN_LOGO === elementType
              ? elementWidth
              : "fit-content",
          justifyContent:
            SUB_PROPERTY_TYPES.MAIN_LOGO === elementType ? "start" : "cenetr",
        };
        style = { ...logoStyle };

        return style;
      } else return {};
    };

    if (commonElements)
      return (
        <>
          {commonElements?.map((item, index) => {
            let getElementData = item?.elements?.[0];
            const renderParentStyle = {
              elements: getElementData,
              isSelected: false,
            };

            return (
              <Box
                key={index}
                sx={{
                  ...commonRenderStyles(renderParentStyle),
                  ...parentConditionBasedstyle(
                    getElementData?.value?.type,
                    getElementData?.value?.size?.width
                  ),
                }}
              >
                <AssetsImageContainer
                  alt="common"
                  src={
                    getElementData?.value?.previewUrl || poweredbyXtendrDefault
                  }
                  style={conditionBasedstyle(getElementData?.value?.type)}
                />
              </Box>
            );
          })}
        </>
      );
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };
  //Responsive handing  data gathering ending

  const renderParentComponentsUI = () => {
    const checkBackground = selectedScreen?.pageSections?.some(
      (item) => item?.actionClass === ACTION_CLASSES.BACKGROUND
    );
    let getBackgroundData = [];

    if (checkBackground) {
      getBackgroundData = selectedScreen?.pageSections?.find(
        (item) => item?.actionClass === ACTION_CLASSES.BACKGROUND
      )?.elements;
    } else {
      getBackgroundData = [
        {
          actionClass: "ColorContainer",
          className: "Color",
          key: "color",
          name: "Color",
          value: {
            color: "#000000",
            // border: "1",
          },
        },
      ];
    }

    const treasureHuntInstructionRenderComponent = () => {
      let collectiableImageData = [];
      let getCollectiableData = selectedScreen?.pageSections?.find(
        (item) => item?.actionClass === PREVIEW_ACTION_CLASSES.TREASURE_PREVIEW
      );
      getCollectiableData?.pageSections?.forEach((pag) => {
        pag?.elements?.forEach((ele) => {
          if (ele?.value?.type === SUB_PROPERTY_TYPES.COLLECTIABLE) {
            collectiableImageData = [pag];
          }
        });
      });

      let aviodableImageData = [];
      let getAviodableData = selectedScreen?.pageSections?.find(
        (item) => item?.actionClass === PREVIEW_ACTION_CLASSES.TREASURE_PREVIEW
      );
      getAviodableData?.pageSections?.filter((pag) => {
        pag?.elements?.forEach((ele) => {
          if (ele?.value?.type === SUB_PROPERTY_TYPES.AVOIDABLE) {
            aviodableImageData = [pag];
          }
        });
      });

      const handleMainOne = () => {
        const instructionData = selectedScreen?.pageSections?.find(
          (item) =>
            item?.actionClass === PREVIEW_ACTION_CLASSES.INSTRUCTION_PREVIEW
        );

        const getBackgroundData = instructionData?.pageSections?.find(
          (i) => i?.actionClass === ACTION_CLASSES.BACKGROUND
        )?.elements;

        const getTextAndButtonData = instructionData?.pageSections?.find(
          (i) => i?.key === "textAndButton"
        )?.elements;

        const backgrounColor = getBackgroundData?.find(
          (item) => item?.key === subPropertycolorKey
        )?.value;

        const getBackground = `url(${
          getBackgroundData?.find((item) => item?.key === subPropertyImageKey)
            ?.value?.previewUrl || ""
        }), ${
          backgrounColor?.gradient ||
          backgrounColor?.color ||
          "linear-gradient(180deg, #15A5CF 0%, #4007B5 100%)"
        }`;

        const fontData = getTextAndButtonData?.find(
          (item) => item?.key === "text"
        )?.value;

        const palyButtonData = getTextAndButtonData?.find(
          (item) => item?.key === "button"
        )?.value;

        const instructionsRenderHandle = () => {
          return (
            <InstructionContainer
              getBackground={getBackground}
              instructionData={instructionData}
            >
              <InstSectionText id="inrText" fontData={fontData}>
                Walk around and collect the below before the times runs out!
              </InstSectionText>

              <CollectionImageSections>
                {collectiableImageData?.map((item) => {
                  return item?.elements?.map((ele, index) => {
                    return (
                      <Box sx={instructionCollectableStyle} key={index}>
                        <model-viewer
                          style={{
                            width: getWidthRatio(47.65),
                            height: getHeightRatio(41.6),
                          }}
                          src={ele?.value?.assetUrl}
                          alt=""
                        />
                        <Box sx={collectiableTextStyle}>
                          {ele?.value?.value && `${ele?.value?.value}`}
                        </Box>
                      </Box>
                    );
                  });
                })}
              </CollectionImageSections>

              <Box sx={instructionCommonTextStyle(fontData)}>
                Avoid the below obstacle
              </Box>

              <Box sx={aviodableParentContainerStyle}>
                {aviodableImageData?.map((item) => {
                  return item?.elements?.map((ele, index) => {
                    return (
                      <Box sx={aviodableImageContainer} key={index}>
                        <model-viewer
                          style={{
                            width: getWidthRatio(47.65),
                            height: getHeightRatio(41.6),
                          }}
                          src={ele?.value?.assetUrl}
                          alt=""
                        />
                        <Box sx={collectiableTextStyle}>
                          {ele?.value?.value && `${ele?.value?.value}`}
                        </Box>
                      </Box>
                    );
                  });
                })}
              </Box>

              <Box
                sx={{
                  ...commonRenderStyles({
                    elements: {
                      value: instructionButtonPosition,
                    },
                    isSelected: selectedProperty?.key === instructionData?.key,
                  }),
                }}
              >
                <ImagePreviewer
                  selectedProperty={selectedProperty}
                  selectedSubProperty={selectedSubProperty}
                  isLibraryUsage={false}
                  colors={{ value: { color: palyButtonData?.color } }}
                  assetKey={palyButtonData?.url || ""}
                  renderId={`mobilePreviewer_${palyButtonData?.type}`}
                  retrivedSourceFiles={retrivedSourceFiles}
                  updateRetrivedSourceFiles={updateRetrivedSourceFiles}
                  svgInsideText={
                    palyButtonData?.buttonName || palyButtonData?.type || "Play"
                  }
                  style={instructionButtonStyle}
                />
              </Box>
            </InstructionContainer>
          );
        };

        return (
          <>
            {selectedProperty?.actionClass ===
            PREVIEW_ACTION_CLASSES.INSTRUCTION_PREVIEW ? (
              instructionsRenderHandle()
            ) : (
              <></>
            )}
          </>
        );
      };

      return <>{handleMainOne()}</>;
    };

    const learderBoardStaticRender = (props) => {
      const { componentData } = props;
      const getLearderBoardCommonElement = componentData?.commonElements;
      const notViewButtons = [
        PREVIEW_ACTION_CLASSES.LEADER_BOARD_PLAYERS,
      ]?.includes(selectedProperty.actionClass);

      const renderLeaderBoardStaticCommonElements = () => {
        return (
          <>
            {getLearderBoardCommonElement?.map((item, index) => {
              const renderParentStyle = {
                elements: item,
                isSelected:
                  selectedProperty?.key === getLearderBoardCommonElement?.key,
              };

              return (
                <Box
                  key={index}
                  sx={{ ...commonRenderStyles(renderParentStyle) }}
                >
                  <Box
                    id={"inrtext"}
                    sx={{ ...leaderBoardFontStyle({ ...item }) }}
                  >
                    {item?.value?.value}
                  </Box>
                </Box>
              );
            })}
          </>
        );
      };

      // leader board static svg handle
      const elementData = componentData?.elements?.find(
        (item) => item?.actionClass === ACTION_CLASSES.STYLE_CONTAINER
      );
      const getColorData = componentData?.elements?.find(
        (data) =>
          data?.actionClass === ACTION_CLASSES.STYLE_WITH_COLOR_CONTAINER
      );
      const elementValue = elementData?.value;

      const renderParentStyle = {
        elements: elementData,
        isSelected: selectedProperty?.key === componentData?.key,
      };

      const renderLeaderBoardStaticSvg = () => {
        return elementValue?.doesQueryResultWithFile ? (
          <Box sx={{ ...commonRenderStyles(renderParentStyle) }}>
            <ImagePreviewer
              selectedProperty={selectedProperty}
              selectedSubProperty={selectedSubProperty}
              isLibraryUsage={false}
              colors={getColorData}
              assetKey={elementData?.value?.url || ""}
              renderId={`mobilePreviewer_${elementData?.value?.url}`}
              retrivedSourceFiles={retrivedSourceFiles}
              updateRetrivedSourceFiles={updateRetrivedSourceFiles}
            />
          </Box>
        ) : (
          <></>
        );
      };

      return notViewButtons ? (
        <></>
      ) : (
        <>
          {renderLeaderBoardStaticCommonElements()}
          {renderLeaderBoardStaticSvg()}
        </>
      );
    };

    const getAllElementsImages = () => {
      const treasureIslandComponentData = selectedScreen?.pageSections?.find(
        (item) => item?.actionClass === PREVIEW_ACTION_CLASSES.TREASURE_PREVIEW
      );

      let arr = [];
      treasureIslandComponentData?.pageSections?.map((item) => {
        return item?.elements?.map((ele) => {
          if (ele?.value?.previewUrl) {
            arr.push(ele);
          }
        });
      });

      return arr?.filter((i) => i?.value?.type !== SUB_PROPERTY_TYPES.DEFAULT);
    };

    const TreasureIslandImagePreviewRenderComponents = (props) => {
      const { componentData } = props;
      let generalItems = {};

      componentData?.pageSections?.forEach((pag) => {
        pag?.elements?.forEach((ele) => {
          if (ele?.value?.type === SUB_PROPERTY_TYPES.DEFAULT) {
            generalItems = ele;
          }
        });
      });

      const renderParentStyle = {
        elements: generalItems,
        isSelected: false,
      };

      return (
        <>
          <Box
            sx={{
              ...commonRenderStyles(renderParentStyle),
              zIndex: 5,
              // ...(getShaderData?.isApplied && generalItems?.value
              //   ? shaderStyleData
              //   : {}),
            }}
          >
            <ModelImageRenderer
              element={generalItems?.value}
              getParentSizes={getRenderParents}
            />
            {[...Array(2).keys()]?.map(() => {
              return getAllElementsImages()?.map((img, index) => {
                return (
                  <RandomImagePlacer
                    mobileScreenWidth={mobileScreenWidth}
                    alt={"image"}
                    src={img?.value?.assetUrl}
                    key={index}
                  />
                );
              });
            })}
          </Box>

          {componentData?.commonElements?.length &&
          generalItems?.value?.assetUrl ? (
            <>
              {componentData?.commonElements?.map((elements, index) => {
                const getRenderElementData = elements?.elements?.[0];
                const renderParentStyle = {
                  elements: getRenderElementData,
                  isSelected: false,
                };

                return getRenderElementData?.value?.previewUrl ? (
                  <Box
                    sx={{
                      ...commonRenderStyles(renderParentStyle),
                      zIndex: 100,
                    }}
                    key={index}
                  >
                    <AssetsImageContainer
                      alt={getRenderElementData?.value?.type || "loader"}
                      src={
                        getRenderElementData?.value?.previewUrl || Preloader_3
                      }
                    />
                  </Box>
                ) : (
                  <></>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </>
      );
    };

    //handle common button preview render
    const buttonPreviewRenderComponents = (props) => {
      const { componentData } = props;

      const elementData = componentData?.elements?.find(
        (item) =>
          item?.actionClass === ACTION_CLASSES.BUTTON_STYLE_WITH_CONTAINER
      );
      const getColorData = componentData?.elements?.find(
        (data) =>
          data?.actionClass === ACTION_CLASSES.STYLE_WITH_COLOR_CONTAINER
      );
      const submitButtonIconsStyle = {
        svg: {
          rect: {
            fill: getColorData?.value?.color,
          },
        },
      };

      const checkCenterThreeButtonAlign =
        componentData?.value?.buttons?.length === 3;
      const checkCenterTwoButtonAlign =
        componentData?.value?.buttons?.length === 2;
      const addCenterButtonStyles = {
        margin: "auto",
        position: "sticky",
        left: "0",
      };
      const checkSubmitType = componentData?.value?.buttons?.some(
        (item) => item?.type === SUB_PROPERTY_TYPES.SUBMIT
      );

      const notViewButtons = [
        PREVIEW_ACTION_CLASSES.LEADER_BOARD_PLAYERS,
      ]?.includes(selectedProperty.actionClass);

      return notViewButtons ? (
        <></>
      ) : (
        <>
          {componentData?.value?.buttons?.map((item, index) => {
            const renderParentStyle = {
              elements: { value: item },
              isSelected: selectedProperty?.key === componentData?.key,
            };

            return (
              <Box
                key={index}
                sx={{
                  ...commonRenderStyles(renderParentStyle),
                  ...(checkCenterThreeButtonAlign
                    ? !checkSubmitType && index === 2
                      ? addCenterButtonStyles
                      : {}
                    : {}),
                  ...(checkCenterTwoButtonAlign
                    ? checkSubmitType && index === 1
                      ? addCenterButtonStyles
                      : {}
                    : {}),
                }}
              >
                {item?.type === SUB_PROPERTY_TYPES.SUBMIT &&
                elementData?.value?.url ? (
                  <Box sx={submitButtonIconsStyle}>{reloadButtonIcon}</Box>
                ) : (
                  <></>
                )}

                <ImagePreviewer
                  selectedProperty={selectedProperty}
                  selectedSubProperty={selectedSubProperty}
                  isLibraryUsage={false}
                  colors={getColorData}
                  assetKey={elementData?.value?.url || ""}
                  renderId={`mobilePreviewer_${item?.type}`}
                  retrivedSourceFiles={retrivedSourceFiles}
                  updateRetrivedSourceFiles={updateRetrivedSourceFiles}
                  svgInsideText={item?.name || item?.type || "button"}
                  svgInsideTextStyle={item}
                />

                {item?.type === SUB_PROPERTY_TYPES.SUBMIT &&
                elementData?.value?.url ? (
                  <Box sx={submitButtonIconsStyle}>{shareButtonIcon}</Box>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </>
      );
    };

    //handle svg preview with leader board players
    const svgPreviewWithLeaderPlayers = (props) => {
      const { componentData } = props;
      let getPageSectionStyle = {};
      let getPageSectionColor = {};
      let leaderPlayerCount = {};
      let leaderPlayerHeading = componentData?.name || "";
      let leaderPlayerLabel = "";
      let buttonStyle = {};
      let textStyle = {};

      //handle get button color
      if (
        selectedScreen?.pageSections?.some(
          (item) => item?.actionClass === PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
        )
      ) {
        const endScreenButtonColor = selectedScreen?.pageSections
          ?.find(
            (item) =>
              item?.actionClass === PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW
          )
          ?.elements?.find(
            (item) =>
              item?.actionClass === ACTION_CLASSES.STYLE_WITH_COLOR_CONTAINER
          )?.value?.color;
        buttonStyle = {
          ".changeFillColor": { fill: endScreenButtonColor || "white" },
        };
      }

      //handle get text color
      if (
        selectedScreen?.pageSections?.some(
          (item) =>
            item?.actionClass === PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC
        )
      ) {
        const endScreenTextstyle = selectedScreen?.pageSections?.find(
          (item) =>
            item?.actionClass === PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC
        )?.commonElements?.[0]?.value;
        textStyle = {
          fontFamily: endScreenTextstyle?.font,
          color: endScreenTextstyle?.fontColor,
        };
      }

      if (componentData?.pageSections?.length) {
        componentData?.pageSections?.forEach((pag) => {
          pag?.elements?.forEach((ele) => {
            if (ele?.actionClass === ACTION_CLASSES.STYLE_CONTAINER) {
              getPageSectionStyle = ele;
            }
            if (
              ele?.actionClass === ACTION_CLASSES.STYLE_WITH_COLOR_CONTAINER
            ) {
              getPageSectionColor = ele;
            }
            if (ele?.actionClass === ACTION_CLASSES.NUMBER_INPUT_CONTAINER) {
              leaderPlayerCount = ele;
              leaderPlayerLabel = pag?.name;
            }
          });
        });
      }

      const getAssetKey = () => getPageSectionStyle?.value?.url;

      //handle players count text
      const svgInsideTextHandle = () => {
        const getPlayerCountArray = generateArray(
          leaderPlayerCount?.value?.value
        );

        return getPlayerCountArray?.map((item, index) => {
          return (
            <LeaderWithPlayerLabel key={index} style={textStyle}>
              {item} . {leaderPlayerLabel} {item}
            </LeaderWithPlayerLabel>
          );
        });
      };

      const elementData = getPageSectionStyle;
      const getColorData = getPageSectionColor;
      const renderParentStyle = {
        elements: elementData,
        isSelected: selectedProperty?.key === componentData?.key,
      };
      const isVisible =
        PREVIEW_ACTION_CLASSES.LEADER_BOARD_PLAYERS ===
        selectedProperty.actionClass;

      return isVisible ? (
        <>
          {elementData?.value?.doesQueryResultWithFile ? (
            <Box
              sx={{ ...commonRenderStyles(renderParentStyle), ...centerStyle }}
            >
              <ImagePreviewer
                selectedProperty={selectedProperty}
                selectedSubProperty={selectedSubProperty}
                isLibraryUsage={false}
                colors={getColorData}
                assetKey={getAssetKey() || ""}
                renderId={`mobilePreviewer_${getAssetKey()}`}
                retrivedSourceFiles={retrivedSourceFiles}
                updateRetrivedSourceFiles={updateRetrivedSourceFiles}
                SvgInsideTextComponent={
                  <LeaderWithPlayerHeadingContainer>
                    <LeaderWithPlayerHeading style={textStyle}>
                      {leaderPlayerHeading}
                    </LeaderWithPlayerHeading>
                    {svgInsideTextHandle()}
                  </LeaderWithPlayerHeadingContainer>
                }
              />
            </Box>
          ) : (
            <></>
          )}

          <LeaderBoardPlayerButton>
            <Box sx={buttonStyle}>{reloadButtonIcon}</Box>
            <Box sx={buttonStyle}>{shareButtonIcon}</Box>
          </LeaderBoardPlayerButton>
        </>
      ) : (
        <></>
      );
    };

    // handle common image preview render
    const imagePreviewRenderComponents = (props) => {
      const { componentData, disabledOutLine = false } = props;

      const typeBasedStyleCheck = [
        SUB_PROPERTY_TYPES.LOGO,
        SUB_PROPERTY_TYPES.FOOTER,
        SUB_PROPERTY_TYPES.PROMPT,
      ];
      const conditionBasedstyle = (elementType) => {
        let style = {};
        const footerStyle = {
          height: typeBasedStyleCheck?.includes(elementType)
            ? "-webkit-fill-available"
            : "fit-content",
        };
        style = {
          ...style,
          ...footerStyle,
        };
        if(props.componentData.name === 'Powered By Asset'){
          style = {
            ...style,
            height: 'auto',
            width: '100%',
          }
        }
        return style;
      };

      const parentConditionBasedstyle = (elementType, elementWidth) => {
        const checkConditions = SUB_PROPERTY_TYPES.MAIN_LOGO === elementType;
        if (checkConditions) {
          let style = {};
          const logoStyle = {
            maxWidth:
              SUB_PROPERTY_TYPES.MAIN_LOGO === elementType
                ? elementWidth
                : "fit-content",
            justifyContent: "start",
          };
          style = { ...logoStyle };

          return style;
        } else return {};
      };

      //handle prompt style and text
      const checkPrompt =
        selectedProperty?.actionClass ===
          ACTION_CLASSES.MULTI_SECTION_CONTAINER ||
        selectedProperty?.elements?.[0]?.value?.type ===
          SUB_PROPERTY_TYPES.PROMPT;

      const svgInsideTextStyle = () => {
        const svgStyle =
          checkPrompt &&
          selectedPropertyLevel?.actionClass ===
            ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
            ? {
                ...(selectedPropertyLevel?.elements?.find(
                  (ele) =>
                    ele?.actionClass === ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
                )?.value || {}),
                width: "100%",
                height: "100%",
              }
            : {
                ...(selectedProperty?.elements?.find(
                  (ele) =>
                    ele?.actionClass === ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
                )?.value || {}),
                width: "50%",
                height: "50%",
              } || {};

        return svgStyle;
      };

      const renderCombinedElements = componentData?.pageSections?.length
        ? componentData?.pageSections?.find(
            (pag) => pag?.actionClass === ACTION_CLASSES.STYLE_CONTAINER
          )?.elements
        : componentData?.elements;

      //handle prompt
      const promptCoachMarkerElement = componentData?.pageSections?.find(
        (pag) =>
          pag?.elements?.find(
            (level) =>
              level?.actionClass !== ACTION_CLASSES.TEXT_INPUT_PREVIEW_CONTAINER
          )
      )?.elements;
      const renderImagePreviewElements = promptCoachMarkerElement?.length
        ? promptCoachMarkerElement
        : renderCombinedElements;

      const disabledOutLineTypes = [SUB_PROPERTY_TYPES.AUDIO];

      return (
        <>
          {renderImagePreviewElements?.map((elements, index) => {
            const elementValue = elements?.value;
            const checkIsSelected =
              selectedSubProperty?.value?.type === elements?.value?.type;
            const checkDisabledOutLine = disabledOutLineTypes?.includes(
              elementValue?.fileType
            )
              ? false
              : disabledOutLine
              ? false
              : checkIsSelected;

            const previewUrl =
              elementValue?.fileType === SUB_PROPERTY_TYPES.AUDIO
                ? defaultAudio
                : elementValue?.previewUrl;
            const renderParentStyle = {
              elements,
              isSelected: checkDisabledOutLine
                ? checkDisabledOutLine
                : componentData?.key === selectedProperty?.key,
            };
            const imagePreviewValueBased = disabledOutLineTypes?.includes(
              elementValue?.fileType
            )
              ? elementValue?.applyAudio
              : true;
            const notViewActionClass = [ACTION_CLASSES.ANIMATION_SELECTOR];

            const someActionClassNotViewImage = !notViewActionClass?.includes(
              elements?.actionClass
            );

            const checkPrompt =
              selectedProperty?.actionClass ===
                ACTION_CLASSES.MULTI_SECTION_CONTAINER ||
              selectedProperty?.elements?.[0]?.value?.type ===
                SUB_PROPERTY_TYPES.PROMPT;

            return imagePreviewValueBased && someActionClassNotViewImage ? (
              <>
                {checkPrompt ? (
                  <>
                    {false ? (
                      <></>
                    ) : (
                      <Box
                        key={index}
                        sx={{
                          ...commonRenderStyles(renderParentStyle),
                        }}
                      >
                        {selectedSubProperty?.actionClass ===
                          "TextInputWithPreview" && elementValue?.value ? (
                          <></>
                        ) : elementValue?.previewUrl ? (
                          <AssetsImageContainer
                            alt={elementValue?.type || "imagePreview"}
                            src={previewUrl || Preloader_3}
                            style={conditionBasedstyle(elementValue?.type)}
                          />
                        ) : (
                          <></>
                        )}

                        {selectedSubProperty?.actionClass ===
                          "TextInputWithPreview" && elementValue?.value ? (
                          <SvgInsideText textStyles={svgInsideTextStyle()}>
                            {elementValue?.value || ""}
                          </SvgInsideText>
                        ) : (
                          <></>
                        )}
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    {elementValue?.type === SUB_PROPERTY_TYPES.PROMPT ? (
                      selectedSubProperty?.value?.type ===
                      SUB_PROPERTY_TYPES.PROMPT ? (
                        elementValue?.value ? (
                          <></>
                        ) : (
                          <Box
                            key={index}
                            sx={{
                              ...commonRenderStyles(renderParentStyle),
                              zIndex: 9999,
                            }}
                          >
                            {elementValue?.previewUrl ? (
                              <AssetsImageContainer
                                alt={elementValue?.type || "imagePreview"}
                                src={previewUrl || Preloader_3}
                                style={{
                                  ...conditionBasedstyle(elementValue?.type),
                                  zIndex: 9999,
                                }}
                              />
                            ) : (
                              <></>
                            )}

                            {elementValue?.value && elementValue?.url ? (
                              <ShowText>{elementValue?.value || "0"}</ShowText>
                            ) : (
                              <></>
                            )}
                          </Box>
                        )
                      ) : (
                        <></>
                      )
                    ) : (
                      <Box
                        key={index}
                        sx={{
                          ...commonRenderStyles(renderParentStyle),
                          ...parentConditionBasedstyle(
                            elementValue?.type,
                            elementValue?.size?.width
                          ),
                        }}
                      >
                        {elementValue?.previewUrl ? (
                          <AssetsImageContainer
                            alt={elementValue?.type || "imagePreview"}
                            src={previewUrl || Preloader_3}
                            style={conditionBasedstyle(elementValue?.type)}
                          />
                        ) : (
                          <></>
                        )}

                        {elementValue?.value && elementValue?.url ? (
                          <ShowText>{elementValue?.value || "0"}</ShowText>
                        ) : (
                          <></>
                        )}
                      </Box>
                    )}
                  </>
                )}
              </>
            ) : (
              <></>
            );
          })}

          {componentData?.commonElements?.length ? (
            <>
              {componentData?.commonElements?.map((elements, index) => {
                const getRenderElementData = elements?.elements?.[0];
                const renderParentStyle = {
                  elements: getRenderElementData,
                  isSelected: false,
                };
                return (
                  <Box
                    sx={{
                      ...commonRenderStyles(renderParentStyle),
                      zIndex: 999,
                    }}
                    key={index}
                  >
                    <AssetsImageContainer
                      alt={getRenderElementData?.value?.type || "loader"}
                      src={
                        getRenderElementData?.value?.previewUrl || Preloader_3
                      }
                    />
                  </Box>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </>
      );
    };

    const glbPreviewRenderComponents = (props) => {
      const { componentData } = props;

      const renderImagePreviewElements = componentData?.pageSections?.length
        ? componentData?.pageSections?.find(
            (pag) => pag?.actionClass === ACTION_CLASSES.STYLE_CONTAINER
          )?.elements
        : componentData?.elements;

      return (
        <>
          {renderImagePreviewElements?.map((elements, index) => {
            const allowAvatarOnly =
              elements?.actionClass !== "AnimationSelector";

            const renderParentStyle = {
              elements,
              isSelected: false,
            };

            return (
              allowAvatarOnly && (
                <Box
                  key={index}
                  sx={{
                    ...commonRenderStyles(renderParentStyle),
                    zIndex: 5,
                    // ...(getShaderData?.isApplied ? shaderStyleData : {}),
                  }}
                >
                  <ModelImageRenderer
                    element={elements?.value}
                    configStyle={true}
                  />
                </Box>
              )
            );
          })}
        </>
      );
    };

    // handle common svg preview render
    const svgPreviewRenderComponents = (props) => {
      const { componentData, disabledOutLine = false } = props;
      let getPageSectionStyle = {};
      let getPageSectionColor = {};

      if (componentData?.pageSections?.length) {
        componentData?.pageSections?.forEach((pag) => {
          pag?.elements?.forEach((ele) => {
            if (ele?.actionClcaseass === ACTION_CLASSES.STYLE_CONTAINER) {
              getPageSectionStyle = ele;
            }
            if (
              ele?.actionClass === ACTION_CLASSES.STYLE_WITH_COLOR_CONTAINER
            ) {
              getPageSectionColor = ele;
            }
          });
        });
      }

      const getAssetKey = (elementData) => {
        return componentData?.pageSections?.length
          ? getPageSectionStyle?.value?.url
          : elementData?.value?.url;
      };

      const elementData = componentData?.pageSections?.length
        ? getPageSectionStyle
        : componentData?.elements?.find(
            (item) =>
              item?.actionClass === ACTION_CLASSES.STYLE_CONTAINER ||
              item?.actionClass === ACTION_CLASSES.TIMER_CONTAINER
          );
      const getColorData = componentData?.pageSections?.length
        ? getPageSectionColor
        : componentData?.elements?.find(
            (data) =>
              data?.actionClass === ACTION_CLASSES.STYLE_WITH_COLOR_CONTAINER
          );
      const elementValue = elementData?.value;
      const checkDisabledOutLine = disabledOutLine
        ? false
        : selectedProperty?.key === componentData?.key;
      const renderParentStyle = {
        elements: elementData,
        isSelected: checkDisabledOutLine,
      };

      const svgInsideTextHandle = () => {
        const svgText =
          selectedProperty?.elements?.find(
            (ele) => ele?.actionClass === ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
          )?.value?.value || "";

        return svgText;
      };

      const svgInsideTextStyle = () => {
        const svgStyle =
          {
            ...(selectedProperty?.elements?.find(
              (ele) =>
                ele?.actionClass === ACTION_CLASSES.TEXTINPUT_WITH_PREVIEW
            )?.value || {}),
            width: "50%",
            height: "50%",
          } || {};

        return svgStyle;
      };

      const renderTypedBasedPreviews = () => {
        if (elementValue?.doesQueryResultWithFile) {
          return (
            <ImagePreviewer
              selectedProperty={selectedProperty}
              selectedSubProperty={selectedSubProperty}
              isLibraryUsage={false}
              colors={getColorData}
              assetKey={getAssetKey(elementData) || ""}
              renderId={`mobilePreviewer_${getAssetKey(elementData)}`}
              retrivedSourceFiles={retrivedSourceFiles}
              updateRetrivedSourceFiles={updateRetrivedSourceFiles}
              svgInsideText={svgInsideTextHandle()}
              svgInsideTextStyle={svgInsideTextStyle()}
            />
          );
        }
      };

      return (
        <Box sx={{ ...commonRenderStyles(renderParentStyle) }}>
          {renderTypedBasedPreviews()}

          {elementValue?.value && elementValue?.url ? (
            <ShowText>{elementValue?.value || "0"}</ShowText>
          ) : (
            <></>
          )}

          {componentData?.commonElements?.length ? (
            <>
              {componentData?.commonElements?.map((elements, index) => {
                const getRenderElementData = elements?.elements?.[0];
                const renderParentStyle = {
                  elements: getRenderElementData,
                  isSelected: false,
                };

                return getRenderElementData?.value?.previewUrl ? (
                  <Box
                    sx={{
                      ...commonRenderStyles(renderParentStyle),
                      zIndex: 999,
                    }}
                    key={index}
                  >
                    <AssetsImageContainer
                      alt={getRenderElementData?.value?.type || "loader"}
                      src={
                        getRenderElementData?.value?.previewUrl || Preloader_3
                      }
                    />
                  </Box>
                ) : (
                  <></>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Box>
      );
    };

    // handle common text preview render
    const textPreviewRenderComponents = (props) => {
      const { componentData } = props;
      const renderElements = componentData?.elements;

      return (
        <>
          {renderElements?.map((elements, index) => {
            const renderParentStyle = {
              elements,
              isSelected: selectedSubProperty?.key === elements?.key,
            };
            return (
              <Box
                key={index}
                sx={{
                  ...commonRenderStyles(renderParentStyle),
                  wordWrap: "break-word",
                  padding: "2%",
                }}
              >
                <Box sx={commonFontStyles({ ...elements })}>
                  {elements?.value?.value}
                </Box>
              </Box>
            );
          })}
        </>
      );
    };

    // handle voice trigger render
    const voiceTriggersPreviewRender = (props) => {
      const { componentData } = props;
      const elementData = componentData?.pageSections?.[0];
      const renderParentStyle = {
        elements: elementData,
        isSelected: false,
      };

      const checkIsSelected =
        selectedProperty.actionClass ===
        PREVIEW_ACTION_CLASSES.VOICE_TRIGGER_PREVIEW;

      return checkIsSelected ? (
        <Box sx={{ ...commonRenderStyles(renderParentStyle) }}>
          <Box sx={{ ...commonFontStyles(elementData) }}>
            Say "{selectedSubProperty?.value?.value}"
          </Box>
        </Box>
      ) : (
        <></>
      );
    };

    // handle video element render
    const renderVideoComponents = (props) => {
      const { componentData, disabledOutLine = false } = props;

      return (
        <>
          {componentData?.elements?.map((elemetData, index) => {
            const renderParentStyle = {
              elements: elemetData,
              isSelected: !disabledOutLine,
            };

            return (
              <Box
                key={index}
                sx={{ ...commonRenderStyles(renderParentStyle), zIndex: 1 }}
              >
                <VideoComponent
                  videoPath={elemetData?.value?.previewUrl || ""}
                />
              </Box>
            );
          })}
        </>
      );
    };

    //handle main call components
    const renderComponents = (props) => {
      const { renderActionClass } = props;

      switch (renderActionClass) {
        case PREVIEW_ACTION_CLASSES.IMAGE_PREVIEW:
          return imagePreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.SVG_PREVIEW:
          return svgPreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.VIDEO_CONTAINER:
          return renderVideoComponents(props);

        case PREVIEW_ACTION_CLASSES.VOICE_TRIGGER_PREVIEW:
          return voiceTriggersPreviewRender(props);

        case PREVIEW_ACTION_CLASSES.GLB_PREVIEW:
          return glbPreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.LEADER_BOARD_STATIC:
          return learderBoardStaticRender(props);

        case PREVIEW_ACTION_CLASSES.TREASURE_PREVIEW:
          return TreasureIslandImagePreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.INSTRUCTION_PREVIEW:
          return treasureHuntInstructionRenderComponent();

        case ACTION_CLASSES.MULTI_SECTION_CONTAINER:
          return imagePreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.TEXT_PREVIEW:
          return textPreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.BUTTON_PREVIEW:
          return buttonPreviewRenderComponents(props);

        case PREVIEW_ACTION_CLASSES.LEADER_BOARD_PLAYERS:
          return svgPreviewWithLeaderPlayers(props);

        default:
          return <></>;
      }
    };

    // handle voice trigger render
    const handle2dVideo = () => {
      const getVideoPageSectionData = selectedScreen?.pageSections?.find(
        (item) => item?.actionClass === ACTION_CLASSES.VIDEO_CONTAINER
      )?.pageSections;

      return getVideoPageSectionData?.map((item) => {
        const props = {
          componentData: item,
          renderActionClass: item?.actionClass,
          disabledOutLine: true,
        };
        return renderComponents(props);
      });
    };

    // handle other elements render
    const handleOtherElements = () => {
      return (
        <>
          {/* elements render */}
          {selectedScreen?.pageSections?.map((item, index) => {
            const props = {
              componentData: item,
              renderActionClass: item?.actionClass,
              disabledOutLine: false,
            };

            return (
              <React.Fragment key={index}>
                {renderComponents(props)}
              </React.Fragment>
            );
          })}

          {/* common element render */}
          {selectedScreen?.commonElements?.map((item, index) => {
            const props = {
              componentData: item,
              renderActionClass: item?.actionClass,
              disabledOutLine: true,
            };

            if (
              item?.actionClass ===
              PREVIEW_ACTION_CLASSES.MAIN_SCREEN_ELEMENT_PREVIEW
            ) {
              return endScreenMainScreenElementPreview();
            } else if (
              item?.actionClass === PREVIEW_ACTION_CLASSES.MAIN_SCREEN_PREVIEW
            ) {
              return endScreenMainScreenPreview();
            } else {
              return (
                <React.Fragment key={index}>
                  {renderComponents(props)}
                </React.Fragment>
              );
            }
          })}
        </>
      );
    };

    return (
      <Box
        sx={{ ...templateCreationStyles(height, width, getBackgroundData) }}
        ref={inputEl}
      >
        <ShaderContainer
          style={getShaderData?.isApplied ? shaderStyleData : {}}
        ></ShaderContainer>

        <Drop handleDrop={handleDrop}>
          {handleOtherElements()}

          {selectedProperty?.actionClass === ACTION_CLASSES.VIDEO_CONTAINER ? (
            handle2dVideo()
          ) : (
            <></>
          )}
        </Drop>
      </Box>
    );
  };

  const { height, width } = useWindowDimensions();

  return renderParentComponentsUI();
}
