import React, { useRef } from "react";
import {
  PreviewQRCodeContainer,
  PreviewTextBoxWrapper,
  PreviewTitleLabel,
  PreviewUrlCopyButton,
  PreviewUrlTextBox,
  PreviewWrapper,
} from "../../../../styles/previewAndPublish/previewTabStyle";
import { QRCodeCanvas } from "qrcode.react";
import { errorMessage, successMessage } from "../../../../redux/constant";
import { useDispatch } from "react-redux";

export default function PreviewTab(props) {
  const { projectUrl } = props;

  const dispatch = useDispatch();

  const handleCopyText = () => {
    navigator.clipboard
      .writeText(projectUrl || "url")
      .then(() => {
        dispatch(successMessage("Text copied to clipboard!"));
      })
      .catch((err) => {
        dispatch(errorMessage("Failed to copy text"));
      });
  };

  return (
    <PreviewWrapper>
      <PreviewTitleLabel>Copy this link to Preview</PreviewTitleLabel>

      <PreviewTextBoxWrapper>
        <PreviewUrlTextBox value={projectUrl || ""} />
        <PreviewUrlCopyButton onClick={handleCopyText}>
          Copy
        </PreviewUrlCopyButton>
      </PreviewTextBoxWrapper>

      <PreviewTitleLabel style={{ margin: "40px 5px 0px 20px" }}>
        Scan the QR Code to preview
      </PreviewTitleLabel>

      <PreviewQRCodeContainer>
        <QRCodeCanvas
          value={projectUrl || "Project Link"}
        />
      </PreviewQRCodeContainer>
    </PreviewWrapper>
  );
}
