export const drakModeLabel = "Dark Mode";
export const resetLabel = "Reset";
export const libraryHeadLabel = "LIBRARY";
export const minimumHeightErrorMsg =
  "Please Update your Screen Height of minimum 600 pixel";
export const minimumWidthErrorMsg =
  "Please Update your Screen width of minimum 750 pixel";

//Screen menu

//library panel
export const checkMeetAndGreetThreeDProperty = "Avatar";
export const meetAndGreetAnimationLabel = "Animation";

//Main preview


