import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { isEmpty } from "lodash";
import * as React from "react";
import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../../utils/getDimensionalRatio";

const ITEM_HEIGHT = 48;

export default function ContextMenu({ options, actinPayload }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  if (isEmpty(options)) return <></>;
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        sx={{
          color: "white",
          border: "none",
        }}
        onClick={handleClick}
      >
        <MoreVertIcon
          sx={{
            width: `${getWidthRatio(20)}px`,
            height: `${getHeightRatio(20)}px`,
            border: "none",
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: `${getWidthRatio(200)}px`,
            background: "#3C3C3C",
            color: "#FFFFFF",
            boxShadow: "0px 5.342281341552734px 62.77180480957031px 0px rgba(0, 0, 0, 0.25)",
            borderRadius: "0.83475rem",
            padding: '1rem',
            marginTop: '-3rem',
            marginLeft: '-3rem',
          },
        }}
      >
        {options?.map(({ name, action, icon }) => (
          <MenuItem
            style={{
              width: `${getWidthRatio(200)}px`,
              background: "#3C3C3C",
              fontFamily: "OpenSans-Medium",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "1.25213rem",
              display: "flex",
              alignItems: "center",
              letterSpacing: "-0.01em",
              color: "#FFFFFF",
              padding: "1rem",
              display: 'flex',
              marginLeft: '1rem'
            }}
            key={name}
            selected={name === "Delete"}
            onClick={() => {
              action(actinPayload);
              handleClose();
            }}
          >
            <img src={icon} style={{marginRight: '1rem'}}/>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
