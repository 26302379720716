import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { errorMessage } from "../../redux/constant";
import { VoiceTriggerTextField } from "../../styles/templateCreation/libraryPanelRenderer";

export default function MultiPlayerInput(props) {
  const {
    handleChangeSubPropertyDatas,
    selectedProperty,
    selectedSubProperty,
    selectedPropertyLevel,
  } = props;

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    const value = e.target?.value;
    setValue(value);

    if (value > selectedPropertyLevel?.max)
      dispatch(
        errorMessage(`Maximum limit ${selectedPropertyLevel?.max} player`)
      );
    else if (value < selectedPropertyLevel?.min)
      dispatch(
        errorMessage(`Enter Minimum ${selectedPropertyLevel?.min} player`)
      );
    else {
      if (selectedSubProperty?.key === "players") {
        handleChangeSubPropertyDatas({ value });
      } else {
        handleChangeSubPropertyDatas([
          {
            ...selectedSubProperty,
            value: { ...selectedSubProperty?.value, value },
          },
        ]);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode !== 13) {
      return !/[0-9]/.test(e.key) && e.preventDefault();
    }
  };

  useEffect(() => {
    setValue(selectedSubProperty?.value?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <VoiceTriggerTextField
      placeholder="Enter between 2-10"
      value={value || ""}
      type={"text"}
      onChange={handleChange}
      isSelected={true}
      onKeyPress={handleKeyPress}
      // onInput={handleInputLimit}
      style={{
        "&::-webkit-inner-spin-button &::-webkit-outer-spin-button": {
          webkitAppearance: "none",
          margin: 0,
        },
        "&:hover &:focus": {
          outline: "none",
        },
      }}
      isNumberInputOnly={true}
    />
  );
}
