import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import landingPage from "../assets/icons/landingPage.png";
import { getHeightRatio, getWidthRatio } from "../utils/getDimensionalRatio";
import { centerStyle } from "./commonStyles";

export const CompanyLogo = styled(Grid)({
  backgroundColor: "#000000",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "clamp(2rem, 1.5vmin, 4rem)",
  fontWeight: 600,
  color: "#fff",
  fontFamily: "Rubik",
  textAlign: "center",
  position: "sticky !important",
  top: 0,
  left: 0,
  height: "85px",
  boxSizing: "border-box",
  backdropFilter: "blur(2px)",
});
export const ProjectListContainer = styled(Grid)({
  padding: "20px 40px",
  height: "calc(100vh - 85px)",
  width: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundImage: `url(${landingPage})`,
});
export const ProjectView = styled(Box)(() => {
  const x1 = window.innerWidth / 1920;
  const exactHeight = 193 * x1;
  const exactWidth = 201 * x1;

  return {
    height: exactHeight,
    width: exactWidth,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#111",
    cursor: "pointer",
    textAlign: "center",
    overflow: "hidden",
    boxSizing: "border-box",
    margin: "auto",
    background: "#49C4D8",
    border: "2px solid #000000",
    borderRadius: "17px",
  };
});
export const SnackBarContainer = styled(Box)(({ mode }) => {
  return {
    height: getHeightRatio(100),
    width: getWidthRatio(859),
    background: mode === "error" ? "#C32323" : "#047C4A",
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(2rem, 2.4vmin, 4rem)",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    borderRadius: "15px",
  };
});
export const snackBarAlertStyle = (snackBarData) => {
  return {
    minHeight: getHeightRatio(70),
    width: getWidthRatio(680),
    fontFamily: "OpenSans-Medium",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "clamp(1.7rem, 2.4vmin, 4rem)",
    color: "#FFFFFF",
    borderRadius: "15px",
    background: snackBarData.color === "error" ? "#C32323" : "#047C4A",
    svg: {
      path: { fill: "#FFFFFF" },
      width: getWidthRatio(34),
      height: getHeightRatio(33),
      margin: `0px ${getWidthRatio(20)}px`,
    },
    ...centerStyle,
  };
};
