import React, { useRef } from "react";
import { FormControl } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  CustomUploadFileInputHolder,
  InputField,
} from "../../styles/sharedComponents";
import { getHeightRatio, getWidthRatio } from "../../utils/getDimensionalRatio";

export default function CustomImageUpload({
  name,
  label,
  error,
  url = "",
  touched,
  value,
  style,
  onChange,
  isViewMode,
  accept,
  required,
  errors,
  disabled,
  size,
  uploadField = false,
  multiple = false,
}) {
  const myRefname = useRef(null);

  const handleClick = (e) => {
    myRefname.current.click();
  };

  const onImageChange = (event) => {
    if (event?.target?.files[0]?.size > 1000000) {
      // return dispatch(errorMsg("Size should be less than 1MB"));
    }
    if (event?.target?.files && event?.target?.files?.[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        uploadField
          ? onChange(event?.target?.files?.[0])
          : onChange(reader?.result);
      };
      reader.readAsDataURL(event?.target?.files?.[0]);
    }
  };

  return (
    <FormControl error={touched?.[name] && errors?.[name]} fullWidth>
      <InputField
        ref={myRefname}
        name={name}
        type={"file"}
        error={touched?.[name] && errors?.[name]}
        onChange={(event) =>
          multiple ? onChange(event?.target?.files) : onImageChange(event)
        }
        label={label}
        disabled={disabled || isViewMode}
        accept={accept}
        required={required ? true : false}
        multiple={multiple}
      />
      <CustomUploadFileInputHolder
        onClick={(e) => handleClick(e)}
        error={touched?.[name] && errors?.[name]}
        style={style}
      >
        <AddIcon
          style={{ height: getHeightRatio(20), width: getWidthRatio(20) }}
        />
      </CustomUploadFileInputHolder>
    </FormControl>
  );
}
