const LibraryUIClass = {
  COLOR_PICKER: "colorPicker",
};

export const libraryRenderList = {
  ColorContainer: [LibraryUIClass.COLOR_PICKER],
  ImageUploadContainer: ["imageUploader"],
  MultipleImageUploadContainer: ["MultipleUploaderContainer"],
  AssetContainer: ["imageViewLister"],
  AssetSelectionContainer: ["imageSelectionLister"],
  TextContainer: ["fontPicker", LibraryUIClass.COLOR_PICKER],
  StyleContainer: ["imageViewLister"],
  ButtonWithStyleContainer: ["endScreenButtonStyle", "imageViewLister"],
  AudioUploadContainer: ["audioContainer"],
  AssetUploadLimiter: ["assetUploadLimiter", "imageUploader"],
  ButtonContainer: ["imageViewLister", LibraryUIClass.COLOR_PICKER],
  TimerContainer: ["timerListContainer", "imageViewLister"],
  AudioUploadTuneContainer: ["TimerTuneContainer", "audioContainer"],
  TextInputContainer: ["voiceTriggerTextContainer"],
  NumberInputContainer: ["numberInputContainer"],
  VideoUploadContainer: ["imageUploader"],
  StyleUploadContainer: ["imageViewLister"],
  StyleWithColorContainer: ["imagePreviewer", LibraryUIClass.COLOR_PICKER],
  TextInputWithPreview: [
    "textInputArea",
    "fontPicker",
    LibraryUIClass.COLOR_PICKER,
  ],
  AnimationSelector: ["animationTypeContainer"],
  AssetUploadWithAnimationContainer: ["imageUploader"],
  AssetStyleListContainer: ["imageViewLister"],

  //own action class
  //3d upload
  AssetUploadContainer: ["assetUploadLimiter", "imageUploader"],
  //old
  SectionWrapper: ["sectionWrapperContainer"],
};

export const restrictGLBActionClasses = ["TreasureHuntAssetPreview"];
