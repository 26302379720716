import React, { useEffect, useState } from "react";
import LineDataChart from "./Charts/LineCharts";
import { Box, Grid } from "@mui/material";
import {
  AnalyticsHeader,
  AnalyticsNavContainer,
  ChartContainer,
  ChartDataContainer,
  ChartDisplayContainer,
  DataSetBox,
  DataSetBoxWithGraph,
  ProjectHeader,
  SidePanelContainer,
  TitleContainer,
  MenuContainer,
  MenuList,
  LogoWrapper,
  FilterContainer,
  ChartInfoText,
  DataSetHeader,
  DataSetContent,
  MenuIconText,
  IconSection,
} from "../styled/DashBoardStyle";
import { ReactComponent as AnalyticsIcon } from "../../../../../assets/icons/analytics.svg";
import ViewIcon from "../../../../../assets/icons/views.png";
import DwellIcon from "../../../../../assets/icons/dwell.png";
import bg_image from "../../../../../assets/icons/anabg.png";

import {
  getHeightRatio,
  getWidthRatio,
} from "../../../../../utils/getDimensionalRatio";
import moment from "moment";
import PieChart from "./Charts/PieChart";
import HomeXtendR_LogoColours from "../../../../../assets/icons/HomeXtendR_LogoColours.png";
import { CustomTextField } from "../../../../shared/CustomTextField";
import { getApiServices, postApiServices } from "../../../../../api/api";
import { apiRoutes } from "../../../../../api/apiPath";
import { DASHBOARD_INFO } from "../../../../../constants/constant";
import IconWrapper from "../../../../shared/IconWrapper";
import { dashboardIcons } from "../../../../../constants/icons";
import { AccountView } from "../../../../../styles/navBar";

const DashBoardAnalytics = ({ templates, projectId }) => {
  const [activeMenu, setActiveMenu] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [rawReports, setRawReports] = useState();
  const [userCredentials, setUserCredentials] = useState({});
  const [projectDetails, setProjectDetails] = useState({});

  const [diffInDates, setDiffInDates] = useState(0);
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const currentDate = moment(new Date()).format("YYYY-MM-DD");

  const [selectedGraphData, setSelectedGraphData] = useState({});

  const renderMenus = [
    {
      key: "Views",
      label: "Views",
      icon: dashboardIcons["views"],
    },
    {
      key: "Engagement",
      label: "Engagement",
      icon: dashboardIcons["engagements"],
    },
    {
      key: "Demographics",
      label: "Demographics",
      icon: dashboardIcons["demographics"],
    },
  ];

  const viewsGraphData = async (data) => {
    const pageIndex = data?.metricHeaders?.findIndex(
      (e) => e?.name === "screenPageViews"
    );

    const engagementIndex = data?.metricHeaders?.findIndex(
      (e) => e?.name === "userEngagementDuration"
    );
    const totalViews = data?.totals?.length
      ? data?.totals[0]?.metricValues[pageIndex]?.value
      : 0;

    const totalDwellTime = data?.totals?.length
      ? data?.totals[0]?.metricValues[engagementIndex]?.value
      : 0;

    const averageViews =
      diffInDates > 0 ? totalViews / diffInDates : totalViews / 30;

    const viewsData = await Promise.all(
      data?.rows?.map((item) => {
        let yearString = item["dimensionValues"]?.[0]?.value?.substring(0, 4);
        let monthString = item["dimensionValues"]?.[0]?.value?.substring(4, 6);
        let dateString = item["dimensionValues"]?.[0]?.value?.substring(6, 8);
        let dateFormat = `${yearString}/${monthString}/${dateString}`;
        return {
          name: moment(dateFormat).format("MMM Do YY"),
          views: item["metricValues"]?.[pageIndex]?.value
            ? parseInt(item["metricValues"]?.[pageIndex]?.value)
            : 0,
        };
      })
    );
    return {
      totalViews,
      totalDwellTime,
      averageViews,
      viewsData,
    };
  };

  const handleUserCredentials = async () => {
    try {
      const { data } = await getApiServices(apiRoutes.users);
      setUserCredentials(data);
    } catch (err) {}
  };

  useEffect(() => {
    handleUserCredentials();
  }, []);

  const enagementGraphData = async (data) => {
    const pageIndex = data?.metricHeaders?.findIndex(
      (e) => e?.name === "userEngagementDuration"
    );
    const engagementData = await Promise.all(
      data?.rows?.map((item) => {
        let yearString = item["dimensionValues"]?.[0]?.value?.substring(0, 4);
        let monthString = item["dimensionValues"]?.[0]?.value?.substring(4, 6);
        let dateString = item["dimensionValues"]?.[0]?.value?.substring(6, 8);

        let dateFormat = `${yearString}/${monthString}/${dateString}`;

        return {
          name: moment(dateFormat).format("MMM Do YY"),
          engagements: item["metricValues"]?.[pageIndex]?.value
            ? parseInt(item["metricValues"]?.[pageIndex]?.value)
            : 0,
        };
      })
    );
    const engagementIndex = data?.metricHeaders?.findIndex(
      (e) => e?.name === "userEngagementDuration"
    );
    const totalViews = data?.totals?.length
      ? data?.totals[0]?.metricValues[pageIndex]?.value
      : 0;

    const totalDwellTime = data?.totals?.length
      ? data?.totals[0]?.metricValues[engagementIndex]?.value
      : 0;

    const averageViews =
      diffInDates > 0 ? totalViews / diffInDates : totalViews / 30;
    return {
      totalViews,
      averageViews,
      totalDwellTime,
      engagementData,
    };
  };

  const constructDemoGraphicsChartData = (incomingData) => {
    return incomingData?.map((item) => {
      return {
        name: item?.["dimensionValues"]?.[0]?.value,
        value: item["metricValues"]?.[0]?.value
          ? parseInt(item["metricValues"]?.[0]?.value)
          : 0,
      };
    });
  };

  const fetchGraphData = async (reportType) => {
    try {
      const getPath = `${apiRoutes.reports}`;
      const urlParams = new URLSearchParams(window.location.search);
      const body = {
        projectId: urlParams?.get("projectId") || "",
        reportType,
        dateRanges: [
          {
            startDate: dateRange?.startDate
              ? dateRange?.startDate
              : startOfMonth,
            endDate: dateRange?.endDate ? dateRange?.endDate : currentDate,
          },
        ],
      };
      const { data } = await postApiServices(getPath, body);
      if (activeMenu?.key === "Demographics") {
        setSelectedGraphData({
          ...selectedGraphData,
          Demographics: data || {},
        });
      } else if (activeMenu?.key === "Views") {
        const views = await viewsGraphData(data["Views"]);
        setSelectedGraphData({
          ...selectedGraphData,
          Views: views || {},
        });
      } else if (activeMenu?.key === "Engagement") {
        const engages = await enagementGraphData(data["Engagement"]);
        setSelectedGraphData({
          ...selectedGraphData,
          Engagement: engages || {},
        });
      }
      setRawReports([]);
    } catch (err) {}
  };

  useEffect(() => {
    if (activeMenu?.key) {
      fetchGraphData(activeMenu?.key);
    } else {
      setActiveMenu(renderMenus[0]);
      fetchGraphData(renderMenus[0]?.key);
    }
  }, [activeMenu?.key]);

  useEffect(() => {
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const currentDate = moment(new Date()).format("YYYY-MM-DD");

    let defaultStartDate = dateRange?.startDate
      ? dateRange?.startDate
      : startOfMonth;

    let defaultEndDate = dateRange?.endDate ? dateRange?.endDate : currentDate;

    let date1 = new Date(defaultStartDate);
    let date2 = new Date(defaultEndDate);
    // To calculate the time difference of two dates
    let Difference_In_Time = date2?.getTime() - date1?.getTime();

    // To calculate the no. of days between two dates
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (!isNaN(Difference_In_Days)) {
      setDiffInDates(Difference_In_Days);
    }
    fetchGraphData(activeMenu?.key);
  }, [dateRange]);

  useEffect(() => {
    const projectDetails = templates?.find((e) => e?.id === projectId);
    setProjectDetails(projectDetails);
  }, [projectId]);

  const SidePanel = () => {
    return (
      <SidePanelContainer
        xs={3}
        md={3}
        style={{
          border: "none",
          height: "auto",
          boxShadow: "none",
          background: "#181818",
        }}
      >
        <LogoWrapper src={HomeXtendR_LogoColours} />
        <MenuContainer>
          {renderMenus?.map((item) => {
            const Icon = item?.icon;
            return (
              <MenuIconText>
                <IconSection isSelected={item?.key === activeMenu?.key}>
                  {Icon}
                </IconSection>
                <MenuList
                  isSelected={item?.key === activeMenu?.key}
                  onClick={() => setActiveMenu(item)}
                >
                  {item?.label}
                </MenuList>
              </MenuIconText>
            );
          })}
        </MenuContainer>
      </SidePanelContainer>
    );
  };

  const renderInfoText = {
    Demographics: DASHBOARD_INFO.AGE_GROUP,
    Views: DASHBOARD_INFO.VIEWS,
    Engagement: DASHBOARD_INFO.ENGAGEMENT,
  };

  const demographComponent = () => {
    return (
      <>
        <DataSetBoxWithGraph>
          <PieChart
            key={"genderMetrics"}
            data={
              constructDemoGraphicsChartData(
                selectedGraphData["Demographics"]?.["genderMetrics"]?.rows
              ) || []
            }
          />
          <ChartInfoText style={{ fontSize: "clamp(1rem, 2vmin, 3rem)" }}>
            {"Gender"}
          </ChartInfoText>
        </DataSetBoxWithGraph>
        <DataSetBoxWithGraph>
          <PieChart
            key={"topTenCities"}
            data={
              constructDemoGraphicsChartData(
                selectedGraphData["Demographics"]?.["topTenCities"]?.rows
              ) || []
            }
          />
          <ChartInfoText style={{ fontSize: "clamp(1rem, 2vmin, 3rem)" }}>
            {"Top 10 cities"}
          </ChartInfoText>
        </DataSetBoxWithGraph>
      </>
    );
  };

  const viewsComponent = () => {
    return (
      <>
        <DataSetBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <DataSetHeader>
              <IconWrapper>
                <img
                  src={ViewIcon}
                  alt="views"
                  style={{
                    width: getWidthRatio(34),
                    height: getHeightRatio(23),
                    marginRight: "1rem",
                  }}
                />
              </IconWrapper>
              <span> Avg.Views</span>
            </DataSetHeader>
            <DataSetContent>
              {!isNaN(selectedGraphData["Views"]?.averageViews)
                ? selectedGraphData["Views"]?.averageViews?.toFixed(2)
                : 0}
            </DataSetContent>
          </Box>
        </DataSetBox>
        <DataSetBox
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <DataSetHeader>
              {" "}
              <IconWrapper>
                <img
                  src={DwellIcon}
                  alt="DwellIcon"
                  style={{
                    width: getWidthRatio(23),
                    height: getHeightRatio(28),
                    marginRight: "1rem",
                  }}
                />
              </IconWrapper>
              <span>Avg. Dwell Time</span>
            </DataSetHeader>
            <DataSetContent>
              {!isNaN(selectedGraphData["Views"]?.totalDwellTime)
                ? selectedGraphData["Views"]?.totalDwellTime
                : 0}secs
            </DataSetContent>
          </Box>
        </DataSetBox>
      </>
    );
  };
  const renderMenuComponents = {
    Demographics: demographComponent(),
    Views: viewsComponent(),
    Engagement: viewsComponent(),
  };

  const renderMenuCharts = {
    Demographics: (
      <PieChart
        key={"ageMetrics"}
        data={
          constructDemoGraphicsChartData(
            selectedGraphData["Demographics"]?.["ageMetrics"]?.rows
          ) || []
        }
      />
    ),
    Views: (
      <LineDataChart
        yAxisKey={"views"}
        xAxisKey={"name"}
        data={selectedGraphData["Views"]?.viewsData || []}
      />
    ),
    Engagement: (
      <LineDataChart
        yAxisKey={"engagements"}
        xAxisKey={"name"}
        data={selectedGraphData["Engagement"]?.engagementData || []}
      />
    ),
  };

  const handleDateChange = (name, value) => {
    const temp = { ...dateRange };
    temp[name] = value;
    setDateRange(temp);
  };

  const renderCharts = () => {
    return (
      <ChartDisplayContainer container xs={9} md={9}>
        <Box sx={{ width: "100%" }}>
          <AnalyticsNavContainer sx={{ height: "100%" }}>
            <TitleContainer>
              <AnalyticsIcon
                style={{
                  width: getWidthRatio(38),
                  height: getHeightRatio(20),
                  marginRight: 20,
                }}
              />
              <AnalyticsHeader>Analytics</AnalyticsHeader>
              <ProjectHeader>{projectDetails?.name || ""}</ProjectHeader>
            </TitleContainer>
            <FilterContainer style={{
              width: '20vw'
            }}>
              <CustomTextField
                type="date"
                style={{ 
                  background: "white",
                  padding: '0',
                }}
                name="startDate"
                onChange={(e) =>
                  handleDateChange(e.target.name, e.target.value)
                }
                value={
                  dateRange?.startDate ? dateRange?.startDate : startOfMonth
                }
              />
              <CustomTextField
                type="date"
                name="endDate"
                style={{
                  background: "white",
                  padding: '0',
                }}
                defaultValue={new Date()}
                onChange={(e) =>
                  handleDateChange(e.target.name, e.target.value)
                }
                value={dateRange?.endDate ? dateRange?.endDate : currentDate}
              />
              <AccountView
                style={{
                  height: getHeightRatio(50.63),
                  width: getWidthRatio(52),
                  textTransform: "uppercase",
                  background: "#000000",
                }}
              >
                {userCredentials?.tenant?.name?.slice(0, 1) || "A"}
              </AccountView>
            </FilterContainer>
          </AnalyticsNavContainer>
          <Grid container gap={"10px"} xs={12} md={12} style={{
            top: '55%',
            transform: 'translateY(-50%)',
            position: 'absolute',
            width: '-webkit-fill-available',
          }}>
            <ChartContainer container xs={7.5} md={7.5}>
              {renderMenuCharts[activeMenu?.key]}
              <ChartInfoText>{renderInfoText[activeMenu?.key]}</ChartInfoText>
            </ChartContainer>
            <ChartDataContainer item xs={4} md={4}>
              {renderMenuComponents[activeMenu?.key]}
            </ChartDataContainer>
          </Grid>
        </Box>
      </ChartDisplayContainer>
    );
  };
  return (
    <Grid
    style={{
      backgroundImage: `url(${bg_image})`,
      backgroundSize: 'cover',
    }}
      container
      sx={{ width: getWidthRatio(1615), height: getHeightRatio(910) }}
    >
      {SidePanel()}
      {renderCharts()}
    </Grid>
  );
};

export default DashBoardAnalytics;
