import React, { useEffect } from "react";
import {
  SvgPreviewContainer,
  SvgPreviewer,
  SvgInsideText,
} from "../../styles/sharedComponents";
import { getSourceFileByAssetKey } from "../../utils/getSourceFile";

export function ImagePreviewer({
  selectedProperty,
  selectedSubProperty,
  isLibraryUsage = false,
  colors,
  renderId,
  assetKey,
  retrivedSourceFiles,
  updateRetrivedSourceFiles,
  svgInsideText = "",
  svgInsideTextStyle = {},
  SvgInsideTextComponent,
  style = {},
}) {
  const insertDomElements = (data) => {
    document.getElementById(renderId).innerHTML = data;

    const checkPageSectionColor =
      selectedProperty?.key === "LeaderBoard"
        ? selectedProperty?.pageSections
            ?.find((item) => item?.key === "color")
            ?.elements?.find((item) => item?.key === "color")
        : selectedProperty?.elements?.find((data) => data?.key === "color");

    const getColors = colors ? colors : checkPageSectionColor;

    const specDocs = document.getElementById(renderId);
    const specDocsSVG = specDocs?.getElementsByTagName("svg");

    const setSVGColors = (tagCollections, tag) => {
      const tagKey = tag || "";
      for (let i = 0; i < tagCollections.length; i++) {
        const pathElement = tagCollections[i];
        const reColorTag = pathElement?.getAttribute(tagKey);
        if (reColorTag) {
          pathElement?.setAttribute(tagKey, getColors?.value?.color);
        }
      }
    };

    if (specDocsSVG?.length) {
      for (let i = 0; i < specDocsSVG.length; i++) {
        const element = specDocsSVG[i];
        if (element) {
          try {
            const changeFillTags =
              element?.getElementsByClassName("changeFillColor");

            const changeStrokeTags =
              element?.getElementsByClassName("changeStrokeColor");

            const changeFillStrokeColor = element?.getElementsByClassName(
              "changeFillStrokeColor"
            );

            if (changeFillTags?.length) {
              setSVGColors(changeFillTags || [], "fill");
            }
            if (changeStrokeTags?.length) {
              setSVGColors(changeStrokeTags || [], "stroke");
            }
            if (changeFillStrokeColor?.length) {
              setSVGColors(changeFillStrokeColor || [], "fill");
              setSVGColors(changeFillStrokeColor || [], "stroke");
            }
          } catch (error) {}
        }
      }
    }
  };

  const updateAssetPreview = async () => {
    const checkSourceFile = retrivedSourceFiles[assetKey];
    if (checkSourceFile) {
      insertDomElements(checkSourceFile);
    } else {
      if (assetKey) {
        try {
          const { data } = await getSourceFileByAssetKey(assetKey);
          await updateRetrivedSourceFiles({
            ...retrivedSourceFiles,
            [assetKey]: data,
          });
          if (data) insertDomElements(data);
        } catch (err) {}
      }
    }
  };

  useEffect(() => {
    updateAssetPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty, selectedSubProperty]);

  return (
    <>
      {assetKey ? (
        <>
          <SvgPreviewContainer>
            <SvgPreviewer id={renderId} isLibraryUsage={isLibraryUsage} />
            {SvgInsideTextComponent ? (
              SvgInsideTextComponent
            ) : svgInsideText ? (
              <SvgInsideText textStyles={svgInsideTextStyle} style={style}>
                {svgInsideText}
              </SvgInsideText>
            ) : (
              <></>
            )}
          </SvgPreviewContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
