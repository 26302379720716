import React from "react";
import { playIcon } from "../../constants/icons";
import {
  AudioViewContainer,
  AudioViewLabel,
  PlayIconWrapper,
} from "../../styles/sharedComponents";

export default function AudioViewComponent({ audioDatas }) {
  return (
    <AudioViewContainer>
      <PlayIconWrapper>{playIcon}</PlayIconWrapper>
      <AudioViewLabel>{audioDatas?.name || "Sample"}</AudioViewLabel>
    </AudioViewContainer>
  );
}
