import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { ROUTE_PATHS } from "../routes/routePaths";
import { ROUTES } from "../routes/routes";
import LandingPage from "./pages/landingPage";
import PasswordReset from "./pages/reset-password";
import ErrorPage from "./pages/errorPage";
import { ProtectedRoute } from "./shared/CheckProtectedRoute";

export default function Root() {
  const location = useLocation();
  const cookies = new Cookies();

  const pathname = location?.pathname;
  const auth = cookies.get("jwtToken");

  // useEffect(() => {
  //   if (pathname === ROUTE_PATHS.landingPage || pathname === "/login") {
  //     cookies.remove("jwtToken");
  //   }
  // }, [pathname]);

  const routeMapping = (path, Component, exact, index) => {
    return (
      <Route
        path={path}
        element={
          <ProtectedRoute isAuthenticated={auth}>
            <Component />
          </ProtectedRoute>
        }
        exact={exact}
        key={index}
      />
    );
  };

  return (
    <Routes>
      {ROUTES?.map(({ path, Component, exact }, index) =>
        routeMapping(path, Component, exact, index)
      )}

      <Route
        path={ROUTE_PATHS.landingPage}
        element={<LandingPage />}
        exact={true}
      />

      <Route
        path={ROUTE_PATHS.resetPassWord}
        element={<PasswordReset />}
        exact={true}
      />

      <Route path="*" element={<ErrorPage />} exact={true} />
    </Routes>
  );
}
